import React from 'react'

const LoaderDot = () =>{
    return(
        <div className="loaderdot">
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
        </div>
    )
}

export default LoaderDot