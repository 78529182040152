import { lazy, useContext } from "react";

const NotificationsList = lazy(() => import("./NotificationsList"));
const NotificationDetail = lazy(() => import("./NotificationDetail"));

const notificationsRoutes = [
    {
      path: "/notifications/list",
      component: NotificationsList
    },
    {
      path: "/notifications/detail/:id",
      component: NotificationDetail
    },
  ];
  
  export default notificationsRoutes;