import React,{useState,useEffect,useRef} from "react";
import {InpDataList} from '@gull';
import { useTranslate } from 'react-redux-multilingual'
import { useHistory } from "react-router-dom";
import axios from 'axios'
import swal from "sweetalert2";

const ScanBadgeInventory = (props) => {
    const t =useTranslate()
    const inventoryScanRh = useRef(null)
    const [rhBar,setRhBar]=useState('')
    const [loadingBar,setLoadingBar]=useState(false)
    const [datasCashier,setDatasCashier] = useState([])
    const dataListDrop = useRef('')
    const [datas,setDatas] = useState([])
    const history=useHistory();

    useEffect(function () {
        if(inventoryScanRh.current){
            setTimeout(()=>{
                inventoryScanRh.current.focus()
            },500)
        }
    }, [inventoryScanRh])

    useEffect(function () {//mise à jour des noms caisser et clients si ils sont renseignés et remplissage de a drop liste du cashier
        let initial = []
        props.section.rh.forEach((value)=>{
            initial.push({
                id:value.id,
                name:value.name+' '+value.firstname,
                reference:value.bar
            })
        })
     
        setDatasCashier(initial)
        setDatas(initial)
        
    }, [])
    
    const handleSubmit = async (rhBar) => {
        setLoadingBar(true)
        if(rhBar!=''){
            const response  = await axios.get(process.env.REACT_APP_API_URL+"api/rh/get_rh_inventaire_by_bar/"+rhBar+"/"+props.id
            ).then((response) => {
                if(!response.data.valid){
                    let interms='';
                    var keys = Object.keys(response.data.errors);
                    for(var i = 0; i < keys.length; i++){
                        interms+=response.data.errors[keys[i]].join(',')+','
                    }
                    interms=interms.split(',')
                    let messageError='';
                    interms.forEach(interm=>messageError+=t(interm)+'. ')
                    messageError=messageError.replace('undefined','');
                    swal.fire(messageError, "", "error");
                }else{
                    //on regarde si le rh est bien attribué à cette bins ou inventaire
                    let rhAutorise 
                    if(props.method=='masse'){
                        rhAutorise = response.data.datas.find(value=>value.inventaire_id==props.id)
                    }else{
                        rhAutorise = response.data.datas.find(value=>value.id==props.divisionId)
                    }
                    

                    if(rhAutorise){
                        if(rhAutorise.inventaire_count.length!=0){
                            //si il y a deja eu un comptage sur la bins
                            swal
                            .fire({
                                title: t('confirmSecondCount')+" ?",
                                icon: "warning",
                                type: "question",
                                showCancelButton: true,
                                confirmButtonColor: "#3085d6",
                                cancelButtonColor: "#d33",
                                confirmButtonText: t('btnYes'),
                                cancelButtonText: t('btnNo'),
                            })
                            .then((result) => {
                                if (result.value) {
                                    history.push(props.path+'/'+response.data.rh.id)
                                }else{
                                    props.setShow(false)
                                }
                            });
                        }else{
                            history.push(props.path+'/'+response.data.rh.id)
                        }
                    }else{
                        swal.fire(t('staffNotAttributeToInventory'), "", "error");
                    }
                }
            }).catch((error) => {
                if(error=='end_session') return;
                let messageError
                if(error.response==undefined){
                    messageError='unknowError'
                }
                else if(error.response.status==403){
                    messageError='forbiddenGeneral'
                }
                else {
                    messageError='unknowError'
                }
                swal.fire(t(messageError), "", "error");
            })
        }
        setRhBar('')
        setLoadingBar(false)
    }

    const handleSearch = (e,declenchement) => {
        setRhBar(e.target.value)
        if(e.target.value.length>=declenchement){
            let ajour = datasCashier.filter(value=>value.name.toLowerCase().includes(e.target.value.toLowerCase()));
            setDatas(ajour)
            dataListDrop.current.classList.remove("d-none");
        }
        else{
            dataListDrop.current.classList.add("d-none");
        }        
    }

    const handleCLick = (id,valeur,inpName,reference) => {
        setRhBar(valeur)
        handleSubmit(reference)
        dataListDrop.current.classList.add("d-none");
    }

    
    const handleKeyPress = (e) => {
        if(e.charCode==13){
            handleSubmit(e.target.value)

            setTimeout(()=>{
                inventoryScanRh.current.blur()
            },200)
            dataListDrop.current.classList.add("d-none");
        }
    }
    
    return (
        <div className="form-group position-relative">
            <label>{t('scanBadgeOrSearch')+' :'}</label>
            <InpDataList
                placeholder={t('scanBadgeOrSearch')}
                value={rhBar}
                onClick={handleCLick}
                onChange={handleSearch}
                datas={datas}
                declenchement={2}
                listRef={dataListDrop}
                inputRef={inventoryScanRh}
                inputName="searchCashier"
                className="form-control"
                onKeyPress={handleKeyPress}
            />
            {(loadingBar) ?
                <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm topBarLeftPosition4"></div></span>
            :
                false
            }
        </div>
    );
    

}

export default ScanBadgeInventory;
