import React, {useRef,useEffect} from 'react';
import { useTranslate } from 'react-redux-multilingual'

const ModalScanCarton = (props) => {
    const t = useTranslate();
    const inputRef = useRef(null);

    useEffect(() => {
        if (inputRef.current) {
          inputRef.current.focus(); // Mettre le focus sur l'input
        }
      }, []);
    

      const cancel = () => {
        if(props.showScanCarton[2]) props.showScanCarton[2](false)//c'est bizzare mais ça a été envoyé comme ça, dans un tableau. C'est le setValidButton
        props.setShowScanCarton(false)
    }

    return(
        <div>
            <div className='fs-3 text-center'>{t('addToBoxMsg')}</div>
            <input 
                type="text" 
                placeholder={t('scanBoxHere')} 
                className='form-control mt-4' 
                value={props.cartonBar} 
                onChange={(e)=>props.setCartonBar(e.target.value)} 
                onKeyPress={(e)=>props.onKeyPress(e)}
                ref={inputRef}
            />
            <div className='mt-3 d-flex justify-content-center'>
                <button className="btn btn-danger" onClick={()=>props.validTransfer([...props.showScanCarton,false])} >{t('no')}</button>
            </div>
            <div className='mt-3 d-flex justify-content-center'>
                <button className="btn btn-warning" onClick={()=>cancel()} >{t('btnCancel')}</button>
            </div>
        </div>
    )
}

export default ModalScanCarton;