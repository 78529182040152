import React, {useState} from 'react';
import axios from 'axios';
import { useTranslate } from 'react-redux-multilingual'
import {notificationsType} from 'app/notificationsType';
import {Form,Badge} from "react-bootstrap";
import Moment from 'react-moment';
import { useHistory, useLocation } from "react-router-dom";

const NotifDetailLine = (props) =>{
    const t = useTranslate();
    const history = useHistory();
    const location = useLocation();
    const domain = location.pathname.split('/');

    const updateState = async (notifId,updateVu,name) =>{
        const response = await axios.put(process.env.REACT_APP_API_URL + "api/alert/update_vu/"+notifId+'/'+updateVu
        ).then(response => {
            if(name=='notif'){
                if(domain[1] == 'notifications'){
                    history.push("/notifications/detail/"+props.value.id)
                }else if(domain[3] == 'notifications'){
                    //Dans le cas où on serait sur les page pos, wms
                    history.push('/'+domain[1]+'/'+domain[2]+'/notifications/detail/'+props.value.id)
                }else{
                    //Dans le cas où on serait sur les page erp, administration, user
                    history.push('/'+domain[1]+'/notifications/detail/'+props.value.id)
                }
            }
        }).catch(error => {
            if(error=='end_session') return;
            if(error.response==undefined){
                props.setError('unknowError')
            }
            else if(error.response.status==403){
                props.setError('forbiddenGeneral')
            }
            else {
                props.setError('unknowError')
            }
        });
    }

    const handleChangeState = (notifId,name) =>{
        if(name=='notif'){
            if(props.value.vu==1){
                //Si la notif à déja été lu, on passe sur la page de detail 
                if(domain[1] == 'notifications'){
                    history.push("/notifications/detail/"+props.value.id)
                }else if(domain[3] == 'notifications'){
                    //Dans le cas où on serait sur les page pos, wms
                    history.push('/'+domain[1]+'/'+domain[2]+'/notifications/detail/'+props.value.id)
                }else{
                    //Dans le cas où on serait sur les page erp, administration, user
                    history.push('/'+domain[1]+'/notifications/detail/'+props.value.id)
                }
            }else{
                //Sinon on change son état et on change de page 
                props.setLoadingNotif(true)
                let updateVu = (props.value.vu==1) ? 0 : 1

                let dataNoEdit = props.allNotificationsFiltre.filter(value=>value.id!=notifId)
                let dataToEdit = props.allNotificationsFiltre.find(value=>value.id==notifId)
                dataToEdit.vu = updateVu
            
                dataNoEdit.splice(props.index,0, dataToEdit)
                props.setAllNotificationsFiltre(dataNoEdit)

                updateState(notifId,updateVu,name)
            }
        }else{
            let updateVu = (props.value.vu==1) ? 0 : 1

            let dataNoEdit = props.allNotificationsFiltre.filter(value=>value.id!=notifId)
            let dataToEdit = props.allNotificationsFiltre.find(value=>value.id==notifId)
            dataToEdit.vu = updateVu
        
            dataNoEdit.splice(props.index,0, dataToEdit)
            props.setAllNotificationsFiltre(dataNoEdit)

            updateState(notifId,updateVu,name)
        }
    }

    return (
        <div className="d-flex notifLine" style={{marginBottom:'2px'}}>
            <div className="notification-icon notifIconDetail" style={{backgroundColor:notificationsType[props.value.type].bg}}>
                <i className={`${notificationsType[props.value.type].icon} text-white mr-1`}></i>
            </div>
            <div className="notification-details flex-grow-1 pointer" onClick={()=>handleChangeState(props.value.id,'notif')}>
                <span>
                    {(props.value.user != null) ?
                        <p className="m-0 d-flex align-items-center" style={{fontSize:'0.9em'}}>
                            <Badge bg="dark">
                                {props.value.user.name}
                            </Badge>
                        </p>
                    :
                        false
                    }
                    <p className="m-0 d-flex align-items-center">
                        <span className={props.stateNotif}>{t(props.value.type)}</span>
                        {(!props.value.vu) && <span className='notificationPastille'></span>}
                        <span className="flex-grow-1"></span>
                        <span className="text-small text-muted ml-auto" style={{marginRight:'1rem',fontSize:'0.75em'}}>
                            <Moment format="DD/MM/YYYY">{props.value.created_at}</Moment>
                        </span>
                    </p>
                    <p className={`${props.stateNotif} text-small text-muted m-0`}>
                        {(window.screen.width<=600) ? 
                            (props.description.length<=32) ? props.description : props.description.substring(0,32)+'...'
                        :
                            (props.description.length<=160) ? props.description : props.description.substring(0,37)+'...'
                        }
                    </p>
                </span>
            </div>
            <div >
                <div>Lu</div>
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                    <Form.Check 
                    type="checkbox"
                    checked={props.value.vu}
                    onChange={()=>handleChangeState(props.value.id,'vu')}
                    />
                </Form.Group>
            </div>
        </div>
    )
};

export default NotifDetailLine;