import React from "react";
import { useTranslate } from 'react-redux-multilingual';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';

const  RefreshButton = (props) => {
    const t = useTranslate();

    const handleClick = ()=>{
        props.setReload(Date.now())
        props.setReloading(true)
    }

    return (
        <button type="button" onClick={() => handleClick()} className="btn btn-warning">
            {props.reLoading ?
                <span className="spinner-border spinner-border-sm"></span>
            :
                <span><FontAwesomeIcon icon={faSyncAlt} /></span>
            }
        </button>
    )
}

export default RefreshButton;