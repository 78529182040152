import React, {useState,useContext} from "react";
import { useTranslate } from 'react-redux-multilingual';
import {FormatedPrice,ModalTagPrintForm,ListPrintWms,CarouselImage} from "@gull";
import { IsAuthContext} from 'app/App';
import { useParams } from "react-router-dom";
import { useCookies} from 'react-cookie';
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp, faAngleDown,faEdit } from '@fortawesome/free-solid-svg-icons'; 
import LineZoneContainWms from './CardInfoArticleComp/LineZoneContainWms'
import axios from 'axios'

const CardInfoArticle = (props) =>{
    const t = useTranslate();
    const { wmsid } = useParams();
    const [showListInfo, setShowListInfo]=useState(false)
    const { authParam } = useContext(IsAuthContext)
    const [show,setShow]=useState(false)
    const [isFor,setIsFor]=useState(false)
    const [error,setError]=useState(false)
    const [loadingEdit,setLoadingEdit]=useState(false)
  
    const [value,setValue]=useState(props.article.article.reference)

    let devise = authParam.company.devise;
    let digit = (authParam.company.devise === "XPF") ? 0 : 2;

    const [cookies] = useCookies(['peripherique']);

    let disponibleInShop = Object.keys(props.article.by_section) && Object.entries(props.article.by_section).find(([key,value])=>value.sectionid==wmsid)

    const handleClick = (name) =>{
        if(name=='table'){
            setShowListInfo(!showListInfo)
        }else if(name=='tagPrint'){
            if(cookies.peripherique){
                setShow(!show)
                setIsFor(name)
            }else{
                setError(t('noConfiguredPeripheral'))
            }

        }else if(name=='changePrinter'){
            setShow(!show)
            setIsFor(name)
        }
    }

    const handleChange = (e) =>{
        setValue(e.target.value)
    }

    const editUpcItem = async (itemId) => {
        setLoadingEdit(true)
        const response  = await axios.put(process.env.REACT_APP_API_URL+"api/erp/article/edit/"+itemId,{
            reference:value
        }).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach((interm)=>{
                    let finalMessage=interm.split('/!');
                    if(finalMessage.length==2){
                        messageError+=t(finalMessage[0],{name:finalMessage[1]})+','
                    }
                    else{
                        messageError+=t(interm)+','
                    }
                })
                messageError=messageError.replace('undefined','');
                setError(messageError)
            }
            else{
                props.setArticle({...props.article, article: {...props.article.article, reference: response.data.data.reference}})
                props.setEditUpc(false)
            }
        }).catch((error) => {
            if(error=='end_session') return;
            let messageError = (error.response && error.response.status==403) ? t('forbiddenGeneral') : t('unknowError')
            setError(messageError)
        })
        setLoadingEdit(false)
    }

    const handleKeyPress = (e,itemId) =>{
        if(e.key === 'Enter' ){
            editUpcItem(itemId)
        }
    }

    let directionArrow = (showListInfo) ? faAngleUp : faAngleDown

    let colorQte = (disponibleInShop[1].disponible > 0) ? 'text-success' : (disponibleInShop[1].disponible < 0) ? 'text-danger' : ''

    return(<>
        <Modal size="md" show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>{t('tagPrint')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {(isFor=='tagPrint') ?
                    <ModalTagPrintForm articlePrice={props.article.article.price} articleName={props.article.article.name} articleUpc={props.article.article.reference} cookies={cookies} from='wms'/>
                :
                    <ListPrintWms from='infoArticleWms'/>
                }
            </Modal.Body>
        </Modal>
        {(!show) &&
            <div className="card mt-4">
                <div className="card-body">
                    <h5 className="card-title fs-5">{props.article.article.name}</h5>
                    <div className="mt-2 mb-4">
                        {(props.article.article && props.article.article.image) ?
                            <CarouselImage images={props.article.article.image} />
                        :
                            <div>
                                <img  src="/assets/images/no-image.png" alt="No image" />
                            </div>
                        }
                    </div>
                    <div className="d-flex justify-content-between">
                        {(props.article.article.parent_name!=null) &&
                            <div>
                                <Link className="btn btn-sm btn-dark mb-1" to={"/wms/"+wmsid+"/parent-details/"+props.article.article.id}>
                                    {t('stockParent')}
                                </Link>
                            </div>
                        }
                        <span>
                            <span className="position-relative">
                                {(props.editUpc) ? 
                                    <input 
                                        ref={props.inputRef} 
                                        type="text" 
                                        value={value} 
                                        onChange={(e)=>handleChange(e)} 
                                        onBlur={()=>editUpcItem(props.article.article.id)}
                                        className="form-control form-control-sm"
                                        onKeyPress={(e)=>handleKeyPress(e,props.article.article.id)}
                                    />
                                :
                                    props.article.article.reference
                                }
                            </span>&nbsp;
                            <span className="text-primary">
                                {(props.editUpc) ? 
                                    false
                                :
                                    <FontAwesomeIcon icon={faEdit} onClick={()=>props.setEditUpc(true)}/>
                                }
                            </span> 
                            {(loadingEdit) &&
                                <span className="loaderEditUpc"><span>&nbsp;</span><div className="spinner-border spinner-border-sm mt-2"></div></span>
                            }
                            <div className="text-end"><b>SKU :</b> {props.article.article.sku}</div>
                        </span>
                    </div>

                    <div className="mb-2 d-flex justify-content-between align-items-center fs-5">
                        <div>{t('disponible')+' : '}<strong className={colorQte}>{disponibleInShop[1].disponible}</strong></div>
                        <strong><FormatedPrice langRegion={'fr-FR'} devise={devise} digit={digit} priceToFormat={props.article.article.price} /></strong>
                    </div>
                    
                    <div className="table-responsive">
                        <table
                        id="section_stock_table"
                        className="table"
                        >
                            <thead>
                                <tr>
                                    <th scope="col">{t('zone')}</th>
                                    <th scope="col" style={{textAlign:'right'}}>{t('quantity')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.entries(props.article.by_section).map(([code,value], index)=>{
                                    if(value.sectionid == wmsid){
                                        return(
                                            Object.entries(value.zone).map(([zone, val], ind) =>(
                                                <LineZoneContainWms key={ind} articleid={props.article.article.id} keyName={zone} value={val} zoneid={val.zoneid}/>
                                            ))
                                            
                                        )
                                    }
                                })}
                                
                            </tbody>
                        </table>
                    </div>

                    <div className="table-responsive">
                        <table
                        id="section_stock_table"
                        className="table"
                        >
                            <thead onClick={()=>handleClick('table')}>
                                <tr className="position-relative">
                                    <th scope="col">{t('otherSection')+' '}<FontAwesomeIcon icon={directionArrow} className='fs-5'/></th>
                                    <th scope="col" className="text-end">{t('disponibleMini')+' / '+t('blockedMini')+' / '+t('aVenir')+' / '+'Total'}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.entries(props.article.by_section).map(([code,value], index)=>{
                                    if(value.sectionid != wmsid){
                                        return(
                                            (showListInfo) ?
                                                <tr key={index}>
                                                    <td>{code}</td>
                                                    <td className="text-end">{value.disponible+' / '+value.non_disponible+' / '+value.to+' / '+value.total}</td>
                                                </tr>
                                            :
                                                false
                                            
                                        )
                                    }
                                })}
                                
                            </tbody>
                        </table>
                    </div>
                    {(error) ? <div className="alert alert-danger mt-1 mb-1">{error}</div> : false}
                    <div className="d-flex justify-content-between align-items-center">
                        <button className="btn btn-primary" onClick={()=>handleClick('tagPrint')}>{t('printItemTag')}</button>
                        <span className="card-link text-primary text-center" onClick={()=>handleClick('changePrinter')}>{t('changePrinter')}</span>
                    </div>
                </div>
            </div>
        }
        </>
    )
}

export default CardInfoArticle;