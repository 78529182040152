import { lazy, useContext } from "react";

const Welcome = lazy(() => import("./Welcome"));
const Etiquette = lazy(() => import("./Etiquette"));

const welcomeRoutes = [
    {
      path: "/welcome/welcome",
      component: Welcome
    },
    {
      path: "/welcome/tag",
      component: Etiquette
    },
  ];
  
  export default welcomeRoutes;