import React,{useContext} from "react";
import { useTranslate } from 'react-redux-multilingual'
import { IsAuthContext } from 'app/App';

const RefundButtonOption = (props) =>{
    const t =useTranslate()
    const { authParam } = useContext(IsAuthContext)
    const modulePos = authParam.modules.find(value=>value.name=='pos')

    return(
        <>
            <h5 className="mb-4 text-center fw-bold">{t('chooseRefundMethod')}</h5>
            <div className="d-flex justify-content-around">
                <button className="btn btn-sm btn-info" onClick={()=>props.handleClick('espece')}>
                    {(props.load.espece) ? <div className="spinner-border spinner-border-sm"></div> : t('espece')}
                </button>
                {(modulePos.params.gift_card.active) &&
                    <button className="btn btn-sm btn-info" onClick={()=>props.handleClick('gift_card')}>
                        {(props.load.gift_card) ? <div className="spinner-border spinner-border-sm"></div> : t('giftcard')}
                    </button>
                }
                <button className="btn btn-sm btn-info" onClick={()=>props.handleClick('autre')}>
                    {(props.load.autre) ? <div className="spinner-border spinner-border-sm"></div> : t('other')}
                </button>
            </div>
            <div className="mb-3">
                {(props.optionRefund) ?
                    <form onSubmit={(e)=>props.cancelSaleOrder(e,props.optionRefund,props.optionRefund)}>
                        {(props.optionRefund == 'gift_card') ?
                            <input ref={props.refundGiftcard} autoComplete='off' type="text" className="form-control mt-5" onChange={(e)=>props.handleChange(e,'gift_card')} placeholder={t('scanGiftCard')}/>
                        :
                            <>
                                <textarea ref={props.refundOther} autoComplete='off' className="form-control mt-5" cols="30" rows="5" onChange={(e)=>props.handleChange(e,'autre')} placeholder={t('enterRefundMethod')}></textarea>
                                <button className="btn btn-sm btn-primary mt-3">{t('btnValidate')}</button>
                            </>
                        }
                    </form>
                :
                    false
                }
            </div>
        </>
    )
}

export default RefundButtonOption;