import React, {useState} from "react";
import { useTranslate } from 'react-redux-multilingual';
import { OverlayTrigger, Tooltip} from "react-bootstrap";
import { ControlAccess } from "@gull";
import { Modal} from "react-bootstrap";

// Style import
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';

const  DetailsInventoryDivisionAdminBtn = (props) => {
    const t = useTranslate();
    const [showRestriction,setShowRestriction]=useState(false)

    const handleClick = () => {
        if(props.setModalTitle) props.setModalTitle(props.titleName)
        if(props.module=='pos' && !props.restriction.go){
            setShowRestriction(true)
        }else{
            props.setDivisionIdInfo(props.divisionId)
            props.setDivisionRhInfo(props.rhIds)
            props.setShow(true)
        }
    }

    const callback = () =>{ 
        setShowRestriction(false)
        props.setRestriction({...props.restriction,go:false})
        props.setDivisionIdInfo(props.divisionId)
        props.setDivisionRhInfo(props.rhIds)
        props.setShow(true)
    }

    return (
        <>
            <Modal show={showRestriction} onHide={() => setShowRestriction(false)}>
                <Modal.Header closeButton>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{marginTop:'80px'}}>
                        <ControlAccess section={props.section} restriction={props.restriction} moduleId={props.posid} setRestriction={props.setRestriction} callback={callback} />
                    </div>
                </Modal.Body>
            </Modal>
            <OverlayTrigger
                placement="bottom"
                overlay={
                    <Tooltip id={`tooltip-${props.index}`}>
                        {t('moreDetails')}
                    </Tooltip>
                }
            >
                <span onClick={() => handleClick()} className='pointer text-dark'><FontAwesomeIcon icon={faEye} /></span>
            </OverlayTrigger> 
        </> 
    )
}

export default DetailsInventoryDivisionAdminBtn;