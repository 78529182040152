import React, {useState} from "react";
import { useTranslate } from 'react-redux-multilingual'
import { useHistory, Link } from "react-router-dom";
import swal from "sweetalert2";
import axios from 'axios'
// Style import
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';

const HeadOptionsInventory = (props) =>{
    const t =useTranslate()
    const history = useHistory();
    const [validButton,setValidButton]=useState(false)

    const verifyCompareEnd = () =>{
        let relanceAppel = setInterval(()=> {
            const response = axios.get(process.env.REACT_APP_API_URL+"api/erp/inventaire/get_one/"+props.id
            ).then((response) => {
                if(!response.data.valid){
                    let interms='';
                    var keys = Object.keys(response.data.errors);
                    for(var i = 0; i < keys.length; i++){
                        interms+=response.data.errors[keys[i]].join(',')+','
                    }
                    interms=interms.split(',')
                    let messageError='';
                    interms.forEach(interm=>messageError+=t(interm)+'. ')
                    messageError=messageError.replace('undefined','');
                    swal.fire(messageError, "", "error");

                }else{  
                    if(response.data.data.status==1){
                        clearInterval(relanceAppel)
                        history.push("/erp/inventory/report/"+props.id)
                    }
                }
            }).catch((error) => {
                if(error=='end_session') return;
                let messageError
                if(error.response==undefined){
                    messageError='unknowError'
                }else if(error.response.status==403){
                    messageError='forbiddenGeneral'
                }else {
                    messageError='unknowError'
                }
                swal.fire(t(messageError), "", "error");
            })
        }, 15000);
    }

    const startCompareCount = async () =>{
        setValidButton(true)
        const response = await axios.get(process.env.REACT_APP_API_URL+"api/erp/inventaire/compare_with_stock/"+props.id
        ).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach(interm=>messageError+=t(interm)+'. ')
                messageError=messageError.replace('undefined','');
                swal.fire(messageError, "", "error");
            }else{  
                props.setWait(true)
                verifyCompareEnd()
                setValidButton(false)
            }
        }).catch((error) => {
            if(error=='end_session') return;
            let messageError
            if(error.response==undefined){
                messageError='unknowError'
            }else if(error.response.status==403){
                messageError='forbiddenGeneral'
            }else {
                messageError='unknowError'
            }
            swal.fire(t(messageError), "", "error");
        })
        setValidButton(false)
    }

    const handleClick = (name) =>{
        if(name=='compare'){
            startCompareCount()
            
        }
    }

    return(<span>
        {t(props.title)}&nbsp;
        {(props.btnValid) ?
            <>
                <button disabled={props.zoneWithBins || Object.keys(props.itemsScannedList).length==0} className="btn btn-sm btn-primary" onClick={()=>props.validateInventory()}>
                    {props.validButton ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : t('validateCount')}
                </button>&nbsp;
            </>
        :
            false
        }
        {(props.btnReturn) ?
            <>
                <Link 
                    className="btn btn-sm btn-warning"
                    to={props.module=='pos' ? 
                        "/pos/"+props.posid+"/inventory/"+props.type+"/"+props.id+'/'+props.zoneid : 
                        "/erp/inventory/"+props.type+"/"+props.id+'/'+props.zoneid}
                    >
                        {t('returnInventory')}
                </Link>&nbsp;
            </>
        :
            false
        }
        {(props.btnReturnHome) ?
            <>
                <Link 
                    className="btn btn-sm btn-success"
                    to={props.module=='pos' ? 
                        "/pos/"+props.posid+"/inventory" : 
                        "/erp/inventory"}
                    >
                        {t('returnInventoryHome')}
                </Link>&nbsp;
            </>
        :
            false
        }
        {(props.btnRefresh) ?
            <>
                <button className="btn btn-sm btn-light" onClick={()=>props.setRefreshPage(Date.now())}>
                    {<FontAwesomeIcon icon={faSyncAlt} />}
                </button>&nbsp;
            </>
        :
            false
        }
        {(props.inventoryData && props.inventoryData.inventaire_division.length == props.finishedDivision.length && props.inventoryData.status==0 && props.module=='erp') ?
            <>
                <button disabled={props.inventoryData.wait==1} className="btn btn-sm btn-primary" onClick={()=>handleClick('compare')}>
                    {validButton ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : t('compareInventoryToStock')}
                </button>
            </>
        :
            false
        }
        {(props.inventoryData && props.inventoryData.status==1 && props.inventoryData.wait!=2 && props.module=='erp') ?
            <>
                <button disabled={props.inventoryData.wait==1} className="btn btn-sm btn-primary" onClick={()=>history.push("/erp/inventory/report/"+props.id)}>
                    {t('seeInventoryReport')}
                </button>
            </>
        :
            false
        }
    </span>
        
    )
}

export default HeadOptionsInventory;