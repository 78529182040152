import React, {useState,useEffect} from "react";
import { useTranslate } from 'react-redux-multilingual'
import swal from "sweetalert2";
import axios from 'axios'
import {ScanBadgeInventory} from '@gull'
import { Modal } from "react-bootstrap";

const useLoad = (sectionId) => {
    const t = useTranslate()
    const [loading,setLoading]=useState(true)
    const [section,setSection]=useState(false)
    
    useEffect(function () {
        (async function() {
            const response = await axios.get(process.env.REACT_APP_API_URL+"api/section/"+sectionId
            ).then((response) => {
                if(response.data.rh==null || response.data.rh.length==0){
                    swal.fire(t('noRhForSection'), "", "error");
                }
                else{
                    setSection(response.data)
                }
            }).catch((error) => {
                if(error=='end_session') return;
                setLoading(false)
                swal.fire(t('unknowError'), "", "error");
            })
            setLoading(false)
        })()
    }, [])

    return [loading,section]
}

const ButtonCountMasse = (props) => {
    const t = useTranslate()
    const [show,setShow]=useState(false)
    const [loading,section]=useLoad(props.sectionId)

    const handleClick = () => {
        setShow(true)
        
    }

    return(
        <div>
            <Modal size="md" show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Staff</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {(!loading) ? 
                        <ScanBadgeInventory path={props.path} section={section} id={props.id} setShow={setShow} method='masse'/>
                    :
                        <span className="d-flex justify-content-center"><div className="spinner-border spinner-border-sm"></div></span>
                    }
                </Modal.Body>
            </Modal>
            <button 
                className="btn btn-sm btn-primary mb-2" 
                onClick={()=>handleClick()}
            >
                {t('startCounting')}
            </button>
        </div>
    )
}

export default ButtonCountMasse;
