import React, {useState,useEffect} from 'react';
import {InputSearchClient} from '@gull'
import { useTranslate } from 'react-redux-multilingual'
import { useForm } from "react-hook-form";
import axios from 'axios';
import swal from "sweetalert2";

const FacturationCreateDossier = (props) => {
   const t = useTranslate();
   const { register, formState: { errors }, handleSubmit, reset, setValue } = useForm();
   const [customerId,setCustomerId] = useState(false)
   const [validButton,setValidButton] = useState(false)
   const [disabled,setDisabled] = useState(false)

   useEffect(function () {
    if(props.clientFacture){ 
        //Exécuté depuis le détail d'un document 
        setCustomerId(props.clientFacture.id) //On renseigne le client du document à éditer
        if(props.allInfos.other_company_id!=null){
            //On renseigne la company lié au document 
            setValue('other_company_id',props.allInfos.other_company_id)
        }
        setDisabled(true)
    }
}, [])

    const onSubmit = async (data) => {
        setValidButton(true)
        let obj = {}
        Object.entries(data).map(([key,value])=>{
            if(value!='') obj[key]=value
        })
        obj["client_id"]=customerId

        const response  = await axios.post(process.env.REACT_APP_API_URL+"api/facture/dossier_b2b/create", obj 
        ).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach(interm=>messageError+=t(interm)+'. ')
                messageError=messageError.replace('undefined','');
                swal.fire(messageError, "", "error");
                reset()
                
            }else{ 
                props.callbackFolderLink(response.data.data)
                setCustomerId(false)
                reset()
            }
        }).catch((error) => {
            if(error=='end_session') return;
            reset()
            let messageError
            if(error.response==undefined){
                messageError='unknowError'
            }
            else if(error.response.status==403){
                messageError='forbiddenGeneral'
            }
            else {
                messageError='unknowError'
            }
            swal.fire(t(messageError), "", "error");
        })
        setValidButton(false)
    };

   return(
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className='d-flex justify-content-center flex-wrap'>
                <div className="form-group" style={{width:props.size}}>
                    <label>{t('selectCustomer')+'*'}</label>
                    <InputSearchClient 
                        setClient={setCustomerId}
                        changeDossierClient={props.clientFacture}
                    />
                </div>
                <div className="form-group" style={{width:props.size}}>
                    <label>{t('docName')+'*'}</label>
                    <input type="text" className='form-control' {...register('name',{required: true})}/>
                    {errors.name?.type === 'required' && (
                        <div className="text-danger mt-1 ml-2">
                            {t('fieldRequired')}
                        </div>
                    )}
                </div>
                <div className="form-group" style={{width:props.size}}>
                    <label>Memo</label>
                    <textarea rows="5" className='form-control' {...register('memo')}></textarea>
                </div>
                {props.company.hasOwnProperty('other_company') && props.company.other_company.length!=0 ?
                    <div className="form-group" style={{width:props.size}}>
                        <label>{t('company')}</label>
                        <select disabled={disabled} className='form-control' {...register('other_company_id')}>
                            <option value="">{props.company.name}</option>
                            {props.company.other_company.map(value=>{
                                return(
                                    <option key={value.id} value={value.id}>{value.name}</option>
                                )
                            })}
                        </select>
                    </div>
                :
                    false
                }
                <div style={{width:props.size}}>
                    <button disabled={validButton || !customerId} className="btn btn-primary mt-3">
                        {validButton ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : t('btnValidate')}
                    </button>
                </div>
            </div>
       </form>
   )
}

export default FacturationCreateDossier;