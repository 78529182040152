import React,{useState,useRef} from "react";
import { InpDataList } from "@gull";
import { useTranslate } from 'react-redux-multilingual'
import axios from 'axios'
import swal from "sweetalert2";

const useSearchItem = () => {
    const [loading,setLoading]=useState(false)
    const [listPromo,setListPromo]=useState([])
    const [datas,setDatas] = useState(listPromo)
    const searchInput = useRef('')
    const t =useTranslate()

    const loadPromos = async (inputValue) => {
        setLoading(true)
        const response = await axios.get(process.env.REACT_APP_API_URL+"api/erp/promotion/like_name/"+inputValue
        ).then((response) => {
            setLoading(false)
            setListPromo(response.data.datas)
            let ajour = response.data.datas.filter((value)=>value.name.replace(/\u00A0/g,' ').toLowerCase().includes(searchInput.current.value.replace(/\u00A0/g,' ').toLowerCase()))
            setDatas(ajour)
        }).catch((error) => {
            if(error=='end_session') return;
            setLoading(false)
            swal.fire(t('unknowError'), "", "error");
        })
    }

    return [loading,loadPromos,listPromo,datas,setDatas,searchInput]
}

const ModalAddPromoToItem = (props) => {
    const t =useTranslate()
    const [loading,loadPromos,listPromo,datas,setDatas,searchInput]=useSearchItem()
    const [searchValueName,setSearchValueName]=useState(false)
    const [loadingMaj,setLoadingMaj]=useState(false)
    const [lastSearch,setLastSearch]=useState() 
    
    const dataListDrop = useRef('')

    const handleSearch = (e,declenchement) => {
        setSearchValueName(e.target.value)
        if(e.target.value.length>=declenchement){
            dataListDrop.current.classList.remove("d-none");

            if(!lastSearch || e.target.value.substring(0, declenchement) != lastSearch.substring(0, declenchement)){
                loadPromos(e.target.value.substring(0, declenchement))
                setLastSearch(e.target.value.substring(0, declenchement))
            }
        }else{
            dataListDrop.current.classList.add("d-none");
            setLastSearch(false)
        }
        if(e.target.value.length!=declenchement){
            let ajour = listPromo.filter((value)=>value.name.toLowerCase().includes(e.target.value.toLowerCase()))
            setDatas(ajour)
        }

            //Ancien code pour la gestion de la recherche
        // if(e.target.value.length>=declenchement){
        //     dataListDrop.current.classList.remove("d-none");
        // }
        // else{
        //     dataListDrop.current.classList.add("d-none");
        // }
        // if(e.target.value.length==declenchement){
        //     loadPromos(e.target.value)
        // }
        // else{
        //     let ajour = listPromo.filter((value)=>value.name.toLowerCase().includes(e.target.value.toLowerCase()))
        //     setDatas(ajour)
        // }
    }

    const handleCLick = async (id,label,inpName,reference) => {
        setLoadingMaj(true)
        const response = await axios.put(process.env.REACT_APP_API_URL+"api/erp/article/add_promotion/"+props.articleId,{
            promotion:id

        }).then((response) => {
            props.setCurrentPromo(response.data.data.current_promotion)
            props.setIncomingPromo(response.data.data.all_current_and_incoming_promotion) 
            props.setModalAddPromotion(false)
            setLoadingMaj(false)
        }).catch((error) => {
            if(error=='end_session') return;
            swal.fire(t('unknowError'), "", "error");
            setLoadingMaj(false)
        })
    }

    const handleBlur = () => {
        setSearchValueName('')
        dataListDrop.current.classList.add("d-none");
    }

    return(<>
        <div className="form-group">
            <InpDataList
                placeholder={t('addToPromotion')+'...'}
                value={searchValueName}
                onClick={handleCLick}
                onChange={handleSearch}
                datas={datas}
                declenchement={4}
                listRef={dataListDrop}
                inputRef={searchInput}
                onBlur={handleBlur}
                inputName="searchPromo"
            />
            {(loading) &&
                <span className='spinnerSearchStockPos'><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span>
            }
        </div>
        {loadingMaj ?
            <div className="d-flex justify-content-center align-items-center mt-3">
                <span>{t('additionPromo')}</span>&nbsp;
                <div className="spinner-grow text-dark loadingDotSize" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
                <div className="spinner-grow text-dark loadingDotSize" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
                <div className="spinner-grow text-dark loadingDotSize" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        : 
            false
        }
        
        </>
    )
}

export default ModalAddPromoToItem;