export function getFirstDayOfWeek(d,week) {//Fonction pour connaitre le lundi de la semaine derniere, semaine en cours et semaine prochaine 
    // clone date object, so we don't mutate it
    let date = new Date(d);
    let day = date.getDay(); // get day of week 
    let dayLastWeek = day + 7 // get day of last week
    let dayNextWeek = day - 7 // get day of next week

    let dayCompare = (week=='now') ? day : (week=='last') ? dayLastWeek : dayNextWeek
    
    // day of month - day of week + (-6 if Sunday), otherwise +1
    let diff = date.getDate() - +dayCompare + (+dayCompare === 0 ? -6 : 1);
    
    return new Date(date.setDate(diff));
}

export function getLastDayOfWeek(d,week) {//Fonction pour connaitre le dernier jour de la semaine derniere, semaine en cours et semaine prochaine 
    // clone date object, so we don't mutate it
    let date = new Date(d);
    let day = date.getDay(); // get last day of week  
    let dayLastWeek = day + 7 // get last day of last week
    let dayNextWeek = day - 7 // get last day of next week

    let dayCompare = (week=='now') ? day : (week=='last') ? dayLastWeek : dayNextWeek
    
    // day of month - day of week (-6 if Sunday), otherwise +1
    let diff = date.getDate() - +dayCompare + (+dayCompare === 0 ? 0 : 7);
    
    return new Date(date.setDate(diff));
}

export function getDaysMonth(jour,mois) {
    let date = new Date();

    let day
    switch (mois) {
        case 'now':
            if(jour=='begin'){
                //premier jour du mois en cours
                day = new Date(date.getFullYear(), date.getMonth(), 1)
            }else{
                //dernier jour du mois en cours
                day = new Date(date.getFullYear(), date.getMonth() + 1, 0)
            }
        break;
        case 'last':
            if(jour=='begin'){
                //premier jour du mois dernier
                day = new Date(date.getFullYear(), date.getMonth() - 1, 1)
            }else{
                //dernier jour du mois dernier
                day = new Date(date.getFullYear(), date.getMonth(), 0)
            }
        break;
        case 'next':
            if(jour=='begin'){
                //premier jour du mois suivant
                day = new Date(date.getFullYear(), date.getMonth() + 1, 1)
            }else{
                //dernier jour du mois suivant
                day = new Date(date.getFullYear(), date.getMonth()+ 2, 0)
            }
        break;
    }    
    return day;
}

export function getToday() {
    const today = new Date(); // Date d'aujourd'hui
    
    return today;
}

export function getYesterday() {
    const yesterday = new Date(); // Date d'aujourd'hui
    yesterday.setDate(yesterday.getDate() - 1);
    
    return yesterday;
}

export function getfirstDayCurrentYear() {
    const today = new Date(); // Date d'aujourd'hui
    const currentYear = today.getFullYear(); // Année en cours

    // Formater la date au format "YYYY-MM-DD"
    const year = String(currentYear);
    const month = '01';
    const day = '01';
    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
    
}

export function getFirstDayLastYearDates() {
    const today = new Date(); // Date d'aujourd'hui
    const lastYear = today.getFullYear() - 1; // Année précédente

    // Formater la date au format "YYYY-MM-DD"
    const year = String(lastYear);
    const month = '01';
    const day = '01';
    const firstDayOfYear = `${year}-${month}-${day}`;
    
    return firstDayOfYear;
}

export function getLastDayLastYearDates() {
    const today = new Date(); // Date d'aujourd'hui
    const lastYear = today.getFullYear() - 1; // Année précédente

    // Formater la date au format "YYYY-MM-DD"
    const year = String(lastYear);
    const month = '12';
    const day = '31';
    const lastDayOfYear = `${year}-${month}-${day}`;
    
    return lastDayOfYear;
}