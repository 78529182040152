import React, {useState} from "react";
import { useTranslate } from 'react-redux-multilingual'
import axios from 'axios'
import swal from "sweetalert2";

const SendTestEmail = (props) => {
    const t = useTranslate();
    const [validButton,setValidButton]=useState(false) 
    const [emailTest,setEmailTest] = useState('')
    const [success,setSuccess] = useState(false)

    const handleChange = (e) => {
        setEmailTest(e.target.value)
    }

    const onSubmit = async (data) => {
        setValidButton(true)

        const response  = await axios.get(process.env.REACT_APP_API_URL+'api/mail/mail_test/'+props.configEmailId+'/'+emailTest
        ).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach((interm)=>{
                    let finalMessage=interm.split('/!');
                    if(finalMessage.length==2){
                        messageError+=t(finalMessage[0],{name:finalMessage[1]})+','
                    }
                    else{
                        messageError+=t(interm)+','
                    }
                })
                messageError=messageError.replace('undefined','');
                swal.fire(messageError, "", "error");
            }
            else{
                setEmailTest('')
                setSuccess(true)
            }
        }).catch((error) => {
            if(error=='end_session') return;
            let messageError = (error.response && error.response.status==403) ? t('forbiddenGeneral') : t('unknowError')
            swal.fire(messageError, "", "error");
        })
        setValidButton(false)
        setTimeout(()=>{
            setSuccess(false)
        },1000)
    }

    return(<>
        <label className="mt-4">{t('sendTestEmail')}</label>
        <div className="input-group mt-1">
            <input disabled={validButton} value={emailTest} type="email" className="form-control" placeholder={t('emailAdress')} onChange={(e)=>handleChange(e,'test')}/>
            <div className="input-group-append">
                <button disabled={emailTest=='' || validButton} className="btn btn-success" type="button" onClick={()=>onSubmit()}>
                    {validButton ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : t('send')}
                </button>
            </div>
        </div> 
        {(success) ? <div className="alert alert-success mt-3">{t('successOperation')}</div> : false}
    </>
    )
}

export default SendTestEmail;