import React, { useState } from 'react';
import { useTranslate } from 'react-redux-multilingual'
import { useForm } from "react-hook-form";
import axios from 'axios'
import swal from "sweetalert2";

const CreateFavorite = (props) =>{
    const t = useTranslate();
    const [validButton,setValidButton]=useState(false) 
    const { register, formState: { errors }, handleSubmit, reset } = useForm();
    
    const onSubmit = async (data) =>{
        setValidButton(true)
        const response  = await axios.post(process.env.REACT_APP_API_URL+"api/filtre/create",{
            page_name:props.tableName,
            filtre_name:data.filtre_name,
            filtre:props.sendData,
        }).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach((interm)=>{
                    let finalMessage=interm.split('/!');
                    if(finalMessage.length==2){
                        messageError+=t(finalMessage[0],{name:finalMessage[1]})+','
                    }
                    else{
                        messageError+=t(interm)+','
                    }
                })
                messageError=messageError.replace('undefined','');
                swal.fire(messageError);
            }
            else{
                props.setShow(false)
                props.setToast(true)
                reset()
            }
        }).catch((error) => {
            if(error=='end_session') return;
            let messageError = (error.response && error.response.status==403) ? t('forbiddenGeneral') : t('unknowError')
            swal.fire(messageError, "", "error");
        })
        setValidButton(false)
    }

    return (<>
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group">
                <label>{t('favoriteName')}</label>
                <input disabled={validButton} type="text" className="form-control" {...register('filtre_name',{required: true,minLength:2,maxLength:191})}/>
                {errors.firstname?.type === 'required' && (
                <div className="text-danger mt-1 ml-2">
                    {t('nameRequired')}
                </div>
                )}
                {errors.firstname?.type === 'minLength' && (
                <div className="text-danger mt-1 ml-2">
                    {t('nameMin2')}
                </div>
                )}
                {errors.firstname?.type === 'maxLength' && (
                <div className="text-danger mt-1 ml-2">
                    {t('pageNameMax191')}
                </div>
                )}
            </div>
            <button disabled={validButton} className="btn btn-primary mt-3">
                {validButton ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : t('btnValidate')}
            </button>
        </form>
        </>
    )
};

export default CreateFavorite;