import React, { useState } from 'react';
import {FormatedPrice} from '@gull'
import { useTranslate } from 'react-redux-multilingual'

const StatisticSaleTable = (props) =>{
    const t = useTranslate();

    return (
        <div className="table-responsive">
            <table
            id="stock_table"
            className="table"
            >
                <thead>
                    <tr>
                        <th scope="col">{t('shops')}</th>
                        <th scope="col">{t('priceHt')}</th>
                        <th scope="col">{t('priceTtc')}</th>
                        {(props.from=='erp') &&
                            <>
                                <th scope="col">{t('grossMargin')}</th>
                                <th scope="col">{t('margePercent')}</th>
                                <th scope="col">{t('prmp')}</th>
                            </>
                        }
                        <th scope="col">{t('nbSales')}</th>
                        <th scope="col">{t('qteSold')}</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(props.statisticDetail).length!=0 ?
                        <>
                            {Object.entries(props.statisticDetail).map(([shopName, value], ind) =>{
                                if(shopName!='total'){
                                    return(
                                        <tr key={ind}>
                                            <td>{shopName}</td>
                                            <td><FormatedPrice langRegion={'fr-FR'} devise={props.devise} digit={props.digit} priceToFormat={value.ht} /></td>
                                            <td><FormatedPrice langRegion={'fr-FR'} devise={props.devise} digit={props.digit} priceToFormat={value.ttc} /></td>
                                            {(props.from=='erp') &&
                                                <>
                                                    <td><FormatedPrice langRegion={'fr-FR'} devise={props.devise} digit={props.digit} priceToFormat={value.marge_brutte} /></td>
                                                    <td>{(value.marge_brutte_percent!=null) ? value.marge_brutte_percent+'%' : '0%'}</td>
                                                    <td>{(value.prmp!=null) ? value.prmp : 0}</td>
                                                </>
                                            }
                                            <td>{value.nbr_vente}</td>
                                            <td>{value.quantity}</td>
                                        </tr>
                                    )
                                }})
                            }
                            <tr>
                                <td>Total :</td>
                                <td><FormatedPrice langRegion={'fr-FR'} devise={props.devise} digit={props.digit} priceToFormat={props.statisticDetail.total.ht} /></td>
                                <td><FormatedPrice langRegion={'fr-FR'} devise={props.devise} digit={props.digit} priceToFormat={props.statisticDetail.total.ttc} /></td>
                                {(props.from=='erp') &&
                                    <>
                                        <td><FormatedPrice langRegion={'fr-FR'} devise={props.devise} digit={props.digit} priceToFormat={props.statisticDetail.total.marge_brutte} /></td>
                                        <td>{(props.statisticDetail.total.marge_brutte_percent!=null) ? props.statisticDetail.total.marge_brutte_percent+'%' : '0%'}</td>
                                        <td>{(props.statisticDetail.total.prmp!=null) ? props.statisticDetail.total.prmp : 0}</td>
                                    </>
                                }
                                <td>{props.statisticDetail.total.nbr_vente}</td>
                                <td>{props.statisticDetail.total.quantity}</td>
                            </tr>  
                        </>  
                    :
                        <tr>
                            <td>{t('tableNoData')}</td>
                            <td></td>
                            <td></td>
                            {(props.from=='erp') &&
                                <>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </>
                            }
                            <td></td>
                            <td></td>
                        </tr> 
                    }    
                </tbody>
            </table>
        </div>
    )
};

export default StatisticSaleTable;