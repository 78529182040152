import React,{useState} from "react";
import { useTranslate } from 'react-redux-multilingual'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus,faMinus} from '@fortawesome/free-solid-svg-icons'
import {CarouselImage} from '@gull'
import { Modal } from "react-bootstrap";

const LineItemToTransform = (props) =>{
    const t =useTranslate()
    const [errorAddQte,setErrorAddQte]=useState(false) 
    const [showCarousel,setShowCarousel]=useState(false) 
    
    const handleClick = (name) =>{
        if(name=='plus'){
            if(props.value.quantity<props.initialInfo.quantity){
                let currentItem = props.articleToList[props.value.id]
                currentItem.quantity = currentItem.quantity = props.value.quantity+1
                props.setArticleToList({...props.articleToList,[props.value.id]:currentItem})
                setErrorAddQte(false)
            }else{
                setErrorAddQte(t('qteReached'))
            }
        }else if(name=='minus'){
            if(props.value.quantity>=1){
                let currentItem = props.articleToList[props.value.id]
                currentItem.quantity = currentItem.quantity = props.value.quantity-1
                props.setArticleToList({...props.articleToList,[props.value.id]:currentItem})
                setErrorAddQte(false)
            }else{
                setErrorAddQte(t('quotaMin'))
            }
        }
    }

    return(
        <>
            <Modal show={showCarousel} onHide={()=>setShowCarousel()}>
                <Modal.Header closeButton>
                    <Modal.Title>{props.value.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {(props.value && props.value.image) ?
                        <CarouselImage images={props.value.image} />
                    :
                        <div></div>
                    }
                </Modal.Body>
            </Modal>
            <div className={props.style}>
                <div className="row">
                    <div className="col-8">
                        <strong className="fsArticlePos">{props.value.name}</strong> 
                    </div>
                    <div className="col-4">
                        {(props.value && props.value.image) ?
                            <div className='text-end mb-1' style={{maxHeight:'60px'}}>
                                <img className="pointer" style={{maxHeight:'50px', maxWidth:'70px'}} src={props.value.image.split(';')[0]} alt={props.value.name} onClick={()=>setShowCarousel(true)}/>
                            </div>
                        :
                            <div className='text-end mb-1' style={{maxHeight:'60px'}}>
                                <img style={{maxHeight:'50px', maxWidth:'70px'}} src="/assets/images/no-image.png" alt="No image" />
                            </div>
                        }
                    </div>
                </div>
                <div className="row marginLeftArticle">
                    <div className="col">
                        <div className="row">
                            <div className="col-3">
                                <strong>{'x'+props.value.quantity}</strong>
                            </div>
                            <div className="col-9 text-end">
                                <button className="btn btn-success btn-sm sizeBtnPos" onClick={()=>handleClick('plus')} ><FontAwesomeIcon icon={faPlus} /></button>&nbsp;
                                <button className="btn btn-danger btn-sm sizeBtnPos" onClick={()=>handleClick('minus')} ><FontAwesomeIcon icon={faMinus} /></button>&nbsp;&nbsp;
                            </div>
                        </div>
                        {(errorAddQte) && (
                            <div className="row text-danger mt-1">
                                {errorAddQte}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default LineItemToTransform;