import React, { useState,useEffect,useContext} from 'react';
import { useTranslate } from 'react-redux-multilingual'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowsAltH,faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import {Badge} from "react-bootstrap";
import axios from 'axios'
import swal from "sweetalert2";
import { IsAuthContext } from 'app/App';
import Clocking from 'app/GullLayout/Layout3/Layout3HeaderComp/Clocking';

const RuleLineItems = (props) =>{
    const t = useTranslate();
    const { authParam } = useContext(IsAuthContext);
    const [validButton,setValidButton]=useState({
        create:false,
        delete:false
    }) 
    const [valueSelect,setValueSelect]=useState({
        updateData:'',
        ruleData:''
    }) 
    const [alreadySelected,setAlreadySelected]=useState([]) 

    let activeB2c = (!authParam.company.advanceparams.hasOwnProperty('b2c_price') || authParam.company.advanceparams.b2c_price) ? true : false
    let activeB2b = (authParam.company.advanceparams.hasOwnProperty('b2b_price') && authParam.company.advanceparams.b2b_price) ? true : false

    useEffect(function () {
        setValueSelect({
            updateData:props.value.name,
            ruleData:props.value.type_maj
        })

        //On crée un arr avec tous les champs à mettre à jour qui ont deja une règle de mise à jour pour ne pas pouvoir le resélectionner 
        let selectedArr = []

        props.allUpdateRules.map(item=>{
            selectedArr.push(item.name)
        })
        setAlreadySelected(selectedArr)

    }, [props.allUpdateRules])

    const handleChange = (e,name) =>{
        if(name=='updateData' && alreadySelected.includes(e.target.value)){
            swal.fire(t('ruleUpDateAlreadyAssigned'), "", "error");
        }else{
            setValueSelect({...valueSelect,[name]:e.target.value})
        }
    }

    const handleSubmit = async () =>{ 
        setValidButton({...validButton,create:true})

        //si l'id == newLine, cela veut dire que c'est un ajout et non une mise à jour
        let ruleId = (props.value.id=='newLine') ? '' : '/'+props.value.id
        let axiosAction = (props.value.id=='newLine') ? axios.post : axios.put

        if(valueSelect.updateData!='' && valueSelect.ruleData!=''){
            const response  = await axiosAction(process.env.REACT_APP_API_URL+"api/bulk_maj"+ruleId, {
                name: valueSelect.updateData,
                type_maj:valueSelect.ruleData
            }).then((response) => {
                if(!response.data.valid){
                    let interms='';
                    var keys = Object.keys(response.data.errors);
                    for(var i = 0; i < keys.length; i++){
                        interms+=response.data.errors[keys[i]].join(',')+','
                    }
                    interms=interms.split(',')
                    let messageError='';
                    interms.forEach((interm)=>{
                        let finalMessage=interm.split('/!');
                        if(finalMessage.length==2){
                            messageError+=t(finalMessage[0],{name:finalMessage[1]})+','
                        }
                        else{
                            messageError+=t(interm)+','
                        }
                    })
                    messageError=messageError.replace('undefined','');
                    swal.fire(messageError, "", "error");
                }
                else{
                    if(props.value.id=='newLine'){
                        let arrdata = props.allUpdateRules.filter(item=>item.id!='newLine')
                        props.setAllUpdateRules([response.data.datas,...arrdata])
                        props.setDisabled(false)
                    }
                }
            }).catch((error) => {
                if(error=='end_session') return;
                let messageError = (error.response && error.response.status==403) ? t('forbiddenGeneral') : t('unknowError')
                swal.fire(messageError, "", "error");
            })
            setValidButton({...validButton,create:false})
        }else{
            swal.fire(t('allFieldsRequired'), "", "error");
            setValidButton({...validButton,create:false})
        }
    }

    const deleteFunc = async () =>{
        setValidButton({...validButton,delete:true})
        const response  = await axios.delete(process.env.REACT_APP_API_URL+"api/bulk_maj/"+props.value.id
        ).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach((interm)=>{
                    let finalMessage=interm.split('/!');
                    if(finalMessage.length==2){
                        messageError+=t(finalMessage[0],{name:finalMessage[1]})+','
                    }
                    else{
                        messageError+=t(interm)+','
                    }
                })
                messageError=messageError.replace('undefined','');
                swal.fire(messageError, "", "error");
            }
            else{
                let arrdata = props.allUpdateRules.filter(item=>item.id!=props.value.id)
                props.setAllUpdateRules(arrdata)
            }
        }).catch((error) => {
            if(error=='end_session') return;
            let messageError = (error.response && error.response.status==403) ? t('forbiddenGeneral') : t('unknowError')
            swal.fire(messageError, "", "error");
        })
        setValidButton({...validButton,delete:false})
    }
    
    const deleteRuleLine = () =>{
        if(props.value.id=='newLine'){
            let arrdata = props.allUpdateRules.filter(item=>item.id!='newLine')
            props.setAllUpdateRules(arrdata)
            props.setDisabled(false)
        }else{
            swal
            .fire({
                title: t('confirmDeleteDivers')+" ?",
                icon: "warning",
                type: "question",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: t('btnYes'),
                cancelButtonText: t('btnNo'),
            })
            .then((result) => {
                if (result.value) {
                    deleteFunc()
                }
            });
            
        }
    }
    
    return (<>
        {(props.index==0) &&
            <div className='row'>
                <div className="col-5 form-group text-center"><label>{t('fieldToUpdate')}</label></div>
                <div className="col-1"></div>
                <div className="col-5 form-group text-center"><label>{t('fieldAutoUpdateItemRule')}</label></div>
                <div className="col-1"></div>
            </div>
        }
        <div className='row mb-2'>
            <div className="col-5">
                <select className='form-control' value={valueSelect.updateData} onChange={(e)=>handleChange(e,'updateData')} >
                    <option value="">-- Select --</option>
                    {props.fieldsData.map((value,ind)=>{
                        if((value==='price' && activeB2c) || (value==='price_b2b' && activeB2b) || (value!='price_b2b' && value!='price')){
                            return(
                                <option key={ind} value={value}>{
                                    (value==='name')? t('itemName') : 
                                    (value==='price')? t('priceTtcB2c') :
                                    (value==='price_b2b')? t('priceHtB2b') :
                                    (value==='indice')? t('prmpIndice') :
                                    (value==='image')? t('imageLink') :
                                    (value==='quota_commande')? t('quantity') :
                                    (value==='commande')? t('orderDate') :
                                    (value==='expedition')? t('expectedDeliveryDate') :
                                    (value==='reception')? t('expectedReceiptDate') :
                                    (value==='user')? t('receivedBy') :
                                    (value.startsWith('price_') && value!='price_b2b')? t('price')+' '+value.split('_')[1] :
                                    (value.startsWith('enumere_'))? t('enumere')+' '+value.split('_')[1] :
                                    t(value) ? t(value) :
                                        value
                                }</option>
                            )
                        }
                    })}
                </select>
            </div>
            <div className="col-1 d-flex justify-content-center align-items-center">
                <FontAwesomeIcon icon={faArrowsAltH} className='fs-2'/>
            </div>
            <div className="col-5">
                <select className='form-control' value={valueSelect.ruleData} onChange={(e)=>handleChange(e,'ruleData')} >
                    <option value="">-- Select --</option>
                    {props.fieldsData.map((value,ind)=>{
                        if((value==='price' && activeB2c) || (value==='price_b2b' && activeB2b) || (value!='price_b2b' && value!='price')){
                            return(
                                <option key={ind} value={value}>{
                                    (value==='name')? t('itemName') : 
                                    (value==='price')? t('priceTtcB2c') :
                                    (value==='price_b2b')? t('priceHtB2b') :                                                                                        
                                    (value==='indice')? t('prmpIndice') :
                                    (value==='image')? t('imageLink') :
                                    (value==='quota_commande')? t('quantity') :
                                    (value==='commande')? t('orderDate') :
                                    (value==='expedition')? t('expectedDeliveryDate') :
                                    (value==='reception')? t('expectedReceiptDate') :
                                    (value==='user')? t('receivedBy') :
                                    (value.startsWith('price_') && value!='price_b2b')? t('price')+' '+value.split('_')[1] :
                                    (value.startsWith('enumere_'))? t('enumere')+' '+value.split('_')[1] :
                                    t(value) ? t(value) :
                                        value
                                }</option>
                            )
                        }
                    })}
                </select>
            </div>
            <div className="col-1 d-flex justify-content-center align-items-center">
                {(validButton.create) ? 
                    <div className="spinner-border spinner-border-sm"></div> 
                : 
                    <h5 disabled={true} style={{marginBottom:'0', cursor:'pointer'}} onClick={()=>handleSubmit()}>
                        <Badge bg="primary" text='white'>
                            {t('btnValidate')}
                        </Badge>
                    </h5>
                }&nbsp;&nbsp;
                {(validButton.delete) ? 
                    <div className="spinner-border spinner-border-sm"></div> 
                : 
                    <FontAwesomeIcon icon={faTimesCircle} className='fs-3 pointer' onClick={()=>deleteRuleLine()}/>
                }
            </div>
        </div>
    </>
    )
};

export default RuleLineItems;