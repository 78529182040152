import React, {useContext} from 'react';
import { useTranslate } from 'react-redux-multilingual'
import { IsAuthContext } from 'app/App';
import LineTableEnumere from './LineTableEnumere'

const BlocTableEnumere = (props) => {
    const t = useTranslate();
    const { authParam } = useContext(IsAuthContext)

    let packagingList = []
    for (let i=0; i<props.maxConditionnement; i++){
        let numeroEnumere = i+1
        let readAccessName = authParam.access.find(access=>access.action == 'article_enumerename'+numeroEnumere+'_@!'+'read')
        let writeAccessName = authParam.access.find(access=>access.action == 'article_enumerename'+numeroEnumere+'_@!'+'write')

        let readAccessRef = authParam.access.find(access=>access.action == 'article_enumerereference'+numeroEnumere+'_@!'+'read')
        let writeAccessRef = authParam.access.find(access=>access.action == 'article_enumerereference'+numeroEnumere+'_@!'+'write')

        let readAccessQte = authParam.access.find(access=>access.action == 'article_enumerequantity'+numeroEnumere+'_@!'+'read')
        let writeAccessQte = authParam.access.find(access=>access.action == 'article_enumerequantity'+numeroEnumere+'_@!'+'write')

        if(readAccessName && readAccessRef && readAccessQte){
            //Si la lecture des 3 champs n'est pas autorisé, on ne retourne pas la ligne 
        }else{
            packagingList.push(
                <LineTableEnumere 
                    key={numeroEnumere}
                    article={props.article}
                    numeroEnumere={numeroEnumere}
                    onSubmit={props.onSubmit}
                    validButton={props.validButton}
                    writeAccessName={writeAccessName}
                    writeAccessRef={writeAccessRef}
                    writeAccessQte={writeAccessQte}
                    readAccessName={readAccessName}
                    readAccessRef={readAccessRef}
                    readAccessQte={readAccessQte}

                    handleEdit={props.handleEdit}
                    onEdit={props.onEdit}
                />
            )
        }
    }

    return(
        <>
            <div className='row mb-2'>
                <div className='card-header text-center'>{t('packagingList')}</div>
            </div>
            <div className="row">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>N°</th>
                            <th></th>
                            <th>{t('name')}</th>
                            <th>{t('reference')}</th>
                            <th>{t('quantity')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {packagingList}
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default BlocTableEnumere;