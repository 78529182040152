import React from 'react';
import { useTranslate } from 'react-redux-multilingual'
import { useHistory } from "react-router-dom";

const ItemDirectionPossible = (props) =>{
    const t = useTranslate();
    const history = useHistory();

    return(<>
        {(props.value.bins) ?
            <div className="col-12 mb-2 border p2">
                <div className='mb-1 d-flex justify-content-center text-center'>
                    <span>{props.value.article_name}</span>
                </div>
                <div className='d-flex justify-content-around'>
                    <span><span>Bins : </span><span className='fw-bold'>{props.value.bins.bar}</span></span>
                    <span><span>{t('toCollect')+' : '}</span><span className='fw-bold'>{props.value.here_tomove}</span></span>
                </div> 
            </div>
        :
            <div className="animated-button1 col-12 mb-2 p-1" onClick={()=>history.push(props.urlRedirect)}>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <div className='mb-1 d-flex justify-content-center flex-column'>
                    <div>{props.value.article_name}</div>
                    <div>{props.value.article_reference}</div>
                    <div>{props.value.article_sku}</div>
                </div>
                <div className='d-flex justify-content-around'>
                    <div style={{display:'inherit'}}><div>{t('zoneWithoutBins')+' :'}</div><div className='fw-bold'>{props.value.zone_name}</div></div>
                    <div style={{display:'inherit'}}><div>{t('toCollect')+' : '}</div><div className='fw-bold'>{props.value.here_tomove}</div></div>
                </div> 
            </div>
        }
        </>
    )
}

export default ItemDirectionPossible;