export function espace_mille(nombre){
    let number
    let prepend
    if(nombre.toString().startsWith("-")){
        number = nombre.toString().replace('-','')
        prepend = '-'
    }else{
        number = nombre
        prepend = ''
    }

    if(number<=999) return prepend+number.toString();
    if(number<=999999) return prepend+number.toString().slice(0,number.toString().length-3)+' '+number.toString().slice(-3);
    if(number<=999999999) return prepend+number.toString().slice(0,number.toString().length-6)+' '+number.toString().slice(-6,-3)+' '+number.toString().slice(-3);
}