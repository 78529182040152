import React, {useState} from "react";
import {  useHistory } from "react-router-dom";
import { useTranslate } from 'react-redux-multilingual'
import { useForm } from "react-hook-form";
import swal from "sweetalert2";
import axios from 'axios'

const CaisseForm = (props) =>{
    const t = useTranslate()
    const { register, formState: { errors }, handleSubmit, setValue } = useForm();
    const [dixmille, setDixmille]=useState(0)
    const [cinqmille, setCinqmille]=useState(0)
    const [mille, setMille]=useState(0)
    const [cinqcent, setCinqcent]=useState(0)
    const [deuxcent, setDeuxcent]=useState(0)
    const [cent, setCent]=useState(0)
    const [cinquante, setCinquante]=useState(0)
    const [vingt, setVingt]=useState(0)
    const [dix, setDix]=useState(0)
    const [cinq, setCinq]=useState(0)
    const [apiError, setApiError] = useState(false)
    const [validButton, setValidButton] = useState(false)
    const history = useHistory();

    var total=(dixmille+cinqmille+mille+cinqcent+deuxcent+cent+cinquante+vingt+dix+cinq)
    var colorTotal = (!props.fondCaisse && total == 280000) ? "text-success" : (total == props.fondCaisse) ? "text-success" : "text-danger"
    var totalSwal = (props.fondCaisse && total != props.fondCaisse) ? total-props.fondCaisse : total
    var titleSwal = (props.fondCaisse && total != props.fondCaisse) ? t('confirmDifferenceFondCaisse') : props.swalTitle

    const onSubmit= async (data) => {
        setValidButton(true)
        setApiError(false)
        const callapi  = await axios.post(process.env.REACT_APP_API_URL+"api/pos/pos/"+props.posid+"/"+props.caisseIdentId, {
            detail_mode_paiement:{
                espece:{
                    total:total,
                    prevu:props.fondCaisse
                }
            }, 
            isfor: props.isfor,
            }).then((response) => {
                if(!response.data.valid){
                    setValidButton(false)
                    let interms='';
                    var keys = Object.keys(response.data.errors);
                    for(var i = 0; i < keys.length; i++){
                        interms+=response.data.errors[keys[i]].join(',')+','
                    }
                    interms=interms.split(',')
                    let messageError='';
                    interms.forEach(interm=>messageError+=t(interm)+'<br/>')
                    messageError=messageError.replace('undefined','');
                    setApiError(messageError)
                }
                else{
                    let localContenu={
                        open: response.data.data.open,
                        posid: response.data.data.id,
                        name: response.data.data.section.name,
                        logo: response.data.data.section.logo,
                        caisse_ident:props.getValues('caisse_ident')
                    }
                    if(props.isfor=='open'){
                        localStorage.setItem('pos',response.data.data.id+','+props.caisseIdentId)
                        localStorage.setItem('infosPos',JSON.stringify(localContenu))
                        history.push('/pos/'+props.posid)

                    }else{
                        localStorage.removeItem('pos')
                        localStorage.removeItem('infosPos')
                        history.push('welcome/welcome/')
                    }
                }
            }).catch((error) => {
                if(error=='end_session') return;
                setValidButton(false)
                if(!error.response){
                    swal.fire(t('unknowError'), "", "error");
                }
                else if(error.response.status===403){
                    swal.fire(t('forbiddenGeneral'), "", "error");
                }
                else {
                    swal.fire(t('unknowError'), "", "error");
                }
            }
        )
        
    }

    const sendForm = () => {
        var form = document.getElementById('formOpen');
        form.click();
    }

    const calcSum= (e) => {
        switch(e.target.name){
            case 'dixmille':
                setDixmille(e.target.value*10000)
                break;
            case 'cinqmille':
                setCinqmille(e.target.value*5000)
                break;
            case 'mille':
                setMille(e.target.value*1000)
                break;
            case 'cinqcent':
                setCinqcent(e.target.value*500)
                break;
            case 'deuxcent':
                setDeuxcent(e.target.value*200)
                break;
            case 'cent':
                setCent(e.target.value*100)
                break;
            case 'cinquante':
                setCinquante(e.target.value*50)
                break;
            case 'vingt':
                setVingt(e.target.value*20)
                break;
            case 'dix':
                setDix(e.target.value*10)
                break;
            case 'cinq':
                setCinq(e.target.value*5)
                break;
        }
    }

    const onfocusAction = (inputId) =>{
        let element = document.getElementById(inputId)
        if(element) element.select()
    }

    return(
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group">
                <div className="row">
                    <div className="col-8">
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text text-white" style={{backgroundColor:"#323e48", borderColor:"#323e48"}}>
                                    10 000 XPF
                                </span>
                            </div>
                            <input onFocus={()=>onfocusAction("dixmille")} style={{textAlign: "right"}} id="dixmille" type="number" className="form-control" defaultValue="0" {...register('dixmille',{required: true,valueAsNumber: true,min: 0, onChange:(e) => calcSum(e)})} />
                            {errors.dixmille?.type === 'required' && (
                                <div className="text-danger mt-1 ml-2">
                                    {t('dixmilleRequired')}
                                </div>
                            )}
                            {errors.dixmille?.type === 'valueAsNumber' && (
                                <div className="text-danger mt-1 ml-2">
                                    {t('dixmilleNumeric')}
                                </div>
                            )}
                            {errors.dixmille?.type === 'min' && (
                                <div className="text-danger mt-1 ml-2">
                                    {t('dixmilleMin0')}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="col-4">
                        <p style={{float:"right"}}><span id="resultdixmille" className="toSum">{new Intl.NumberFormat('fr-FR').format(dixmille)}</span> XPF</p>
                    </div>
                </div>
            </div>
            <div className="form-group">
                <div className="row">
                    <div className="col-8">
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text text-white" style={{backgroundColor:"#323e48", borderColor:"#323e48"}}>
                                    5 000 XPF
                                </span>
                            </div>
                            <input onFocus={()=>onfocusAction("cinqmille")} style={{textAlign: "right"}} id="cinqmille" type="number" className="form-control" defaultValue="0" {...register('cinqmille',{required: true,valueAsNumber: true,min: 0, onChange:(e) => calcSum(e)})} />
                            {errors.cinqmille?.type === 'required' && (
                                <div className="text-danger mt-1 ml-2">
                                    {t('cinqmilleRequired')}
                                </div>
                            )}
                            {errors.cinqmille?.type === 'valueAsNumber' && (
                                <div className="text-danger mt-1 ml-2">
                                    {t('cinqmilleNumeric')}
                                </div>
                            )}
                            {errors.cinqmille?.type === 'min' && (
                                <div className="text-danger mt-1 ml-2">
                                    {t('cinqmilleMin0')}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="col-4">
                        <p style={{float:"right"}}><span id="resultcinqmille" className="toSum">{new Intl.NumberFormat('fr-FR').format(cinqmille)}</span> XPF</p>
                    </div>
                </div>
            </div>
            <div className="form-group">
                <div className="row">
                    <div className="col-8">
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text text-white" style={{backgroundColor:"#323e48", borderColor:"#323e48"}}>
                                    1 000 XPF
                                </span>
                            </div>
                            <input onFocus={()=>onfocusAction("mille")} style={{textAlign: "right"}} id="mille" type="number" className="form-control" defaultValue="0" {...register('mille',{required: true,valueAsNumber: true,min: 0, onChange:(e)=> calcSum(e)})}/>
                            {errors.mille?.type === 'required' && (
                                <div className="text-danger mt-1 ml-2">
                                    {t('milleRequired')}
                                </div>
                            )}
                            {errors.mille?.type === 'valueAsNumber' && (
                                <div className="text-danger mt-1 ml-2">
                                    {t('milleNumeric')}
                                </div>
                            )}
                            {errors.mille?.type === 'min' && (
                                <div className="text-danger mt-1 ml-2">
                                    {t('milleMin0')}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="col-4">
                        <p style={{float:"right"}}><span id="resultmille" className="toSum">{new Intl.NumberFormat('fr-FR').format(mille)}</span> XPF</p>
                    </div>
                </div>
            </div>
            <div className="form-group">
                <div className="row">
                    <div className="col-8">
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text text-white" style={{backgroundColor:"#323e48", borderColor:"#323e48"}}>
                                    500 XPF
                                </span>
                            </div>
                            <input onFocus={()=>onfocusAction("cinqcent")} style={{textAlign: "right"}} id="cinqcent" type="number" className="form-control" defaultValue="0" {...register('cinqcent',{required: true,valueAsNumber: true,min: 0,onChange:(e) => calcSum(e)})} />
                            {errors.cinqcent?.type === 'required' && (
                                <div className="text-danger mt-1 ml-2">
                                    {t('cinqcentRequired')}
                                </div>
                            )}
                            {errors.cinqcent?.type === 'valueAsNumber' && (
                                <div className="text-danger mt-1 ml-2">
                                    {t('cinqcentNumeric')}
                                </div>
                            )}
                            {errors.cinqcent?.type === 'min' && (
                                <div className="text-danger mt-1 ml-2">
                                    {t('cinqcentMin0')}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="col-4">
                        <p style={{float:"right"}}><span id="resultcinqcent" className="toSum">{new Intl.NumberFormat('fr-FR').format(cinqcent)}</span> XPF</p>
                    </div>
                </div>
            </div>
            <div className="form-group">
                <div className="row">
                    <div className="col-8">
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text text-white" style={{backgroundColor:"#323e48", borderColor:"#323e48"}}>
                                    200 XPF
                                </span>
                            </div>
                            <input onFocus={()=>onfocusAction("deuxcent")} style={{textAlign: "right"}} id="deuxcent" type="number" className="form-control" defaultValue="0" {...register('deuxcent',{required: true,valueAsNumber: true,min: 0, onChange:(e) => calcSum(e)})} />
                            {errors.deuxcent?.type === 'required' && (
                                <div className="text-danger mt-1 ml-2">
                                    {t('deuxcentRequired')}
                                </div>
                            )}
                            {errors.deuxcent?.type === 'valueAsNumber' && (
                                <div className="text-danger mt-1 ml-2">
                                    {t('deuxcentNumeric')}
                                </div>
                            )}
                            {errors.deuxcent?.type === 'min' && (
                                <div className="text-danger mt-1 ml-2">
                                    {t('deuxcentMin0')}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="col-4">
                        <p style={{float:"right"}}><span id="resultdeuxcent" className="toSum">{new Intl.NumberFormat('fr-FR').format(deuxcent)}</span> XPF</p>
                    </div>
                </div>
            </div>
            <div className="form-group">
                <div className="row">
                    <div className="col-8">
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text text-white" style={{backgroundColor:"#323e48", borderColor:"#323e48"}}>
                                    100 XPF
                                </span>
                            </div>
                            <input onFocus={()=>onfocusAction("cent")} style={{textAlign: "right"}} id="cent" type="number" className="form-control" defaultValue="0" {...register('cent',{required: true,valueAsNumber: true,min: 0, onChange:(e) => calcSum(e)})}/>
                            {errors.cent?.type === 'required' && (
                                <div className="text-danger mt-1 ml-2">
                                    {t('centRequired')}
                                </div>
                            )}
                            {errors.cent?.type === 'valueAsNumber' && (
                                <div className="text-danger mt-1 ml-2">
                                    {t('centNumeric')}
                                </div>
                            )}
                            {errors.cent?.type === 'min' && (
                                <div className="text-danger mt-1 ml-2">
                                    {t('centMin0')}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="col-4">
                        <p style={{float:"right"}}><span id="resultcent" className="toSum">{new Intl.NumberFormat('fr-FR').format(cent)}</span> XPF</p>
                    </div>
                </div>
            </div>
            <div className="form-group">
                <div className="row">
                    <div className="col-8">
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text text-white" style={{backgroundColor:"#323e48", borderColor:"#323e48"}}>
                                    50 XPF
                                </span>
                            </div>
                            <input onFocus={()=>onfocusAction("cinquante")} style={{textAlign: "right"}} id="cinquante" type="number" className="form-control" defaultValue="0" {...register('cinquante',{required: true,valueAsNumber: true,min: 0,onChange:(e) => calcSum(e)})} />
                            {errors.cinquante?.type === 'required' && (
                                <div className="text-danger mt-1 ml-2">
                                    {t('cinquanteRequired')}
                                </div>
                            )}
                            {errors.cinquante?.type === 'valueAsNumber' && (
                                <div className="text-danger mt-1 ml-2">
                                    {t('cinquanteNumeric')}
                                </div>
                            )}
                            {errors.cinquante?.type === 'min' && (
                                <div className="text-danger mt-1 ml-2">
                                    {t('cinquanteMin0')}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="col-4">
                        <p style={{float:"right"}}><span id="resultcinquante" className="toSum">{new Intl.NumberFormat('fr-FR').format(cinquante)}</span> XPF</p>
                    </div>
                </div>
            </div>
            <div className="form-group">
                <div className="row">
                    <div className="col-8">
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text text-white" style={{backgroundColor:"#323e48", borderColor:"#323e48"}}>
                                    20 XPF
                                </span>
                            </div>
                            <input onFocus={()=>onfocusAction("vingt")} style={{textAlign: "right"}} id="vingt" type="number" className="form-control" defaultValue="0" {...register('vingt',{required: true,valueAsNumber: true,min: 0, onChange:(e) => calcSum(e)})}/>
                            {errors.vingt?.type === 'required' && (
                                <div className="text-danger mt-1 ml-2">
                                    {t('vingtRequired')}
                                </div>
                            )}
                            {errors.vingt?.type === 'valueAsNumber' && (
                                <div className="text-danger mt-1 ml-2">
                                    {t('vingtNumeric')}
                                </div>
                            )}
                            {errors.vingt?.type === 'min' && (
                                <div className="text-danger mt-1 ml-2">
                                    {t('vingtMin0')}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="col-4">
                        <p style={{float:"right"}}><span id="resultvingt" className="toSum">{new Intl.NumberFormat('fr-FR').format(vingt)}</span> XPF</p>
                    </div>
                </div>
            </div>
            <div className="form-group">
                <div className="row">
                    <div className="col-8">
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text text-white" style={{backgroundColor:"#323e48", borderColor:"#323e48"}}>
                                    10 XPF
                                </span>
                            </div>
                            <input onFocus={()=>onfocusAction("dix")} style={{textAlign: "right"}} id="dix" type="number" className="form-control" defaultValue="0" {...register('dix',{required: true,valueAsNumber: true,min: 0, onChange:(e) => calcSum(e)})}/>
                            {errors.dix?.type === 'required' && (
                                <div className="text-danger mt-1 ml-2">
                                    {t('dixRequired')}
                                </div>
                            )}
                            {errors.dix?.type === 'valueAsNumber' && (
                                <div className="text-danger mt-1 ml-2">
                                    {t('dixNumeric')}
                                </div>
                            )}
                            {errors.dix?.type === 'min' && (
                                <div className="text-danger mt-1 ml-2">
                                    {t('dixMin0')}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="col-4">
                        <p style={{float:"right"}}><span id="resultdix" className="toSum">{new Intl.NumberFormat('fr-FR').format(dix)}</span> XPF</p>
                    </div>
                </div>
            </div>
            <div className="form-group">
                <div className="row">
                    <div className="col-8">
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text text-white" style={{backgroundColor:"#323e48", borderColor:"#323e48"}}>
                                    5 XPF
                                </span>
                            </div>
                            <input onFocus={()=>onfocusAction("cinq")} style={{textAlign: "right"}} id="cinq" type="number" className="form-control" defaultValue="0" {...register('cinq',{required: true,valueAsNumber: true,min: 0, onChange:(e) => calcSum(e)})}/>
                            {errors.cinq?.type === 'required' && (
                                <div className="text-danger mt-1 ml-2">
                                    {t('cinqRequired')}
                                </div>
                            )}
                            {errors.cinq?.type === 'valueAsNumber' && (
                                <div className="text-danger mt-1 ml-2">
                                    {t('cinqNumeric')}
                                </div>
                            )}
                            {errors.cinq?.type === 'min' && (
                                <div className="text-danger mt-1 ml-2">
                                    {t('cinqMin0')}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="col-4">
                        <p style={{float:"right"}}><span id="resultcinq" className="toSum">{new Intl.NumberFormat('fr-FR').format(cinq)}</span> XPF</p>
                    </div>
                </div>
            </div>                                
            <div className="offset-8 col-4">
                <p className="font-weight-bold" style={{float:"right"}}><strong>
                    <span className={colorTotal}>{new Intl.NumberFormat('fr-FR').format(total)} XPF</span>
                </strong></p>
            </div>
            {(apiError) ? <div className="alert alert-danger mt-3" dangerouslySetInnerHTML={{ __html: apiError }}></div> : false}
            <button
                disabled={validButton}
                type="button"
                className="btn btn-primary"
                onClick={() => {
                    swal
                    .fire({
                        title: titleSwal+" "+new Intl.NumberFormat('fr-FR').format(totalSwal)+" XPF "+t('ofFondCaisse')+" ?",
                        text: "",
                        icon: "warning",
                        type: "question",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: t('btnYes'),
                        cancelButtonText: t('btnNo'),
                        allowOutsideClick: false,
                    })
                    .then((result) => {
                        if (result.value) {
                            sendForm()
                        }
                    });
                }}
            >
                {validButton ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : t('btnValidate')}
            </button>
            <button className="d-none" id="formOpen" >Envoi</button>
        </form>
    )
}

export default CaisseForm;