import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image,Font } from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
    body:{
        paddingTop: 15,
        paddingBottom: 15,
        paddingHorizontal: 15,
    },
    titleFooter: {
        paddingTop:'7',
        paddingBottom:'2',
        // fontFamily:'Rubik',
        left:'2%',
        width: '100%',
        position: 'absolute',
        fontSize: 5,
        bottom: 0,
        textAlign: 'center',
    },
    bodyFooter: {
        textAlign: 'center',
    },
    blocHeadContainer: {
        width:'100%',
        fontSize:8,
        flexDirection: 'row',
        flexWrap: 'wrap', 
        justifyContent: 'flex-start', 
    },
    blocHead: {
        width: 'auto',
        marginRight:10,
        marginBottom:5 ,
    },
    table: {
        display: 'table',
        width: 'auto',
        marginTop:5,
        borderStyle: 'solid',
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0,
    },
    tableRow: {
        flexDirection: 'row',
    },
    tableColHeader: {
        width: '10%',
        borderStyle: 'solid',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        backgroundColor: '#f2f2f2',
    },
    tableCol: {
        width: '10%',
        borderStyle: 'solid',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
    },
    tableCellHeader: {
        margin: 5,
        fontSize: 6,
        fontWeight: 'bold',
    },
    tableCell: {
        margin: 5,
        fontSize: 6,
    },
    logo: {
        margin:'auto',
        height:10,
        width:10,
    },
    memo: {
        fontSize: 8,
        marginBottom:2,
    },
  });

const ExportDatatablePdf = (props) => {
    let memo = props.dataHeadPdfExport.find(data=>!Array.isArray(data.value))

    return(<Document>
        <Page size="A4" orientation={props.orientationPdf} style={styles.body}>
            <View style={styles.blocHeadContainer}>
                <View style={styles.blocHead}>
                    <Text style={{color:'#000000'}}>
                        {props.authParam.company.name}
                    </Text>
                    <Text style={{color:'#000000'}}>
                        {props.typeDocument}
                    </Text>
                </View>
                {props.dataHeadPdfExport.map((data,index)=>{
                    if(Array.isArray(data.value)){
                        return(
                            <View key={index} style={styles.blocHead}>
                                {data.value.map((item,ind)=>{
                                    let fontStyle = ind==0 ? {color:'#000000'} : {color:'#303030'}
                                    return(
                                        <Text key={ind} style={fontStyle}>
                                            {item}
                                        </Text>
                                    )
                                })}
                            </View>
                        )
                    }
                })}
            </View>
            {memo &&
                <View style={styles.memo}>
                    <Text>
                        Memo
                    </Text>
                    <Text style={{color:'#303030'}}>
                        {memo.value}
                    </Text>
                </View>
            }
            <View style={styles.table}>
                {/* Header */}
                <View style={styles.tableRow}>
                    {props.columnOrganize.map(title=>{
                        if(props.columnShow.includes(title.dataField)){
                            return(
                                <View key={title.dataField} style={styles.tableColHeader}>
                                    <Text style={styles.tableCellHeader}>{title.text.replace('*','')}</Text>
                                </View>
                            )
                        }
                    })}
                </View>
                {/* Data Rows */}
                {props.bodyData.map((value,index)=>{
                    return(
                        <View key={value.id} style={styles.tableRow}>
                            {props.columnOrganize.map((title,ind)=>{
                                if(props.columnShow.includes(title.dataField)){
                                    return(
                                        <View key={ind} style={styles.tableCol}>
                                            <Text style={styles.tableCell}>{value[title.dataField]}</Text>
                                        </View>
                                    )
                                }
                            })}
                        </View>
                    )
                })}
            </View>

            <View style={styles.titleFooter} fixed> 
                <Text style={styles.bodyFooter} render={({ pageNumber, totalPages }) => (
                    `${pageNumber} / ${totalPages}`
                )}  />
                <Text style={styles.bodyFooter}>{props.t('generateFreebird')}</Text>
            </View>
        </Page>
    </Document>
    )
};

export default ExportDatatablePdf;
