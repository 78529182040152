import React, { useState, useEffect } from "react";
import { useTranslate } from 'react-redux-multilingual'
import { Loading, GoalsAttribute, ObjectifDay,ObjectifPeriod,ObjectifCreate,ObjectifDelete,ObjectifDayChart,RhGoalsDayChart} from "@gull";
import axios from 'axios'
import { Tabs, Tab } from "react-bootstrap";
import SweetAlert from "sweetalert2-react";

const useLoadObjectif = (sectionid) => {
    const [loading,setLoading]=useState(true)
    const [error,setError]=useState(false)
    const [hier,setHier]=useState(false);
    const [today,setToday]=useState(false);
    const [demain,setDemain]=useState(false);
    const [realHier,setRealHier]=useState(false);
    const [realToday,setRealToday]=useState(false);
    const [realDemain,setRealDemain]=useState(false);
    const [realRh,setRealRh]=useState(false);
    const [period,setPeriod]=useState(true)
    const [section,setSection]=useState(false)
    const [relance,setRelance]=useState(Date.now()) 
    const [tabChange,setTabChange]=useState(false) 
    const [restrictionGoals,setRestrictionGoals]=useState({
        go:true,
        grades:[]
    })

    useEffect(function () {
        (async function() {
            if(!tabChange) setLoading(true)
            const response = await axios.get(process.env.REACT_APP_API_URL+"api/pos/objectif/threeday/"+sectionid
            ).then((response) => {

                setLoading(false)
                setTabChange(false)
                if(response.data.hier) setHier(response.data.hier)
                else setHier(false)
                if(response.data.today) setToday(response.data.today)
                else setToday(false)
                if(response.data.demain) setDemain(response.data.demain)
                else setDemain(false)
                if(response.data.real_rh) setRealRh(response.data.real_rh)
                else setRealRh(false)
                setRealHier(response.data.real_hier)
                setRealToday(response.data.real_today)
                setRealDemain(response.data.real_demain)
                setPeriod(false)
                setPeriod(true)
            }).catch((error) => {
                if(error=='end_session') return;
                setLoading(false)
                setError(true)
            })
        })()
    }, [sectionid,relance])
    useEffect(function () {
        (async function() {
            const response = await axios.get(process.env.REACT_APP_API_URL+"api/section/"+sectionid
            ).then((response) => {
                let restrictionGoals = response.data.restriction.filter(value=>value.action==='reviewAllGols')
                if(restrictionGoals.length>0){
                    if(restrictionGoals[0].grade.length > 0){
                        setRestrictionGoals({
                            go:false,
                            grades: restrictionGoals[0].grade.map(value=>value.id)
                        })
                    }
                }
                //setRestrictionGoals(response.data)
            }).catch((error) => {
                if(error=='end_session') return;
                setError(true)
            })
        })()
    }, [sectionid])
    return [loading,error,hier,today,demain,setHier,setToday,setDemain,realHier,realToday,realDemain,period,realRh,setRealRh,section,restrictionGoals,setRestrictionGoals,setRelance,setTabChange]
}

const ObjectifStaffAttribute = (props) => {
    const [section,setSection]=useState(false)
    const [restriction,setRestriction]=useState({
        go:true,
        grades:[]
    })
    useEffect(function () {
        (async function() {
            const response = await axios.get(process.env.REACT_APP_API_URL+"api/section/"+props.sectionid
            ).then((response) => {
                setSection(response.data)
                let restriction = response.data.restriction.filter(value=>value.action==='repartGoals')
                if(restriction.length>0){
                    if(restriction[0].grade.length > 0){
                        setRestriction({
                            go:false,
                            grades: restriction[0].grade.map(value=>value.id)
                        })
                    }
                }
            }).catch((error) => {
                if(error=='end_session') return;
            })
        })()
    }, [])

    return (section) ? <GoalsAttribute noUp={true} section={section} from={props.from} restriction={restriction} setRestriction={setRestriction}/> : false;
}


const ObjectifPosContain = (props) => {
    const t = useTranslate();
    const [loading,error,hier,today,demain,setHier,setToday,setDemain,realHier,realToday,realDemain,period,realRh,setRealRh,section,restrictionGoals,setRestrictionGoals,setRelance,setTabChange] = useLoadObjectif(props.sectionid)

    const correctionChartSize = (k)=>{
        props.setShowTabsContain({...props.showTabsContain,[k]:true})
        if(k=='today'){ 
            setRelance(Date.now())
            setTabChange(true)
        }
    }

    if(loading){
        return (<Loading></Loading>);
    }
    else if(error){
        return (<SweetAlert show="true" title={t('unknowError')} type="error" />);
    }

    return (
        <Tabs defaultActiveKey="today" id="uncontrolled-tab-example" onSelect={(k)=>correctionChartSize(k)}>
            <Tab eventKey="today" title={t('today')} >
                {(today && realToday && props.showTabsContain.today) ?
                    <>
                        <ObjectifDay from={props.from} data={today} setObjectif={setToday}/>
                        <ObjectifDayChart from={props.from} data={today} real={realToday}/>
                        {props.moduleParam.params.staff.active && (props.moduleParam.params.staff.active && !Array.isArray(props.moduleParam.params.staff.params) && props.moduleParam.params.staff.params.hasOwnProperty('objectifs') && props.moduleParam.params.staff.params.objectifs) ?
                            <RhGoalsDayChart from={props.from} sectionid={props.sectionid} section={section} restrictionGoals={restrictionGoals} setRestrictionGoals={setRestrictionGoals} isfor="today" data={today} realRh={realRh} />
                        :
                            false
                        }
                    </>
                    :
                    <div className="alert alert-danger">{t('noObjectif')}</div>
                }
            </Tab>
            <Tab eventKey="hier" title={t('hier')}>
                {(hier && realHier && props.showTabsContain.hier) ?
                    <>
                        <ObjectifDay from={props.from}  data={hier} setObjectif={setHier}/>
                        <ObjectifDayChart from={props.from} data={hier} real={realHier}/>
                        {props.moduleParam.params.staff.active && (props.moduleParam.params.staff.active && !Array.isArray(props.moduleParam.params.staff.params) && props.moduleParam.params.staff.params.hasOwnProperty('objectifs') && props.moduleParam.params.staff.params.objectifs) ?
                            <RhGoalsDayChart from={props.from} sectionid={props.sectionid} section={section} restrictionGoals={restrictionGoals} setRestrictionGoals={setRestrictionGoals} isfor="hier" data={hier} realRh={realRh} />
                        :
                            false
                        }
                    </>
                    :
                    <div className="alert alert-danger">{t('noObjectif')}</div>
                }
            </Tab>
            <Tab eventKey="demain" title={t('demain')}>
                {(demain && realDemain && props.showTabsContain.demain) ?
                    <>
                        <ObjectifDay from={props.from}  data={demain} setObjectif={setDemain}/>
                        <ObjectifDayChart from={props.from} data={demain} real={realDemain}/>
                        {props.moduleParam.params.staff.active && (props.moduleParam.params.staff.active && !Array.isArray(props.moduleParam.params.staff.params) && props.moduleParam.params.staff.params.hasOwnProperty('objectifs') && props.moduleParam.params.staff.params.objectifs) ?
                            <RhGoalsDayChart from={props.from} sectionid={props.sectionid} section={section} restrictionGoals={restrictionGoals} setRestrictionGoals={setRestrictionGoals} isfor="demain" data={demain} realRh={realRh} />
                        :
                            false
                        }
                    </>
                    :
                    <div className="alert alert-danger">{t('noObjectif')}</div>
                }
            </Tab>
            <Tab eventKey="period" title={t('period')}>
                {(period && props.showTabsContain.period) &&
                    <ObjectifPeriod from={props.from} moduleParam={props.moduleParam} sectionid={props.sectionid} section={section} restrictionGoals={restrictionGoals} setRestrictionGoals={setRestrictionGoals}/>
                }
            </Tab>
            {(props.from=='erp') &&
                <Tab eventKey="create" title={t('createGoals')}>
                    <ObjectifCreate sectionid={props.sectionid} />
                </Tab>
            }
            {props.moduleParam.params.staff.active && (props.moduleParam.params.staff.active && !Array.isArray(props.moduleParam.params.staff.params) && props.moduleParam.params.staff.params.hasOwnProperty('objectifs') && props.moduleParam.params.staff.params.objectifs) ?
                <Tab eventKey="attributeStaffGoals" title={t('attributeStaffGoals')}>
                    <ObjectifStaffAttribute sectionid={props.sectionid} from={props.from} />
                </Tab>
            :
                false
            }
            {(props.from=='erp') &&
                <Tab eventKey="delete" title={t('deleteGoals')}>
                    <ObjectifDelete sectionid={props.sectionid} />
                </Tab>
            }
        </Tabs>
    )
}

export default ObjectifPosContain;
