import React from 'react';
import { useTranslate } from 'react-redux-multilingual';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus} from '@fortawesome/free-solid-svg-icons';

const AddButton = (props) =>{
    const t =useTranslate() 
    
    return(
        <span>
            {props.title}&nbsp;&nbsp;
            <button
                type="button"
                className="btn btn-sm btn-success"
                onClick={()=>props.setShowModal(true)}
            >
                <FontAwesomeIcon icon={faPlus} />
            </button>
        </span>
    )
}

export default AddButton;