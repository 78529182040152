import React, {useState} from "react";
import { useTranslate } from 'react-redux-multilingual'
import swal from "sweetalert2";
import axios from 'axios'

const ButtonDeleteMasse = (props) => {
    const t = useTranslate()
    const [validButton,setValidButton]=useState(false)

    const deleteEnMasse = async () =>{
        setValidButton(true)
        const response = await axios.post(process.env.REACT_APP_API_URL+"api/erp/inventaire_division/delete_group",{
            delete:props.selectedRow
        }).then((response) => {
            setValidButton(false)
            props.setRelance(Date.now())
            props.setSelectedRow([])
            props.setSelected(false)

            //on met à jour le nom bre de ligne par page pour le bon fonctionnement du cochage 
            let newDatas=[]
            props.afficheTab.map(value=>{
                if(!props.selectedRow.includes(value.id)) newDatas.push(value)
            })

            props.setAfficheTab(newDatas)
            
        }).catch((error) => {
            if(error=='end_session') return;
            let errorName = (error.response==undefined) ? 'unknowError' : (error.response.status==403) ? 'forbiddenGeneral' : 'unknowError'
            swal.fire(t(errorName), "", "error");
        })
        setValidButton(false)
    }

    const handleAttribute = () =>{
        if(props.inputBar.current) props.inputBar.current.focus()
    }

    return(
        <div>
            <button 
                disabled={validButton} 
                className="btn btn-danger mb-2" 
                onClick={() => {
                    swal
                    .fire({
                        title: t('confirmDeleteAllPage')+"?",
                        icon: "warning",
                        type: "question",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: t('btnYes'),
                        cancelButtonText: t('btnNo'),
                    })
                    .then((result) => {
                        if (result.value) {
                            deleteEnMasse()
                        }
                    });
                }}
            >
                {validButton ? <div className="spinner-border spinner-border-sm"></div> : t('btnDelete')}
            </button>&nbsp;&nbsp;
            <button className="btn btn-primary mb-2" onClick={()=>handleAttribute()}>{t('attribuerSelection')}</button>
        </div>
    )
}

export default ButtonDeleteMasse;
