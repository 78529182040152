import React, {useState,useEffect} from "react";
import { useTranslate } from 'react-redux-multilingual'
import axios from 'axios'
import swal from "sweetalert2";
import { Form } from "react-bootstrap";

const CancelInventoryForm = (props) => {
    const t = useTranslate()
    const [checked,setChecked]=useState(false)
    const [validButton,setValidButton]=useState(false)

    //si activeCancelAll est undefined, on affiche le chois de tout annuler car tous les staff ont effectué ce comptage 
    let activeCancelAll = Object.keys(props.divisionRhInfo).length!=0 && props.divisionRhInfo.find(value=>value.already==false)

    const handleChange = (e,name) => {
        setChecked(name);
    }

    const handleSubmit = async () =>{
        let urlParam = (checked=='all') ? props.idDivision : props.idDivision+'/'+checked //checked ici représente l'id du rh sélectionné
        let paramDelete = (props.cancelAllInventory) ? "delete_inventaire_count" : "delete_count"
        setValidButton(true)

        const response = await axios.delete(process.env.REACT_APP_API_URL+"api/erp/inventaire_division/"+paramDelete+"/"+urlParam
        ).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach(interm=>messageError+=t(interm)+'. ')
                messageError=messageError.replace('undefined','');
                swal.fire(messageError, "", "error");

            }else{   
                setValidButton(false)
                props.setRelance(Date.now)
                props.setShowCancelModal(false)
            }
        }).catch((error) => {
            if(error=='end_session') return;
            let errorName = (error.response==undefined) ? 'unknowError' : (error.response.status==403) ? 'forbiddenGeneral' : 'unknowError'
            swal.fire(t(errorName), "", "error");
        })
        setValidButton(false)
    }

    return (<div>
        <strong>{t('selectCancellationOption')+' :'}</strong>
        <div className="mb-2"></div>
        {(props.divisionRhInfo.length>1 && !activeCancelAll) ? 
            <Form.Check
                className="mb-1"
                type="radio"
                checked={checked=='all'}
                id="custom-switch"
                label={t('allCounts')}
                onChange={(e) => handleChange(e,'all')}
            />
        :
            false
        }
        {props.divisionRhInfo.length!=0 &&
            props.divisionRhInfo.map(value=>{
                if(value.already){
                    return(
                        <Form.Check
                            className="mb-1"
                            key={value.id}
                            type="radio"
                            checked={checked==value.id}
                            id="custom-switch"
                            label={value.name+' '+value.firstname}
                            onChange={(e) => handleChange(e,value.id)}
                        />
                    )
                }
            })
        }
        <button disabled={validButton || !checked} className="btn btn-primary mt-2" onClick={()=>handleSubmit()}>
            {validButton ? <div className="spinner-border spinner-border-sm"></div> : t('btnValidate')}
        </button>
        </div>
    )
}

export default CancelInventoryForm;
