import React, {useState} from "react";
import { useTranslate } from 'react-redux-multilingual'
import swal from "sweetalert2";
import axios from 'axios'
import { useParams } from "react-router-dom";
import { Badge} from "react-bootstrap";
// Style import
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import AttributeInventoryToStaff from "../AttributeInventoryToStaff";

const CreateInventoryZone = (props) => {
    const t = useTranslate()
    const [validButton,setValidButton]=useState(false)
    const { id } = useParams();


    const removeStaff = (id) =>{
        let newDatas = props.staffList.filter(value=>value.id!=id)
        props.setStaffList(newDatas)
    }
    
    const handleChange = (e) =>{
        //on empeche l'écriture des "" '' / et \
        props.setZoneName(e.target.value.replace(/['"/\\]/g, ''))
    }

    const handleSubmit = async (e) =>{
        e.preventDefault();
        setValidButton(true)

        let obj = {}
        if(props.zoneEdit){
            //en cas d'ajout de staff apres avoir créé la zone d'inventaire
            obj['inventaire_division']=[props.zoneEdit]
        }else if(props.zoneEditMasse){
            //en cas d'ajout de staff en masse par sélection 
            obj['inventaire_division']=props.selectedRow
        }else{
            //en cas de création de zone d'inventaire
            obj['name']=props.zoneName
        }
        if(props.staffList.length!=0){
            let arr=[]
            props.staffList.map(value=>
                arr.push(value.id)
            )

            obj['rh']=arr
        }

        let url = (props.zoneEdit || props.zoneEditMasse) ? "api/erp/inventaire_division/rh_attribution/" : "api/erp/inventaire_division/create_zone_inventaire/";     
        let axiosMethod = (props.zoneEdit || props.zoneEditMasse) ? axios.put : axios.post

        const response = await axiosMethod(process.env.REACT_APP_API_URL+url+id, obj
        ).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach(interm=>messageError+=t(interm)+'. ')
                messageError=messageError.replace('undefined','');
                swal.fire(messageError, "", "error");

            }else{
                if(props.zoneEdit || props.zoneEditMasse){
                    props.setRelance(Date.now())
                }else{
                    //on ajoute une nouvelle zone à la liste
                    let oldData = props.allInventoryZone.inventaire_division
                    oldData.push(response.data.data)
                    props.setAllInventoryZone({...props.allInventoryZone,inventaire_division:oldData})
                    props.setAfficheTabZone(oldData.slice(0,10))
                }
            }
        }).catch((error) => {
            if(error=='end_session') return;
            let errorName = (error.response==undefined) ? 'unknowError' : (error.response.status==403) ? 'forbiddenGeneral' : 'unknowError'
            swal.fire(t(errorName), "", "error");
        })

        props.setZoneName('')
        props.setStaffList([])
        setValidButton(false)
        if(props.zoneEdit) props.setZoneEdit(false)
        if(props.zoneEditMasse) props.setZoneEditMasse(false)
        props.setSelected(false)
        props.setSelectedRow([])
    }

    return(<>
        <form onSubmit={(e)=>handleSubmit(e)}>
            <div className="row d-flex align-items-center">
                <div className="col form-group">
                    <label htmlFor="name">{t('zoneName')}</label>
                    <input value={props.zoneName} type="text" className="form-control" onChange={(e)=>handleChange(e)}/>
                </div>
                <AttributeInventoryToStaff
                    searchInput={props.searchInput}
                    section={props.section}
                    staffList={props.staffList}                 
                    setStaffList={props.setStaffList}
                />
                <div className="col-2">
                    <button disabled={validButton} className="btn btn-primary" >
                        {validButton ? <div className="spinner-border spinner-border-sm"></div> : (props.selectedRow.length!=0) ? t('attribuer') : t('btnValidate')}
                    </button>
                </div>
            </div>
           
        </form>
        <div >
            {(props.staffList.length!=0) ? 
                props.staffList.map(value=>(
                    <span key={value.id} >
                        <Badge bg="primary" text="white">{value.name}&nbsp;&nbsp;<span className="text-danger pointer" onClick={()=>removeStaff(value.id)}>{<FontAwesomeIcon icon={faTimesCircle} />}</span></Badge>&nbsp;&nbsp;
                    </span>
                ))
            :
                false
            }
        </div>
    </>
    )
}

export default CreateInventoryZone;
