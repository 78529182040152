import React, {useState} from 'react';
import axios from "axios";
import { useTranslate } from 'react-redux-multilingual'
import swal from "sweetalert2";

const LineZoneContainWms = (props) => {
    const t = useTranslate();
    const [showTd,setShowTd]=useState(false)
    const [zoneContain,setZoneContain]=useState(false)
    let clicableClass={
        0:'',
        1:'tableLineClick'
    }

    const showDetailsSection = (zoneid) => {
        setZoneContain(false)
        setShowTd(!showTd);

        if(!showTd){
            const response = axios.get(process.env.REACT_APP_API_URL+"api/erp/article/stock_in_zone/"+props.articleid+"/"+zoneid
            ).then((response) => {
                setZoneContain(response.data)
            }).catch((error) => {
                if(error=='end_session') return;
                swal.fire(t('unknowError'), "", "error");
            });
        }
    }

    return(
        <>  
            <tr className={(props.value.total>0) ? 'alert-success '+clicableClass[props.value.havebins] : (props.value.total<0) ? 'alert-danger '+clicableClass[props.value.havebins] : clicableClass[props.value.havebins]} onClick={() => showDetailsSection(props.value.zoneid)}>
                <td>{props.keyName}</td>
                <td style={{textAlign:'right'}}>{props.value.total}</td>
            </tr>
            {(props.value.havebins) ?
                (showTd) &&
                <>
                    {(!zoneContain) ?
                        <tr><td><small><div className="spinner-border spinner-border-sm"></div></small></td></tr>
                        :
                        Object.entries(zoneContain.by_bins).map(([keyName, value], ind) =>{
                            return(
                                <tr key={ind}>
                                    <td>{keyName}</td>
                                    <td style={{textAlign:'right'}}>{value}</td>
                                </tr>
                            )
                        })
                    }
                </>
                :
                false
            }
        </>
    )
}

export default LineZoneContainWms;