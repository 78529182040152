import React from 'react';
import { useTranslate } from 'react-redux-multilingual'

const ItemContain = (props) => {
    const t=useTranslate()

    return(
        <table className="table">
            <thead>
                <tr>
                    <th scope="col">{t('items')}</th>
                    {(props.needColBins) ? <th scope="col">Bins</th> : false}
                    <th scope="col" style={{textAlign:'right'}}>{t('quantity')}</th>
                </tr>
            </thead>
            <tbody>
            {(props.contenu.length!=0) ? 
                props.contenu.map((value,ind)=>{
                    if(props.needColBins){
                        //ici c'est pour les zones avec bins
                        return(<tr key={ind}>
                            <td>{value.name}</td>
                            <td>{value.bins}</td>
                            <td style={{textAlign:'right'}}>{value.quota}</td>
                        </tr>)
                    }
                    else if(value.bins_id){
                        // ici c'est pour les bins
                        return(<tr key={ind}>
                                <td>{value.name}</td>
                                <td style={{textAlign:'right'}}>{value.quota}</td>
                            </tr>)
                    }else{
                        // ici c'est au cas où mais normalement on tombe jamais dedans
                        return(<tr key={ind}>
                            <td>{value.name}</td>
                            <td style={{textAlign:'right'}}>{value.quota}</td>
                        </tr>)
                    }
                })
            :
                <tr>
                    <td>{props.msgNoItem}</td>
                    {(props.needColBins) ? <td></td> : false}
                    <td></td>
                </tr>
            }
            </tbody>
        </table> 
    )
}

export default ItemContain;