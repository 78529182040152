import React, {useState, useEffect} from "react";
import { useTranslate } from 'react-redux-multilingual';
import { CardPerso,ArticleFilterSearch2} from "@gull";
import axios from 'axios'
import swal from "sweetalert2";
import { useParams } from "react-router-dom";
import { articleHardData } from 'app/articleHardData';
import { Modal } from "react-bootstrap";
import ModalAddItemToList from './ModalAddItemToList'

const useLoadInit = () => {
    const t = useTranslate();
    const [loading,setLoading]=useState(false)
    const [sizeDatas,setSizeDatas]=useState(0)
    const [relance, setRelance] =useState(Date.now())
    const [sendData, setSendData] = useState({})
    const [filtre,setFiltre] = useState({})
    const [goFiltre,setGoFiltre]=useState(false) 
    const [showModalAdd,setShowModalAdd]=useState(false) 
    const [selectValue,setSelectValue]=useState({
        list:'1',
        action:'ajout'
    }) 

    useEffect(function () {
        (async function() {
            if(goFiltre){
                setLoading(true)
                const response = await axios.post(process.env.REACT_APP_API_URL+"api/erp/article/list/50/1",filtre
                ).then((response) => {
                    setGoFiltre(false)
                    setLoading(false)
                    setSizeDatas(response.data.count)
                }).catch((error) => {
                    if(error=='end_session') return;
                    let messageError
                    if(error.response==undefined){
                        messageError='unknowError'
                    }
                    else if(error.response.status==403){
                        messageError='forbiddenGeneral'
                    }
                    else {
                        messageError='unknowError'
                    }
                    
                    swal.fire(t(messageError), "", "error");
                    setLoading(false)
                })
            }
        })()
    }, [relance])
    
    return [loading,setSizeDatas,sizeDatas,setRelance,sendData,setSendData,filtre,setFiltre,setGoFiltre,showModalAdd,setShowModalAdd,selectValue,setSelectValue]
}

const AddItemToList = (props) => {
    const t = useTranslate();
    const { id } = useParams();
    const [loading,setSizeDatas,sizeDatas,setRelance,sendData,setSendData,filtre,setFiltre,setGoFiltre,showModalAdd,setShowModalAdd,selectValue,setSelectValue] = useLoadInit()
    
    const [showModalCustomFiltre, setShowModalCustomFiltre] = useState(false);
    const [disabledAddSelection,setDisabledAddSelection]=useState(true) 
    const [validButton,setValidButton]=useState(false) 

    useEffect(function () {
        const isAnyKeyNonEmptyArray = Object.values(sendData).some(value => Array.isArray(value) && value.length > 0);

        if(isAnyKeyNonEmptyArray){
            setDisabledAddSelection(false)
        }else{
            setDisabledAddSelection(true) 
        }
    }, [sendData])


    const resetFiltreItemsToPromo = () =>{
        setGoFiltre(true)
        let allFilter = {}
        let keysToReset = Object.keys(sendData).filter(key=> key != Object.keys(allFilter).find(value=>value===key))

        keysToReset.map(value=>
            allFilter[value]=[]
        )

        setSendData(allFilter)
        setFiltre({})
        setRelance(Date.now())
        setSizeDatas(false)
    }
    
    const filterItemsPromo = (data) =>{
        setGoFiltre(true)

        let obj={}

        if(data){
            Object.entries(data).map(([key,value])=>{
                obj[key]=value
            })      
        }else{
            let keys = Object.keys(sendData);
            for(var i = 0; i < keys.length; i++){
                if(Array.isArray(sendData[keys[i]])){
                    obj[keys[i]]=[]
                    sendData[keys[i]].forEach(value=>obj[keys[i]].push(value.id))
                }
                else{
                    obj[keys[i]]=sendData[keys[i]]
                }
            }
        }
        
        setFiltre(obj)
        setShowModalAdd(obj)
        setRelance(Date.now())
    }

    const addItemsSubmit = async (ItemsToAdd) =>{
        setValidButton(true)

        if(props.from=='fideliteRecompenseConfigure'){
            let obj = {
                ...ItemsToAdd,
            }

            const response = await axios.post(process.env.REACT_APP_API_URL+props.urlAddItems+props.cumulPointVal, obj
            ).then((response) => {
                if(!response.data.valid){
                    let interms='';
                    var keys = Object.keys(response.data.errors);
                    for(var i = 0; i < keys.length; i++){
                        interms+=response.data.errors[keys[i]].join(',')+','
                    }
                    interms=interms.split(',')
                    let messageError='';
                    interms.forEach(interm=>messageError+=t(interm)+'. ')
                    messageError=messageError.replace('undefined','');
                    swal.fire(messageError, "", "error");
                }
                else {
                    props.setCumulPointVal('')
                    props.setParamFidelite([...props.paramFidelite,response.data.datas])
                    resetFiltreItemsToPromo()
                    setValidButton(false)
                    setShowModalAdd(false)
                }
            }).catch((error) => {
                console.log(error)
                if(error=='end_session') return;
                let messageError
                if(error.response==undefined){
                    messageError='unknowError'
                }
                else if(error.response.status==403){
                    messageError='forbiddenGeneral'
                }
                else {
                    messageError='unknowError'
                }
                
                swal.fire(t(messageError), "", "error");
                setValidButton(false)
            })
        }else{
            let obj = {
                ...ItemsToAdd,
                action:selectValue.action
            }
    
            //pour les promos on à besoin de spécifier sur quelle liste les articles iront
            let endUrl = (props.from=='promo') ? "/"+selectValue.list : ''
    
            const response = await axios.post(process.env.REACT_APP_API_URL+props.urlAddItems+id+endUrl, obj
            ).then((response) => {
                resetFiltreItemsToPromo()
                setValidButton(false)
                setShowModalAdd(false)
                setSelectValue({
                    list:'1',
                    action:'ajout'
                }) 
                if(selectValue.list=='1'){//Pour les promos, selon la liste d'article à remplir on vérifie laquelle on veut relancer, mais par défaut en relance la liste une 
                    props.setReload(Date.now())
                }else{
                    props.setReloadSecond(Date.now())
                }
            }).catch((error) => {
                if(error=='end_session') return;
                let messageError
                if(error.response==undefined){
                    messageError='unknowError'
                }
                else if(error.response.status==403){
                    messageError='forbiddenGeneral'
                }
                else {
                    messageError='unknowError'
                }
                
                swal.fire(t(messageError), "", "error");
                setValidButton(false)
            })
        }
    }

    return (<>
        <Modal show={showModalAdd} onHide={() => setShowModalAdd(false)} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>{t('addItems')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ModalAddItemToList 
                    loading={loading} 
                    sizeDatas={sizeDatas} 
                    selectValue={selectValue} 
                    setSelectValue={setSelectValue} 
                    addItemsSubmit={addItemsSubmit} 
                    showModalAdd={showModalAdd} 
                    setShowModalAdd={setShowModalAdd}
                    validButton={validButton}
                    relatedItemsList={props.relatedItemsList}
                    from={props.from}
                />
            </Modal.Body>
        </Modal>
        <CardPerso header={
                <span className="d-flex justify-content-between align-items-center">
                    {t('itemsSelection')}
                    <span>
                        <button 
                            className="btn btn-danger" 
                            onClick={()=>props.setShowAccordion(false)}
                        >{t('btnClose')}
                        </button>
                    </span>
                </span>
            }>
            {props.children}
            {(props.cumulPointVal == undefined || props.cumulPointVal!='' ) &&
                <div className="row">
                    <div className="col">
                        <ArticleFilterSearch2
                            liaisons={props.liaisons}
                            sizeDatas={sizeDatas}
                            customFields={props.customFields}
                            setSizeDatas={setSizeDatas} 
                            setFiltre={setFiltre}
                            setReload={props.setReload}
                            customInterface={props.customInterface}
                            setCustomInterface={props.setCustomInterface}
                            sendData={sendData}
                            setSendData={setSendData}
                            setShowModalCustomFiltre={setShowModalCustomFiltre}
                            showModalCustomFiltre={showModalCustomFiltre}
                            initAllFilter={{}}
                            interface='filtreArticle' //Variable qui permet d'identifier l'url sur laquelle filtrer
                            hardData={articleHardData}
                            moduleName='items'

                            tableName={props.tableName}
                            setToast={props.setToast}

                            addItemPromoBtn='true'
                            resetFiltreItemsToPromo={resetFiltreItemsToPromo}
                            filterItemsPromo={filterItemsPromo}
                            disabledAddSelection={disabledAddSelection}
                            loading={loading}
                        >
                        </ArticleFilterSearch2>
                    </div>
                </div>
            }
        </CardPerso>
        </>
    )
}

export default AddItemToList;
