import React, { useEffect } from 'react';
import { useTranslate } from 'react-redux-multilingual'
import { useForm } from "react-hook-form";
import { tgc_list } from "app/tgc";

const EditPriceForm = (props) =>{
    const t = useTranslate();
    const { register, formState: { errors }, handleSubmit, setValue } = useForm();

    useEffect(function () {
        setValue(props.editName,props.defaultValue)
        if(props.editName=='price' || props.editName=='price_b2b_ttc') setValue('tgc',props.defaultValue2)//Si on edit le prix, on peut en meme temps éditer la tgc, c'est pour cela qu'on set aussi la valeur de la tgc 
    }, [])

    const handleChangeNumber = (e,name) => {
        setValue(name,e.target.value.replace(/\D/g,''));   
    }

    return (
        <form onSubmit={handleSubmit(props.onSubmit)}>
            {(props.editName=='price' || props.editName=='price_b2b_ttc') ?
                <span className='row'>
                    <div className='col-6'>
                        {props.editName=='price_b2b_ttc' ?
                            <input autoComplete='off' disabled type="text" className='form-control' value={props.defaultValue}/>
                        :
                            <input autoComplete='off' disabled={props.validButton} type="text" className='form-control' {...register(props.editName,{onChange:(e) => handleChangeNumber(e,props.editName)})}/>
                        }
                        
                        
                    </div>
                    <div className='col-6'>
                        <select disabled={props.validButton} className="form-control" {...register('tgc')}>
                            {tgc_list.map((value,index)=>
                                <option key={index} value={value}>{value}%</option>
                            )}
                        </select>
                    </div>
                </span>
            :
                <input autoComplete='off' disabled={props.validButton} type="text" className='form-control' {...register(props.editName,{onChange:(e) => handleChangeNumber(e,props.editName)})}/>
            }
            <button disabled={props.validButton} className="btn btn-sm btn-primary mt-3">
                {props.validButton ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : t('btnValidate')}
            </button>
        </form>
    )
};

export default EditPriceForm;