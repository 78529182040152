import React, { useState } from "react";
import { useTranslate } from 'react-redux-multilingual'
import { Form } from "react-bootstrap";

const CheckBoxCampany = (props) =>{
    const t = useTranslate()
    
    let  checkedShop = new Array;
    if(props.modalEditId && Object.keys(props.promoOne).length!=0){
        if(props.isCompany=='true'){
            if(props.promoOne.company.length!=0)  checkedShop.push(props.promoOne.company[0].id) 
        }else{
            if(props.promoOne.other_company.length!=0){
                props.promoOne.other_company.map(value=>
                    checkedShop.push(value.id)    
                )
            }
        }
    }
    
    let checkedValue = (!props.modalEditId) ? false : (checkedShop.includes(props.otherCompany.id)) ? true : false

    const [checked,setChecked]=useState(checkedValue)

    const handleChange = (e) => {
        setChecked(!checked);

        //Lors de la sélection de company, Si la promo est une promo panier, on vide les champs suivants 
        if(props.showInputPromo && props.showInputPromo!='percent' && props.showInputPromo!='franche' && props.showInputPromo!='prixfixe'){
            props.setShowInputPromo(false)
            props.reset({
                'type':'',
                'value.0':'',
                'value.1':'',
                'begin':'',
                'end':'',
                'value':'',
            })
        }

        let companyId = (props.isCompany=='true') ? props.otherCompany.id+'-company' : props.otherCompany.id

        if(props.selectedCompany.includes(companyId)){
            let newArr = props.selectedCompany.filter(value=>value!=companyId)
            props.setSelectedCompany(newArr)
        }else{
            props.setSelectedCompany([...props.selectedCompany,companyId]) 
        }
    }

    return (
        <Form.Check
            {...props.register("other_company[]")}
            type="switch"
            id="custom-switch"
            disabled={props.showInputPromo && props.showInputPromo!='percent' && props.showInputPromo!='franche' && props.showInputPromo!='prixfixe'}
            checked={checked}
            value={(props.isCompany=='true') ? props.otherCompany.id+'-company' : props.otherCompany.id}//'-company' est rajouté pour différencier la company d'une other company qui pourrait avoir le meme ID
            label={props.otherCompany.code}
            onChange={(e) => handleChange(e)}
        />
    )
}

export default CheckBoxCampany;
