import React,{useState,useEffect} from "react";
import { useTranslate } from 'react-redux-multilingual'
import {AddPaymentRecord} from '@gull'
import axios from 'axios'
import swal from "sweetalert2";
import LinePaymentRecord from './Comp/LinePaymentRecord'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle} from '@fortawesome/free-solid-svg-icons';

const ModalChangePaymentMethod = (props) =>{
    const t =useTranslate()
    const [allPaymentRecords,setAllPaymentRecords]=useState([])
    const [ticketId,setTicketId]=useState(false)
    const [ticketDetail, setTicketDetail]=useState({})
    const [disabledAddPayLine,setDisabledAddPayLine]=useState(false) 
    
    const [loading,setLoading]=useState({
        data:false,
    })

    let url = (props.from=='commande') ? "api/pos/commande/get_by_reference/"+props.barTicket+"/"+props.posid : "api/pos/ticket/get_by_reference/"+props.barTicket

    useEffect(function () {
        (async function() {
            setLoading({...loading,data:true})
            const response = await axios.get(process.env.REACT_APP_API_URL+url
            ).then((response) => {
                if(!response.data.valid){
                    let interms='';
                    var keys = Object.keys(response.data.errors);
                    for(var i = 0; i < keys.length; i++){
                        interms+=response.data.errors[keys[i]].join(',')+','
                    }
                    interms=interms.split(',')
                    let messageError='';
                    interms.forEach(interm=>messageError+=t(interm)+'. ')
                    messageError=messageError.replace('undefined','');
                    swal.fire(messageError, "", "error");
                }
                else{   
                    if(props.from=='commande'){
                        setAllPaymentRecords(response.data.data.ticket.paiement_records)
                        setTicketId(response.data.data.ticket.id)
                        setTicketDetail(response.data.data.ticket)
                    }else{
                        setAllPaymentRecords(response.data.data.paiement_records)
                        setTicketId(response.data.data.id)
                        setTicketDetail(response.data.data)
                    }
                    setLoading({...loading,data:false})
                }
            }).catch((error) => {
                if(error=='end_session') return;
                let errorName = (error.response==undefined) ? 'unknowError' : (error.response.status==403) ? 'forbiddenGeneral' : 'unknowError'
                swal.fire(t(errorName), "", "error");
            })
            setLoading({...loading,data:false})
        })()
    }, [])

    if(loading.data){
        return(
            <span className="d-flex justify-content-center"><div className="spinner-border spinner-border-sm"></div></span>
        )
    }else{
        return(
            <div className="row">
                <div className="col">
                    <div className="table-responsive">
                        <table
                        className="table"
                        >
                            <thead>
                                <tr>
                                    <th scope="col">{t('modePaiement')}</th>
                                    <th scope="col" className="d-flex justify-content-between align-items-center">
                                        {t('amount')}
                                        {props.from!='pos' ? 
                                            <AddPaymentRecord 
                                                allPaymentRecords={allPaymentRecords} 
                                                setAllPaymentRecords={setAllPaymentRecords} 
                                                disabledAddPayLine={disabledAddPayLine} 
                                                setDisabledAddPayLine={setDisabledAddPayLine} 
                                            />
                                                :
                                            false
                                        }
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {(allPaymentRecords.length!=0 && Object.keys(ticketDetail).length!=0) ?
                                    allPaymentRecords.map((value,index)=>{
                                        return(
                                            <LinePaymentRecord
                                                key={index}
                                                value={value}
                                                currentShop={props.currentShop}
                                                devise={props.devise}
                                                digit={props.digit}
                                                posid={props.posid}
                                                ticketId={ticketId}
                                                allPaymentRecords={allPaymentRecords}
                                                setAllPaymentRecords={setAllPaymentRecords}
                                                dataTicket={props.dataTicket}
                                                setDataTicket={props.setDataTicket}
                                                pos_id={props.pos_id}
                                                caisseIdent={ticketDetail.caisse_ident_id}
                                                module={props.module}
                                                from={props.from}
                                                setDataSaleOrder={props.setDataSaleOrder}
                                                setDisabledAddPayLine={setDisabledAddPayLine} 
                                            />
                                        )
                                    })
                                :
                                    <tr>
                                        <td>{t('noPaymentMethodToEdit')}</td>
                                        <td></td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )    
    }
}

export default ModalChangePaymentMethod;