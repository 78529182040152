import React from "react";
import { useTranslate } from 'react-redux-multilingual'
import { CardPerso,ObjectifSectionChart } from "@gull";


const ObjectifDay = (props) => {
    const t = useTranslate()

    return (<>
        <div className="row">
            <div className="col-xl-3 col-md-6">
                <CardPerso header={"CA TTC - "+t('shop')}>
                    <ObjectifSectionChart isFor="cattc" data={props.data} real={props.real}/>
                </CardPerso>
            </div>
            {(props.from=='erp') && 
                <div className="col-xl-3 col-md-6">
                    <CardPerso header={"CA HT - "+t('shop')}>
                        <ObjectifSectionChart isFor="ca" data={props.data} real={props.real}/>
                    </CardPerso>
                </div>
            }
            <div className="col-xl-3 col-md-6">
                <CardPerso header={"PM TTC - "+t('shop')}>
                    <ObjectifSectionChart isFor="pmttc" data={props.data} real={props.real}/>
                </CardPerso>
            </div>
            {(props.from=='erp') && 
                <div className="col-xl-3 col-md-6">
                    <CardPerso header={"PM HT - "+t('shop')}>
                        <ObjectifSectionChart isFor="pm" data={props.data} real={props.real}/>
                    </CardPerso>
                </div>
            }
            <div className="col-xl-3 col-md-6">
                <CardPerso header={"IV - "+t('shop')}>
                    <ObjectifSectionChart isFor="iv" data={props.data} real={props.real}/>
                </CardPerso>
            </div>
            {(props.data.entre!=0) ?
                <div className="col-xl-3 col-md-6">
                    <CardPerso header={"TF - "+t('shop')}>
                        <ObjectifSectionChart isFor="tf" data={props.data} real={props.real} />
                    </CardPerso>
                </div>
                :
                <div className="col-xl-3 col-md-6">
                    <CardPerso header={"TF - "+t('shop')}>
                        <div className="alert alert-danger">{t('needEntre')}</div>
                    </CardPerso>
                </div>
            }
        </div>
    </>)
}

export default ObjectifDay;