import React from "react";
import { useTranslate } from 'react-redux-multilingual'

const Raccourci = (props) => {
    const t =useTranslate()

    const handleClick = (title) => {
        if(title=='giftcard'){
            props.raccourciAction(title,false,false)
        }else if(title=='article'){
            props.raccourciAction(title,props.raccourciTypeId,false)
        }else{
            props.raccourciAction(title,false,props.raccourciTypeId)
        }
    } 

    let raccourciTitle = props.title.toUpperCase()
    let raccourciColor = (props.raccourciType=='giftcard') ? "col-12 bg-primary rounded raccourciPos text-white" : (props.raccourciType=='article') ? "col-12 rounded raccourciPos text-white styleImgRaccourci" : "col-12 bg-info rounded raccourciPos text-white"

    return (
        <div className={`${(window.screen.width>1200) ? "col-4" : "col-6"} position-relative`} style={{padding:"1px"}}>
            <div className={raccourciColor} onClick={() => handleClick(props.raccourciType)}>
                <div className="textAlign" style={{width:'100%', height:'100px'}}>
                    {(props.raccourciType=='article' && props.image!=null) ?
                        <img src={props.image.split(';')[0]} alt={props.title} className="imgRaccourci"/>
                    :
                        raccourciTitle
                    }
                </div>
            </div>
            {(props.raccourciType=='article' && props.image!=null) &&
                <>
                    <div className="itemTitle pointer text-center rounded-bottom" onClick={() => handleClick(props.raccourciType)}>{raccourciTitle}</div>
                </>
            }
            {(props.disabled) ?
                <div className="disabled rounded">
                </div>
            :
                false
            }
        </div>
    )
}

export default Raccourci;  