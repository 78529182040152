import React from 'react';
import { useTranslate } from 'react-redux-multilingual';
import swal from "sweetalert2";


const SelectFields = (props)=> {
    const t = useTranslate();
    
    const handleChange = (e) => {
        if(props.multipleCards==='true'){
            let arr = [];
            //On crée un nouveau tableau avec les valeurs de tous les tableaux existant
            Object.entries(props.datas).forEach(([keyName, data]) => {
                arr = arr.concat(data)
            })
            if (e.target.value){
                //On crée un tableau qui reprend les valeurs du tableau sélectionné 
                let newArr = props.datas[props.keyName]
                //On rajoute la nouvelle valeur dans le nouveau tableau créé
                newArr.push(e.target.value)
                //On met à jour mon objet initial en indiquant que le nouveaut tableau remplaçera les valeurs de la clé sélectionné
                props.setDatas({...props.datas,[props.keyName]: newArr})
            }
        }else{
            if (e.target.value){
                if(props.datas.length>0){
                    props.setDatas([...props.datas, e.target.value])
                }else{
                    props.setEmptyConf(false)
                    props.setNoDefaultConf(false)
                    props.setDatas([e.target.value])
                }
            }
        }
    }

    return(
        <div className="row ">
            <form onChange={(e) => handleChange(e)}>
                <div className="col form-group">
                    <label className="col-form-label col-form-label-sm">{t('labelSelectConfigue')}</label>
                    <select className="form-control form-control-md" >
                        <option value="">-- Select --</option>
                        {(props.fields) && props.fields.map((value,index)=>
                                <option key={index} value={value}>
                                    {
                                        (value==='name')? t('itemName') : 
                                        (value==='price')? t('priceTtcB2c') :
                                        (value==='price_b2b')? t('priceHtB2b') :
                                        (value==='indice')? t('prmpIndice') :
                                        (value==='image')? t('imageLink') :
                                        (value==='quota_commande')? t('quantity') :
                                        (value==='commande')? t('orderDate') :
                                        (value==='expedition')? t('expectedDeliveryDate') :
                                        (value==='reception')? t('expectedReceiptDate') :
                                        (value==='user')? t('receivedBy') :
                                        (value.startsWith('name_'))? t('packagingName')+' '+value.split('_')[1] :
                                        (value.startsWith('reference_'))? t('barcodePackaginglist')+' '+value.split('_')[1] :
                                        (value.startsWith('quantity_'))? t('packagedQuantity')+' '+value.split('_')[1] :
                                        (value.startsWith('price_') && value!='price_b2b')? t('price')+' '+value.split('_')[1] :
                                        t(value) ? t(value) : 
                                        value
                                    } 
                                </option>
                            )
                        }
                    </select>
                </div>
            </form>
        </div>
    )
}

export default SelectFields ;