import React,{useState} from "react";
import { useTranslate } from 'react-redux-multilingual'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle} from '@fortawesome/free-solid-svg-icons';
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const AddPaymentRecord = (props) =>{
    const t =useTranslate()

    const addNewPayRecordLine = () =>{
        props.setAllPaymentRecords([...props.allPaymentRecords, {gift_card:null,mode:'',montant:'',id:null}])
        props.setDisabledAddPayLine(true)
    }


    return(
        <OverlayTrigger
            placement="bottom"
            overlay={
                <Tooltip>
                    {t('addNewPayment')}
                </Tooltip>
            }
        >
            <div>
                {(props.disabledAddPayLine) ? 
                    <FontAwesomeIcon className="text-light" icon={faPlusCircle}/>
                :
                    <FontAwesomeIcon className="text-success pointer" icon={faPlusCircle} onClick={()=>addNewPayRecordLine()}/>
                }
            </div>
        </OverlayTrigger>  
    )    
}

export default AddPaymentRecord;