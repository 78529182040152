import React, {useState,useContext,useEffect} from 'react';
import { useTranslate } from 'react-redux-multilingual'
import {Loading} from '@gull'
import {PDFViewer} from '@react-pdf/renderer';
import ExportDatatablePdf from '../../../app/views/Pdf/ExportDatatablePdf'  
import {IsAuthContext} from 'app/App'

const GeneratePdfExport = (props) => {
    const t = useTranslate();
    const [orientationPdf,setOrientationPdf] = useState("landscape") 
    const { authParam } = useContext(IsAuthContext);
    const [showPdf,setShowPdf] = useState(true)

    useEffect(function () {
        setShowPdf(false)
        setTimeout(() => {
            setShowPdf(true)
        }, 100);
    }, [props.columnOrganize])

    return(
        <div className='mb-2'>
            <div>
                <button className={`btn btn-sm ${orientationPdf=='landscape' ? 'btn-success' : 'btn-outline-success'} mb-3`} onClick={()=>setOrientationPdf("landscape")}>{t('landscape')}</button>&nbsp;&nbsp;
                <button className={`btn btn-sm ${orientationPdf=='portrait' ? 'btn-warning' : 'btn-outline-warning'} mb-3`} onClick={()=>setOrientationPdf("portrait")}>{t('portrait')}</button>
            </div>
            <div style={{height:'80vh'}}>
                {props.showAccordion.pdf ?
                    <PDFViewer style={{width:'100%',height:'100%'}}>
                        {showPdf &&
                            <ExportDatatablePdf 
                                orientationPdf={orientationPdf}
                                t={t}
                                dataHeadPdfExport={props.dataHeadPdfExport}
                                columnShow={props.columnShow.filter(value=>value!='actions' && value!='action' && value!='masque')}
                                columnOrganize={props.columnOrganize.filter(item=>item.dataField!='actions' && item.dataField!='action' && item.dataField!='masque')}
                                bodyData={props.bodyData}
                                typeDocument={props.typeDocument}
                                authParam={authParam}
                            />
                        }
                    </PDFViewer>
                :
                    false
                }
            </div>
        </div>
    )
}

export default GeneratePdfExport;