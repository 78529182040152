import React, { useState } from 'react';
import { useTranslate } from 'react-redux-multilingual'
import swal from "sweetalert2";
import axios from "axios";
import Moment from 'react-moment';

const CommandeListPos = (props) =>{
    const t = useTranslate();

    const handleClick = (commandeId) =>{
        props.setModalTitle(t('salesOrder'))
        props.setShow(true)
        const test = axios.get(process.env.REACT_APP_API_URL+"api/pos/commande/get_by_id/"+commandeId+'/'+props.posid
        ).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach(interm=>messageError+=t(interm)+'. ')
                messageError=messageError.replace('undefined','');
                swal.fire(messageError, "", "error");
            }else{  
                if(response.data.data.cancel==1 || response.data.data.ticket.status==1){
                    props.setLastSaleOrder(false)
                }else{
                    props.setLastSaleOrder(response.data.data)
                    
                }
            }
        }).catch((error) => {
            if(error=='end_session') return;
            let errorName = (error.response==undefined) ? 'unknowError' : (error.response.status==403) ? 'forbiddenGeneral' : 'unknowError'
            swal.fire(t(errorName), "", "error");
        })
    }
    
    return (
        <div className='col-12 commandeCaisse'>
            <ul className="list-group list-group-flush">
                {props.dataFiltered.length!=0 ?
                    props.dataFiltered.map((value,ind) => {
                        let style = (ind%2 == 0) ? 'stripeBg stripePadding px-2 tableLineClick row' : 'stripePadding px-2 tableLineClick row';

                        return(
                            <li key={value.id} className={style} onClick={()=>handleClick(value.id)}>
                                <span className='col-4 fw-bold fs-5'>{'N° : '+value.numero}</span>
                                <span className='col-4' style={{fontSize:'0.8em'}} >
                                    {(value.prevu!=null) ?
                                        <Moment format="DD/MM/YYYY HH:mm:ss">{value.prevu}</Moment>
                                    :
                                        <span>-</span>
                                    }
                                </span>
                                <span className='col-4' style={{fontSize:'0.8em'}} >{value.ticket.client.firstname+' '+value.ticket.client.name}</span>
                            </li>
                        )
                    })
                :
                    <span className='text-center mt-5 fw-bold'>{t('noSalesOrder')}</span>
                }                
            </ul>
        </div>
    )
};

export default CommandeListPos;