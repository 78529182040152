import React, {useState,useContext,useEffect} from 'react';
import { useTranslate } from 'react-redux-multilingual'
import { IsAuthContext } from 'app/App';
import swal from "sweetalert2";
import axios from 'axios'

const PriorityPriceForm = (props) => {
    const t = useTranslate();
    const { authParam } = useContext(IsAuthContext);
    const [validButton,setValidButton] = useState(false)
    const [success, setSuccess] = useState(false)
    const [priorityPice,setPriorityPrice] = useState('')

    useEffect(function () {
        setPriorityPrice(props.defaultValue)
    }, [props.defaultValue])

    const submit = async (e) => {
        e.preventDefault()
        setValidButton(true)

        if(props.from!='clientType' || (props.from=='clientType' && priorityPice!='')){
            let obj={
                article_price: priorityPice=='' ? null : priorityPice
            }

            if(props.from=='clientType') obj['client_type']=props.clientType

            let method = props.from=='clientType' ? axios.post : axios.put

            const response  = await method(process.env.REACT_APP_API_URL+props.url, obj
            ).then((response)=> {
                if(!response.data.valid){
                    let interms='';
                    var keys = Object.keys(response.data.errors);
                    for(var i = 0; i < keys.length; i++){
                        interms+=response.data.errors[keys[i]].join(',')+','
                    }
                    interms=interms.split(',')
                    let messageError='';
                    interms.forEach(interm=>messageError+=t(interm)+'. ')
                    messageError=messageError.replace('undefined','');
                    swal.fire(t(messageError), "", "error");
                }else {
                    setSuccess(true)
                }
            }).catch((error)=>{
                if(error=='end_session') return;
                let messageError
                if(error.response==undefined){
                    messageError='unknowError'
                }else if(error.response.status==403){
                    messageError='forbiddenGeneral'
                }else {
                    messageError='unknowError'
                }
                swal.fire(t(messageError), "", "error");
            })
            setValidButton(false)
            setTimeout(()=>{
                setSuccess(false)
            }, 1000)
        }
        if(props.from=='clientType' && priorityPice==''){//Pour retirer une configuration de prix prioritaire sur les types de clients
            const response  = await axios.delete(process.env.REACT_APP_API_URL+'api/company/client_type_article_price/'+props.clientTypeDeleteId
            ).then((response)=> {
                if(!response.data.valid){
                    let interms='';
                    var keys = Object.keys(response.data.errors);
                    for(var i = 0; i < keys.length; i++){
                        interms+=response.data.errors[keys[i]].join(',')+','
                    }
                    interms=interms.split(',')
                    let messageError='';
                    interms.forEach(interm=>messageError+=t(interm)+'. ')
                    messageError=messageError.replace('undefined','');
                    swal.fire(t(messageError), "", "error");
                }else {
                    setSuccess(true)
                }
            }).catch((error)=>{
                if(error=='end_session') return;
                let messageError
                if(error.response==undefined){
                    messageError='unknowError'
                }else if(error.response.status==403){
                    messageError='forbiddenGeneral'
                }else {
                    messageError='unknowError'
                }
                swal.fire(t(messageError), "", "error");
            })
            setValidButton(false)
            setTimeout(()=>{
                setSuccess(false)
            }, 1000)
        }
    };

    return(<form onSubmit={(e)=>submit(e)}>
        <span className='row'>
            <span className="col-10">
                <select disabled={validButton} value={priorityPice} className='form-control' onChange={(e)=>setPriorityPrice(e.target.value)}>
                    <option value=''>{t('noPriorityPricing')}</option>
                    {authParam.company.advanceparams.hasOwnProperty('prix_supplementaire') && authParam.company.advanceparams.prix_supplementaire.length!=0 &&
                        authParam.company.advanceparams.prix_supplementaire.map((value,index)=>{
                            let numero = index+1
                            if(value['destination_'+numero]==props.isFor){
                                return(
                                    <option key={numero} value={value['designation_'+numero]}>{value['designation_'+numero]}</option>
                                )
                            }
                        })
                    }
                </select>
            </span>
            <span className="col-2">
                <button disabled={validButton} className="btn btn-primary">
                    {validButton ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : t('btnValidate')}
                </button>
            </span>
            {(success) ? <div className="alert alert-success mt-3">{t('successOperation')}</div> : false}
        </span>
        </form>
    )
}

export default PriorityPriceForm;