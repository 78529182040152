import React,{useState} from "react";
import {ScanBadgeInventory} from '@gull'
import { useTranslate } from 'react-redux-multilingual';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Modal } from "react-bootstrap";

// Style import
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardCheck } from '@fortawesome/free-solid-svg-icons';

const  ManualCountingButton = (props) => {
    const t = useTranslate();
    const [show,setShow]=useState(false)

    const handleClick = () => {
        setShow(true)
        
    }

    return (
        <>
            <Modal size="md" show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Staff</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {(props.section) ? 
                        <ScanBadgeInventory path={props.path} section={props.section} id={props.id} divisionId={props.divisionId} setShow={setShow} method='division'/>
                    :
                        <span className="d-flex justify-content-center"><div className="spinner-border spinner-border-sm"></div></span>
                    }
                </Modal.Body>
            </Modal>
            <OverlayTrigger
                placement="bottom"
                overlay={
                    <Tooltip id={`tooltip-${props.index}`}>
                        {t('manualCounting')}
                    </Tooltip>
                }
            >
                <span onClick={() => handleClick()} style={{cursor:'Pointer', color:'#9da6c4'}}><FontAwesomeIcon icon={faClipboardCheck} /></span>
            </OverlayTrigger> 
        </> 
    )
}

export default ManualCountingButton;