import React, {useState} from 'react';
import { useTranslate } from 'react-redux-multilingual'
import { useForm } from "react-hook-form";
import axios from 'axios'

const ControlAccessLogUser = (props) => {
   const t = useTranslate();
   const { register, formState: { errors }, handleSubmit } = useForm();
   const [validButton,setValidButton] = useState(false)
   const [apiError,setApiError] = useState(false)

    const onSubmit = async (data) => {
        setValidButton(true)
        setApiError(false)

        const response  = await axios.post(process.env.REACT_APP_API_URL+"api/access/go_access/"+props.module+'/'+props.accessName, data
        ).then((response) => {
            //Une fonction callback qui retourne un boulean est utilisé ici, afin de pouvoir exécuter une logique précise selon le module où est appelé ControlAccessLogUser  
            if(!response.data.valid){
                props.accessCallback(false)
                setApiError(t('forbiddenGeneral'))
            }else{
                props.accessCallback(true)
            }
        }).catch((error) => {
            if(error=='end_session') return;
            if(error.response==undefined){
                setApiError(t('unknowError'))
            }
            else if(error.response.status==403){
                setApiError(t('forbiddenGeneral'))
            }
            else {
                setApiError(t('unknowError'))
            }
        })
        setValidButton(false)
    }

   return(
        <div className="auth-content">
            <div className="card o-hidden" style={{backgroundColor:"rgba(255,255,255)"}}>
                <div className="row">
                    <div className="col-md-7" style={{backgroundColor:"rgba(255,255,255)"}}>
                        <div className="p-4">
                            <h5 className="mb-2 text-center">{t('authorizationRequiredForThisAction')}</h5>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="form-group">
                                    <label htmlFor="email">Email</label>
                                    <input
                                    className="form-control form-control-rounded position-relative"
                                    type="email"
                                    {...register("email", { required: true})}
                                    />
                                    {errors.email?.type === 'required' && (
                                    <div className="text-danger mt-1 ml-2">
                                        {t('emailRequired')}
                                    </div>
                                    )}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="password">{t('password')}</label>
                                    <input
                                    className="form-control form-control-rounded"
                                    type="password"
                                    {...register("password", { required: true })}
                                    />
                                    {errors.password?.type === 'required' && (
                                    <div className="text-danger mt-1 ml-2">
                                        {t('passwordRequired')}
                                    </div>
                                    )}
                                </div>
                                <button
                                    className="btn btn-rounded btn-primary w-100 my-1 mt-2"
                                    type="submit"
                                    disabled={validButton}
                                >
                                    {validButton ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : t('btnValidate')}
                                </button>
                                {apiError ? <div className='mt-2 alert alert-danger'>{apiError}</div> : false}
                            </form>
                        </div>
                    </div>
                    <div
                        className="col-md-5 text-center "
                        style={{
                            backgroundSize: "cover",
                            backgroundColor: "rgba(0,0,0)"
                        }}
                    >
                        <div className="pe-3 auth-right">
                            <img className="img-fluid p-3" src="/assets/images/logo.png"  />
                        </div>
                    </div>
                </div>
            </div>
        </div>
   )
}

export default ControlAccessLogUser;