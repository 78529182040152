import "../fake-db";
import React, { useState, useEffect, Suspense } from "react";
import "../styles/app/app.scss";
import { Provider } from "react-redux";
import { Router, useLocation } from "react-router-dom";
import AppContext from "./appContext";
import history from "@history";
import routes from "./RootRoutes";
import { Store } from "./redux/Store";
import { renderRoutes } from "react-router-config";
import RootRoutes from "./RootRoutes";
import { Loading } from "@gull";
import { CookiesProvider, useCookies} from 'react-cookie';
import swal from "sweetalert2";


//translation
import translations from './translations'
import { IntlProvider } from 'react-redux-multilingual'
import { IntlActions } from 'react-redux-multilingual'
import axios from 'axios'
require('dotenv').config()

export const IsAuthContext = React.createContext()
export const PeripheriqueContext = React.createContext()
export const BackgroundContext = React.createContext()

axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem("jwt_token");
  config.headers.Authorization =  "Bearer " + token;
  return config;
});

//Pour le cookie, durée du cookie 5 minutes
let current_date = new Date()
let timestampdate = current_date.setTime(current_date.getTime()+(5*60*1000));
let expire_date = new Date(timestampdate)

function useAxiosInterceptor() {
  const [cookies, setCookie] = useCookies(['lang','end_session'])
  let my_lang = (cookies.lang!=undefined) ? cookies.lang: 'fr'
  useEffect(() => {
    const interceptor = axios.interceptors.response.use(
      (response) => {
        if (response.data.token) {
          localStorage.setItem('jwt_token', response.data.token);
        }
        return response;
      },
      (error) => {
        if (error.response && error.response.status === 401) {
          let last_url = window.location.pathname
          swal.fire({
            title: translations[my_lang].messages.expireSession,
            icon: 'error',
            showConfirmButton: false,
            allowOutsideClick: false,
          });
          setTimeout(() => {
            //si la session à expirée, un cookie de 5 minutes avec la derniere url sera créé
            //si on se reconnecte dans le temps, on sera redirigé vers la derniere url, sinon vers la page d'accueil 
            setCookie("end_session",last_url,{ path: "/", expires: expire_date }) 
            window.location.href = '/session/signin';
          }, 3000);
          return Promise.reject('end_session');
        }
        return Promise.reject(error);
      }
    );
    return () => {
      axios.interceptors.response.eject(interceptor);
    };
  }, []);
}

function ProvideAuth({ children }) {
  const [authParam, setAuthParam] = useProvideAuth();
  return (
    <IsAuthContext.Provider value={{authParam,setAuthParam}}>
      {children}
    </IsAuthContext.Provider>
  );
}

function ProvidePeripherique({ children }) {
  const [peripheriqueParam, setPeripheriqueParam] = useState(false);

  return (
    <PeripheriqueContext.Provider value={{peripheriqueParam,setPeripheriqueParam}}>
      {children}
    </PeripheriqueContext.Provider>
  );
}

function ProvideBackground({ children }) {
  const [backgroundParam, setBackgroundParam] = useState("#ecf0f5");

  return (
    <BackgroundContext.Provider value={{backgroundParam,setBackgroundParam}}>
      {children}
    </BackgroundContext.Provider>
  );
}

let baseAuthParam = {
  isauth : 'wait',
  userid: false,
  user: false,
  company: false,
  modules: false,
  roles: false,
  sections: false
}

const useProvideAuth = (initial = baseAuthParam) => {
  const [authParam,setAuthParam] = useState(initial);
  const uninterceptedAxiosInstance = axios.create();

  useEffect(function () {
      (async function() {
          const response = await uninterceptedAxiosInstance.get(process.env.REACT_APP_API_URL+"api/auth/me",{headers: {'Authorization': 'Bearer ' + localStorage.getItem("jwt_token")}}
          ).then((response) => {
            setAuthParam({
              isauth:true,
              userid:response.data.id,
              user: response.data.name,
              company: response.data.company,
              modules: response.data.module,
              roles: response.data.role,
              sections: response.data.section,
              access:response.data.access
            })
          }).catch((error) => {
            setAuthParam({
              isauth : false,
              userid:false,
              user: false,
              company: false,
              logo: null,
              modules: false,
              roles: false,
              sections: false,
              access:false
            })
          })
      })()
  }, [])
  
  return [authParam, setAuthParam];
}

let cookieLang = document.cookie.replace(/(?:(?:^|.*;\s*)lang\s*\=\s*([^;]*).*$)|^.*$/, "$1");
let beginLang = (cookieLang !== '') ? cookieLang : 'fr';
Store.dispatch(IntlActions.setLocale(beginLang));

export function useChangeLang(initialLang = 'fr'){
  const [lang,setLang] = useState(initialLang)
  const [cookies, setCookie] = useCookies(['lang'])

  const langToggle = function (valeur)  {
      setLang(v => valeur)
      Store.dispatch(IntlActions.setLocale(valeur))
      let current_date = new Date()
      let timestampdate = current_date.setDate(current_date.getDate() + 90)
      let expire_date = new Date(timestampdate)
      setCookie('lang', valeur, { path: '/', expires: expire_date});
  }

  return [
      lang,
      langToggle
  ]
}


function App() {
  useAxiosInterceptor();

  return (
    <AppContext.Provider value={{ routes }}>
      <Provider store={Store}>
        <IntlProvider translations={translations}>
          <CookiesProvider>
            <ProvideBackground>
              <ProvidePeripherique>
                <ProvideAuth>
                    <Suspense fallback={<Loading></Loading>}>
                      <Router history={history}>{renderRoutes(RootRoutes)}</Router>
                    </Suspense>
                </ProvideAuth>
              </ProvidePeripherique>
            </ProvideBackground>
          </CookiesProvider>
        </IntlProvider>
      </Provider>
    </AppContext.Provider>
  );
}

export default App;
