import React, { useState } from 'react';
import axios from 'axios';
import { useTranslate } from 'react-redux-multilingual';
import {CardPerso} from '@gull'
import swal from "sweetalert2";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const PriorityDock = (props)=> {
    const [dockPos, setDockPos] = useState(props.listDockToShow);
    const t = useTranslate();

    const [validButton,setValidButton] = useState(false)
    const [apiError,setApiError] = useState(false)
    const [success,setSuccess] = useState(false)
    
    function handleOnDragEnd(result) {
        if (!result.destination) return;
        
        const items = Array.from(dockPos);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        
        setDockPos(items);
    }

    const onSubmit = async () => {
        setSuccess(false)
        setApiError(false)
        setValidButton(true)

        let obj={section:[]}

        dockPos.map(value=>(
            obj.section.push(value.id)
        ))

        const response  = await axios.put(process.env.REACT_APP_API_URL+"api/company/priorea", obj
        ).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach(interm=>messageError+=t(interm)+'. ')
                messageError=messageError.replace('undefined','');
                setApiError(messageError)
            }
            else{
                setSuccess(t('successOperation'))
                props.setReload(Date.now())
            }
        }).catch((error) => {
            if(error=='end_session') return;
            let errorReturn
            if(error.response==undefined){
                errorReturn='unknowError'
            }
            else if(error.response.status==403){
                errorReturn='forbiddenGeneral'
            }
            else {
                errorReturn='unknowError'
            }
            swal.fire(t(errorReturn), "", "error");
        })
        setValidButton(false)
        setTimeout(() => {
            setSuccess(false)
          }, 1000);
    }
    
    return (
        <div>
            <CardPerso >
                <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="dock">
                        {(provided) => (
                            <ul  {...provided.droppableProps} ref={provided.innerRef} style={{paddingLeft:"0", listStyle: 'none'}}>
                                {dockPos.map((value, index) =>(
                                    <Draggable key={"dock"+value.code} draggableId={"dock"+value.code} index={index}>
                                        {(provided) => (
                                            <div className="row">
                                                <li 
                                                    ref={provided.innerRef} 
                                                    {...provided.draggableProps} 
                                                    {...provided.dragHandleProps}
                                                    className="badge badge-primary bg-primary mb-1 p-2" 
                                                >
                                                    <big>
                                                        {value.name}
                                                    </big>                                            
                                                </li>
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </ul>
                        )}
                    </Droppable>
                </DragDropContext>
                {(success) ? <div className="alert alert-success mt-1" dangerouslySetInnerHTML={{ __html: success }}></div> : false}
                {(apiError) ? <div className="alert alert-danger mt-1" dangerouslySetInnerHTML={{ __html: apiError}}></div> : false}
                <button 
                    className="btn btn-primary mb-1" 
                    onClick={()=> onSubmit()}
                >
                    {validButton ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : t('btnValidate')}
                </button>
            </CardPerso>
        </div>
    );
  }
  
  export default PriorityDock;