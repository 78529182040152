import React, {useState,useContext} from "react";
import { useTranslate } from 'react-redux-multilingual';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {ModalTagPrintForm} from "@gull";
import { PeripheriqueContext } from '../../../app/App';
import { Modal } from "react-bootstrap";
// Style import
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTag } from '@fortawesome/free-solid-svg-icons';
import { useCookies} from 'react-cookie';

const TagArticleBtn = (props) => {
    const t = useTranslate();
    const [showTagPrint,setShowTagPrint]=useState(false)
    const { peripheriqueParam } = useContext(PeripheriqueContext)  
    const [cookies] = useCookies(['peripheriqueTagErp']);

    let paramPeripherique = props.from=='erp' ? cookies : peripheriqueParam

    //qteToPrint est une valeur passé pour le moment uniquement depuis les po, afin d'imprimer autant d'étiquette qu'il y a de poduit reçu
    //TagArticleBtn est un composant pour le moment utilisé que dans le POS, c'est pour cela que la props from est passé en dur sur <ModalTagPrintForm />
    
    return (<>
            <Modal size="md" show={showTagPrint} onHide={() => setShowTagPrint(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('tagPrint')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ModalTagPrintForm articlePrice={props.articlePrice} articleName={props.articleName} articleUpc={props.articleUpc} peripheriqueParam={paramPeripherique} qteToPrint={props.qteToPrint} from={props.from}/>
                </Modal.Body>
            </Modal>
            <OverlayTrigger
                placement="bottom"
                overlay={
                    <Tooltip id={`tooltip-${props.index}`}>
                        {t('printItemTag')}
                    </Tooltip>
                }
            >
                <span onClick={() => setShowTagPrint(!showTagPrint)} className='pointer text-secondary'><FontAwesomeIcon icon={faTag} /></span>
            </OverlayTrigger> 
        </> 
    )
}

export default TagArticleBtn;