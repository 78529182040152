export const articleHardData = [
'reference',
'name',
'sku',
'price',
'price_b2b',
'tgc',
'parent_name',
'devise_achat',
'firstlevelcat',
'secondlevelcat',
'thirdlevelcat',
'fourthlevelcat',
'fifthlevelcat',
'fournisseur',
'marque',
];