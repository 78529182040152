import React,{useState} from "react";
import { useTranslate } from 'react-redux-multilingual'
import {FormatedPrice,ControlAccess} from "@gull"
import { modePaiementDefaultAll } from 'app/modePaiementDefaultAll';
import swal from "sweetalert2";
import axios from 'axios'

const LineRefund = (props) =>{
    const t =useTranslate()
    const [loading,setLoading]=useState(false)
    const [showRestriction,setShowRestriction]=useState(false) 
    const idsLocalStorage=localStorage.getItem("pos").split(',')
   

    const handleDelete = async () =>{
        setLoading(true)
        const response = await axios.post(process.env.REACT_APP_API_URL+"api/pos/ticket/construction/"+props.posid+"/"+idsLocalStorage[1]+"/"+idsLocalStorage[0],{
            id:props.cookies.current_ticket,
            delete_remboursement:true
        }).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach(interm=>messageError+=t(interm)+'. ')
                messageError=messageError.replace('undefined','');
                swal.fire(messageError, "", "error");
            }
            else{   
                props.setCurrentTicket(response.data.data)
                setLoading(false)
            }
        }).catch((error) => {
            if(error=='end_session') return;
            swal.fire(t('unknowError'), "", "error");
        })
        setLoading(false)
    }

    const handleClick = () =>{
        if(!props.restrictionRemoveLinePayment.go){
            setShowRestriction(true)
        }else{
            handleDelete()
        }
    }

    const callback = () =>{
        handleDelete() 
        setShowRestriction(false)
        props.setRestrictionRemoveLinePayment({...props.restrictionRemoveLinePayment,go:false})
    }

    const actionHideControlAccess = () =>{
        setShowRestriction(false)
    }
    
    return(
        <div className={props.style+'d-flex align-items-center'}>
            <div className="col">
                {(props.value.mode=='espece') ?
                    t('refundEspece')
                :
                    t('refundOther')
                }
            </div>
            <div className="col-4 text-center">
                <FormatedPrice langRegion={'fr-FR'} devise={props.devise} digit={props.digit} priceToFormat={props.value.montant} />
            </div>
            {(props.currentTicket.status==0) ?
                <div className="col-3">
                    <button className="btn btn-sm btn-danger" onClick={()=>handleClick()}>{(loading) ? <div className="spinner-border spinner-border-sm"></div> : t('btnCancel')}</button>
                </div>
            :
                false
            }
            {(showRestriction) ?
                <div className="col-12 mt-2">
                    <ControlAccess section={props.section} noMarge={true} focus={true} btnHideControlAccess={true} actionHideControlAccess={actionHideControlAccess} restriction={props.restrictionRemoveLinePayment} moduleId={props.posid} setRestriction={props.setRestrictionRemoveLinePayment} callback={callback} />
                </div>
            :
                false
            }
        </div>
    )
}

export default LineRefund;