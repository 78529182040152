import React from 'react';
import {Text} from '@react-pdf/renderer';
import { createGlobalStyle } from 'styled-components';

export const convertHtmlToText = (html) =>{
    
    function supprimerBalisesSpan(chaine) {
        //fonction qui retire la balise <span> avec son style dans la chaine de caractère écrite 
        const regex = /<span\b[^>]*>(.*?)<\/span>/gi;
        return chaine.replace(regex, "$1");
    }

    const parts = supprimerBalisesSpan(html).split(/(<\/?[a-z]+>)/);
    const htmlTag = ['<strong>','</strong>','</em>','<em>','\n','<p>']

    return (
        <Text>
            {parts.map((part, index) => {
                if(htmlTag.includes(part)){
                    if(part=='<strong>'){
                        if(parts[index+1].replace('&nbsp;', '') == '' && parts[index+2].replace('&nbsp;', '') == '<em>'){
                            //dans le cas où le texte soit en gras et italic
                            return (
                                <Text key={index} style={{fontFamily:'Rubik', fontWeight:"bold", fontStyle: "italic"}}>
                                    {parts[index+1].replace('&nbsp;', '')}
                                </Text>
                            );
                        }else{
                            //dans le cas où le texte soit en gras
                            return (
                                <Text key={index} style={{fontFamily:'Rubik', fontWeight:"bold"}}>
                                    {parts[index+1].replace('&nbsp;', '')}
                                </Text>
                            );
                        }
                    }else if(part=='<em>'){
                        //dans le cas où le texte soit en italic
                        return (
                            <Text key={index} style={{fontFamily:'Rubik', fontStyle: "italic"}}>
                                {parts[index+1].replace('&nbsp;', '')}
                            </Text>
                        );
                    }else if(part=='\n'){
                        //dans le cas d'un retour à la ligne
                        return (
                            <Text key={index}>{"\n"}</Text>
                        );
                    }else if(part=='<p>'){
                        //dans le cas où le texte n'a pas de style
                        return (
                            <Text key={index}>{parts[index+1].replace('&nbsp;', '')}</Text>
                        );
                    }else if(part=='</strong>' && parts[index+1]!='' || part=='</em>' && parts[index+1]!=''){
                        //dans le cas où le texte n'a pas de style
                        return (
                            <Text key={index}>{parts[index+1].replace('&nbsp;', '')}</Text>
                        );
                    }
                }
            })}
        </Text>
    )
}