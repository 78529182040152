import React, {useState} from "react";
import { useTranslate } from 'react-redux-multilingual'
import axios from 'axios'
import swal from "sweetalert2";

const ControlInventoryQuantityAdmin = (props) => {
    const t = useTranslate()
    const [manualModifCount,setManualModfiCount]=useState(false)
    const [inpuValue,setInputValue]=useState('')
    const [hasToBeCreate,setHasToBeCreate]=useState(false)

    const modifyStaffCount = async () => {
        let axiosParam = (hasToBeCreate) ? axios.post : axios.put
        let binsParam = (props.binsId!='') ? "/"+props.binsId : ""
        let url = (hasToBeCreate) ? "api/wms/inventaire_count/create_one/"+props.divisionId+"/"+props.rhId+binsParam : "api/wms/inventaire_count/edit_one/"+props.countId

        let obj={}

        if(hasToBeCreate){//dans le cas d'une création de comptage
            obj['article']=props.articleId
            obj['total']=inpuValue
        }else{
            obj['total']=inpuValue
        }

        const response  = await axiosParam(process.env.REACT_APP_API_URL+url,obj
        ).then((response)=> {
            if(response.data.valid){
                let data = props.divisionInfo[props.articleId+'dd'+props.binsId]
                data['rh'+props.rhId] = inpuValue
                props.setDivisionInfo({...props.divisionInfo,[props.articleId+'dd'+props.binsId]:data})
            }else {
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach(interm=>messageError+=t(interm)+'. ')
                messageError=messageError.replace('undefined','');
                swal.fire(messageError, "", "error");
            }
        }).catch((error)=>{
            if(error=='end_session') return;
            let messageError
            if(error.response==undefined){
              messageError='unknowError'
            }
            else if(error.response.status==403){
              messageError='forbiddenGeneral'
            }
            else {
              messageError='unknowError'
            }
            swal.fire(t(messageError), "", "error");
        })
        setHasToBeCreate(false)
    } 

    const handleChange = (e) =>{
        setInputValue(e.target.value)
    }

    const handleDbleClick = (count) =>{
        setManualModfiCount(true)
        setInputValue(count)
        //si il n'y a pas eu de comptage passe hasToBeCreate à true pour définir que c'est une création de comptage et non un edit
        if(count=='undefined') setHasToBeCreate(true)
    }

    const handleBlur = () =>{
        setManualModfiCount(false)
        modifyStaffCount()
    }

    const handleKeyPress = (e) =>{
        if(e.key === 'Enter' ){
            setManualModfiCount(false)
            modifyStaffCount()
        }
    }

    return (<>
        {(!manualModifCount) ? 
            <td onDoubleClick={()=>handleDbleClick(props.quantityCount)} className='text-center'>
                {props.quantityCount}
            </td>
        :
            <input 
                value={inpuValue}
                autoFocus
                type="number" 
                className="form-control"
                onBlur={()=>handleBlur()}
                onChange={(e)=>handleChange(e)}
                onKeyPress={(e)=>handleKeyPress(e)}
            />
        }
        </>
    );
}

export default ControlInventoryQuantityAdmin;
