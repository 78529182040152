import React, { useState,useEffect } from "react";
import {OrderFormNext,AlertCurrentReceipt} from "@gull"
import { useTranslate } from 'react-redux-multilingual'
import axios from 'axios'
import swal from "sweetalert2";

const SearchSalesOrder = (props) =>{
    const t = useTranslate();
    const [inputValue,setInputValue]=useState('')
    const [ticketSalesOrder,setTicketSalesOrder]=useState(false)
    const [load,setLoad]=useState({
        ticket:false,
    })

    useEffect(function () {
        const input = document.querySelector('#scanSalesOrder');
        setTimeout(()=>{
            input.focus()
        },300)
        

    }, [])

    const handleChange = (e) =>{
        setInputValue(e.target.value)
    }

    const handleSubmit = async (e)=>{
        e.preventDefault();
        setLoad({...load,ticket:true})

        const test = await axios.get(process.env.REACT_APP_API_URL+"api/pos/commande/get_by_reference/"+inputValue+'/'+props.posid
        ).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach(interm=>messageError+=t(interm)+'. ')
                messageError=messageError.replace('undefined','');
                swal.fire(messageError, "", "error");
            }else{  
                setTicketSalesOrder(response.data.data)
                setLoad({...load,ticket:false})
            }
        }).catch((error) => {
            if(error=='end_session') return;
            swal.fire(t('unknowError'), "", "error");
        })
        setLoad({...load,ticket:false})
        setInputValue('')
    }

    return(
        <>
            <div className="row d-flex justify-content-center mb-3">
                <div className="col-8">
                    <form onSubmit={(e)=>handleSubmit(e)}>
                        <input id="scanSalesOrder" disabled={props.ticketEnCours.message} value={inputValue} type="text" className="form-control" autoComplete="off" placeholder={t('scanSalesOrderHere')} onChange={(e)=>handleChange(e)}/>
                    </form>
                </div>
            </div>
            {(props.ticketEnCours.message) ?
                <AlertCurrentReceipt />
            :
                (load.ticket) ?
                    <span className="d-flex justify-content-center"><div className="spinner-border spinner-border-sm mt-2"></div></span>
                :   
                    (ticketSalesOrder && ticketSalesOrder.cancel==0 && ticketSalesOrder.ticket.status==0) ? 
                        <OrderFormNext 
                            ticketCommande={ticketSalesOrder}
                            setTicketCommande={setTicketSalesOrder}
                            posid={props.posid}
                            digit={props.digit}
                            devise={props.devise}
                            from='searchSaleOrder'
                            expire_date={props.expire_date}
                            cookies={props.cookies}
                            setCookie={props.setCookie}
                            removeCookie={props.removeCookie}
                            resetSale={props.resetSale}
                            closeModal={props.closeModal}
                            setIsSalesOrder={props.setIsSalesOrder}
                            setModalTitle={props.setModalTitle}
                            setShow={props.setShow}
                        />
                    :
                        (ticketSalesOrder && ticketSalesOrder.cancel==1) ?
                            <div className="alert alert-danger mt-3">{t('saleOrderCanceled')}</div>
                        :
                            (ticketSalesOrder && ticketSalesOrder.ticket.status==1) ?
                                <div className="alert alert-danger mt-3">{t('saleOrderFinish')}</div>
                            :
                                false
            }
        </>
    )
}

export default SearchSalesOrder;