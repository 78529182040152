import React,{useState,useEffect,useRef} from "react";
import { CardPerso,ArticleBarSearch,LineItemInventory} from '@gull';
import { useTranslate } from 'react-redux-multilingual'
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import axios from 'axios'
import swal from "sweetalert2";
import HeadOptionsInventory from '../HeadOptionsInventory'

const useSearchItem = () => {
    const t =useTranslate()
    const [loadingSearchItem,setLoadingSearchItem]=useState(false)
    const [listSearchItem,setListSearchItem]=useState([])
    const [datas,setDatas] = useState(listSearchItem)
    const searchInput = useRef('')

    const loadItems = async (inputValue) => {
        setLoadingSearchItem(true)
        const response = await axios.get(process.env.REACT_APP_API_URL+"api/erp/article/name/"+inputValue
        ).then((response) => {
            setLoadingSearchItem(false)
            setListSearchItem(response.data.datas)
            let ajour = response.data.datas.filter((value)=>value.name.replace(/\u00A0/g,' ').toLowerCase().includes(searchInput.current.value.replace(/\u00A0/g,' ').toLowerCase()))
            setDatas(ajour)
        }).catch((error) => {
            if(error=='end_session') return;
            setLoadingSearchItem(false)
            let errorName = (error.response==undefined) ? 'unknowError' : (error.response.status==403) ? 'forbiddenGeneral' : 'unknowError'
            swal.fire(t(errorName), "", "error");
        })
    }

    return [loadingSearchItem,setLoadingSearchItem,loadItems,listSearchItem,datas,setDatas,searchInput]
}

const BinsInventoryScan = (props) => {
    const t =useTranslate()
    const [loadingSearchItem,setLoadingSearchItem,loadItems,listSearchItem,datas,setDatas,searchInput]=useSearchItem()
    const [inputScan,setInputScan]=useState('')
    const [itemsScannedList,setItemsScannedList]=useState({})
    const [validButton,setValidButton]=useState(false)
    const [blur,setBlur]=useState(Date.now())
    const { posid,id,zoneid,binsinventoryid,binsid,binsnum,rhid} = useParams();
    const history = useHistory();

    useEffect(function () {
        if(searchInput.current){
            setTimeout(()=>{
                searchInput.current.focus()
            },300)
        }
    }, [searchInput])

    useEffect(()=>{
        setTimeout(()=>{
            if(searchInput.current){
                searchInput.current.focus()
            }
        },500)

    },[blur])

    const addNewItem = async (e,articleid,inputname,articleBar) =>{
        let article = (e) ? e.target.value : articleid
        let urlArticle = (e) ? "api/erp/article/reference/" : "api/erp/article/one/"
        
        if(article){
            if(itemsScannedList[article]){//on vérifie si l'article existe deja dans la liste par l'id si celui ci à été rajouté par la recherche manuelle
                let currentItem = itemsScannedList[article]
                currentItem.qte = +currentItem.qte + 1

                setItemsScannedList({[article]:currentItem,...itemsScannedList})

            }else if(Object.values(itemsScannedList).find((value)=>value.reference==article)){//on vérifie si l'article existe deja dans la liste par sa reference si celui ci à été rajouté scan de code barre
                let currentItem = itemsScannedList[Object.values(itemsScannedList).find((value)=>value.reference==article).id]
                currentItem.qte = +currentItem.qte + 1

                setItemsScannedList({[Object.values(itemsScannedList).find((value)=>value.reference==article).id]:currentItem,...itemsScannedList})

            }else{//si il n'a pas encore été scanné
                setLoadingSearchItem(true)
                const response = await axios.get(process.env.REACT_APP_API_URL+urlArticle+article
                ).then((response) => {
                    if(response.data.hasOwnProperty('valid') && !response.data.valid){
                        swal.fire(t('articleNoReferenceFind'), "", "error");
                    }else{   
                        if(e){
                            setItemsScannedList({[response.data.data.article.id]:{article_name:response.data.data.article.name,id:response.data.data.article.id,reference:response.data.data.article.reference,qte:1},...itemsScannedList})
                        }else{
                            setItemsScannedList({[response.data.data.id]:{article_name:response.data.data.name,id:response.data.data.id,reference:response.data.data.reference,qte:1},...itemsScannedList})
                        }
                        
                    }
                }).catch((error) => {
                    if(error=='end_session') return;
                    let errorName = (error.response==undefined) ? 'unknowError' : (error.response.status==403) ? 'forbiddenGeneral' : 'unknowError'
                    swal.fire(t(errorName), "", "error");
                })
                setLoadingSearchItem(false)
            }
            
        }
        setBlur(Date.now())
        setInputScan('')
    }

    const validateInventory = async () =>{
        setValidButton(true)

        let data = {}  

        if(Object.keys(itemsScannedList).length!=0){
            let itemArr = []
            let qteArr = []
            Object.entries(itemsScannedList).map(([key,value])=>{
                itemArr.push(value.id)
                qteArr.push(value.qte)
            })

            data['article']=itemArr
            data['total']=qteArr
        }

        const response = await axios.post(process.env.REACT_APP_API_URL+"api/wms/inventaire_count/comptage_zone/"+binsinventoryid+'/'+rhid+'/'+binsid,data
        ).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach(interm=>messageError+=t(interm)+'. ')
                messageError=messageError.replace('undefined','');
                swal.fire(messageError, "", "error");

            }else{ 
                setItemsScannedList({})
                setValidButton(false)
                if(props.module=='pos'){
                    history.push("/pos/"+posid+"/inventory/bins/"+id+'/'+zoneid)
                }else{{
                    history.push("/erp/inventory/bins/"+id+'/'+zoneid)
                }}
            }
        }).catch((error) => {
            if(error=='end_session') return;
            let errorName = (error.response==undefined) ? 'unknowError' : (error.response.status==403) ? 'forbiddenGeneral' : 'unknowError'
            swal.fire(t(errorName), "", "error");
        })
        setValidButton(false)
    }
    
    return (<div style={(props.module=='pos') ? {marginTop:'-30px'} : {marginTop:'0'}}>
        <CardPerso header={
            <HeadOptionsInventory 
                title='manualCounting' 
                btnValid={true} 
                btnReturn={true} 
                btnReturnHome={true}
                type='bins' 
                id={id} 
                zoneid={zoneid} 
                posid={posid} 
                validateInventory={validateInventory}
                itemsScannedList={itemsScannedList}
                validButton={validButton}
                zoneWithBins={false}
                module={props.module}
            />}>
            <div className="row">        
                <div className="offset-1">
                    <ArticleBarSearch 
                        loadingSearchItem={loadingSearchItem}
                        loadItems={loadItems}
                        listSearchItem={listSearchItem}
                        datas={datas}
                        setDatas={setDatas}
                        valueInput={inputScan}
                        setValueInput={setInputScan}
                        handleSubmit={addNewItem}
                        searchInput={searchInput}
                        noBlur={false}
                    />
                    <strong>{binsnum}</strong>
                </div>
                
                <div className='row mx-auto mt-3 d-flex justify-content-center'>
                    {(Object.keys(itemsScannedList).length!=0) ? 
                        Object.entries(itemsScannedList).map(([key,value],ind)=>{
                            let style = (ind%2 == 0) ? 'stripeBg stripePadding px-2 col-8' : 'stripePadding px-2 col-8';
                            return(
                                <LineItemInventory
                                    key={key}
                                    itemValue={value}
                                    itemsList={itemsScannedList}
                                    setItemsList={setItemsScannedList}
                                    style={style}
                                    setBlur={setBlur}
                                />
                            )
                        })
                    :
                        false
                    }
                </div>
            </div>
        </CardPerso>
        </div>
    );
    

}

export default BinsInventoryScan;
