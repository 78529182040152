import React,{useState,useEffect} from "react";
import { FormatedPrice } from "@gull"
import { useTranslate } from 'react-redux-multilingual'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle, faCommentAlt} from '@fortawesome/free-solid-svg-icons'
import NoteModal from "./Component/NoteModal"
import SelectSeller from "../SelectSeller"
import axios from 'axios'
import swal from "sweetalert2";
import DeleteLineButton from './Component/DeleteLineButton'

const LineItemGiftCard = (props) =>{
    const t =useTranslate()
    const [showNote,setShowNote]=useState(false)
    const [loading,setLoading]=useState(false)
    const idsLocalStorage=localStorage.getItem("pos").split(',')

    const showModal = () =>{
        setShowNote(true)
    }

    const closeModal = (name) =>{
        if(name == 'note'){
            setShowNote(false)
        }
        props.setRelanceFocus(Date.now())
    }

    return(
        <>
            <NoteModal 
                showNote={showNote}
                closeModal={closeModal}
                posid={props.posid}
                cookies={props.cookies}
                currentTicket={props.currentTicket}
                setCurrentTicket={props.setCurrentTicket}
                lineId={props.lineId}
                item={props.item}
                module='pos'
            />
            <div className={props.style}>
                <div className="row">
                    <div className="col">
                        <strong className="fsArticlePos">{t('giftcard')}</strong> 
                        <span> : </span>&nbsp;
                        <strong className="fsArticlePos">{props.item.details.reference}</strong>
                    </div>
                </div>
                <div className="row marginLeftArticle">
                    {/* <div className="col-5 m-0 p-0">
                        <div className="row ">
                            <strong className="fsArticlePos">{props.item.details.reference}</strong>
                        </div>
                    </div> */}
                    <div className="col-4">
                        <div className="row">
                            <div className="col-1 m-0 p-0 zIndexBtnNotePos">
                                <button className="btn btn-primary btn-sm sizeBtnPos" onClick={()=>showModal()}><FontAwesomeIcon icon={faCommentAlt} /></button>
                            </div>
                        </div>
                    </div>
                    <div className="col-4 m-0 p-0">
                        <SelectSeller 
                            section={props.section} 
                            cookies={props.cookies}
                            item={props.item}
                            lineId={props.item.id}
                            posid={props.posid}
                            currentTicket={props.currentTicket}
                            setCurrentTicket={props.setCurrentTicket} 
                            setRelanceFocus={props.setRelanceFocus}
                            from='pos'//à passer pour obligatoirement pour connaitre les options de son module 
                        />
                    </div>
                    <div className="col-4">
                        <div className="text-end m-0 p-0 priceArticlePos totalPricePosition">
                            <FormatedPrice langRegion={'fr-FR'} devise={props.devise} digit={props.digit} priceToFormat={props.item.prix_remise} />
                        </div>
                        <span className="m-0 p-0 crossPosition">
                            {(loading) ?
                                    <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span>
                                :
                                    <DeleteLineButton
                                        setLoading={setLoading}
                                        posid={props.posid}
                                        idsLocalStorage={idsLocalStorage}
                                        item={props.item}
                                        cookies={props.cookies}
                                        setCurrentTicket={props.setCurrentTicket}
                                        setRelanceFocus={props.setRelanceFocus}
                                        from='lineItemGiftCard'
                                        restrictionDeleteLineItem={props.restrictionDeleteLineItem}
                                        setRestrictionDeleteLineItem={props.setRestrictionDeleteLineItem}
                                        section={props.section} 
                                    />
                            }
                        </span>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LineItemGiftCard;