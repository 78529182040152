import React,{useContext} from "react";
import { useTranslate } from 'react-redux-multilingual'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf} from '@fortawesome/free-solid-svg-icons';
import { IsAuthContext } from 'app/App';

const OptionsButtonFinalisation = (props) =>{
    const t =useTranslate()
    const { authParam } = useContext(IsAuthContext)
    const modulePos = authParam.modules.find(value=>value.name=='pos')

    return(
        <div className="d-flex justify-content-between">
            <button className="btn btn-sm btn-primary" onClick={()=>props.handleClick('print')}>
                {(props.load.print) ? <div className="spinner-border spinner-border-sm"></div> : t('btnPrint')}
            </button>
            <button className="btn btn-sm btn-primary" onClick={()=>props.handleClick('pdf')}>
                {(props.load.pdf) ? <div className="spinner-border spinner-border-sm"></div> : t('download')}&nbsp;<FontAwesomeIcon icon={faFilePdf} className='fs-5' />
            </button>
            {(props.dataSaleOrder.cancel==0 && props.dataSaleOrder.ticket.status==0) ?
                <>
                    {((modulePos.params.to.active && props.dataSaleOrder.section_collect_id == props.posid) || (modulePos.params.to.active && props.dataSaleOrder.ticket.section_id == props.posid)) &&
                        <button className="btn btn-sm btn-warning" onClick={()=>props.handleClick('to_Transform')}>
                            {(props.load.alert) ? <div className="spinner-border spinner-border-sm"></div> : t('createATransferOrder')}
                        </button>
                    }
                    <button className="btn btn-sm btn-light" onClick={()=>props.handleClick('alertOffice')}>
                        {(props.load.alertOffice) ? <div className="spinner-border spinner-border-sm"></div> : t('createNotification')}
                    </button>
                    {(props.from!='formSaleOrder') ?
                        <>
                            {(props.dataSaleOrder.section_collect_id == props.posid) ? 
                                <button className="btn btn-sm btn-info" onClick={()=>props.handleClick('finalize')}>
                                    {(props.load.finalize) ? <div className="spinner-border spinner-border-sm"></div> : t('finalize')}
                                </button>
                            :
                                false
                            }
                            {(props.dataSaleOrder.section_collect_id == props.posid || props.dataSaleOrder.ticket.section_id == props.posid) &&
                                <button className="btn btn-sm btn-danger" onClick={()=>props.handleClick('cancel')}>
                                    {(props.load.cancel) ? <div className="spinner-border spinner-border-sm"></div> : t('btnCancelSalesOrder')}
                                </button>
                            }
                        </>
                    :
                        false}
                    </>
            :
                false
            }
        </div>
    )
}

export default OptionsButtonFinalisation;