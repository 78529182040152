import React,{useState,useContext,useEffect} from "react";
import {PaymentCompleted,FormatedPrice} from "@gull"
import { useTranslate } from 'react-redux-multilingual'
import {Tabs, Tab} from "react-bootstrap";
import PaymentFormLeft from './Component/PaymentFormLeft'
import PaymentFormRight from './Component/PaymentFormRight'
import FidelitePaymentForm from './Component/FidelitePaymentForm'
import RefundForm from './Component/RefundForm'
import OrderForm from './Component/OrderForm'
import OrderFormNext from './Component/OrderFormNext'
import { IsAuthContext } from 'app/App';
import { useParams } from "react-router-dom";
import { modePaiementCb } from 'app/modePaiementCb';
import { modePaiementDefaultAll } from 'app/modePaiementDefaultAll';
import GenerateDevis from "./Component/Devis/GenerateDevis";

const PaymentForm = (props) =>{
    const t =useTranslate()
    const { posid } = useParams();
    const {authParam} = useContext(IsAuthContext);
    const [giftCardScan,setGiftCardScan]=useState(false)
    const [valueDue,setValueDue]=useState(0)
    const [btnDisabled,setBtnDisabled]=useState(false)
    const [currentPayment,setCurrentPayment]=useState('espece')
    const [showTabFidelite,setShowTabFidelite]=useState(false)  
    const [monnaieRendu,setMonnaieRendu]=useState(0)
    const [showDevisPrint,setShowDevisPrint]=useState(false) 

    useEffect(function () {
        let versement = 0
        
        Object.keys(props.currentTicket).length!= 0 && props.currentTicket.paiement_records.map(item=>{
            versement+=item.montant
            
        })

        let newDue = +props.totalAchat - +versement
        //ici dans le cas d'un remboursement par carte cadeau dû à un réglement par une catégorie 'other' on force le restant dû à etre à 0 et non un chiffre négatif
        if(giftCardScan){
            if(+newDue < 0){
                setValueDue(0)
            }
            setGiftCardScan(false)
        }else{
            if(newDue>2){//Si il reste 1f ou 2f à payer à cause de l'arrondi à l'inférieur, on passe le valueDue à 0 
                setValueDue(newDue)
            }else{
                setValueDue(0)
            }
        }

    }, [props.currentTicket])

    useEffect(function () {
        if(props.clientFideliteInfo && props.clientFideliteInfo.data.length!=0){//Le client à le droit de bénéficier de ses points de fidélité 
            if(props.clientFideliteInfo.mode_fidelite=='cashback'){
                //Si on est en cachback, on vérifie si un seuil minimum de cashbakc à été configuré pour afficher ou non le mode depaiement fidélité
                if(!props.seuilCashBack){
                    setShowTabFidelite(true);
                }else if(props.seuilCashBack && +props.clientFideliteInfo.valeur_cfp >= +props.seuilCashBack){
                    setShowTabFidelite(true);
                }else{
                    setShowTabFidelite(false);
                }
            }else if(props.clientFideliteInfo.mode_fidelite=='recompense'){
                //ici on récupère tous les ids des articles possible à offrir en récompense fidélité 
                let arrIdsFreeItems = Object.values(props.clientFideliteInfo.data).flat()
                // ici on récupère tous les articles du ticket qui font parti de la liste des récompenses
                let arrItemsRecompense = []
                props.currentTicket.ticket_detail.map(value=>{
                    if(value.article && arrIdsFreeItems.includes(value.article.id)){
                        for(let i=0; i< +value.quantity; i++){
                            arrItemsRecompense.push(value)
                        }
                    }
                })

                let allFidelitePayment = props.currentTicket.paiement_records.filter(value=>value.mode=='recompense')

                if(arrItemsRecompense.length!=0){
                    //On vérifie si dans notre panier on a des articles qui font parti du programme de fidélité
                    if(arrItemsRecompense.length == allFidelitePayment.length){
                        setCurrentPayment('espece')
                        setShowTabFidelite(false)
                    }else{
                        // setCurrentPayment('fidelite')
                        setShowTabFidelite(true);
                    }
                }else{
                    setCurrentPayment('espece')
                    setShowTabFidelite(false)
                }
            }else if(props.clientFideliteInfo.mode_fidelite=='giftcard'){
                //ici on récupère tous les montants des cartes cadeau dispo en récompense
                let arrGcRecompense = Object.values(props.clientFideliteInfo.data)
                //ici on récupère toutes les cartes cadeau qui ont été ajoutées au ticket si il y en a
                let allGiftCardOnReceipt = props.currentTicket.ticket_detail.filter(value=>value.special=='gift_card')
                //Ici on récupère uniquement les cartes cadeau du ticket oû le montant fait parti des carte cadeau dispo en récompense
                let giftCardWithFideliteMatch = [] 
                allGiftCardOnReceipt.map(value=>{
                    if(arrGcRecompense.includes(value.prix_remise.toString())){
                        giftCardWithFideliteMatch.push(value.prix_remise)
                    }
                })
                //Ici on vérifie si sur les cartes cadeau ou la carte cadeau du panier correspond à une des cartes cadeau offert par le programme de ficélité 
                //Si le client à le droit à une carte cadeau de 2000XPF et qu'il y a une carte cadeau de 5000xpf dans son panier, on ne propose pas le paiement par fidélité 

                let allFidelitePayment = props.currentTicket.paiement_records.filter(value=>value.mode=='fidelite')

                if(giftCardWithFideliteMatch.length!=0){
                    //On vérifie si il y a des cartes cadeaux qui correspondent a une récompense du programme de fidélité
                    // setCurrentPayment('fidelite')
                    // setShowTabFidelite(true);

                    if(giftCardWithFideliteMatch.length == allFidelitePayment.length){
                        setCurrentPayment('espece')
                        setShowTabFidelite(false)
                    }else{
                        // setCurrentPayment('fidelite')
                        setShowTabFidelite(true);
                    }

                }else{
                    setCurrentPayment('espece')
                    setShowTabFidelite(false)
                }
            }
        }else{
            setCurrentPayment('espece')
            setShowTabFidelite(false)
        }

    }, [props.clientFideliteInfo])

    let currentShop = authParam.sections.find(shop=>shop.id == posid)

    let cbPaymentMethod = []
    let otherPaymentMethod = []

    if(currentShop.modepaiement!=null){
        for(var i=0; i<currentShop.modepaiement.length; i++){ //on vérifie si le mode de paiement par cb est autorisé sur ce shop
            if(modePaiementCb.includes(currentShop.modepaiement[i])){
                cbPaymentMethod.push(currentShop.modepaiement[i])
            }
        }
    
        for(var i=0; i<currentShop.modepaiement.length; i++){ //on vérifie si les autres modes de paiement sont autorisés sur ce shop
            if(!modePaiementDefaultAll.includes(currentShop.modepaiement[i])){
                otherPaymentMethod.push(currentShop.modepaiement[i])
            }
        }
    }

    const changePayMethod = (k)=>{
        setCurrentPayment(k)
    }

    let total = props.totalAchat

    let devise = authParam.company.devise;
    let digit = (authParam.company.devise === "XPF") ? 0 : 2;
    
    return(
        <div className="row">
            {(props.ticketCommande) ?
                <OrderFormNext 
                    ticketCommande={props.ticketCommande}
                    setTicketCommande={props.setTicketCommande}
                    devise={devise}
                    digit={digit}
                    posid={posid}
                    from='formSaleOrder'
                    setModalTitle={props.setModalTitle}
                    setShow={props.setShow}
                />
            :
                (props.orderForm) ?
                    <OrderForm 
                        currentTicket={props.currentTicket}
                        authParam={authParam}
                        currentShop={currentShop}
                        setTicketCommande={props.setTicketCommande}
                        resetSale={props.resetSale}
                        posid={posid}
                        devise={devise}
                        setOrderForm={props.setOrderForm}
                        setCookie={props.setCookie}
                        expire_date={props.expire_date}
                        actualShop={props.actualShop}
                        setRelanceCommande={props.setRelanceCommande}
                        allCommandes={props.allCommandes}
                        setAllCommandes={props.setAllCommandes}
                    />
                :
                    (showDevisPrint) ?
                        <GenerateDevis 
                            currentTicket={props.currentTicket} 
                            setShowDevisPrint={setShowDevisPrint} 
                            setBtnDisabled={setBtnDisabled} 
                            setModalTitle={props.setModalTitle}
                            setCookie={props.setCookie}
                            expire_date={props.expire_date}
                            setCurrentTicket={props.setCurrentTicket}
                            resetSale={props.resetSale}
                            countItems={props.countItems}
                            devise={devise}
                        />
                    :
                    (currentShop.modepaiement!=null) ?
                        <>
                            {(props.currentTicket.status==1) ? 
                                <div className="col-6">
                                    <PaymentCompleted />
                                    <div className="mt-5">
                                        <h3 >{t('giveChange')+' : '} <strong className='totalPos2'>{<FormatedPrice langRegion={'fr-FR'} devise={devise} digit={digit} priceToFormat={monnaieRendu} />}</strong></h3>
                                    </div>
                                </div>
                            :
                                (total<0) ?
                                    <div className="col-6">
                                        <RefundForm
                                            total={Math.abs(total)}
                                            cookies={props.cookies}
                                            currentTicket={props.currentTicket}
                                            setCurrentTicket={props.setCurrentTicket}
                                            clientId={props.clientId}
                                            posid={posid}
                                            modulePos={props.modulePos}
                                        />
                                    </div>
                                :
                                    (currentPayment) ?
                                        <div className="col-6">
                                            <Tabs activeKey={currentPayment} id="uncontrolled-tab-example" style={{marginTop:'1px'}} onSelect={(k)=>changePayMethod(k)}>
                                                {(currentShop.modepaiement.includes('espece')) &&
                                                    <Tab eventKey="espece" title={t('espece')}>
                                                        <PaymentFormLeft
                                                            monnaieRendu={monnaieRendu}
                                                            setMonnaieRendu={setMonnaieRendu}
                                                            total={total}
                                                            cookies={props.cookies}
                                                            clientId={props.clientId}
                                                            currentTicket={props.currentTicket}
                                                            setCurrentTicket={props.setCurrentTicket}
                                                            devise={devise}
                                                            digit={digit}
                                                            currentPayment={currentPayment}
                                                            valueDue={valueDue}
                                                            posid={posid}
                                                            authParam={authParam}
                                                            giftCardScan={giftCardScan}
                                                            setGiftCardScan={setGiftCardScan}
                                                            isFor='espece'
                                                            btnDisabled={btnDisabled}
                                                            setBtnDisabled={setBtnDisabled}
                                                        />                
                                                    </Tab>
                                                }
                                                {(currentShop.modepaiement.includes('cheque')) &&
                                                    <Tab eventKey="cheque" title={t('cheque')}>
                                                        <PaymentFormLeft
                                                            monnaieRendu={monnaieRendu}
                                                            setMonnaieRendu={setMonnaieRendu}
                                                            total={total}
                                                            cookies={props.cookies}
                                                            clientId={props.clientId}
                                                            currentTicket={props.currentTicket}
                                                            setCurrentTicket={props.setCurrentTicket}
                                                            devise={devise}
                                                            digit={digit}
                                                            currentPayment={currentPayment}
                                                            valueDue={valueDue}
                                                            posid={posid}
                                                            authParam={authParam}
                                                            giftCardScan={giftCardScan}
                                                            setGiftCardScan={setGiftCardScan}
                                                            isFor='cheque'
                                                            btnDisabled={btnDisabled}
                                                            setBtnDisabled={setBtnDisabled}
                                                            modulePos={props.modulePos}
                                                        />                
                                                    </Tab>
                                                }
                                                {(cbPaymentMethod.length!=0) &&
                                                    <Tab eventKey="card" title={t('card')}>
                                                        <PaymentFormLeft
                                                            monnaieRendu={monnaieRendu}
                                                            setMonnaieRendu={setMonnaieRendu}
                                                            total={total}
                                                            cookies={props.cookies}
                                                            clientId={props.clientId}
                                                            currentTicket={props.currentTicket}
                                                            setCurrentTicket={props.setCurrentTicket}
                                                            devise={devise}
                                                            digit={digit}
                                                            currentPayment={currentPayment}
                                                            valueDue={valueDue}
                                                            posid={posid}
                                                            authParam={authParam}
                                                            giftCardScan={giftCardScan}
                                                            setGiftCardScan={setGiftCardScan}
                                                            isFor='card'
                                                            btnDisabled={btnDisabled}
                                                            setBtnDisabled={setBtnDisabled}
                                                        />                
                                                    </Tab>
                                                }
                                                {(currentShop.modepaiement.includes('giftcard')) &&
                                                    <Tab eventKey="giftcard" title={t('giftcard')}>
                                                        <PaymentFormLeft
                                                            monnaieRendu={monnaieRendu}
                                                            setMonnaieRendu={setMonnaieRendu}
                                                            total={total}
                                                            cookies={props.cookies}
                                                            clientId={props.clientId}
                                                            currentTicket={props.currentTicket}
                                                            setCurrentTicket={props.setCurrentTicket}
                                                            devise={devise}
                                                            digit={digit}
                                                            currentPayment={currentPayment}
                                                            valueDue={valueDue}
                                                            posid={posid}
                                                            authParam={authParam}
                                                            giftCardScan={giftCardScan}
                                                            setGiftCardScan={setGiftCardScan}
                                                            isFor='giftcard'
                                                            btnDisabled={btnDisabled}
                                                            setBtnDisabled={setBtnDisabled}
                                                        />                
                                                    </Tab>
                                                }
                                                {(currentShop.modepaiement.includes('virement')) &&
                                                    <Tab eventKey="virement" title={t('virement')}>
                                                        <PaymentFormLeft
                                                            monnaieRendu={monnaieRendu}
                                                            setMonnaieRendu={setMonnaieRendu}
                                                            total={total}
                                                            cookies={props.cookies}
                                                            clientId={props.clientId}
                                                            currentTicket={props.currentTicket}
                                                            setCurrentTicket={props.setCurrentTicket}
                                                            devise={devise}
                                                            digit={digit}
                                                            currentPayment={currentPayment}
                                                            valueDue={valueDue}
                                                            posid={posid}
                                                            authParam={authParam}
                                                            giftCardScan={giftCardScan}
                                                            setGiftCardScan={setGiftCardScan}
                                                            isFor='virement'
                                                            btnDisabled={btnDisabled}
                                                            setBtnDisabled={setBtnDisabled}
                                                        />                
                                                    </Tab>
                                                }
                                                {(otherPaymentMethod.length!=0) &&
                                                    <Tab eventKey="other" title={t('other')}>
                                                        <PaymentFormLeft
                                                            monnaieRendu={monnaieRendu}
                                                            setMonnaieRendu={setMonnaieRendu}
                                                            total={total}
                                                            cookies={props.cookies}
                                                            clientId={props.clientId}
                                                            currentTicket={props.currentTicket}
                                                            setCurrentTicket={props.setCurrentTicket}
                                                            devise={devise}
                                                            digit={digit}
                                                            currentPayment={currentPayment}
                                                            valueDue={valueDue}
                                                            posid={posid}
                                                            authParam={authParam}
                                                            giftCardScan={giftCardScan}
                                                            setGiftCardScan={setGiftCardScan}
                                                            isFor='other'
                                                            btnDisabled={btnDisabled}
                                                            setBtnDisabled={setBtnDisabled}
                                                        />                
                                                    </Tab>
                                                }
                                                {(showTabFidelite) &&
                                                    <Tab eventKey="fidelite" title={t('loyalty')}>
                                                        <FidelitePaymentForm
                                                            clientFideliteInfo={props.clientFideliteInfo}
                                                            devise={devise}
                                                            digit={digit}
                                                            btnDisabled={btnDisabled}
                                                            setBtnDisabled={setBtnDisabled}
                                                            cookies={props.cookies}
                                                            posid={posid}
                                                            setCurrentTicket={props.setCurrentTicket}
                                                            currentTicket={props.currentTicket}
                                                            setClientFideliteInfo={props.setClientFideliteInfo}
                                                            setShowBtnFidelite={props.setShowBtnFidelite}
                                                            valueDue={valueDue}
                                                        />                
                                                    </Tab>
                                                }
                                            </Tabs>
                                        </div>
                                    :false
                            }
                            <div className="col-6">
                                <PaymentFormRight
                                    cookies={props.cookies}
                                    setCurrentTicket={props.setCurrentTicket}
                                    posid={posid}
                                    total={total}
                                    devise={devise}
                                    digit={digit}
                                    valueDue={valueDue}
                                    id='paymentDue'
                                    currentTicket={props.currentTicket}
                                    setCookie={props.setCookie}
                                    expire_date={props.expire_date}
                                    setOrderForm={props.setOrderForm}
                                    setModalTitle={props.setModalTitle}
                                    setTicketCommande={props.setTicketCommande}
                                    resetSale={props.resetSale}
                                    btnDisabled={btnDisabled}
                                    setBtnDisabled={setBtnDisabled}
                                    modulePos={props.modulePos}
                                    restrictionRemoveLinePayment={props.restrictionRemoveLinePayment}
                                    setRestrictionRemoveLinePayment={props.setRestrictionRemoveLinePayment}
                                    section={props.section}
                                    pointsFidelite={props.pointsFidelite}
                                    setPointsFidelite={props.setPointsFidelite}
                                    setClientFideliteInfo={props.setClientFideliteInfo}
                                    setShowBtnFidelite={props.setShowBtnFidelite}
                                    setShowDevisPrint={setShowDevisPrint}
                                />
                            </div>
                        </>
                    :
                        <span className="text-center">{t('tableNoModePaiement')}</span>
            }
        </div>
    )
}

export default PaymentForm;