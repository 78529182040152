import React, {useState,useContext} from 'react';
import { useTranslate } from 'react-redux-multilingual'
import { useForm } from "react-hook-form";
import axios from 'axios'
import swal from "sweetalert2";
import { IsAuthContext } from 'app/App';
import {Form} from 'react-bootstrap'

const CheckBoxUser = (props) => {
    const t = useTranslate()
    const [checked,setChecked]=useState((props.infoAccess && props.infoAccess.user.find(user=>user.id==props.user.id)) ? true : false)

    const handleChange = (e) => {
        setChecked(!checked);
    }
    return (<div className='mt-1'>
        <Form.Check
            {...props.register("users[]")}
            type="switch"
            id="custom-switch"
            checked={checked}
            value={props.user.id}
            label={props.user.name}
            onChange={(e) => handleChange(e)}
        />
        </div>
    )
}

const ModalEditUserAccess = (props) => {
    const t = useTranslate();
    const [validButton,setValidButton] = useState(false)
    const { register, formState: { errors }, handleSubmit, setValue } = useForm();
    let infoAccess = props.allAccess.find(access=>access.action==props.accessName)
    const { authParam, setAuthParam } = useContext(IsAuthContext)

    const onSubmit = async (data) =>{
        setValidButton(true)

        let obj = {
            isfor : props.module,
            action : props.accessName,
            users: !data.users ? [] : (Array.isArray(data.users)) ? data.users : [data.users]
        }

        let arrayIds = data.users ? [...data.users] : []

        const response = await axios.post(process.env.REACT_APP_API_URL+"api/access/edit_or_create", obj
        ).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach(interm=>messageError+=t(interm)+'. ')
                messageError=messageError.replace('undefined','');
                swal.fire(messageError, "", "error");
            }else{   
                if(arrayIds.includes(authParam.userid.toString())){
                    let arr = [...authParam.access,{action:response.data.datas.action, company_id:response.data.datas.company_id, created_at:response.data.datas.created_at, id:response.data.datas.id, isFor:response.data.datas.isFor}]
                    setAuthParam({...authParam,access:arr})
                }else{
                    let newArr = authParam.access.filter(value=>value.action!=props.accessName)
                    setAuthParam({...authParam,access:newArr})
                }
                props.setRelance(Date.now())
                props.closeModal()
            }
        }).catch((error) => {
            if(error=='end_session') return;
            swal.fire(t('unknowError'), "", "error");
        })
        setValidButton(false)
    }

    return(
        <form className='row d-flex justify-content-center' onSubmit={handleSubmit(onSubmit)}>
            <div className='col-10'>
                {props.company.users.map(user=>{
                    return(
                        <CheckBoxUser key={user.id} user={user} register={register} infoAccess={infoAccess} setValue={setValue}/>
                    )
                })}
                <button className='btn btn-primary mt-3'>
                    {validButton ? <span><div className="spinner-border spinner-border-sm"></div></span> : t('btnValidate')}
                </button>
            </div>
        </form>
    )
}

export default ModalEditUserAccess;