import { lazy, useContext } from "react";

const Rhtime = lazy(() => import("./Rhtime"));
const StaffTime = lazy(() => import("./StaffTime/StaffTime"));

const rhtimeRoutes = [
    {
      path: "/rhtime/welcome",
      component: Rhtime
    },
    {
      path: "/rhtime/stafftime",
      component: StaffTime
    },
  ];
  
  export default rhtimeRoutes;