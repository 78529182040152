import React from 'react';
import { useTranslate } from 'react-redux-multilingual'
import Moment from 'react-moment';
import { Link } from "react-router-dom";

const ModalRestockToComeDetails = (props) => {
    const t = useTranslate();

    return(<>
        <div className="table-responsive">
            <table
            id="section_restock"
            className="table"
            style={{fontSize:'10px'}}
            >
                <thead>
                    <tr>
                        <th scope="col">{t('transferOrder')}</th>
                        <th scope="col">{t('expectedReceiptDate')}</th>
                        <th scope="col">{t('quantity')}</th>
                        <th scope="col">{t('status')}</th>
                    </tr>
                </thead>
                <tbody>
                    {props.inSection.map((value,ind)=>{
                        let path = (props.from=='erp') ? '/erp/transfer-order/details/'+value.to.id : '/pos/'+props.posid+'/transfer-order-detail/'+value.to.id
                        let toStatus = (value.to.deposed_status==1) ? t('itemsPartiallyReceived') : (value.to.deposed_status==2) ? t('itemsTotallyReceived') : (value.to.picked_status==0) ? t('notCollected') : (value.to.picked_status==1) ? t('processOfCollecting') : (value.to.picked_status==2) ? t('collected') : t('notCollected')
                        return(
                            <tr key={ind}>
                                <td className='lien-to'><Link to={path}>{value.to.reference}</Link></td>
                                <td><Moment format="DD/MM/YYYY">{value.to.expected}</Moment></td>
                                <td>{value.quantite}</td>
                                <td>{toStatus}</td>
                            </tr>
                        )}
                    )}
                </tbody>
            </table>
        </div>
    </>);
}

export default ModalRestockToComeDetails;