import React, {useState,useEffect} from 'react';
import { useTranslate } from 'react-redux-multilingual'
import {Loading} from '@gull'
import axios from 'axios'
import swal from "sweetalert2";
import { Tabs, Tab } from "react-bootstrap";
import ReportDetailView from './Comp/ReportDetailView'

const useLoad = (moduleId) => {
    const t = useTranslate();
    const [loading,setLoading]=useState(true)
    const [allReports,setAllReports]=useState([])
    const [tabkey,setTabKey]=useState(false) 

    useEffect(function () {
        (async function() {
            const response = await axios.get(process.env.REACT_APP_API_URL+"api/pbi/get_all_of_user_in_module/"+moduleId
            ).then((response) => {
                if(!response.data.valid){
                    let interms='';
                    var keys = Object.keys(response.data.errors);
                    for(var i = 0; i < keys.length; i++){
                        interms+=response.data.errors[keys[i]].join(',')+','
                    }
                    interms=interms.split(',')
                    let messageError='';
                    interms.forEach(interm=>messageError+=t(interm)+'. ')
                    messageError=messageError.replace('undefined','');
                    swal.fire(messageError, "", "error");
                    setLoading(false)
                }else{
                    setLoading(false)
                    if(response.data.datas.length!=0){
                        setTabKey(response.data.datas[0].name)
                    }else{
                        setTabKey('report')
                    }
                    setAllReports(response.data.datas)
                }
            }).catch((error) => {
                if(error=='end_session') return;
                swal.fire(t('unknowError'), "", "error");
                setLoading(false)
            })
        })()
    }, [])
    
    return [loading,allReports,tabkey,setTabKey]
}

const ReportPbiView = (props) => {
   const t = useTranslate();
   const [loading,allReports,tabkey,setTabKey] = useLoad(props.moduleId)

    if(loading || !tabkey){
        return(
            <Loading></Loading>
        )
    }

   return(
       <div>
            <Tabs activeKey={tabkey} id="uncontrolled-tab-example" onSelect={(k)=>setTabKey(k)}>
                {allReports.length!=0 ?
                    allReports.map(report=>{
                        return(
                            <Tab key={report.id} eventKey={report.name} title={report.name}>
                                <ReportDetailView report={report} />
                            </Tab>
                        )
                    })
                :
                    <Tab eventKey='report' title={t('reportPbi')}>
                        <div className='d-flex justify-content-center alert alert-info'>{t('noReportingAvl')}</div>
                    </Tab>
                }
            </Tabs>
       </div>
   )
}

export default ReportPbiView;