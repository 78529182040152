import React, {useState,useEffect} from 'react';
import { useTranslate } from 'react-redux-multilingual';
import { CardPerso,ProfilActionButton,EditModalActionButton,CustomToggleList,AddButton,DeleteActionButton,ExportArticleButton,ColumDataTableReorganize} from "@gull";
import { Modal } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import cellEditFactory from "react-bootstrap-table2-editor";
import Moment from 'react-moment';
import {scrollXDataTable} from 'app/scrollXDataTable';
import { useCookies} from 'react-cookie';
import axios from 'axios'
import swal from "sweetalert2";

const CustomDataTable = (props) => {
    const t = useTranslate();
    
    useEffect(() => {
        scrollXDataTable(window.screen.width,props.columnShow.length)
    }, [window.screen.width,props.columnShow.length]);
    
    return (
        <BootstrapTable
            {...props.propst.baseProps}
            bootstrap4
            pagination={paginationFactory(props.paginationOptions)}
            noDataIndication={t('tableNoData')}
            defaultSorted={props.defaultSorted}
            onTableChange={ props.handleTableChange }
            cellEdit={cellEditFactory({ 
                mode: "dbclick",
                blurToSave: true
                })}
            rowStyle={ props.rowStyle }
            defaultSortDirection={props.defaultSortDirection}
        />
    );
};

const PurchaseOrdersTab = (props) => {
    const t = useTranslate();
    let { SearchBar } = Search;
    const [cookies, setCookie] = useCookies(['purchase_order_Lists','purchase_order_tab_reorganize']);
    const [perPage,setPerPage]=useState(50)
    const [currentPage,setCurrentPage] = useState(1)
    const [memoModal,setMemoModal]=useState(false)
    const [lang, setLang] = useCookies(['lang']);

    const [columnOrganize,setColumnOrganize]=useState([])
    const [showColumnOrganize,setShowColumnOrganize]=useState(false)

    //Pour le cookie
    let current_date = new Date()
    let timestampdate = current_date.setDate(current_date.getDate() + 90)
    let expire_date = new Date(timestampdate)

    let cookieLecture = ['name','status','section','created_at','updated_at','memo','poNbr','fournisseur','lines_number','action'];
    if(cookies.purchase_order_Lists){
        let columnShowVar = cookies.purchase_order_Lists;
        cookieLecture = columnShowVar.split(',')
    }
    const [columnShow,setColumnShow] = useState(cookieLecture)

    var maxCarac = 15;

    const deletePo = async (poId) => {
        const response = await axios.delete(process.env.REACT_APP_API_URL+"api/erp/po/"+poId
        ).then((response) => {
            let newData = props.purchaseOrders.filter(value=>value.id!=poId)
            props.setPurchaseOrders(newData)
            
        }).catch((error) => {
            if(error=='end_session') return;
            let messageError
            if(error.response==undefined){
                messageError='unknowError'
            }else if(error.response.status==403){
                messageError='forbiddenGeneral'
            }else {
                messageError='unknowError'
            }
            swal.fire(t(messageError), "", "error");
        })
    };

    let purchaseOrdersList = new Array
    props.purchaseOrders.forEach((value,ind) => {
        let obj = {
            id: value.id,
            masque:'',
            name: value.name,
            poNbr: value.bar,
            memo: (value.memo.length>maxCarac) ? <span>{value.memo.replace( /(<([^>]+)>)/ig, '').substring(0,maxCarac)}<span className='lien-to' onClick={()=>setMemoModal(value.memo.replace( /(<([^>]+)>)/ig, ''))}>...</span></span> : value.memo.replace( /(<([^>]+)>)/ig, ''), 
            status: (value.status == 0) ? t('notDelivered') : (value.status == 1) ? t('enCours') : (value.status == 2) ? t('completed') : false,
            created_at: <Moment format="DD/MM/YYYY HH:mm:ss">{value.created_at}</Moment>,
            updated_at: <Moment format="DD/MM/YYYY HH:mm:ss">{value.updated_at}</Moment>,
            user_name: value.user.name,
            section:(value.section!=null) ? value.section.name : '',
            action: <span className="d-flex justify-content-between">
                        <ProfilActionButton path={'/erp/purchase-order/details/'+value.id} openWindow={true}/>
                        <EditModalActionButton setShowEdit={props.setShow} hisId={value.id} setModalEditId={props.setModalEditId}/> 
                        {value.deletable ? <DeleteActionButton deleteFunctionArgument={value.id} actionType={'typeDeleteX'} nameType={'delete'} toDelete={value.name} customClickEvent={deletePo}/> : false}
                    </span>,
            memo_total: value.memo,
            fournisseur:(value.fournisseur != null) ? value.fournisseur.name : '',
            lines_number:value.count,
            index: ind + 1,
        }
        props.customFieldsCol.forEach((item,index)=>{
            if(value.custom_fields){
                obj[item.name]=value.custom_fields[item.name]
            }
        })
        purchaseOrdersList.push(obj)
    })

    let defaultSorted = [
        {
          dataField: "name",
          order: "asc"
        }
    ];

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
          Showing rows { from } to { to } of { props.sizeDatas }
        </span>
    );
    
    const sizePerPageOptionRenderer = ({
        text,
        page,
        onSizePerPageChange
    }) => (
        <li
            key={ text }
            role="presentation"
            className="dropdown-item"
            onMouseDown={ (e) => {
                e.preventDefault();
                onSizePerPageChange(page);
                setPerPage(page)
            } }
        >
            <a
                href="#"
                tabIndex="-1"
                role="menuitem"
                data-page={ page }
                style={ { color: 'black' } }
            >
                { text }
            </a>
        </li>
    );

    let paginationOptions = {
        page:currentPage,
        paginationSize: 5,
        pageStartIndex: 1,
        firstPageText: t('tableFirst'),
        prePageText: t('tableBack'),
        nextPageText: t('tableNext'),
        lastPageText: t('tableLast'),
        nextPageTitle: t('tableNextTitle'),
        prePageTitle: t('tableBackTitle'),
        firstPageTitle: t('tableFistTitle'),
        lastPageTitle: t('tableLastTitle'),
        sizePerPageOptionRenderer,
        sizePerPage: perPage,
        showTotal: true,
        paginationTotalRenderer: customTotal,
    };

    const headerSortingStyle = { backgroundColor: '#c8e6c9' };

    let columns = [
        {
            dataField: "action",
            text: "Actions",
            editable: false,
            headerClasses: 'head-action-row',
            classes: 'body-action-row',
        },
        {
            dataField: "masque",
            text: '',
            editable: false,
            formatter: (rowContent, row) => {
                return (    
                    <div style={{display:"block",width:"82px"}}></div>
                )
            },
            style: (cell, row, rowIndex, colIndex) => {
                return {
                    width:'99px',
                    backgroundColor:'white'
                };
            },
        },
        {
            dataField: "name",
            text: t('tableName'),
            editable:false,
            hidden: (columnShow.includes("name")) ? false : true,
            sort: true,
            headerSortingStyle
        },
        {
            dataField: "poNbr",
            text: t('poNbr'),
            editable:false,
            hidden: (columnShow.includes("poNbr")) ? false : true,
            sort: true,
            headerSortingStyle
        },
        {
            dataField: "status",
            text: t('state'),
            editable:false,
            hidden: (columnShow.includes("status")) ? false : true,
            sort: true,
            headerSortingStyle
        },
        {
            dataField: "section",
            text: t('assignedTo'),
            editable:false,
            hidden: (columnShow.includes("section")) ? false : true,
            sort: true,
            headerSortingStyle
        },
        {
            dataField: "created_at",
            text: t('createdAt'),
            editable:false,
            hidden: (columnShow.includes("created_at")) ? false : true,
            sort: true,
            headerSortingStyle
        },
        {
            dataField: "updated_at",
            text: t('lastUpdate'),
            editable: false,
            hidden: (columnShow.includes("updated_at")) ? false : true,
            sort: true,
            headerSortingStyle
        },
        {
            dataField: "memo",
            text: "Memo",
            editable: false,
            hidden: (columnShow.includes("memo")) ? false : true,
            sort: true,
            headerSortingStyle
        },
        {
            dataField: "fournisseur",
            text: t("fournisseur"),
            editable: false,
            hidden: (columnShow.includes("fournisseur")) ? false : true,
            sort: true,
            headerSortingStyle
        },
    ];

    props.customFieldsCol.map(value=>columns.push({
        dataField: value.name,
        text:  value.name,
        editable: false,
        sort: true,
        headerSortingStyle,
        hidden: (columnShow.includes(value.name)) ? false : true
    }))

    columns.push(
        {
            dataField: "user_name",
            text: t('createBy'),
            editable: false,
            hidden: (columnShow.includes("user_name")) ? false : true,
            sort: true,
            headerSortingStyle
        },
        {
            dataField: "lines_number",
            text: t('linesNumber'),
            editable: false,
            hidden: (columnShow.includes("lines_number")) ? false : true,
            sort: true,
            headerSortingStyle
        },
    )

    useEffect(function () {
        let columnCookie
        let arrCol = []
            
        if(cookies.purchase_order_tab_reorganize){
            let newValueToIncludeInCookie
            let cookieLecture = cookies.purchase_order_tab_reorganize.split(',')

            let already_done=[];
            cookieLecture.map(value=>{
                columns.map(item=>{
                    if(item.dataField==value){
                        arrCol.push(item)
                        already_done.push(value);
                    }
                })
            })
            columns.map(item=>{
                if(!already_done.includes(item.dataField)){ 
                    //si une nouvelle à été ajouté aux customsField, elle n'est pas ajoutée desuite au cookie de reorganisation si celui ci existe
                    //dans le cas où il n'existerait pas, on va créer un nouveau cookie afin de l'inclure pour ne pas avoir d'erreur sur les export 
                    newValueToIncludeInCookie=true
                    arrCol.push(item);
                }
            })

            columnCookie = arrCol
            if(newValueToIncludeInCookie==true){ 
                let arr = []
                arrCol.map(value=>
                    arr.push(value.dataField)
                )
                setCookie("purchase_order_tab_reorganize",arr.join(),{ path: "/", expires: expire_date })
            }
        }else{
            columnCookie = columns
        } 
        setColumnOrganize(columnCookie)
    }, [])

    useEffect(function () {
        if(cookies.purchase_order_Lists){
            let cookieColumnShow = cookies.purchase_order_Lists.split(',')
            let columnsDatas = [] //contient toutes les dataFields de columns
            let arrColumnShow = [] //contient toutes les données sans doublons
            let arrColumnShowDoublon = [] //contient les doublons
            let arrColumnShowIntrus = [] // continent les fausse données

            columns.map(item=>{
                columnsDatas.push(item.dataField)
            })

            cookieColumnShow.map(value=>{
                //on vérifie si le cookie contien des doublons
                if(!arrColumnShow.includes(value)){
                    arrColumnShow.push(value)
                }else{
                    //Si value existe dejà c'est qu'il y a un doublon 
                    arrColumnShowDoublon.push(value)
                }
                //on vérifie si les données du cookies sont des données qui exisent dans le tableau columns
                if(!columnsDatas.includes(value)) arrColumnShowIntrus.push(value)
                
            })

            if(arrColumnShowDoublon.length!=0 || arrColumnShowIntrus.length!=0){ 
                //Si le cookie contient des doublons et des données qui n'existe pas en meme temps
                let uniformData = arrColumnShow.filter(value=> !arrColumnShowIntrus.includes(value))

                setCookie("purchase_order_Lists",uniformData.join(),{ path: "/", expires: expire_date })
            }
        }
    }, [])

    const handleTableChange = async (type, { page, sizePerPage }) => {
        let urlParam = {
            status:props.poState
        }
        setPerPage(sizePerPage)
        const response = await axios.post(process.env.REACT_APP_API_URL+"api/erp/po/all/"+sizePerPage+"/"+page,urlParam
        ).then((response) => {
            props.setPurchaseOrders(response.data.datas)
            props.setSizeDatas(response.data.count)
            setCurrentPage(page)
        }).catch((error) => {
            if(error=='end_session') return;
            swal.fire(t('unknowError'), "", "error");
        })
    }

    const rowStyle = (row, rowIndex) => {
        const style = {};

        if (row.status == t('notDelivered')) {
          style.backgroundColor = '#fccfcf';

        }else if(row.status == t('enCours')){
          style.backgroundColor = '#ffffbb';

        }else{
            style.backgroundColor = '#d9fcd2';
        }
       
        return style;
    };

    const handleToggleColumns = (column,indexToEdit) => {
        if(column.toggle){
            var newColumnShow = columnShow.filter(value=>value!=column.dataField)

            //Modification pour la mise à jour des status hidden de chaque colonnes
            let dataColumn = columnOrganize.filter(value=>value.dataField!=column.dataField)
            let newData = column
            newData.hidden = true

            dataColumn.splice(indexToEdit,0, newData)

            setColumnOrganize(dataColumn)
            //End

            setColumnShow(newColumnShow)
        }
        else{
            var newColumnShow = [...columnShow,column.dataField]

            //Modification pour la mise à jour des status hidden de chaque colonnes
            let dataColumn = columnOrganize.filter(value=>value.dataField!=column.dataField)
            let newData = column
            newData.hidden = false

            dataColumn.splice(indexToEdit,0, newData)

            setColumnOrganize(dataColumn)
            //End

            setColumnShow(newColumnShow)
        }
        setCookie("purchase_order_Lists",newColumnShow.join(),{ path: "/", expires: expire_date }) 
    }

    return (<>
        <Modal show={memoModal} onHide={() => setMemoModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    {memoModal}
                </div>
            </Modal.Body>
        </Modal>
        <div className="row">
            <div className='col-3 mb-3'>
                <div className="form-group">
                    <select className="form-control" value={props.poState} onChange={(e) => props.onChange(e)}>
                        <option value="nofinal">{t('allPo')+' '+'('+t('exceptReceivedVoucher')+')'}</option>
                        <option value="0">{t('notDelivered')+' '+'('+t('only').toLowerCase()+')'}</option>
                        <option value="1">{t('enCours')+' '+'('+t('only').toLowerCase()+')'}</option>
                        <option value="2">{t('completed')+' '+'('+t('only').toLowerCase()+')'}</option>
                        <option value="all">{t('allPo')}</option>
                    </select>
                </div>
            </div>
        </div>
        <CardPerso header={t('purchaseOrders')}>
            {props.loading ?
                <div className='d-flex justify-content-center'>
                    <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span>
                </div>
            :
                columnOrganize.length!=0 ?
                    <small>
                        <ToolkitProvider
                        striped
                        keyField="index"
                        data={purchaseOrdersList}
                        columns={columnOrganize}
                        search
                        columnToggle
                        >
                        {(propst) => (
                            <>
                                <div className="d-flex justify-content-between">
                                    <div className='d-flex align-items-baseline'>
                                        <ColumDataTableReorganize 
                                            columnOrganize={columnOrganize}
                                            setColumnOrganize={setColumnOrganize}
                                            setCookie={setCookie}
                                            cookieName='purchase_order_tab_reorganize'
                                            showColumnOrganize={showColumnOrganize}
                                            setShowColumnOrganize={setShowColumnOrganize}
                                            { ...propst.columnToggleProps } 
                                            executWhenToggle={handleToggleColumns}
                                            btnSwitch={true}//pour affiche ou non le btn swith
                                            initSwith={false}//initialier à false il affichera par défaut le toggle des colonnes, sur true il affichera le drag and drop pour changer la position des colonnes
                                        />&nbsp;&nbsp;
                                        <ExportArticleButton filtre={{}} lang={lang.lang} title={t('downloadAllPoCsv')} type="button" url={"api/erp/po/csvitem_all"}></ExportArticleButton>
                                    </div>
                                    <div className="d-flex justify-content-end align-items-center">
                                        <span className="mb-2 me-1">{t('tableSearch')}:</span>
                                        <SearchBar {...propst.searchProps} className="mb-2" />
                                    </div>
                                </div>
                                
                                <div className="position-relative">
                                    <CustomDataTable 
                                        propst={propst}
                                        paginationOptions={paginationOptions}
                                        defaultSorted={defaultSorted}
                                        rowStyle={rowStyle}
                                        columnShow={columnShow}
                                        handleTableChange={handleTableChange}
                                        defaultSortDirection="asc"
                                    />
                                </div>
                            </>
                        )}
                        </ToolkitProvider>
                    </small>
                :
                    <div></div>
                
            }
        </CardPerso>
    </>)
}

export default PurchaseOrdersTab;