import React, { useState,useEffect } from "react";
import { useTranslate } from 'react-redux-multilingual'
import axios from 'axios'
import swal from "sweetalert2";
import { FormatedPrice} from "@gull"
import Moment from 'react-moment';

const CreditGiftCardModal = (props) =>{
    const t = useTranslate();
    const [inputValue,setInputValue]=useState('')
    const [giftCardDetail,setGiftCardDetail]=useState({})
    const [load,setLoad]=useState(false)

    useEffect(function () {
        const input = document.querySelector('#scanCreditGiftCard');
        setTimeout(()=>{
            input.focus()
        },300)

    }, [])

    const handleChange = (e) =>{
        setInputValue(e.target.value)
    }

    const handleSubmit = async (e)=>{
        e.preventDefault();
        setLoad(true)

        const response = await axios.get(process.env.REACT_APP_API_URL+"api/pos/gift_card/get_by_reference/"+inputValue
        ).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach(interm=>messageError+=t(interm)+'. ')
                messageError=messageError.replace('undefined','');
                swal.fire(messageError, "", "error");
            }
            else{  
                if(response.data.data.sections_avaible.includes(props.posid)){ 
                    swal.fire(t('notValidGiftCard'), "", "error");
                }else{
                    setGiftCardDetail(response.data.data)
                }
                setLoad(false)
                
            }
        }).catch((error) => {
            if(error=='end_session') return;
            swal.fire(t('unknowError'), "", "error");
        })
        setLoad(false)
        setInputValue('')
    }

    let dateExpire = (Object.keys(giftCardDetail).length!=0) ? giftCardDetail.gift_card_records.filter(value=>value.is=='credit') : false
    let lastExpireDate = dateExpire[+dateExpire.length-1]

    return(
        <>
            <div className="row d-flex justify-content-center mb-3">
                <div className="col-8">
                    <form onSubmit={(e)=>handleSubmit(e)}>
                        <input id="scanCreditGiftCard" value={inputValue} type="text" className="form-control" autoComplete="off" placeholder={t('scanGiftCard')} onChange={(e)=>handleChange(e)}/>
                    </form>
                </div>
            </div>
            {(load) ?
                <span className="d-flex justify-content-center"><div className="spinner-border spinner-border-sm mt-2"></div></span>
            :
                (Object.keys(giftCardDetail).length!=0) ?
                    <div className="row d-flex justify-content-center mb-3">
                        <div className="col-8 fs-4 mt-2">
                            {(+giftCardDetail.disponible > 0) ?
                                <>
                                    <div className="d-flex justify-content-between">
                                        <span className="fw-bold">{t('availableFund')+' : '}</span>
                                        <span className="text-success">{<FormatedPrice langRegion={'fr-FR'} devise={props.devise} digit={props.digit} priceToFormat={giftCardDetail.disponible} />}</span>
                                    </div>
                                    <div className="fs-5 text-muted">{t('dateExpire')+' : '}<span>{<Moment format="DD/MM/YYYY">{lastExpireDate.expire}</Moment>}</span></div>
                                </>
                            :
                                <strong className="text-danger text-center">{t('noFundAvailable')}</strong>
                            }
                            
                        </div>
                    </div>
                :
                    false
            }
        </>
    )
}

export default CreditGiftCardModal;