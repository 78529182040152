import React, {useState,useRef,useEffect} from "react";
import { useTranslate } from 'react-redux-multilingual'
import { useForm } from "react-hook-form";
import axios from 'axios'

const ControlAccess = (props) => {
    const t =useTranslate()
    const { register, formState: { errors }, handleSubmit, reset } = useForm();
    const [apiError,setApiError] = useState(false)
    const [loadingBar,setLoadingBar] = useState(false)

    useEffect(()=>{
        if(props.focus && props.focus==true){
            setTimeout(()=>{
                const input = document.querySelector('#controlAccess');
                if(input) input.focus()
            },500)
        }
    },[])

    const onSubmit = async (data) => {
        setLoadingBar(true)
        setApiError(false)
        const response  = await axios.get(process.env.REACT_APP_API_URL+"api/rh/bar/"+props.moduleId+'/'+data.bar, 
        ).then((response) => {
            if(!response.data.valid){
                setApiError('noRhOrNoAuth')
                reset()
            }
            else{
                if(props.restriction.grades.includes(+response.data.data.grade_id)){
                    props.setRestriction({...props.restriction,go:true})
                    if(props.callback) props.callback((props) && props,response.data);
                    if(props.setShowBtnBack) props.setShowBtnBack(true) //cette conditrion permet d'afficher le bouton "retour" uniquement si il y a une restriction sur l'affichage des objectifs
                    if(props.setManagerIdGoals) props.setManagerIdGoals(response.data.data.id); //cette condition concerne l'acces aux objectifs, si le badge est celui d'un responsable, on retourne ses chiffres à lui et ceux qui ont vendu 
                }
                else{
                    if(props.setIndividualRhIdGoals) props.setIndividualRhIdGoals(response.data.data.id); //cette condition concerne l'acces aux objectifs, si le badge ne correspond au grade concerné, on retourne que les objectifs du badge concerné 
                    if(props.setShowBtnBack) props.setShowBtnBack(true) //cette conditrion permet d'afficher le bouton "retour" uniquement si il y a une restriction sur l'affichage des objectifs
                    setApiError('noRhOrNoAuth')
                }
            }
        }).catch((error) => {
            if(error=='end_session') return;
            reset()
            if(error.response==undefined){
                setApiError('unknowError')
            }
            else if(error.response.status==403){
                setApiError('forbiddenGeneral')
            }
            else {
                setApiError('unknowError')
            }
        })
        setLoadingBar(false)
    }
    
    return(
        <div className="row" style={{marginTop: (props.noMarge) ? 0 : '-60px'}}>
            <div className="col-10 offset-1">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <input  id='controlAccess' className="form-control" type="password" placeholder={t('scanBadge')+'...'} {...register('bar',{required: true})}/>
                    {(apiError) &&
                        <p className="text-danger text-center mt-3"><strong>{t(apiError)}</strong></p>
                    }
                </form>
                {(props.btnHideControlAccess && props.btnHideControlAccess==true) &&
                    <button className="btn btn-sm btn-danger mt-2" onClick={()=>props.actionHideControlAccess()}>{t('btnCancel')}</button>
                }
            </div>
            <div className="col-12 text-center">
                {(loadingBar || props.callbackLoading) &&
                    <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm mt-2"></div></span>
                }
            </div>
        </div>
    )
}

export default ControlAccess