import React,{useState} from "react";
import { useTranslate } from 'react-redux-multilingual'

const FiltreSearchBar = (props) => {
    const t =useTranslate()
    const [value,setValue]=useState('')

    const handleChange = (e) =>{
        setValue(e.target.value)
        if(e.target.value==''){
            //utilisé sur le controle de l'inventaire comme il y a 2 tableau affichés
            if(props.reset) props.setFilteredData(props.dataToFiltre)
        }
        
        //on recherche sur notre liste d'article, l'article ou les articles qui correspondent à notre recherche
        let filteredResults = Object.values(props.dataToFiltre).filter((item) => 
            item.article_name.toLowerCase().includes(e.target.value.toLowerCase())
        );

        props.setFilteredData(filteredResults)
    }
    
    return (
        <div className="row d-flex justify-content-end">
            <div className="col-5 form-group">
                <label>{t('searchArticle')}</label>
                <input value={value} type="text" className="form-control" onChange={(e)=>handleChange(e)}/>
            </div>
        </div>
    );
    

}

export default FiltreSearchBar;
