export function controlValidEan13(code){
    // Vérifiez que le code a exactement 13 chiffres
    if (!/^\d{13}$/.test(code)) {
        return false;
    }

    // Convertir la chaîne en tableau de chiffres
    let digits = code.split('').map(Number);

    // Calculer la somme pondérée
    let sum = 0;
    for (let i = 0; i < 12; i++) {
        sum += digits[i] * (i % 2 === 0 ? 1 : 3);
    }

    // Calculer le chiffre de contrôle attendu
    let checkDigit = (10 - (sum % 10)) % 10;

    // Vérifiez que le dernier chiffre correspond au chiffre de contrôle
    return checkDigit === digits[12];
}