import React, { useState, useEffect} from "react";
import axios from 'axios';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import { useForm } from "react-hook-form";
import { useTranslate } from 'react-redux-multilingual'

const Clocking = (props)=> { 
    const t = useTranslate();
    const { register, formState: { errors }, handleSubmit, reset , setFocus} = useForm();
    const [apiError,setApiError] = useState(false)
    const [loadingBar,setLoadingBar] = useState(false)
    const [validButton,setValidButton] = useState(false)
    
  
    useEffect(()=>{
        setFocus('bar')
        props.setUserClocking(false)
    },[])
    
    const onSubmit = async (data) => {
        setLoadingBar(true)
        setApiError(false)
        const response  = await axios.get(process.env.REACT_APP_API_URL+"api/rh/bar/"+props.domain[2]+'/'+data.bar, 
        ).then((response) => {
            if(!response.data.valid){
                setApiError(true)
                reset()
            }
            else{
                props.setUserClocking(response.data)
    
            }
        }).catch((error) => {
            if(error=='end_session') return;
            reset()
            if(error.response==undefined){
                setApiError('unknowError')
            }
            else if(error.response.status==403){
                setApiError('forbiddenGeneral')
            }
            else {
                setApiError('unknowError')
            }
        })
        setLoadingBar(false)
    }
      
    
    return(
        <div className="row" >
            <div className="col ">
                <form onSubmit={handleSubmit(onSubmit)}>
                <input className="form-control inputClocking" type="password" placeholder={t('scanBadge')+'...'}  {...register('bar')}  />
                {(loadingBar) ?
                    <span className="spinnerPointageScan"><div className="spinner-border spinner-border-sm"></div></span>
                :
                    false
                }
                </form>
                {(props.userClocking) && 
                <div className="alert alert-warning mt-3">
                    <div>
                    { t('confirmClockingX')+' '+props.userClocking.data.name+' '+props.userClocking.data.firstname+' ?'}
                    </div>
                    <button disabled={validButton} onClick={() =>  props.setShowClocking(false)} className="btn btn-primary mt-3">
                    {validButton ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : t('btnValidate')}
                    </button>
                </div>
                }
                {(apiError)&&
                <div className="alert alert-danger mt-3">
                    {t('errorClocking')}
                </div>
                }
            </div>
        </div>
    )
}

export default Clocking;