import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image, Svg} from '@react-pdf/renderer';
import {controlValidEan13} from 'app/controlValidEan13'
var JsBarcode = require('jsbarcode');

const ItemTagsBarCode = (props) => {

    const styles = StyleSheet.create({
        codebar: {
            height: '2cm',
            fontSize: 5,
        },
        articleName: {
            textAlign:'center',
            fontSize: 7,
            marginBottom: 4,
        },
        tag: {
            padding: 10,
            width: '5cm',
            height: '3cm',
            border:1,
            marginTop: 4,
        },
        articlePrice: {
            textAlign:'center',
            fontSize: 9,
        },
        barCode: {
            textAlign:'center',
            fontSize: 8,
            top:-6
        },
        ean1: {
            fontSize: 8,
            top:-3,
            left:-1
        },
        ean2: {
            fontSize: 8,
            top:-8,
            left:21
        },
        ean3: {
            fontSize: 8,
            top:-13,
            left:74
        }
    });

    //On vérifie 
    let formatCodeBarre = (props.articleBarCode && controlValidEan13(props.articleBarCode)) ? "EAN13" : "CODE128"

    let canvas;
    canvas = document.createElement('canvas');

    JsBarcode(canvas, props.articleBarCode, {
        // width: 2,
        format: formatCodeBarre,
        height: (formatCodeBarre=='EAN13') ? 80 : 30,
        fontSize: 12,
        displayValue: false,
    });
    const barcode = canvas.toDataURL();

    let firstDigit = (formatCodeBarre=='EAN13') ? props.articleBarCode.toString().substring(0, 1) : false
    let nextSixDigits = (formatCodeBarre=='EAN13') ? props.articleBarCode.toString().substring(1, 7) : false
    let lastSixDigits = (formatCodeBarre=='EAN13') ? props.articleBarCode.toString().substring(7, 13) : false

    return( 
            <View style={styles.tag}>
                <Text style={styles.articleName}>{props.articleName}</Text>
                <Image style={styles.codebar} src={barcode}/>
                {formatCodeBarre=="CODE128" ? 
                    <Text style={styles.barCode}>{props.articleBarCode}</Text>
                :
                    <>
                        <Text style={styles.ean1}>{firstDigit}</Text>
                        <Text style={styles.ean2}>{nextSixDigits}</Text>
                        <Text style={styles.ean3}>{lastSixDigits}</Text>
                    </>
                }
                <Text style={styles.articlePrice}>{props.articlePrice}</Text>
            </View>
        )
    };

export default ItemTagsBarCode;
