import React, { useState,useEffect } from 'react';
import { useTranslate } from 'react-redux-multilingual'
import {ControlAccess} from '@gull'
import { useForm } from "react-hook-form";
import axios from 'axios'
import swal from "sweetalert2";
import { tgc_list } from "app/tgc";

const useLoad = (posid) =>{
    const t = useTranslate();
    const [loading,setLoading]=useState(true) 
    const [section,setSection]=useState(false)
    const [restriction,setRestriction]=useState({
        go:true,
        grades:[]
    })
    
    useEffect(function () {
        (async function() {
            const response = await axios.get(process.env.REACT_APP_API_URL+"api/section/"+posid
            ).then((response) => {
                let restriction = response.data.restriction.filter(value=>value.action==='itemCreation')
                if(restriction.length>0){
                    if(restriction[0].grade.length > 0){
                        setRestriction({
                            go:false,
                            grades: restriction[0].grade.map(value=>value.id)
                        })
                    }
                }
                setSection(response.data)
            }).catch((error) => {
                if(error=='end_session') return;
                swal.fire(t('unknowError'), "", "error");
            })
            setLoading(false)
        })()
    }, [])

    return [loading,section,restriction,setRestriction]
}


const CreateItemFormPos = (props) =>{
    const t = useTranslate();
    const  [loading,section,restriction,setRestriction] = useLoad(props.posid)
    const { register, formState: { errors }, handleSubmit, reset, setValue } = useForm();
    const [validButton, setValidButton] = useState(false)
    const idsLocalStorage=localStorage.getItem("pos").split(',')

    
    const handleChangeNumberPoint = (e,name) => {
        setValue(name,e.target.value.replace(/[^0-9\.]/g, '')); 
    }

    const addItemToBasket = async (articleid) =>{
        
        let data ={
            article_id:articleid
        }
        
        if(props.cookies.current_ticket){
            data['id']=props.cookies.current_ticket

        }else{
            if(props.cashierId){
                data['rh_id']=props.cashierId
            }
            if(props.clientId){
                data['client_id']=props.clientId
            }
        }
        
        let currentTicketCookie

        const response = await axios.post(process.env.REACT_APP_API_URL+"api/pos/ticket/construction/"+props.posid+"/"+idsLocalStorage[1]+"/"+idsLocalStorage[0], data
        ).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach(interm=>messageError+=t(interm)+'. ')
                messageError=messageError.replace('undefined','');
                swal.fire(messageError, "", "error");
            }
            else{   
                reset()
                props.setCurrentTicket(response.data.data)
                currentTicketCookie=response.data.data.id
                if(!props.cookies.current_ticket){
                    props.setCookie("current_ticket",currentTicketCookie,{ path: "/", expires: props.expire_date }) 
                }
                
                let articleAfficheur = response.data.data.ticket_detail.find(value=>value.article_id == articleid)
                
                if(props.peripheriqueParam && props.peripheriqueParam.afficheur && props.peripheriqueParam.afficheur.model=='second_screen_display'){
                    // affiche et envoyé en param 'reason' pour afficher le contenu du ticket sur l'écran secondaire, si on envoie 'clear' il néttoiera l'ensemble de l'afficheir
                    props.affichageClient(response.data.data,false,false,'add',false,false,props.peripheriqueParam)

                }else{
                    props.affichageClient(articleAfficheur.article.name,articleAfficheur.prix_remise,articleAfficheur.quantity,0,props.devise,t,props.peripheriqueParam)
                    //le 0 sur la variable 'raison' est passé pour définir que les infos ne concerne pas le total
                }
            }
        }).catch((error) => {
            if(error=='end_session') return;
            if(!error.response){
                swal.fire(t('unknowError'), "", "error");
            }
            else if(error.response.status===403){
                swal.fire(t('forbiddenGeneral'), "", "error");
            }
            else {
                swal.fire(t('unknowError'), "", "error");
            }
        })
        props.setShow(false)
        props.setRelanceFocus(Date.now())
        setValidButton(false)
    }

    const onSubmit = async (data) => {
        setValidButton(true)
        let obj = {
            name:data.name,
            price:data.price,
            tgc:data.tgc,
            reference:(data.reference!='') ? data.reference : null,
            havestock:'non',
            is_create_from:'pos',
            from_shop:props.actual_shop
        }
        
        const callapi  = await axios.post(process.env.REACT_APP_API_URL+"api/erp/article",obj
            ).then((response) => {
                if(!response.data.valid){
                    let interms='';
                    var keys = Object.keys(response.data.errors);
                    for(var i = 0; i < keys.length; i++){
                        interms+=response.data.errors[keys[i]].join(',')+','
                    }
                    interms=interms.split(',')
                    let messageError='';
                    interms.forEach((interm)=>{
                        let finalMessage=interm.split('/!');
                        if(finalMessage.length==2){
                            messageError+=t(finalMessage[0],{name:finalMessage[1]})+'<br/>'
                        }
                        else{
                            messageError+=t(interm)+'<br/>'
                        }
                    })
                    messageError=messageError.replace('undefined','');
                    swal.fire(messageError, "", "error");
                    setValidButton(false)
                }
                else{
                    addItemToBasket(response.data.data.id)
                }
            }).catch((error) => {
                if(error=='end_session') return;
                if(!error.response){
                    swal.fire(t('unknowError'), "", "error");
                }
                else if(error.response.status===403){
                    swal.fire(t('forbiddenGeneral'), "", "error");
                }
                else {
                    swal.fire(t('unknowError'), "", "error");
                }
                setValidButton(false)
            }
        )
    }

    if(loading){
        return(<span className='d-flex justify-content-center'><div className="spinner-border spinner-border-sm"></div></span>)
    }

    
    if(restriction.go){
        return (
            <div className='row'>
                {/* <ControlAccess section={props.section} restriction={props.restriction} moduleId={props.posid} setRestriction={props.setRestriction} callback={callback} /> */}
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="mb-2">
                        <label>{t('itemName')}*:</label>
                        <input autoComplete='off' type="text" className="form-control" {...register('name',{required: true})} />
                        {errors.name?.type === 'required' && (
                        <div className="text-danger mt-1 ml-2">
                            {t('nameRequired')}
                        </div>
                        )}
                    </div>
                    <div className="mb-2">
                        <label>{t('reference')}:</label>
                        <input autoComplete='off' type="text" className="form-control" {...register('reference')} />
                    </div>
                    <div className="mb-2">
                        <label>{t('priceTtc')}*:</label>
                        <input autoComplete='off' type="text" className="form-control" {...register('price',{required: true, onChange:(e) => handleChangeNumberPoint(e,'price')})}/>
                        {errors.price?.type === 'required' && (
                        <div className="text-danger mt-1 ml-2">
                            {t('priceRequired')}
                        </div>
                        )}
                    </div>
                    <div className="mb-2">
                        <label>{t('tgc')}*:</label>
                        <select type="text" className="form-control" {...register('tgc',{required: true})}>
                            <option value="">-- Select --</option>
                            {tgc_list.map((value,index)=>
                                <option key={index} value={value}>{value}%</option>
                            )}
                        </select>
                        {errors.tgc?.type === 'required' && (
                        <div className="text-danger mt-1 ml-2">
                            {t('tgcRequired')}
                        </div>
                        )}
                    </div>
                    <button disabled={validButton} className="btn btn-primary mt-3">
                        {validButton ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : t('btnValidate')}
                    </button>
                </form>
            </div>
        )
    }
    else{
        return(
            <div style={{marginTop:'60px'}}>
                <ControlAccess section={section} restriction={restriction} setRestriction={setRestriction} moduleId={props.posid}/>
            </div>
        )
    }
};

export default CreateItemFormPos;