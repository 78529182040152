import React, { useState } from 'react';
import { useTranslate } from 'react-redux-multilingual'
import { Dropdown} from "react-bootstrap";


const SearchCommande = (props) =>{
    const t = useTranslate();

    return (<>
        <Dropdown.Item eventKey="5" onClick={()=>props.handleClick('lastSalesOrder')}>{t('lastSalesOrder')}</Dropdown.Item>
        {(props.actualShop.interface == null) &&
            <Dropdown.Item eventKey="6" onClick={()=>props.handleClick('salesOrder')}>{t('searchSalesOrder')}</Dropdown.Item>
        }
    </>
    )
};

export default SearchCommande;