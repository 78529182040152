import React from "react";
import { useTranslate } from 'react-redux-multilingual';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const  ViewPdfButton = (props) => {
    const t = useTranslate();
    
    const handleShow = (ticketRef) =>{
        props.setRaisonPrint('pdf')
        props.setTicketRef(ticketRef)
        props.setRelance(Date.now())
        if(props.setGiftPdf) props.setGiftPdf(false)
        if(props.setThisShop) props.setThisShop(props.sectionId)
    }

    return (
        <OverlayTrigger
            placement="bottom"
            overlay={
                <Tooltip id={`tooltip-${props.index}`}>
                    {props.title}
                </Tooltip>
            }
        >
            <span onClick={() => handleShow(props.ticketRef)} style={{cursor:'Pointer'}}><FontAwesomeIcon icon={faFilePdf} /></span>
        </OverlayTrigger>  
    )
}

export default ViewPdfButton;