import React,{useState} from "react";
import { useTranslate } from 'react-redux-multilingual';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import axios from 'axios';
import swal from "sweetalert2";

// Style import
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus,faTimesCircle } from '@fortawesome/free-solid-svg-icons';

const  BtnAddRemovePromoClient = (props) => {
    const t = useTranslate();
    const [validButton,setValidButton] = useState(false)

    const handleClick = async () => {
        setValidButton(true)
        let obj = {}

        if(props.action=='add'){
            obj['promotions']=[...props.promoAlreadyAssignIds,props.promoId]
        }else{
            let newData = props.promoAlreadyAssignIds.filter(idPromo=>idPromo!=props.promoId)
            obj['promotions']=newData
        }

        const response = await axios.put(process.env.REACT_APP_API_URL+"api/client/edit_client_promotion/"+props.clientId,obj
        ).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach(interm=>messageError+=t(interm)+'. ')
                messageError=messageError.replace('undefined','');
                swal.fire(messageError, "", "error");
            }
            else{  
                if(props.action=='add'){
                    //on ajoute l'id de la promo récemment attribué à la liste des ids de promo deja attribuées
                    props.setPromoAlreadyAssignIds([...props.promoAlreadyAssignIds,props.promoId])

                    //on retire la promo attribuée du tableau qui contient les promos attribuables
                    let newDataAssignable = props.promoToAssign.filter(value=>value.id!=props.promoId)
                    props.setPromoToAssign(newDataAssignable)

                    //on ajoute la promo récemment attribuée au tableau des promos deja attribuées
                    let dataToAssign = props.promoToAssign.find(item=>item.id==props.promoId)
                    props.setPromoAlreadyAssign([dataToAssign,...props.promoAlreadyAssign])

                }else{
                    //on retire l'id de la promo au tableau des ids deja attribués
                    let newArrAlreadyAssignedIds = props.promoAlreadyAssignIds.filter(idPromo=>idPromo!=props.promoId)
                    props.setPromoAlreadyAssignIds(newArrAlreadyAssignedIds)

                    //on ajoute la promo récemment retiré aux promos attribuables
                    let dataToRemove = props.promoAlreadyAssign.find(item=>item.id==props.promoId)
                    props.setPromoToAssign([dataToRemove,...props.promoToAssign])

                    //on retire la promo du tableau des promos deja attribuées
                    let newDataAssignable = props.promoAlreadyAssign.filter(value=>value.id!=props.promoId)
                    props.setPromoAlreadyAssign(newDataAssignable)
                }
            }
        }).catch((error) => {
            if(error=='end_session') return;
            swal.fire(t('unknowError'), "", "error");
        })
        setValidButton(false)
    }

    return (<>
        {validButton ? 
            <span><div className="spinner-border spinner-border-sm"></div></span>
        :
            <OverlayTrigger
                placement="bottom"
                overlay={
                    <Tooltip id={`tooltip-${props.index}`}>
                        {t(props.message)}
                    </Tooltip>
                }
            >
                <span onClick={() => handleClick()} style={{cursor:'Pointer'}} className={props.iconColor} ><FontAwesomeIcon icon={props.icon=='faPlus' ? faPlus : faTimesCircle} /></span>
            </OverlayTrigger>  
        }
        </>
    )
}

export default BtnAddRemovePromoClient;