import React, { useState, useEffect } from 'react';
import { useTranslate } from 'react-redux-multilingual'
import {FormatedPrice,CarouselImage,LoaderDot} from '@gull'
import {Modal} from 'react-bootstrap';
import { promotions2Inputs,promotionsPrefixe,promotionsPercent} from 'app/allPromotions';
// Style import
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

const PosInfoPrice = (props) =>{
    const t = useTranslate();
    const [promo,setPromo]=useState('') 
    const [prixPromo,setPrixPromo]=useState(false) 

    useEffect(function () {
        let promoData = ''

        if(Object.keys(props.priceDataAlert).length!=0 && props.priceDataAlert.current_promotion!=null && props.priceDataAlert.current_promotion[props.actualShop.code]){
            if(promotions2Inputs.includes(props.priceDataAlert.current_promotion[props.actualShop.code].type)){
                promoData = props.priceDataAlert.current_promotion[props.actualShop.code].value[0]+' '+t('for')+' '+props.priceDataAlert.current_promotion[props.actualShop.code].value[1]
                
            }else if(promotionsPrefixe.includes(props.priceDataAlert.current_promotion[props.actualShop.code].type)){
                if(promotionsPercent.includes(props.priceDataAlert.current_promotion[props.actualShop.code].type)){
                    if(props.priceDataAlert.current_promotion[props.actualShop.code].value.length>1){
                        let promoStr = ''
                        props.priceDataAlert.current_promotion[props.actualShop.code].value.map(promoAmount=>{
                            promoStr += '-'+promoAmount+'% '
                        })
                        promoData = promoStr
                    }else{
                        promoData = '-'+props.priceDataAlert.current_promotion[props.actualShop.code].value+'%'
                    }
                }else{
                    promoData = '-'+props.priceDataAlert.current_promotion[props.actualShop.code].value+' '+props.devise
                }
            }else{
                promoData = props.priceDataAlert.current_promotion[props.actualShop.code].value
            }

            
            //Calcule du nouveau tarif remisé dans le cas d'une promo type : remise pourcentage ou net et remise supp en ù sur artilde deja remisé en %
            if(props.priceDataAlert.current_promotion[props.actualShop.code].type=='percent'){
                let coefcalcul = +props.priceDataAlert.current_promotion[props.actualShop.code].value[0] / 100 - 1
                setPrixPromo(+props.priceDataAlert.price * +Math.abs(coefcalcul))

            }else if(props.priceDataAlert.current_promotion[props.actualShop.code].type=='franche'){
                setPrixPromo(+props.priceDataAlert.price - +props.priceDataAlert.current_promotion[props.actualShop.code].value[0])

            }else if(props.priceDataAlert.current_promotion[props.actualShop.code].type=='suppercent'){
                let coefcalcul = +props.priceDataAlert.current_promotion[props.actualShop.code].value[0] / 100 - 1
                let coefcalcul2 = +props.priceDataAlert.current_promotion[props.actualShop.code].value[1] / 100 - 1

                let firstPromo = +props.priceDataAlert.price * +Math.abs(coefcalcul)

                setPrixPromo(+firstPromo * +Math.abs(coefcalcul2))
            }
        }

        setPromo(promoData)

    }, [props.priceDataAlert])

    return (
        <Modal
            show={props.showAlert}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                {(props.loadingAlert) ?
                    <div className='text-center'>
                        <span className='text-primary fs-3 fw-bold'>{props.loadingAlert} <LoaderDot /> </span>
                    </div>
                :
                    Object.keys(props.priceDataAlert).length!=0 ?
                        <div className='text-center position-relative'>
                            <strong className='fs-1'><FormatedPrice langRegion={'fr-FR'} devise={props.devise} digit={props.digit} priceToFormat={props.priceDataAlert.price} /></strong>
                            <div className='fs-5'><b>{props.priceDataAlert.name}</b></div>
                            <button className='btn btn-danger mt-4 fs-5' style={{position:'absolute', top:'-5%', right:'0.5%'}} onClick={()=>props.setShowAlert(false)}><FontAwesomeIcon icon={faTimesCircle} /></button>
                            
                            {(props.priceDataAlert.image) ?
                                <div className='mt-3'>
                                    <hr />
                                    <CarouselImage images={props.priceDataAlert.image}/>
                                </div>
                            :
                                <div className='mb-3 text-center'>
                                    <hr />
                                    <img  src="/assets/images/no-image.png" alt="No image" style={{maxHeight:'230px'}}/>
                                </div>
                            }
                            
                            {(promo!='') &&
                                <div className='mt-3 fs-5 text-primary'>
                                    <hr />
                                    <div>{t('currentPromo')+' :'}</div>
                                    <div>{t(props.priceDataAlert.current_promotion[props.actualShop.code].type)+' : '+promo}</div>
                                    {prixPromo && <div className='fw-bold fs-4'><u>{t('discountedPrice')+' :'}&nbsp;<FormatedPrice langRegion={'fr-FR'} devise={props.devise} digit={props.digit} priceToFormat={prixPromo} /></u></div>}
                                </div>
                            }
                        </div>
                    :
                        false
                }
            </Modal.Body>
        </Modal>
    )
};

export default PosInfoPrice;