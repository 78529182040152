import React from "react";
import {Type} from 'react-bootstrap-table2-editor';
import {FormatedPrice} from "@gull";

const priceSuppColumnGenerate = (suppPrice, columnShow, headerSortingStyle, articleEditionDatatable,t,authParam) => {

    const columns = [];

    suppPrice.map((value,index)=>{
        let numero = index+1

        let readAccessPrice = authParam.access.find(access=>access.action == 'article_pricesuppht'+value['designation_'+numero]+'_@!'+'read')
        let writeAccessPrice = authParam.access.find(access=>access.action == 'article_pricesuppht'+value['designation_'+numero]+'_@!'+'write')
        let textAppendPrice = (!writeAccessPrice && value['destination_'+numero]=='b2b') ? '*' : ''

        if(!readAccessPrice){ 
            let dataInitPrice = {
                dataField: 'price_'+value['designation_'+numero],
                text:  t('priceHt')+' '+value['designation_'+numero]+textAppendPrice,
                hidden: (columnShow.includes('price_'+value['designation_'+numero])) ? false : true,
                formatter: (rowData) => rowData==null || rowData=='' ? '' : (<FormatedPrice langRegion={'fr-FR'} devise={authParam.company.devise} digit={2} priceToFormat={rowData} />),
                sort: false,
                headerSortingStyle,
            }
            if(!writeAccessPrice && value['destination_'+numero]=='b2b'){
                //Si le user est autorisé au éditer ce champ, on intègre validator pour éditer le champ en question
                dataInitPrice['validator'] = (newValue, row, column, done) => {
                    articleEditionDatatable(row,column.dataField,newValue,done,false,t)
                    return {
                        async: true
                    };
                }
            }else{
                //Si le user n'est pas autorisé à éffectuer des modification, on envoie "editable:false"
                dataInitPrice['editable'] = false
            }
            columns.push(dataInitPrice)
        }

        let readAccessPriceTtc = authParam.access.find(access=>access.action == 'article_pricesuppttc'+value['designation_'+numero]+'_@!'+'read')
        let writeAccessPriceTtc = authParam.access.find(access=>access.action == 'article_pricesuppttc'+value['designation_'+numero]+'_@!'+'write')
        let textAppendPriceTtc = (!writeAccessPriceTtc  && value['destination_'+numero]=='b2c') ? '*' : ''

        if(!readAccessPriceTtc){ 
            let dataInitPriceTtc = {
                dataField: 'price_'+value['designation_'+numero]+'_ttc',
                text:  t('priceTtc')+' '+value['designation_'+numero]+textAppendPriceTtc,
                hidden: (columnShow.includes('price_'+value['designation_'+numero]+'_ttc')) ? false : true,
                formatter: (rowData) => rowData==null || rowData=='' ? '' : (<FormatedPrice langRegion={'fr-FR'} devise={authParam.company.devise} digit={2} priceToFormat={rowData} />),
                sort: false,
                headerSortingStyle,
            }
            if(!writeAccessPriceTtc && value['destination_'+numero]=='b2c'){
                //Si le user est autorisé au éditer ce champ, on intègre validator pour éditer le champ en question
                dataInitPriceTtc['validator'] = (newValue, row, column, done) => {
                    articleEditionDatatable(row,column.dataField.replace('_ttc',''),newValue,done,false,t)
                    return {
                        async: true
                    };
                }
            }else{
                //Si le user n'est pas autorisé à éffectuer des modification, on envoie "editable:false"
                dataInitPriceTtc['editable'] = false
            }
            columns.push(dataInitPriceTtc)
        }

        let readAccessMarge = authParam.access.find(access=>access.action == 'article_pricesuppmarge'+value['designation_'+numero]+'_@!'+'read')

        if(!readAccessMarge){ 
            let dataInitMarge = {
                dataField: 'marge_'+value['designation_'+numero],
                text:  t('marge')+' '+value['designation_'+numero],
                hidden: (columnShow.includes('marge_'+value['designation_'+numero])) ? false : true,
                formatter: (rowData) => rowData==0 || rowData==null ? '' : (<FormatedPrice langRegion={'fr-FR'} devise={authParam.company.devise} digit={2} priceToFormat={rowData} />),
                sort: false,
                editable:false,
                headerSortingStyle,
            }
            columns.push(dataInitMarge)
        }

        let readAccessMargePercent = authParam.access.find(access=>access.action == 'article_pricesuppmargepercent'+value['designation_'+numero]+'_@!'+'read')

        if(!readAccessMargePercent){ 
            let dataInitMargePercent = {
                dataField: 'margePercent_'+value['designation_'+numero],
                text:  t('margePercent')+' '+value['designation_'+numero],
                hidden: (columnShow.includes('margePercent_'+value['designation_'+numero])) ? false : true,
                formatter: (rowData,row) => (row.hasOwnProperty('price_'+value['designation_'+numero]) && row['price_'+value['designation_'+numero]]!=null && row['price_'+value['designation_'+numero]]!=0) ? rowData+' %' : '',
                sort: false,
                editable:false,
                headerSortingStyle,
            }
            columns.push(dataInitMargePercent)
        }

    })
    return columns;
};

export default priceSuppColumnGenerate;