import React, { useState } from 'react';
import { useTranslate } from 'react-redux-multilingual'
import swal from "sweetalert2";
import axios from 'axios'
import { useForm } from "react-hook-form";
import DevisForm from './DevisForm'
import DevisPrint from './DevisPrint'

const GenerateDevis = (props) =>{
    const t = useTranslate();

    const { register, formState: { errors }, handleSubmit, reset, setValue} = useForm();
    const [validButton,setValidButton] = useState(false)
    const [showPrintDevis,setShowPrintDevis]=useState(false) 

    const onSubmit = async (data) =>{
        setValidButton(true)

        let obj={}
        if(data.note && data.note!=''){
            obj['note']=data.note
        }

        let axiosAction = (props.currentTicket.devis!=null) ? axios.put : axios.post
        let urlDevis = (props.currentTicket.devis!=null) ? "api/pos/devis/edit/"+props.currentTicket.devis_id : "api/pos/devis/create/"+props.currentTicket.id

        const callapi  = await axiosAction(process.env.REACT_APP_API_URL+urlDevis, obj)
        .then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach((interm)=>{
                    let finalMessage=interm.split('/!');
                    if(finalMessage.length==2){
                        messageError+=t(finalMessage[0],{name:finalMessage[1]})+'<br/>'
                    }
                    else{
                        messageError+=t(interm)+'<br/>'
                    }
                })
                messageError=messageError.replace('undefined','');
                swal.fire(messageError, "", "error");
            }
            else{
                setShowPrintDevis(response.data.data)
                props.setCookie("last_quotation",response.data.data.ticket.id,{ path: "/", expires: props.expire_date }) 
                props.resetSale('devis')
            }
        }).catch((error) => {
            if(error=='end_session') return;
            let errorName = (error.response==undefined) ? 'unknowError' : (error.response.status==403) ? 'forbiddenGeneral' : 'unknowError'
            swal.fire(t(errorName), "", "error");
        })
        reset()
        setValidButton(false)
    }

    const cancelAction = () =>{
        props.setBtnDisabled(false)
        props.setShowDevisPrint(false)
        props.setModalTitle(t('btnPay'))
    }
    
    return (
        <div>
            {(showPrintDevis) ?
                <DevisPrint devisInfo={showPrintDevis} devise={props.devise}/>
            :
                <DevisForm handleSubmit={handleSubmit} onSubmit={onSubmit} register={register} validButton={validButton} cancelAction={cancelAction} countItems={props.countItems} setValue={setValue} currentTicket={props.currentTicket}/>
            }
        </div>
    )
};

export default GenerateDevis;