import React, {useState,useRef,useEffect} from 'react';
import axios from "axios";
import { useTranslate } from 'react-redux-multilingual'
import swal from "sweetalert2";

const SectionRppsl = (props) =>{
    const t = useTranslate();

    const handleChangeRppsl = (e,name) =>{
        if(name=='rp'){
            props.setRpValue(e.target.value)

        }else if(name=='psl'){
            props.setPslValue(e.target.value)
        }
    }

    const handleSubmit = async (e) =>{
        e.preventDefault();

        let axiosAction = props.rppslId != 0 ? axios.put : axios.post;
        let url = props.rppslId != 0 ? "api/erp/rppsl/edit/"+props.rppslId : "api/erp/rppsl/create/"+props.rppslToEdit.id+"/"+props.sectionId;

        props.setValidButton(true)
        const response  = await axiosAction(process.env.REACT_APP_API_URL+url,{
            rp: props.rpValue,
            psl: props.pslValue
        }).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach((interm)=>{
                    let finalMessage=interm.split('/!');
                    if(finalMessage.length==2){
                        messageError+=t(finalMessage[0],{name:finalMessage[1]})+'<br/>'
                    }
                    else{
                        messageError+=t(interm)+'<br/>'
                    }
                })
                    messageError=messageError.replace('undefined','');
                    swal.fire(messageError, "", "error");
                    props.setValidButton(false)
            }else{
                props.setIsAReload(true)
                props.setReload(Date.now())
            }
        }).catch((error) => {
            if(error=='end_session') return;
            let errorName = (error.response==undefined) ? 'unknowError' : (error.response.status==403) ? 'forbiddenGeneral' : 'unknowError'
            swal.fire(t(errorName), "", "error");
            props.setValidButton(false)
        })
    }


    return(<>
      <form onSubmit={(e)=>handleSubmit(e)}>
            <h4 className='mb-1 font-weight-bold'>{props.sectionToEdit.name}</h4>
            <label htmlFor="article name" className='mb-2'>{props.rppslToEdit.name}</label>
            
            <div className="row">
                <label htmlFor="rp" className='mb-1 col-7'>Rp</label>
                <label htmlFor="psl" className='mb-1 col-5'>Psl</label>
            </div>
            <div className="row d-flex align-items-center">
                <div className="form-group col-5">
                    <input ref={props.rppslRef} value={props.rpValue} className="form-control" type="number" onChange={(e)=>handleChangeRppsl(e,'rp')}/>
                </div>
                <div className="col-2 text-center fs-2">/</div>
                <div className="form-group col-5">
                    <input value={props.pslValue} className="form-control" type="number" onChange={(e)=>handleChangeRppsl(e,'psl')}/>
                </div>
            </div>
            <button disabled={props.validButton} className="btn btn-primary mt-3">
                {props.validButton ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : t('btnValidate')}
            </button>
        </form>
    </>
    )
}

const RppslModalEdit = (props) => {
    const t = useTranslate();
    const rppslRef = useRef('')

    useEffect(()=>{
        if(rppslRef.current){
            rppslRef.current.focus()
            rppslRef.current.select()
        }
    
    },[rppslRef.current])
    
    let rppslToEdit = props.rppsl.find(value=>value.id == props.articleIdModal)
    let sectionToEdit = props.section.find(value=>value.id == props.sectionId)

    return(<>
        <SectionRppsl 
            rppslRef={rppslRef} 
            rppslToEdit={rppslToEdit} 
            sectionToEdit={sectionToEdit} 
            rpValue={props.rpValue} 
            setRpValue={props.setRpValue} 
            pslValue={props.pslValue} 
            setPslValue={props.setPslValue} 
            rppslId={props.rppslId} 
            sectionId={props.sectionId} 
            setReload={props.setReload}
            validButton={props.validButton}
            setValidButton={props.setValidButton}
            setIsAReload={props.setIsAReload}
        />
    </>)
}

export default RppslModalEdit;
