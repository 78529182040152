import React, {useState} from "react";
import { useTranslate } from 'react-redux-multilingual'
import { CardPerso } from "@gull";
import { useForm } from "react-hook-form";
import swal from "sweetalert2";
import axios from 'axios'


const ObjectifDay = (props) => {
    const t = useTranslate()
    const { register, formState: { errors }, handleSubmit, reset } = useForm();
    const [periodButton,setPeriodButton] = useState(false)
    const [periodError,setPeriodError] = useState(false)
    const [elementModif,setElementModif]=useState({
        begin: 'day',
        end: false,
    })
    const [success,setSuccess]= useState(false)

    const periodSubmit = async (data) => {
        setPeriodButton(true)
        setPeriodError(false)
        setSuccess(false)
        const callapi  = await axios.post(process.env.REACT_APP_API_URL+"api/pos/objectif/delete/"+props.sectionid, {
            day: data.day,
            begin: data.begin,
            end: (data.end) ? data.end : data.begin
            }).then((response) => {
                if(!response.data.valid){
                    let interms='';
                    var keys = Object.keys(response.data.errors);
                    for(var i = 0; i < keys.length; i++){
                        interms+=response.data.errors[keys[i]].join(',')+','
                     }
                     interms=interms.split(',')
                     let messageError='';
                     interms.forEach(interm=>messageError+=interm+'<br/>')
                     messageError=messageError.replace('undefined','');
                     setPeriodError(messageError)
                }
                else{
                    setSuccess(t('successOperation'))
                }
            }).catch((error) => {
                if(error=='end_session') return;
                if(!error.response){
                    setPeriodError('unknowError')
                }
                else if(error.response.status===403){
                    setPeriodError('forbiddenGeneral')
                }
                else {
                    setPeriodError('unknowError')
                }
            }
        )
        setPeriodButton(false)
    }

    const togglePeriod = (e) => {
        if(e.target.value=='day'){
            setElementModif({
                begin: 'day',
                end: false,
            })
        }
        else{
            setElementModif({
                begin: 'de',
                end: 'toA',
            })
        }
    }

    const sendForm = () => {
        var form = document.getElementById('hideSend');
        form.click();
    }

    return (<>
        <CardPerso header={t('delete')+' '+t('objectifs')}>
            <form onSubmit={handleSubmit(periodSubmit)}>
                <div className="form-group col-md-6">
                    <select className="form-control" defaultValue="oneDay" {...register('day', {onChange:(e) => togglePeriod(e)})}>
                        <option value="oneDay">{t('oneDay')}</option>
                        <option value="manyDays">{t('manyDays')}</option>
                    </select>
                </div>
                <div className="form-group row">
                    <div className="col-md-6">
                        <label htmlFor="begin">{t(elementModif.begin)}</label>
                        <input className="form-control" type="date"{...register('begin',{required: true})}/>
                        {errors.begin?.type === 'required' && (
                        <div className="text-danger col-12 mt-1 ml-2">
                            {t('beginRequired')}
                        </div>
                        )}
                    </div>
                    {(elementModif.end) &&
                        <div className="col-md-6">
                            <label htmlFor="end">{t(elementModif.end)}</label>
                            <input className="form-control" type="date"{...register('end',{required: true})}/>
                            {errors.end?.type === 'required' && (
                            <div className="text-danger col-12 mt-1 ml-2">
                                {t('endRequired')}
                            </div>
                            )}
                        </div>
                    }
                </div>
                {(periodError) ? <div className="alert alert-danger mt-3" dangerouslySetInnerHTML={{ __html: periodError }}></div> : false}
                {(success) ? <div className="alert alert-success mt-3" dangerouslySetInnerHTML={{ __html: success }}></div> : false}
                <button className="d-none" id="hideSend" >Envoi</button>
                <button
                type="button"
                disabled={periodButton}
                className="btn btn-danger"
                onClick={() => {
                    swal
                    .fire({
                        title: t('confirmDeleteGoals'),
                        text: t('irreversibleAction'),
                        icon: "warning",
                        type: "question",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: t('btnYes'),
                        cancelButtonText: t('btnNo'),
                    })
                    .then((result) => {
                        if (result.value) {
                            sendForm()
                        }
                    });
                }}>
                    {t('btnDelete')}
                </button>
            </form>
        </CardPerso>
    </>)
}

export default ObjectifDay;
