import React, {useContext} from "react";
import { IsAuthContext } from 'app/App';
import {HeadInfos,FormatedPrice} from "@gull";
import { useTranslate } from 'react-redux-multilingual'
import Moment from 'react-moment';

const HeadInventory = (props) => {
    const t = useTranslate()
    const { authParam } = useContext(IsAuthContext);
    let devise = authParam.company.devise;
    let digit = (authParam.company.devise === "XPF") ? 0 : 2;

    return(
        <div className="row mb-2">
            <div className="col-5">
                <HeadInfos  titre={props.inventoryInfos.name}/>
            </div>
            <div className="col-7">
                <div className="row">
                    <div className="col-3 mb-2">
                        <div className="fw-bold">{t('shop')+' :'}</div>
                        <div>{props.inventoryInfos.section && props.inventoryInfos.section.name}</div>
                    </div>
                    <div className="col-3 mb-2">
                        <div className="fw-bold">Zone :</div>
                        <div>{props.inventoryInfos.zone && props.inventoryInfos.zone.name}</div>
                    </div>
                    <div className="col-3 mb-2">
                        <div className="fw-bold">{t('createdAt')+' :'}</div>
                        <div>{<Moment format="DD/MM/YYYY">{props.inventoryInfos.created_at}</Moment>}</div>
                    </div>
                    <div className="col-3 mb-2">
                        <div className="fw-bold">{t('inventoryType')+' :'}</div>
                        <div>{(props.inventoryInfos.type=='zone') ? 'Zone' : (props.inventoryInfos.type=='categorie') ? t('categorysingular') : 'Bins'}</div>
                    </div>
                    {(props.inventoryInfos.hasOwnProperty('total_valeur_stock_new')) &&
                        <>
                            <div className="col-3 mb-2">
                                <div className="fw-bold">{t('newTotalValueStock')+' :'}</div>
                                <div>{<FormatedPrice langRegion={'fr-FR'} devise={devise} digit={digit} priceToFormat={props.inventoryInfos.total_valeur_stock_new} />}</div>
                            </div>
                            <div className="col-3 mb-2">
                                <div className="fw-bold">{t('oldTotalValueStock')+' :'}</div>
                                <div>{<FormatedPrice langRegion={'fr-FR'} devise={devise} digit={digit} priceToFormat={props.inventoryInfos.total_valeur_stock_old} />}</div>
                            </div>
                            <div className="col-3 mb-2">
                                <div className="fw-bold">{t('ecart')+' :'}</div>
                                <div>{<FormatedPrice langRegion={'fr-FR'} devise={devise} digit={digit} priceToFormat={+props.inventoryInfos.total_valeur_stock_new - +props.inventoryInfos.total_valeur_stock_old} />}</div>
                            </div>
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

export default HeadInventory;
