import React, {useState} from "react";
import {CreateFavorite} from '@gull'
import { useTranslate } from 'react-redux-multilingual';
import { Modal } from "react-bootstrap";

const  BtnFavoriteCreate = (props) => {
    const t = useTranslate();
    const [show,setShow]=useState(false)  


    return (<>
        <Modal size="md" show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>{t('addToFavorite')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <CreateFavorite tableName={props.tableName} sendData={props.sendData} setToast={props.setToast} setShow={setShow}/>
            </Modal.Body>
        </Modal>
        <button 
            className="btn btn-success mt-3" 
            style={{marginLeft: '10px'}} 
            onClick={()=>setShow(true)}
        >
            {t('addToFavorite')}
        </button>
    </>
    )
}

export default BtnFavoriteCreate;