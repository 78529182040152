import React from "react";
import { useTranslate } from 'react-redux-multilingual';
import { OverlayTrigger, Tooltip } from "react-bootstrap";

// Style import
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';

const  AttributeToStaff = (props) => {
    const t = useTranslate();

    const handleClick = () => {
        props.callback(props.zoneId,props.zoneName,props.rhList)
    }

    return (
        <OverlayTrigger
            placement="bottom"
            overlay={
                <Tooltip id={`tooltip-${props.index}`}>
                    {t('attribuer')}
                </Tooltip>
            }
        >
            <span onClick={() => handleClick()} style={{cursor:'Pointer', color:'#82c46c'}}><FontAwesomeIcon icon={faUserPlus} /></span>
        </OverlayTrigger>  
    )
}

export default AttributeToStaff;