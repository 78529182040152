import React, {useState} from "react";
import { useTranslate } from 'react-redux-multilingual';
import {ListFavorite} from '@gull'
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import axios from 'axios'
import swal from "sweetalert2";

// Style import
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

const  BtnFavoriteList = (props) => {
    const t = useTranslate();
    const [showFavorite,setShowFavorite]=useState(false)
    const [allFav,setAllFav]=useState([]) 
    const [loading,setLoading]=useState(false) 

    const getAllFavorites = async () =>{
        setShowFavorite(true)
        setLoading(true)
        const response  = await axios.post(process.env.REACT_APP_API_URL+"api/filtre/getallfilter",{
            page_name:props.tableName,
        }).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach((interm)=>{
                    let finalMessage=interm.split('/!');
                    if(finalMessage.length==2){
                        messageError+=t(finalMessage[0],{name:finalMessage[1]})+','
                    }
                    else{
                        messageError+=t(interm)+','
                    }
                })
                messageError=messageError.replace('undefined','');
                swal.fire(messageError, "", "error");
            }
            else{
                setAllFav(response.data.data)
            }
        }).catch((error) => {
            if(error=='end_session') return;
            let messageError = (error.response && error.response.status==403) ? t('forbiddenGeneral') : t('unknowError')
            swal.fire(messageError, "", "error");
        })
        setLoading(false)
    }

    return (<>
        <Modal size="lg" show={showFavorite} onHide={() => setShowFavorite(false)}>
            <Modal.Header closeButton>
                <Modal.Title>{t('listOfFavorite')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {loading ?
                    <span className="d-flex justify-content-center"><div className="spinner-border spinner-border-sm"></div></span>
                :   
                    <ListFavorite 
                        setAllFav={setAllFav} 
                        allFav={allFav} 
                        onSubmit={props.onSubmit} 
                        filterItemsPromo={props.filterItemsPromo}
                        setFiltre={props.setFiltre} 
                        setSendData={props.setSendData} 
                        setFavoriteFilterActived={props.setFavoriteFilterActived}
                        setCustomFilter={props.setCustomFilter}
                        setShowFavorite={setShowFavorite}
                    />
                }
            </Modal.Body>
        </Modal>
        <OverlayTrigger
            placement="bottom"
            overlay={
                <Tooltip id={`tooltip-${props.index}`}>
                    {t('listOfFavorite')}
                </Tooltip>
            }
        >
            <span className="text-primary" onClick={() => getAllFavorites()} style={{cursor:'Pointer'}}><FontAwesomeIcon icon={faStar} className="text-success" /></span>
        </OverlayTrigger> 
        </>
    )
}

export default BtnFavoriteList;