import React,{useState,useRef,useEffect,useContext} from "react";
import { useTranslate } from 'react-redux-multilingual'
import { InpDataList } from "@gull";
import axios from 'axios';
import swal from "sweetalert2";
import { IsAuthContext } from 'app/App';

const SelectSeller = (props) =>{
    const t =useTranslate()
    const { authParam } = useContext(IsAuthContext);
    const [sellerNameInput,setSellerNameInput] = useState('')
    const [invalidInput,setInvalidInput] = useState(true)
    const [dataSeller,setDataSeller]=useState([])
    const [loadNewSeller,setLoadNewSeller]=useState(false)
    const [reload,setReload]=useState(Date.now)
    const [datas,setDatas] = useState([])
    const [barObj,setBarObj] = useState({})
    const [rhIdObj,setRhIdObj] = useState({})
    const idsLocalStorage=(props.from=='pos' ) ? localStorage.getItem("pos").split(',') : [0,0]

    const moduleOption = authParam.modules.find(value=>value.name==props.from) //props.from qui est soit 'erp' soit 'pos'
    const sellerRequired = (moduleOption.params.staff.active && !Array.isArray(moduleOption.params.staff.params) && moduleOption.params.staff.params.hasOwnProperty('seller_required') && moduleOption.params.staff.params.seller_required) ? true : false
    const sellerSlectShow = (moduleOption.params.staff.active && !Array.isArray(moduleOption.params.staff.params) && moduleOption.params.staff.params.hasOwnProperty('seller_field') && moduleOption.params.staff.params.seller_field) ? true : false
    
    const dataListDrop = useRef('')
    const searchInput = useRef('')

    useEffect(function () {
        let initial = []
        let initialBarObj = {};
        let initialRhIdObj = {};
        props.section.rh.forEach((value)=>{
            initial.push({
                id:value.id,
                name:value.name+' '+value.firstname
            })
            initialBarObj={...initialBarObj,[value.bar]:value.name+' '+value.firstname}
            initialRhIdObj={...initialRhIdObj,[value.bar]:value.id}
        })
        setBarObj(initialBarObj)
        setRhIdObj(initialRhIdObj)
        setDataSeller(initial)
        setDatas(initial)
        if(props.item.vendeur!=null){
            setSellerNameInput(props.item.vendeur.name+' '+props.item.vendeur.firstname)
            setInvalidInput(false)

        }else if(props.currentTicket && props.currentTicket.rh!=null){
            setSellerNameInput(props.currentTicket.rh.name+' '+props.currentTicket.rh.firstname)
            setInvalidInput(false)
        }else{
            setSellerNameInput('')
            setInvalidInput(true)
        }
    }, [props.currentTicket,reload])

    const noRedInput = () => {
        setSellerNameInput('')
        setInvalidInput(false)
    }

    const handleSearch = (e,declenchement) => {
        setSellerNameInput(e.target.value)
        if(e.target.value.length>=declenchement){
            let ajour = dataSeller.filter(value=>value.name.toLowerCase().includes(e.target.value.toLowerCase()));
            setDatas(ajour)
            dataListDrop.current.classList.remove("d-none");
        }
        else{
            dataListDrop.current.classList.add("d-none");
        }
    }

    let urlParam = (props.from=='pos') ? idsLocalStorage[1]+"/"+idsLocalStorage[0] : props.caisseIdent+'/'+props.pos_id

    const handleCLick = async (id,name,firstname) => {
        setSellerNameInput(name+' '+firstname)
        setInvalidInput(false)
        setLoadNewSeller(true)
        const response = await axios.post(process.env.REACT_APP_API_URL+"api/pos/ticket/construction/"+props.posid+"/"+urlParam,{
            id:(props.cookies && props.cookies.current_ticket) ? props.cookies.current_ticket : props.ticketId,
            detail_id:props.lineId,
            vendeur_id:id

        }).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach(interm=>messageError+=t(interm)+'. ')
                messageError=messageError.replace('undefined','');
                swal.fire(messageError, "", "error");
            }
            else{
                if(props.module && props.module=='salesOrder'){
                    props.setTicketDetail({...props.commandeDetail,ticket:response.data.data})
                    setReload(Date.now())
                }else if(props.from=='pos'){   
                    props.setCurrentTicket(response.data.data)
                }else if(props.from=='erp'){
                    props.setTicketDetail(response.data.data)
                    setReload(Date.now())
                }
                setLoadNewSeller(false)
            }
        }).catch((error) => {
            if(error=='end_session') return;
            swal.fire(t('unknowError'), "", "error");
        })
        setLoadNewSeller(false)
    }

    const handleKeyPress = async (e) => {
        if(e.charCode==13){
            if(barObj[e.target.value]){
                setSellerNameInput(barObj[e.target.value])
                setLoadNewSeller(true)
                setInvalidInput(false)
                const response = await axios.post(process.env.REACT_APP_API_URL+"api/pos/ticket/construction/"+props.posid+"/"+urlParam,{
                    id:(props.cookies && props.cookies.current_ticket) ? props.cookies.current_ticket : props.ticketId,
                    detail_id:props.lineId,
                    vendeur_id:rhIdObj[e.target.value]
        
                }).then((response) => {
                    if(!response.data.valid){
                        let interms='';
                        var keys = Object.keys(response.data.errors);
                        for(var i = 0; i < keys.length; i++){
                            interms+=response.data.errors[keys[i]].join(',')+','
                        }
                        interms=interms.split(',')
                        let messageError='';
                        interms.forEach(interm=>messageError+=t(interm)+'. ')
                        messageError=messageError.replace('undefined','');
                        swal.fire(messageError, "", "error");
                    }
                    else{
                        if(props.module && props.module=='salesOrder'){
                            props.setTicketDetail({...props.commandeDetail,ticket:response.data.data})
                            setReload(Date.now())
                            
                        }else if(props.from=='pos'){   
                            props.setCurrentTicket(response.data.data)
                        }else if(props.from=='erp'){
                            props.setTicketDetail(response.data.data)
                            setReload(Date.now())
                        }
                        setLoadNewSeller(false)
                    }
                }).catch((error) => {
                    if(error=='end_session') return;
                    swal.fire(t('unknowError'), "", "error");
                })
                setLoadNewSeller(false)

                setTimeout(()=>{
                    searchInput.current.blur()
                },200)
            }
        }
    }

    const handleBlur = () => {
        setReload(Date.now)
        if(props.from=='pos') props.setRelanceFocus(Date.now())
        dataListDrop.current.classList.add("d-none");
    }

    if(sellerSlectShow){//si on a choisit d'afficher le champ vendeur dans les options staff
        return(
            <div className="row d-flex justify-content-end position-relative">
                <div className="col-11 m-0 p-0">
                    <InpDataList
                        placeholder={t('seller')}
                        value={sellerNameInput}
                        onClick={handleCLick}
                        onChange={handleSearch}
                        datas={datas}
                        declenchement={3}
                        listRef={dataListDrop}
                        inputRef={searchInput}
                        onBlur={handleBlur}
                        inputName="searchSeller"
                        from='barLeft'
                        className={(sellerRequired && invalidInput) ? "form-control is-invalid" : "form-control"}
                        onFocus={noRedInput}
                        onKeyPress={handleKeyPress}
                    />
                </div>
                {(loadNewSeller) ?
                        <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm sellerPosition"></div></span>
                    :
                        false
                }
            </div>
        )
    }else{
        return(
            <div></div>
        )
    }

    
}

export default SelectSeller;