import React, { useState,useEffect} from "react";
import { useTranslate } from 'react-redux-multilingual'
import axios from 'axios'
import swal from "sweetalert2";

const DateEdit = (props) => {
    const t = useTranslate()
    const [valueInput,setValueInput]=useState({
        date:'',
        time:''
    })
    const [validButton,setValidButton]=useState(false)

    useEffect(function () {
        let separatedDateNTime = props.ticketDetail.date_validation.split(' ')
        let formatedTime = separatedDateNTime[1].split(':')

        setValueInput({
            date:separatedDateNTime[0],
            time:formatedTime[0]+':'+formatedTime[1]
        })
    }, [])

    const handlechange = (e,name) =>{
        setValueInput({...valueInput,[name]:e.target.value})
    }

    const handleSubmit = async (e) =>{
        e.preventDefault()
        setValidButton(true)
        const response = await axios.post(process.env.REACT_APP_API_URL+"api/pos/ticket/construction/"+props.ticketDetail.section_id+"/"+props.ticketDetail.caisse_ident_id+"/"+props.ticketDetail.pos_id,{
            id:props.ticketDetail.id,
            date_validation: valueInput.date+' '+valueInput.time
        }).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach(interm=>messageError+=t(interm)+'. ')
                messageError=messageError.replace('undefined','');
                swal.fire(messageError, "", "error");
            }
            else{   
                setValidButton(false)
                props.setEditDate(false)
                props.setTicketDetail(response.data.data)
            }
        }).catch((error) => {
            if(error=='end_session') return;
            swal.fire(t('unknowError'), "", "error");
        })
        setValidButton(false)

    }
    
    return(
        <div>
            <form onSubmit={(e)=>handleSubmit(e)}>
                <input value={valueInput.date} type="date" className="form-control mb-1" onChange={(e)=>handlechange(e,'date')}/>
                <input value={valueInput.time} type="time" className="form-control" onChange={(e)=>handlechange(e,'time')}/>
                <button disabled={validButton} className="btn btn-primary btn-sm mt-2">
                    {validButton ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : t('btnValidate')}
                </button>
            </form>
        </div>
    )
}

export default DateEdit;