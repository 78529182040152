import React, {useState,useRef} from "react";
import { useTranslate } from 'react-redux-multilingual'
import { InpDataList,FormatedPrice,CardPerso } from "@gull";
import axios from 'axios'
import swal from "sweetalert2";
import { useParams } from "react-router-dom";
import { Modal,Button } from "react-bootstrap";
import DetailGiftCardModal from "./component/DetailGiftCardModal"
import Moment from 'react-moment';

const useSearchClients = () => {
    const t =useTranslate()
    const [loadingSearchClient,setLoadingSearchClient]=useState(false)
    const [listSearchClient,setListSearchClient]=useState([])
    const [datas,setDatas]=useState([])
    const searchInputClient = useRef('')

    const loadClients = async (toSearch) => {
        setLoadingSearchClient(true)
        const response = await axios.get(process.env.REACT_APP_API_URL+"api/client/name/"+toSearch
        ).then((response) => {
            setListSearchClient(response.data.datas)
            let initial = []
            response.data.datas.forEach((value)=>
                initial.push({
                    id: value.id,
                    name: value.name+' '+value.firstname+' - '+value.email+' - '+value.tel
                })
            )
            setListSearchClient(initial)
            let ajour = initial.filter((value)=>value.name.toLowerCase().includes(searchInputClient.current.value.toLowerCase()))
            setDatas(ajour)
            setLoadingSearchClient(false)
        }).catch((error) => {
            if(error=='end_session') return;
            setLoadingSearchClient(false)
            swal.fire(t('unknowError'), "", "error");
        })
    }

    return [loadingSearchClient,loadClients,listSearchClient,datas,setDatas,searchInputClient]
}

const SearchHistoriqueGiftCard = (props) => {
    const t =useTranslate()
    const [loadingSearchClient,loadClients,listSearchClient,datas,setDatas,searchInputClient]=useSearchClients()
    const [valueInputClient,setValueInputClient]=useState('')
    const [allGiftCards,setAllGiftCards]=useState([])
    const [detailGiftCards,setDetailGiftCards]=useState({})
    const [show,setShow]=useState(false)
    const [load,setLoad]=useState({
        allGiftCard:false,
        oneGiftCard:true
    })
    const [lastSearch,setLastSearch]=useState(false) 
    const dataListDropClient = useRef('')
    const { posid } = useParams();

    const handleSearch = (e,declenchement) => {
        setValueInputClient(e.target.value)
        if(e.target.value.length>=declenchement){
            dataListDropClient.current.classList.remove("d-none");

            if(!lastSearch || e.target.value.substring(0, declenchement) != lastSearch.substring(0, declenchement)){
                loadClients(e.target.value.substring(0, declenchement))
                setLastSearch(e.target.value.substring(0, declenchement))
            }
        }else{
            dataListDropClient.current.classList.add("d-none");
            setLastSearch(false)
        }
        if(e.target.value.length!=declenchement){
            let ajour = listSearchClient.filter((value)=>value.name.toLowerCase().includes(e.target.value.toLowerCase()))
            setDatas(ajour)
        }
    }

    const handleCLick = (id,valeur) => {
        setValueInputClient(valeur)
        dataListDropClient.current.classList.add("d-none");
        setDatas([]) 
        loadAllGiftCards(id)
    }

    const loadAllGiftCards = async (clientId) =>{
        setLoad({...load,allGiftCard:true})
        const response = await axios.get(process.env.REACT_APP_API_URL+"api/pos/gift_card/get_all_of_client/"+clientId
        ).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach(interm=>messageError+=t(interm)+'. ')
                messageError=messageError.replace('undefined','');
                swal.fire(messageError, "", "error");
            }
            else{   
                setAllGiftCards(response.data.datas)
                setLoad({...load,allGiftCard:false})
            }
        }).catch((error) => {
            if(error=='end_session') return;
            swal.fire(t('unknowError'), "", "error");
        })
        setLoad({...load,allGiftCard:false})
    }

    const getGiftCardDetail = async (giftCardId) =>{
        setShow(true)
        setLoad({...load,oneGiftCard:true})
        const response = await axios.get(process.env.REACT_APP_API_URL+"api/pos/gift_card/get_one/"+giftCardId
        ).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError=''; 
                interms.forEach(interm=>messageError+=t(interm)+'. ')
                messageError=messageError.replace('undefined','');
                swal.fire(messageError, "", "error");
            }
            else{   
                setDetailGiftCards(response.data.data)
                setLoad({...load,oneGiftCard:false})
            }
        }).catch((error) => {
            if(error=='end_session') return;
            swal.fire(t('unknowError'), "", "error");
        })
        setLoad({...load,oneGiftCard:false})
    }

    return(<>
        <Modal show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>{t('detail')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {(load.oneGiftCard) ? 
                    <span className="d-flex justify-content-center"><div className="spinner-border spinner-border-sm"></div></span>
                : 
                    <DetailGiftCardModal 
                        detailGiftCards={detailGiftCards}
                        devise={props.devise}
                        digit={props.digit}
                    />
                }
            </Modal.Body>
        </Modal>
        <div className="row d-flex justify-content-center mt-4 position-relative">
            <div className="col-8">
                <InpDataList
                    placeholder={t('customerPlacehlder')}
                    value={valueInputClient}
                    onClick={handleCLick}
                    onChange={handleSearch}
                    datas={datas}
                    declenchement={4}
                    listRef={dataListDropClient}
                    inputRef={searchInputClient}
                />
            </div>
            {(loadingSearchClient) ? <span><div className="spinner-border spinner-border-sm historiqueGiftCardPosition"></div></span> : false }
        </div>
        <div className="row mt-4 d-flex justify-content-center">
            <CardPerso>
                <table
                    className="table"
                >
                    <thead>
                        <tr>
                            <th scope="col" className="text-center">{t('giftcard')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(load.allGiftCard) ? 
                            <tr className="mt-3 d-flex justify-content-center">
                                <td className="spinner-border spinner-border-sm"></td>
                            </tr>
                        : 
                            allGiftCards.length!=0 && allGiftCards.map((value,ind) =>{
                                
                                let dateExpire = value.gift_card_records.filter(value=>value.is=='credit')
                                let lastExpireDate = dateExpire[+dateExpire.length-1]
                                
                                let style = (ind%2 == 0) ? 'stripeBg stripePadding pointer btnTicket' : 'stripePadding pointer btnTicket';
                                if(posid){
                                    if(value.sections_avaible.includes(+posid)){
                                        return(
                                            <tr key={value.id} className={style} onClick={()=>getGiftCardDetail(value.id)}>
                                                <td className="row" style={{marginLeft:'0', marginRight:'0'}}>
                                                    <strong className="col-4">{value.reference}</strong> 
                                                    <span className="col-4 text-center">{t('dateExpire')+': '}<strong>{<Moment format="DD/MM/YYYY">{lastExpireDate.expire}</Moment>}</strong></span>
                                                    <span className="col-4 text-end">
                                                        <span className="d-inline-block">{<FormatedPrice langRegion={'fr-FR'} devise={props.devise} digit={props.digit} priceToFormat={value.disponible} />}</span>
                                                    </span>
                                                </td>
                                            </tr>
                                        )
                                    }
                                }else{
                                    return(
                                        <tr key={value.id} className={style} onClick={()=>getGiftCardDetail(value.id)}>
                                            <td className="row" style={{marginLeft:'0', marginRight:'0'}}>
                                                <strong className="col-4">{value.reference}</strong> 
                                                <span className="col-4 text-center">{t('dateExpire')+': '}<strong>{<Moment format="DD/MM/YYYY">{lastExpireDate.expire}</Moment>}</strong></span>
                                                <span className="col-4 text-end">
                                                    <span className="d-inline-block">{<FormatedPrice langRegion={'fr-FR'} devise={props.devise} digit={props.digit} priceToFormat={value.disponible} />}</span>
                                                </span>
                                            </td>
                                        </tr>
                                    )
                                }                        
                            })
                        }
                    </tbody>
                </table>
            </CardPerso>
        </div>
        </>
    )
    
}

export default SearchHistoriqueGiftCard;