import React, {useContext,lazy,Suspense} from 'react';
import { useTranslate } from 'react-redux-multilingual'
import { IsAuthContext } from 'app/App';
import { Loading } from '@gull';

const BtnEditField = lazy (() => import("./BtnEditField"));
const CarouselImage = lazy (() => import("../../carousel/CarouselImage"));
const EditImageForm = lazy (() => import("./EditFormComp/EditImageForm"));

const BlocItemImg = (props) => {
    const t = useTranslate();
    const { authParam } = useContext(IsAuthContext);

    let unauthorizedUser = authParam.access.find(access=>access.action==props.allowAccessName+'read')

    if(unauthorizedUser){//Si unauthorizedUser est true, c'est qu'il y a une restriction sur notre compte, on ne peut donc pas faire d'édition 
        return(<></>)
    }else{
        return(
            <div className={props.style}>
                {props.editable=='true' && <BtnEditField isFor={props.isFor} allowAccessName={props.allowAccessName} onEdit={props.onEdit} handleEdit={props.handleEdit} />}
                {(props.onEdit.image) ?
                    <EditImageForm onSubmit={props.onSubmit} image={props.image} validButton={props.validButton}/>
                :
                    (props.image) ?
                        <Suspense fallback={<Loading></Loading>}>
                            <CarouselImage images={props.image} />
                        </Suspense>
                    :
                        <div className='mb-3 text-center'>
                            <img  src="/assets/images/no-image.png" alt="No image" />
                        </div>
                }
            </div>
        )
    }
}

export default BlocItemImg;