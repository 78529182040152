import React, {useState,useEffect} from "react";

const InpDataList = (props) => {
    const [listHeight,setListHeight]=useState(0) 
    let delay = (props.delay) ? props.delay : false
    let saClass = (props.from=="scanArticleWms") ? "list-group d-none dataListPerso z-index1000 mt-5" : "list-group d-none dataListPerso z-index1000"

    useEffect(function () {
        if(props.listRef.current!='' && props.datas && props.datas.length!=0) setListHeight(+props.listRef.current.offsetHeight)
    }, [props.datas])

    return (<>
        {(props.listPosition && props.listPosition=="top") &&
            <div ref={props.listRef} className={`${saClass} flex-column-reverse`} style={{top:'-'+listHeight+'px', right:'0'}}>
                {props.datas && props.datas.map((value,ind)=>
                    (props.from=='reorganizationStock') ?
                        <li onMouseDown={()=> props.onClick(value)} className="list-group-item list-group-item-action pointer" key={ind}>
                            {value}
                        </li>
                    :
                        <li onMouseDown={()=> props.onClick(value.id,value.name,props.inputName,value.reference,value.quota,value.image,value.nbr)} className="list-group-item list-group-item-action pointer text-start" key={ind}>
                            {(props.showImg) ? 
                                <div className="row">
                                    <div className="col-9">
                                        {value.name}
                                    </div>
                                    <div className="col-3 m-0 p-0">
                                        {(value.image) ?
                                            <div className='text-end m-0 p-0' style={{maxHeight:'60px'}}>
                                                <img style={{maxHeight:'50px', maxWidth:'70px'}} src={value.image.split(';')[0]} alt={value.name}/>
                                            </div>
                                        :
                                            <div className='text-end m-0 p-0' style={{maxHeight:'60px'}}>
                                                <img style={{maxHeight:'50px', maxWidth:'70px'}} src="/assets/images/no-image.png" alt="No image" />
                                            </div>
                                        }
                                    </div>
                                </div>
                            :
                                value.name
                            }
                        </li>
                )}
            </div>
        }

        <input 
            ref={props.inputRef}
            type="text"
            className={(props.className) ? props.className : "form-control"}
            placeholder={(props.placeholder) && props.placeholder}
            value={(props.value) ? props.value : ''}
            onChange={(e) => props.onChange(e,props.declenchement,delay)}
            onBlur={(props.onBlur) ? () => props.onBlur(props.inputName) : () => {return false}}
            onFocus={(props.onFocus) ? () => props.onFocus(props.inputName) : () => {return false} }
            onKeyPress={(props.onKeyPress) ? (e) => props.onKeyPress(e) : () => {return false} }
            disabled={(props.disabled) ? props.disabled : false}
            inputMode={(props.inputMode) ? props.inputMode : 'text'}
        />
        {(props.listPosition==undefined || props.listPosition=='bottom') &&
            <div ref={props.listRef}  className={saClass} style={props.padOptionPosition ? props.padOptionPosition : {}}>
                {props.datas && props.datas.map((value,ind)=>
                    (props.from=='reorganizationStock') ?
                        <li onMouseDown={()=> props.onClick(value)} className="list-group-item list-group-item-action pointer" key={ind}>
                            {value}
                        </li>
                    :
                        <li onMouseDown={()=> props.onClick(value.id,value.name,props.inputName,value.reference,value.quota,value.image,value.nbr)} className="list-group-item list-group-item-action pointer text-start" key={ind}>
                            {(props.showImg) ? 
                                <div className="row">
                                    <div className="col-9">
                                        {value.name}
                                    </div>
                                    <div className="col-3 m-0 p-0">
                                        {(value.image) ?
                                            <div className='text-end m-0 p-0' style={{maxHeight:'60px'}}>
                                                <img style={{maxHeight:'50px', maxWidth:'70px'}} src={value.image.split(';')[0]} alt={value.name}/>
                                            </div>
                                        :
                                            <div className='text-end m-0 p-0' style={{maxHeight:'60px'}}>
                                                <img style={{maxHeight:'50px', maxWidth:'70px'}} src="/assets/images/no-image.png" alt="No image" />
                                            </div>
                                        }
                                    </div>
                                </div>
                            :
                                value.name
                            }
                        </li>
                )}
            </div>
        }
    </>)
};

export default InpDataList;