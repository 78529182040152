import React, {lazy,Suspense} from 'react';
import { useTranslate } from 'react-redux-multilingual'
import { Loading } from '@gull';

// Style import
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit,faWindowClose } from '@fortawesome/free-solid-svg-icons';

const EditEnumereForm = lazy (() => import("./EditFormComp/EditEnumereForm"));

const LineTableEnumere = (props) => {
    const t = useTranslate();

    return(
        <tr>
            <td>{props.numeroEnumere}</td>
            {(props.onEdit['enumere'+props.numeroEnumere]) ?
                <Suspense fallback={<Loading></Loading>}>
                    <EditEnumereForm 
                        article={props.article}
                        numeroEnumere={props.numeroEnumere}
                        onSubmit={props.onSubmit}
                        validButton={props.validButton}
                        writeAccessName={props.writeAccessName}
                        writeAccessRef={props.writeAccessRef}
                        writeAccessQte={props.writeAccessQte}
                        readAccessName={props.readAccessName}
                        readAccessRef={props.readAccessRef}
                        readAccessQte={props.readAccessQte}
                    />
                </Suspense>
            :
                <>  
                    {(props.writeAccessName && props.writeAccessRef && props.writeAccessQte) ?
                        <td></td>
                    :
                        <td> 
                            <span onClick={() => props.handleEdit('enumere'+props.numeroEnumere)} style={{cursor:'Pointer'}}>
                                {!props.onEdit['enumere'+props.numeroEnumere] ?
                                    <FontAwesomeIcon className='text-primary' icon={faEdit} />
                                :
                                    <FontAwesomeIcon className='text-danger' icon={faWindowClose} />
                                }
                            </span>
                        </td>
                    }
                    {!props.readAccessName ? <td>{(props.article.hasOwnProperty('name_'+props.numeroEnumere) && props.article['name_'+props.numeroEnumere]!=null) ? props.article['name_'+props.numeroEnumere] : '-'}</td> : <td></td>}
                    {!props.readAccessRef ? <td>{(props.article.hasOwnProperty('reference_'+props.numeroEnumere) && props.article['reference_'+props.numeroEnumere]!=null) ? props.article['reference_'+props.numeroEnumere] : '-'}</td> : <td></td>}
                    {!props.readAccessQte ? <td style={{width:'10%'}}>{(props.article.hasOwnProperty('quantity_'+props.numeroEnumere) && props.article['quantity_'+props.numeroEnumere]!=null) ? props.article['quantity_'+props.numeroEnumere] : '-'}</td> : <td></td>}
                </>
            }
        </tr>
    )
}

export default LineTableEnumere;