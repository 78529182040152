import React,{useState,useRef,useEffect,useContext} from "react";
import { CardPerso,Raccourci,ArticleBarSearch} from "@gull";
import { useTranslate } from 'react-redux-multilingual'
import SweetAlert from "sweetalert2-react";
import axios from 'axios'
import swal from "sweetalert2";
import { IsAuthContext } from 'app/App';

const useSearchItem = () => {
    const [loadingSearchItem,setLoadingSearchItem]=useState(false)
    const [generalErrorSearchItem,setGeneralErrorSearchItem]=useState(false)
    const [listSearchItem,setListSearchItem]=useState([])
    const [datas,setDatas] = useState(listSearchItem)
    const searchInput = useRef('')

    const loadItems = async (inputValue) => {
        setLoadingSearchItem(true)
        const response = await axios.get(process.env.REACT_APP_API_URL+"api/erp/article/name/"+inputValue
        ).then((response) => {
            setLoadingSearchItem(false)
            setListSearchItem(response.data.datas)
            let ajour = response.data.datas.filter((value)=>value.name.replace(/\u00A0/g,' ').toLowerCase().includes(searchInput.current.value.replace(/\u00A0/g,' ').toLowerCase()))
            setDatas(ajour)
        }).catch((error) => {
            if(error=='end_session') return;
            setLoadingSearchItem(false)
            setGeneralErrorSearchItem(true)
        }) 
    }

    return [loadingSearchItem,setLoadingSearchItem,loadItems,generalErrorSearchItem,setGeneralErrorSearchItem,listSearchItem,datas,setDatas,searchInput]
}

const BarRight = (props) => {
    const t =useTranslate()
    const [loadingSearchItem,setLoadingSearchItem,loadItems,generalErrorSearchItem,setGeneralErrorSearchItem,listSearchItem,datas,setDatas,searchInput]=useSearchItem()
    const [valueInput,setValueInput]=useState('')
    
    const [disabled,setDisabled]=useState(false)
    const idsLocalStorage=localStorage.getItem("pos").split(',')
    const [allRaccourci,setAllRaccourci]=useState([])
    const raccourciArr = ['article_id','fifthlevelcat_id','firstlevelcat_id','fourthlevelcat_id','secondlevelcat_id','thirdlevelcat_id']
    const { authParam } = useContext(IsAuthContext);
    const modulePos = authParam.modules.find(value=>value.name=='pos')
    const [negativeAlreadyScan,setNegativeAlreadyScan]=useState([]) 

    useEffect(function () { //fonction qui lance et relance le focus sur l'input de recheche d'article
        if(searchInput.current){
            setTimeout(()=>{
                if(searchInput.current){
                    searchInput.current.focus()
                }
            },500)
        }
    }, [props.relanceFocus,searchInput])

    useEffect(function () { 
        if(props.section && props.section.profil_raccourci_caisse!=null){
            if(props.section.profil_raccourci_caisse.raccourci_caisse_detail.length!=0){  
                //on réorganise l'ordre d'affichage des raccourcis selon la varibale position
                let bonOrdre = props.section.profil_raccourci_caisse.raccourci_caisse_detail.sort((a, b) => (a.position > b.position ? 1 : -1))
                setAllRaccourci(bonOrdre)
            }
        }
        
    }, [props.section])

    const handleSubmit = async (e,articleid) =>{
        if(e) e.preventDefault();
        setLoadingSearchItem(true)
        props.setDisabledPay(true)
        props.setDisabledCloudButton(true)
        
        let data  
        if(articleid){//Si on recherche l'article à la main
            data = { 
                article_id:articleid
            }
        }else{//si on scan un article
            data = { 
                reference:valueInput
            }
        }
        
        if(props.cookies.current_ticket){
            data['id']=props.cookies.current_ticket

        }else{
            if(props.cashierId){
                data['rh_id']=props.cashierId
            }
            if(props.clientId){
                data['client_id']=props.clientId
            }
        }
        
        let currentTicketCookie

        const response = await axios.post(process.env.REACT_APP_API_URL+"api/pos/ticket/construction/"+props.posid+"/"+idsLocalStorage[1]+"/"+idsLocalStorage[0], data
        ).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach(interm=>messageError+=t(interm)+'. ')
                messageError=messageError.replace('undefined','');
                swal.fire(messageError, "", "error");
            }
            else{   
                //Vérification si L'article est en stock négatif
                if(!Array.isArray(response.data.negative_stock)){
                    //On vérifie si negative_stock est un objet car si il n'y pas d'article en stock négatif, negative_stock est un []
                    let newarr = []

                    Object.keys(response.data.negative_stock).map(idArticle=>{
                        //ici on reconstruit "negativeAlreadyScan" si il y a un nouvel article en stock négatif
                        newarr.push(idArticle.toString())

                        //Si l'artile en stock négatif n'a pas encoré été scanné ou à deja été scannée mais son stock va passer en négatif, on affiche une alerte
                        if(!negativeAlreadyScan.includes(idArticle.toString())) swal.fire(t('stockNegativeAlertMsg'), "", "warning");
                    })

                    setNegativeAlreadyScan(newarr)
                }

                props.setCurrentTicket(response.data.data)
                currentTicketCookie=response.data.data.id
                if(!props.cookies.current_ticket){
                    props.setCookie("current_ticket",currentTicketCookie,{ path: "/", expires: props.expire_date }) 
                }
                
                let articleAfficheur
                if(articleid){
                    articleAfficheur = response.data.data.ticket_detail.find(value=>value.article_id == articleid)
                }else{
                    articleAfficheur = response.data.data.ticket_detail.find(value=>{
                        if(value.article){
                            return value.article.reference == valueInput
                        }
                    })
                }
                
                if(props.peripheriqueParam && props.peripheriqueParam.afficheur && props.peripheriqueParam.afficheur.model=='second_screen_display'){
                    // affiche et envoyé en param 'reason' pour afficher le contenu du ticket sur l'écran secondaire, si on envoie 'clear' il néttoiera l'ensemble de l'afficheir
                    props.affichageClient(response.data.data,false,false,'add',false,false,props.peripheriqueParam)

                }else{
                    if(articleAfficheur) props.affichageClient(articleAfficheur.article.name,articleAfficheur.prix_remise,articleAfficheur.quantity,0,props.devise,t,props.peripheriqueParam)
                    //le 0 sur la variable 'raison' est passé pour définir que les infos ne concerne pas le total
                }
            }
            setLoadingSearchItem(false)
        }).catch((error) => {
            console.log(error)
            if(error=='end_session') return;
            swal.fire(t('unknowError'), "", "error");
        })
        setLoadingSearchItem(false)
        setValueInput('')
        setDisabled(false)
        props.setRelanceFocus(Date.now())
        props.setDisabledCloudButton(false)
    }

    const getNextShorcut = async (categoryName,categoryId) =>{
        const response = await axios.get(process.env.REACT_APP_API_URL+"api/pos/raccourci_caisse/get_next/"+categoryName+"/"+categoryId
        ).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach(interm=>messageError+=t(interm)+'. ')
                messageError=messageError.replace('undefined','');
                swal.fire(messageError, "", "error");
            }
            else{   
                let orderedNextShortCut = response.data.next.sort((a, b) => {
                    // Comparez les valeurs de la propriété 'affiche' de chaque objet
                    // Utilisez localeCompare() pour comparer les chaînes de caractères de manière alphabétique
                    return a.affiche.localeCompare(b.affiche);
                });
                props.setDataNextShortcut(orderedNextShortCut)
            }
        }).catch((error) => {
            if(error=='end_session') return;
            swal.fire(t('unknowError'), "", "error");
        })
        setDisabled(false)
    }

    const toggleAlertSearchItem = () => {
        setGeneralErrorSearchItem(false)
    }

    const raccourciAction = (name,articleId,categorieId) =>{
        if(name=='giftcard'){
            props.setShowModalGiftCard(true)
        }else if(name=='article'){
            handleSubmit(false,articleId)
            setDisabled(true)
        }else{
            let paramCategorieId = (props.nextShortcutString!='') ? categorieId.toString()+'-'+props.nextShortcutString : categorieId.toString()
            //Si aucun nextShortcutString existe, on exécute la recherche via categorieId, sinon on récupère le chainage des précédentes recherche et on ajoute la derniere afin d'affiner notre recherche
            
            getNextShorcut(name,paramCategorieId)
            props.setNextShortcutString(paramCategorieId)
            // props.setLastShortCutClicked({...props.lastShortCutClicked,[categorieId]:name})//on sauvegarde le type choisit 
            props.setLastShortCutClicked([name,...props.lastShortCutClicked])//on sauvegarde le type choisit 
            setDisabled(true)
        }
    }

    const getPreviousSearchRaccourci = () =>{
        let arrRaccourci = props.nextShortcutString.split('-')

        if(arrRaccourci.length==1){
            //Si il y a eu que un seul filtrage, on efface tout pour revenir que les raccourcis de base
            props.resetSearchRaccourci()
        }else{
            //Si il y a eu plus d'un filtrage, on revient sur le filtrage précédent
            let stRaccourciIds = ''
            arrRaccourci.map((raccourciId,index)=>{
                if(index!=0){
                    if(stRaccourciIds==''){
                        stRaccourciIds=raccourciId.toString()
                    }else{
                        stRaccourciIds+='-'+raccourciId.toString()
                    }
                }
            })

            // getNextShorcut(props.lastShortCutClicked[arrRaccourci[1]],stRaccourciIds)
            //Comme le dernier filtre est à l'index 0, si on souhaite revenir sur le filtre précédent, il faut utiliser le filtre qui suit à l'index 1 
            getNextShorcut(props.lastShortCutClicked[1],stRaccourciIds)
            let newArr = []
            props.lastShortCutClicked.map((value,index)=>{
                if(index!=0) newArr.push(value)
            })
            props.setLastShortCutClicked(newArr)
            props.setNextShortcutString(stRaccourciIds)
            setDisabled(true)
        }
    }

    return(<>
        <ArticleBarSearch 
            loadingSearchItem={loadingSearchItem}
            loadItems={loadItems}
            listSearchItem={listSearchItem}
            datas={datas}
            setDatas={setDatas}
            dataNextShortcut={props.dataNextShortcut}
            resetSearchRaccourci={props.resetSearchRaccourci}
            getPreviousSearchRaccourci={getPreviousSearchRaccourci}
            valueInput={valueInput}
            setValueInput={setValueInput}
            handleSubmit={handleSubmit}
            setRelanceFocus={props.setRelanceFocus}
            searchInput={searchInput}
            noBlur={false}
        />
        <div className={`col-12 ${props.view}`}>
            <div className="row" style={{width:'99%',margin:'auto'}}>
                {(props.dataNextShortcut.length!=0) ?
                    <>
                        {props.dataNextShortcut.map(item=>(
                            <Raccourci 
                                key={item.id}
                                title={item.affiche} 
                                image={item.image}
                                raccourciType={item.type}
                                raccourciAction={raccourciAction}
                                raccourciTypeId={item.id}
                                setRelanceFocus={props.setRelanceFocus}
                                disabled={disabled}
                            />
                        ))}
                    </>
                :
                    <>
                        {(allRaccourci.length!=0) ?
                            allRaccourci.map(value=>{
                                let raccourciType
                                for(let i=0; i<raccourciArr.length; i++){
                                    if(value[raccourciArr[i]]!=null){
                                        raccourciType=raccourciArr[i].replace('_id', '')
                                    }
                                }
                                
                                return(
                                    <Raccourci 
                                        key={value.id}
                                        title={value.affiche} 
                                        image={value.image}
                                        raccourciType={raccourciType}
                                        raccourciAction={raccourciAction}
                                        raccourciTypeId={value[raccourciType+'_id']}
                                        setRelanceFocus={props.setRelanceFocus}
                                        disabled={disabled}
                                    />
                                )
                            })
                        :
                            false
                        }
                        {(modulePos.params.gift_card.active) &&
                            <Raccourci 
                                title={t('giftcard')} 
                                raccourciType='giftcard'
                                raccourciAction={raccourciAction}
                                setRelanceFocus={props.setRelanceFocus}
                                disabled={disabled}
                            />
                        }
                    </>
                }
            </div>
        </div>
        {(generalErrorSearchItem) &&
            <SweetAlert show="true" title={t('unknowError')} type="error" onConfirm={() => toggleAlertSearchItem()}/>
        }
    </>
    )
}

export default BarRight;