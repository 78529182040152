import React,{useState} from "react";
import {OrderFormNext} from "@gull"
import { useTranslate } from 'react-redux-multilingual';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye} from '@fortawesome/free-solid-svg-icons'
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import axios from 'axios';
import swal from "sweetalert2";

const  ReOpenSaleOrderBtn = (props) => {
    const t = useTranslate();
    const [show,setShow]=useState(false)
    const [commandeClientData,setCommandeClientData]=useState({})
    const [loading,setLoading]=useState(false)
    const [modalTitle,setModalTitle]=useState(t('salesOrder')) 

    const handleClick = async () =>{
        setShow(!show)
        setLoading(true)
        let sectionIdParam = (props.from=='toList') ? '' : "/"+props.posid

        const response = await axios.get(process.env.REACT_APP_API_URL+"api/pos/commande/get_by_id/"+props.commandeId+sectionIdParam
        ).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach(interm=>messageError+=t(interm)+'. ')
                messageError=messageError.replace('undefined','');
                swal.fire(messageError, "", "error");
                setLoading(false)
            }else{
                setCommandeClientData(response.data.data)
                setLoading(false)
            }
        }).catch((error) => {
            if(error=='end_session') return;
            let messageError
            if(error.response==undefined){
                messageError='unknowError'
            }else if(error.response.status==403){
                messageError='forbiddenGeneral'
            }else {
                messageError='unknowError'
            }
            swal.fire(t(messageError), "", "error");
            setLoading(false)
        })
    }

    return (<>
        <Modal size="lg" show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>{modalTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {(loading) ? 
                    <div className='d-flex justify-content-center'>
                        <div className="spinner-border spinner-border-sm"></div>
                    </div>
                :
                    (Object.keys(commandeClientData).length!=0) ? 
                        <OrderFormNext 
                            ticketCommande={commandeClientData}
                            allCommande={props.allCommande}
                            setAllCommande={props.setAllCommande}
                            devise={props.devise}
                            digit={props.digit}
                            posid={props.posid}
                            setModalTitle={setModalTitle}
                            setGetNewData={props.setGetNewData}
                            from={props.from}
                            setShow={setShow}
                        />
                    :
                        false
                }
            </Modal.Body>
        </Modal>

        {(props.noIcon && props.noIcon=='true') ?
            <span className='list-group lien-to' onClick={() => handleClick()}>{t('seeSaleOrder')}</span>
        :
            <OverlayTrigger
                placement="bottom"
                overlay={
                    <Tooltip id={`tooltip-${props.index}`}>
                        {t('voirLe')+' '+t('salesOrder').toLowerCase()}
                    </Tooltip>
                }
            >
                <span onClick={() => handleClick()} className='pointer'><FontAwesomeIcon icon={faEye} /></span>
            </OverlayTrigger> 
        }
        </>
    )
}

export default ReOpenSaleOrderBtn;