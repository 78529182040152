import React from "react";
import { useTranslate } from 'react-redux-multilingual'
import { bank } from 'app/bank';

const CheckPayForm =  (props) =>{
    const t =useTranslate()

    return(<>
            <div className="row input-group">
                <div className="col">
                    <label htmlFor="modepaiement">{t('labelBank')}</label>
                    <select value={props.bankName} type="text" className="form-control mb-2" onChange={(e)=>props.handleChange(e,'bank')}>
                        <option value="">-- Select --</option>
                        {bank.map((value)=>(
                            <option key={value} value={value}>{value}</option>
                        ))}
                    </select>
                </div>
            </div>
            <div className="row input-group mb-2">
                <div className="col">
                    <label htmlFor="checknumber">{t('checkNumber')}</label>
                    <input autoComplete="off" className='form-control'  value={props.checkNumVal} type="text" onChange={(e)=>props.handleChange(e,'checknumber')} />
                </div>
            </div>
            <div className="row input-group mb-2">
                <div className="col">
                    <label htmlFor="nomdutireur">{t('drawerName')}</label>
                    <input autoComplete="off" className='form-control' placeholder="Mr DUPONT" value={props.nomTireur} type="text" onChange={(e)=>props.handleChange(e,'drawerName')} />
                </div>
            </div>
    </>
    )
}

export default CheckPayForm;