import React, {useState,useEffect,useContext,Suspense,lazy} from 'react';
import { useTranslate } from 'react-redux-multilingual'
import {Loading} from '@gull'
import axios from 'axios'
import swal from "sweetalert2";
import { IsAuthContext} from 'app/App';
import { PDFViewer} from '@react-pdf/renderer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle,faEye,faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import {Badge} from "react-bootstrap";
import Facturation from '../../../../app/views/Pdf/Facturation'

// const Facturation = lazy(() => import("../../../../app/views/Pdf/Facturation"));

const SelectedInvoiceLine = (props) => {
    const t = useTranslate();
    const {authParam} = useContext(IsAuthContext);
    const [allInfos,setAllInfos] = useState({}) 
    const [companyInfos,setCompanyInfos] = useState({}) 
    const [clientDetail,setClientDetail]=useState({})
    const [itemsBilling,setItemsBilling]=useState([])
    const [loadingFacture,setLoadingFacture]=useState(false)

    let devise = authParam.company.devise;
    let digit = (authParam.company.devise === "XPF") ? 0 : 2;

    const removeMethod = (name,douaneCatSelected) => {
        if(name=='line'){
            //On retire la ligne et on réaffiche la nouvelle liste
            let newList = props.factureAchatSelected.filter(item=>item.id!=props.value.id)
            props.setFactureAchatSelected(newList)

            //On retire l'id de la ligne retiré pour qu'elle se réaffiche dans le select des facture d'achat
            let newIdList = props.arrAchatSelected.filter(id=>id!=props.value.id)
            props.setArrAchatSelected(newIdList)
        }else{
            let selectedFactureAchat = props.factureAchatSelected.map(invoice=>invoice.id)
            let toEdit = props.value //on récupère les infos de la ligne qu'on va lier à un catégorie douanière
            let indexToEdit = selectedFactureAchat.indexOf(props.value.id)

            let newAssociateCustoms = toEdit.associate.filter(item=>item!=douaneCatSelected)//On retire la catégorie douanière
            toEdit.associate = newAssociateCustoms //On met à jout le nouvel array des catégorie douanière
            
            let newData = props.factureAchatSelected.filter(invoice=>invoice.id!=props.value.id)
            
            newData.splice(indexToEdit,0, toEdit)
            props.setFactureAchatSelected(newData)
        }
    };

    const linkToCustomsCat = (e) => {
        let selectedFactureAchat = props.factureAchatSelected.map(invoice=>invoice.id)
        let toEdit = props.value //on récupère les infos de la ligne qu'on va lier à un catégorie douanière
        let indexToEdit = selectedFactureAchat.indexOf(props.value.id)

        toEdit.associate.push(e.target.value) //on ajoute dans les infos de la facture d'achat sélectionné, la catégorie dounière qui sera ventilé par la facture 

        let newData = props.factureAchatSelected.filter(invoice=>invoice.id!=props.value.id)

        newData.splice(indexToEdit,0, toEdit)
        props.setFactureAchatSelected(newData)
    };

    const getFactureAchat = async () => {
        setLoadingFacture(true)
        props.setDisplayingInvoiceId(props.value.id)
        const response = await axios.get(process.env.REACT_APP_API_URL+"api/facture/invoice/get_one/"+props.value.id
        ).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach(interm=>messageError+=t(interm)+'. ')
                messageError=messageError.replace('undefined','');
                swal.fire(messageError, "", "error");
                setLoadingFacture(false)
                props.setDisplayingInvoiceId(false)
            }
            else{   
                if(response.data.data.status==1){
                    setClientDetail(response.data.data.fournisseur)
                    setItemsBilling(response.data.data.facture_article_list)
                    setAllInfos(response.data.data)
                    if(response.data.data.other_company_id!=null){
                        setCompanyInfos(response.data.data.other_company)
                    }else{
                        setCompanyInfos(response.data.data.company)
                    }
                }else{
                    props.setDisplayingInvoiceId(false)
                    swal.fire({
                        title: t('notAvailable'),
                        icon: 'error',
                        timer: 1500, // Définir le temps en millisecondes après lequel la fenêtre se fermera
                        showConfirmButton: false // Cacher le bouton de confirmation
                    })
                }
                setLoadingFacture(false)
            }
        }).catch((error) => {
            if(error=='end_session') return;
            swal.fire(t('unknowError'), "", "error");
            setLoadingFacture(false)
            props.setDisplayingInvoiceId(false)
        })
    };

    return(<>
        <li key={props.value.id} className={props.style}>
            <span className='col-5 fw-bold d-flex flex-wrap justify-content-center'>
                {t('billingBuy')+' '+props.value.name}
                {loadingFacture ? 
                    <span className='text-center text-muted mt-1'><FontAwesomeIcon icon={faEye}/></span>
                :
                    (props.displayingInvoiceId && props.displayingInvoiceId==props.value.id) ?
                        <span className='text-center text-danger mt-1 pointer' onClick={()=>props.setDisplayingInvoiceId(false)}><FontAwesomeIcon icon={faEyeSlash} /></span>
                    :
                        <span className='text-center text-primary mt-1 pointer' onClick={()=>getFactureAchat()}><FontAwesomeIcon icon={faEye} /></span>
                }
            </span>
            <div className="form-group col-5">
                <label>{t('linkToDouaneCat')}</label>
                <select disabled={props.validButton} className='form-control form-control-sm' onChange={(e)=>linkToCustomsCat(e)}>
                    <option value="">-- Select --</option>
                    {props.allCustomsCategories.map(douaneCat=>{
                        if(!props.value.associate.includes(douaneCat)){
                            return(
                                <option key={douaneCat} value={douaneCat}>{douaneCat}</option>
                            )
                        }
                    })}
                </select>
            </div>
            <span className='col-2 d-flex justify-content-end' >
                <button disabled={props.validButton} className='btn btn-sm btn-danger' onClick={()=>removeMethod('line',false)} >
                    {t('btnRemove')}
                </button>
            </span>
            <div className="col-12">
                {props.value.associate.length==0 ? 
                    <Badge bg='warning'>{t('linkTo')+" "+t('allItems').toLowerCase()}</Badge>
                :
                    props.value.associate.map((douaneCatSelected)=>
                        <span key={douaneCatSelected}>
                            <Badge bg="primary" text="white">
                                <span className='d-flex align-items-center'>
                                    {douaneCatSelected}&nbsp;&nbsp;
                                    {props.validButton ?
                                        <span className="text-muted">
                                            {<FontAwesomeIcon icon={faTimesCircle} />}
                                        </span>
                                    :
                                        <span className="text-danger pointer" onClick={()=>removeMethod('douaneCat',douaneCatSelected)}>
                                            {<FontAwesomeIcon icon={faTimesCircle} />}
                                        </span>
                                    }
                                </span>
                            </Badge>&nbsp;&nbsp;
                        </span>
                    )
                }
            </div>
        </li>
        {(props.displayingInvoiceId && props.displayingInvoiceId==props.value.id) ?
            (loadingFacture) ? 
                <div className="d-flex justify-content-center mt-2 mb-2"><div className="spinner-border spinner-border-sm"></div></div>
            :
                <>
                    {Object.keys(allInfos).length!=0 && 
                        <PDFViewer style={{width:'100%', height:'80vh'}}>
                            <Facturation 
                                t={t}
                                documentType={allInfos.type}
                                companyInfos={companyInfos}
                                billing={itemsBilling}
                                clientDetail={clientDetail}
                                devise={devise}
                                digit={digit}
                                allInfos={allInfos}
                            />
                        </PDFViewer>
                    }
                </>
        :
            false
        }
    </>
    )
}

export default SelectedInvoiceLine;