import React,{useState} from "react";
import { useTranslate } from 'react-redux-multilingual'
import RefundGiftCard from './RefundOption/RefundGiftCard'
import RefundEspece from './RefundOption/RefundEspece'
import RefundOther from './RefundOption/RefundOther'
import axios from 'axios'
import swal from "sweetalert2";

const RefundForm = (props) =>{
    const t =useTranslate()
    const [refundMode,setRefundMode]=useState(false)
    const [inputValue,setInputValue]=useState({
        inputBarCode:'',
        inputNoteOther:''
    }) 
    const [load,setLoad]=useState(false) 
    const [errorPay,setErrorPay]=useState(false)

    const idsLocalStorage=localStorage.getItem("pos").split(',')

    const refundApi = async (data) =>{
        const response = await axios.post(process.env.REACT_APP_API_URL+"api/pos/ticket/construction/"+props.posid+"/"+idsLocalStorage[1]+"/"+idsLocalStorage[0], data
        ).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach(interm=>messageError+=t(interm)+'. ')
                messageError=messageError.replace('undefined','');
                swal.fire(messageError, "", "error");
            }
            else{   
                props.setCurrentTicket(response.data.data)
                setInputValue({
                    inputBarCode:'',
                    inputNoteOther:''
                })
                setLoad(false)
            }
        }).catch((error) => {
            if(error=='end_session') return;
            swal.fire(t('unknowError'), "", "error");
        })
        setLoad(false)
        setTimeout(()=>{
            setErrorPay(false)
        },1500)
    }
    
    return(<>
        {(!refundMode) ?
            <div className="d-flex flex-column justify-content-between align-items-center" style={{height:'80%'}}>
                <label className="fw-bold text-center">{t('chooseRefundMethod')}</label>
                <div className="row" style={{width:'90%'}}>
                    <button className="p-3 btn btn-lg btn-success" onClick={()=>setRefundMode('espece')}>
                        {t('espece')}
                    </button>
                </div>
                {(props.modulePos.params.gift_card.active) &&
                    <div className="row" style={{width:'90%'}}>
                        <button className="p-3 btn btn-lg btn-warning" onClick={()=>setRefundMode('giftcard')}>
                            {t('giftcard')}
                        </button>
                    </div>
                }
                <div className="row" style={{width:'90%'}}>
                    <button className="p-3 btn btn-lg btn-info" onClick={()=>setRefundMode('other')}>
                        {t('other')}
                    </button>
                </div>
            </div>
        :
            <div>
                <div className="mb-3 d-flex justify-content-between align-items-center">
                    {(refundMode=='espece') ? <label className="fw-bold text-center">{t('refundEspece')}</label> : false}
                    {(refundMode=='giftcard') ? <label className="fw-bold text-center">{t('refundGiftcard')}</label> : false}
                    {(refundMode=='other') ? <label className="fw-bold text-center">{t('refundOther')}</label> : false}
                    <button className="btn btn-sm btn-danger" onClick={()=>setRefundMode(false)}>{t('retour')}</button>
                </div>
                {(refundMode=='giftcard') ?
                    <RefundGiftCard
                        total={props.total}
                        cookies={props.cookies}
                        currentTicket={props.currentTicket}
                        clientId={props.clientId}
                        inputValue={inputValue}
                        setInputValue={setInputValue}
                        errorPay={errorPay}
                        setErrorPay={setErrorPay}
                        load={load}
                        setLoad={setLoad}
                        refundApi={refundApi}
                    />
                :
                    (refundMode=='espece') ?
                        <RefundEspece
                            total={props.total}
                            cookies={props.cookies}
                            load={load}
                            setLoad={setLoad}
                            refundApi={refundApi}
                        />
                    :
                        (refundMode=='other') ?
                            <RefundOther
                                total={props.total}
                                cookies={props.cookies}
                                inputValue={inputValue}
                                setInputValue={setInputValue}
                                errorPay={errorPay}
                                setErrorPay={setErrorPay}
                                load={load}
                                setLoad={setLoad}
                                refundApi={refundApi}
                            />
                        :
                            false
                }
            </div>
        }
    </>
    )
}

export default RefundForm;