import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';
import Moment from 'react-moment';
import {espace_mille} from 'app/espace_mille'
import {numberToWordsFr} from 'app/numberToWords'
import FontRubikRegular from './fonts/Rubik-Regular.ttf';
import FontRubikItalic from './fonts/Rubik-Italic.ttf';
import FontRubikBold from './fonts/Rubik-Bold.ttf';
import FontRubikBoldItalic from './fonts/Rubik-BoldItalic.ttf';
import { convertHtmlToText } from 'app/htmlToTextPdf'
import { modePaiementMustBeTranslate,modePaiementFidelite } from 'app/modePaiementDefaultAll';
var JsBarcode = require('jsbarcode');

Font.register({
    family: 'Rubik',
    fonts: [
        {
            src: FontRubikRegular,
        },
        {
            src: FontRubikBold,
            fontWeight: 'bold',
        },
        {
            src: FontRubikItalic,
            fontStyle: 'italic',
        },
        {
            src: FontRubikBoldItalic,
            fontWeight: 'bold',
            fontStyle: 'italic',
        },
    ],
});

// Create styles
const styles = StyleSheet.create({
    body:{
        paddingTop: 35,
        paddingBottom: 90,
        paddingHorizontal: 35,
    },
    titleBar: {
        padding:'15',
        marginBottom:'25',
        backgroundColor:'rgba(239, 239, 240, 1)',
        flexDirection:'row',
        justifyContent:'space-between',
        fontFamily:'Rubik'
    },
    titleFooter: {
        padding:'7',
        backgroundColor:'rgba(239, 239, 240, 1)',
        left:'10%',
        fontFamily:'Rubik',
        width: '90%',
        position: 'absolute',
        fontSize: 9,
        bottom: 30,
        textAlign: 'center',
    },
    numCompte:{
        fontFamily:'Rubik',
        position: 'absolute',
        left:0,
        right:0,
        fontSize: 9,
        bottom: 70,
        textAlign: 'center',
    },
    titleContain: {
        fontSize:'10',
    },
    infosCompany: {
        marginRight:'40',
        flexDirection:'row',
        justifyContent:'space-between',
        fontFamily:'Rubik'
    },
    descriptionCompany:{
        fontSize:'10',
        flexDirection:'column',
        width:'70%',
        marginBottom:'20'
    },
    logocontain:{
        width:'30%',
    },
    logo:{
        width:60,
        height:60,
        marginBottom:8
    },
    tab: {
        borderTop:1, 
        borderColor:'#d3d3d3',
        width:'9cm',
        paddingTop:5, 
        paddingBottom: 5,
        fontSize:'8',
    },
    tab2: {
        borderTop:1, 
        borderColor:'#d3d3d3',
        width:'1.5cm',
        paddingTop:5, 
        paddingBottom: 5,
        textAlign: 'center',
        fontSize:'8'
    },
    tab3: {
        borderTop:1, 
        borderColor:'#d3d3d3',
        width:'2.5cm',
        paddingTop:5, 
        paddingBottom: 5,
        textAlign: 'center',
        fontSize:'8'
    },
    tab4: {
        borderTop:1, 
        borderColor:'#d3d3d3',
        width:'4cm',
        paddingTop:5, 
        paddingBottom: 5,
        fontSize:'8'
    },
    tab5: {
        paddingTop:5, 
        paddingBottom: 5,
        fontSize:'8'
    },
    tabContainer:{
        flexDirection: 'row',
        justifyContent: 'center',
        marginRight:'40',
        fontFamily:'Rubik'
    },
    tabContainer2:{
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    hr:{
        borderTop:1.5,
        borderColor:'#d3d3d3',
        width:'70%',
        color:'#d3d3d3',
        margin:'auto'
    },
    textBottom:{
        marginRight:'40',
        marginTop:'20',
        fontSize:'8'
    },
    codebar: {
        width: 150,
        height: 20,
    }
  });

  var now = new Date();
  
const TicketFacture = (props) => {
    let canvas;
    // For Barcode
    canvas = document.createElement('canvas');
    JsBarcode(canvas, props.ticketFacture.bar, {
        displayValue: false,
    });
    const barcode = canvas.toDataURL();

    let logo = (props.companyInfos) ? process.env.REACT_APP_API_URL+'images/'+props.companyInfos.logo : false

    // let fideliteTicket = (props.pointsFidelite.mode=='cashback') ? espace_mille(+props.pointsFidelite.cashbackAcquis)+props.devise : espace_mille(+props.pointsFidelite.acquis)+'Pts'
    // let fideliteTotal = (props.pointsFidelite.mode=='cashback') ? espace_mille(+props.pointsFidelite.cashbackTotal)+props.devise : espace_mille(+props.pointsFidelite.total)+'Pts'

    return(<Document>
        <Page size="A4" style={styles.body}>
            <View style={styles.titleBar}>
                <Text style={styles.titleContain}>{props.t(props.documentType)+' '+props.ticketFacture.bar}</Text>
                <Text style={styles.titleContain}>
                    <Text>{props.t('emisLe')+' : '}</Text>
                    <Text><Moment format="DD/MM/YYYY">{props.ticketFacture.created_at}</Moment></Text>
                </Text>
            </View>
            <View style={styles.infosCompany}>
                <View style={styles.descriptionCompany}>
                    {(props.companyInfos.infos_superieur_ticket!=null) ? 
                        props.companyInfos.infos_superieur_ticket.map((value,ind)=>{
                            return(
                                <Text key={ind} style={{marginBottom:'4'}}>{value}</Text>
                            )
                        })
                    :
                        (props.companyInfos.name != null) ?
                            <Text style={{marginBottom:'4'}}>{props.companyInfos.name}</Text> 
                        : 
                            false
                    }
                </View>
                {(logo && props.companyInfos.logo && props.companyInfos.logo != null) ? 
                    <View style={styles.logocontain}>
                        <Image style={styles.logo}  src={logo} />
                        <Image style={styles.codebar} src={barcode}/>
                    </View>
                :
                    false
                }
            </View>

            <View style={{marginBottom:'20'}}>
                <Text style={styles.hr}></Text>
            </View>
            {(props.ticketFacture.client!=null) ? 
                <View style={styles.infosCompany}>
                    <View style={{width:'50%'}}>
                        <Text style={{marginBottom:'4',fontSize:'11'}}>{props.t('recipient')+' :'}</Text>
                        <Text style={{marginBottom:'4',fontSize:'9'}}>{props.ticketFacture.client.firstname+' '+props.ticketFacture.client.name}</Text>
                        {(props.ticketFacture.client !=null && props.ticketFacture.client.tel!=null && props.ticketFacture.client.tel!='') ? <Text style={{marginBottom:'4',fontSize:'9'}}><Image src="/assets/images/phone.png" />{' '+props.ticketFacture.client.tel}</Text>:false}
                        {(props.ticketFacture.client !=null && props.ticketFacture.client.email!=null && props.ticketFacture.client.email!='') ? <Text style={{marginBottom:'4',fontSize:'9'}}><Image src="/assets/images/email.png" />{' '+props.ticketFacture.client.email}</Text>:false}
                    </View>
                    <View style={{width:'50%'}}>
                        <Text style={{marginBottom:'4',fontSize:'9'}}>{(props.ticketFacture.client.infos && props.ticketFacture.client.infos.adresse!=null) ? props.ticketFacture.client.infos.adresse : false}</Text>
                        <Text style={{marginBottom:'4',fontSize:'9'}}>{(props.ticketFacture.client.infos && props.ticketFacture.client.infos.quartier!=null) ? props.ticketFacture.client.infos.quartier : false}</Text>
                        <Text style={{marginBottom:'4',fontSize:'9'}}>{(props.ticketFacture.client.infos && props.ticketFacture.client.infos.ville!=null) ? props.ticketFacture.client.infos.ville : false}</Text>
                        <Text style={{marginBottom:'4',fontSize:'9'}}>{(props.ticketFacture.client.infos && props.ticketFacture.client.infos.codepostal!=null) ? props.ticketFacture.client.infos.codepostal : false}</Text>
                        <Text style={{marginBottom:'4',fontSize:'9'}}>{(props.ticketFacture.client.infos && props.ticketFacture.client.infos.pays!=null) ? props.ticketFacture.client.infos.pays : false}</Text>
                    </View>
                </View>
            :
                false
            }

            <View style={styles.tabContainer}>
                <Text style={styles.tab}>{props.t('designation')}</Text>
                <Text style={styles.tab3}>{props.t('priceTtc')}</Text>
                <Text style={styles.tab3}>{props.t('discount')}</Text>
                <Text style={styles.tab2}>{props.t('tgc')}</Text>
                <Text style={styles.tab2}>{props.t('quantity')}</Text>
                <Text style={styles.tab3}>{props.t('total')}</Text>
            </View>

            {props.ticketFacture.ticket_detail.map((value,ind)=>{
                let bg = (ind%2 == 0) ? {backgroundColor:'rgba(239, 239, 240, 1)'} : {backgroundColor:'white'};
                let newTotal
            
                return(
                    <View key={ind} style={[styles.tabContainer,bg]}>
                        <View style={styles.tab}>
                            <Text style={{marginBottom:'1'}}>{(value.article!=null) ? value.article.name : false}</Text>
                            <Text style={{marginBottom:'1'}}>{(value.special=='gift_card') ? props.t('giftcard')+' '+value.details.reference : false}</Text>
                            <Text style={{marginBottom:'1'}}>{(value.special=='retour') ? value.article_retour.name : false}</Text>
                            
                            {(value.note!=null) ? <Text>{value.note}</Text> : false}
                        </View>
                        <Text style={styles.tab3}>{espace_mille(Math.round(value.unitaire))+props.devise}</Text>
                        <Text style={styles.tab3}>{espace_mille(value.remise)+props.devise}</Text>
                        <Text style={styles.tab2}>{value.tgc+'%'}</Text>
                        <Text style={styles.tab2}>{value.quantity}</Text>
                        <Text style={styles.tab3}>{espace_mille(Math.round(+value.prix_remise))+props.devise}</Text>
                    </View>
                )
            })}

            <View style={styles.infosCompany}>
                <View style={{width:'50%', marginTop:'15', border:'1.5',padding:'5',borderColor:'#ff0000'}}>
                    <View style={{flexDirection: 'row', alignItems:'center'}}>
                        <Text style={{marginBottom:'4',fontSize:'10'}}>{props.t('paidOn')+' : '}</Text>
                        <Text style={{marginBottom:'4',fontSize:'10'}}><Moment format="DD/MM/YYYY">{props.ticketFacture.created_at}</Moment></Text>
                    </View>
                    {/* Lignes concernant les remboursements */}
                    {props.ticketFacture.remboursement_records.map(value=>{
                        return(
                            <View>
                                <View key={value.id} style={{flexDirection: 'row', alignItems:'center'}}>
                                    <Text style={{marginBottom:'4',fontSize:'10'}}>{(value.mode=='espece') ? props.t('refundEspece') : props.t('refundOther')}</Text>
                                    <Text style={{marginBottom:'4',fontSize:'8'}}>{'  : '+espace_mille(value.montant)+props.devise}</Text>
                                </View>
                                {(value.mode=='autre') ?<View style={{flexDirection: 'column'}}><Text style={{fontSize:'8'}}>{value.note}</Text></View>:false}
                            </View>
                            )
                    })}

                    {props.ticketFacture.paiement_records.length!=0 ?
                        props.ticketFacture.paiement_records.map((value,ind)=>{
                            return(
                                <View key={ind}>
                                    <View key={value.id} style={{flexDirection: 'row', alignItems:'center'}}>
                                        <Text style={{marginBottom:'4',fontSize:'10'}}>{props.t('modePaiement')+' : '}</Text>
                                        <Text style={{marginBottom:'4',fontSize:'8'}}>{modePaiementFidelite.includes(value.mode) ? props.t('loyalty') : (modePaiementMustBeTranslate.includes(value.mode)) ? props.t(value.mode)+'  ' : value.mode+'  '}</Text>
                                        <Text style={{marginBottom:'4',fontSize:'8'}}>{espace_mille(value.montant)+props.devise}</Text>
                                    </View>
                                    {(value.mode=='espece' && +value.rendu!=0) ? 
                                        <View style={{fontSize:7}}>
                                            <Text>{props.t('deposited')+' : '+espace_mille(value.verse)+props.devise}</Text>
                                            <Text>{props.t('changeBack')+' : '+espace_mille(value.rendu)+props.devise}</Text>
                                        </View> 
                                    : 
                                        (value.mode=='cheque' && value.check_infos!=null) ?
                                            <View style={{fontSize:7}}>
                                                {value.check_infos.banque!='' ? <Text>{props.t('labelBank')+' : '+value.check_infos.banque}</Text>:false}
                                                {value.check_infos.nbr!='' ? <Text>{props.t('checkNumber')+' : '+value.check_infos.nbr}</Text>:false}
                                            </View> 
                                    :
                                        <Text></Text>
                                    }
                                </View>
                            )
                        })
                    :
                        false
                    }
                </View>
                <View style={{width:'50%'}}>
                    <View style={styles.tabContainer2}>
                        <Text style={[styles.tab4,{textAlign:'left',paddingLeft:'7'}]}>{props.t('totalHt')}</Text>
                        <Text style={[styles.tab4,{textAlign:'right',paddingRight:'7'}]}>{espace_mille(+props.totalAchat - +props.totalTgc)+props.devise}</Text>
                    </View>
                    {Object.entries(props.tgcForTicketPdf).map(([key,value])=>{
                        return(
                            <View key={key} style={styles.tabContainer2}>
                                <Text style={{textAlign:'left',paddingLeft:'7',width:'4cm',paddingTop:5, paddingBottom: 5,fontSize:'8'}}>{'TGC '+key+'%'}</Text>
                                <Text style={{textAlign:'right',paddingRight:'7',width:'4cm',paddingTop:5, paddingBottom: 5,fontSize:'8'}}>{espace_mille(Math.round(value))+props.devise}</Text>
                            </View>
                        )
                    })}
                    <View style={styles.tabContainer2}>
                        <Text style={[styles.tab4,{textAlign:'left',paddingLeft:'7'}]}>{props.t('total')}</Text>
                        <Text style={[styles.tab4,{textAlign:'right',paddingRight:'7'}]}>{espace_mille(Math.round(+props.totalAchat))+props.devise}</Text>
                    </View>
                    <View style={[styles.tabContainer2,{fontSize:'9', marginTop:'10',fontFamily:'Rubik'}]}>
                        <Text style={{paddingLeft:'7'}}>{numberToWordsFr(Math.round(+props.totalAchat), props.devise)}</Text>
                    </View>
                </View>
            </View>

            {(props.companyInfos.other_company.length!=0) ?
                <Text style={styles.numCompte} fixed> 
                    {(props.companyInfos.other_company[0].advanceparams.banque != null) ? <Text style={{marginBottom:'4'}}>{props.t('labelBank')+' : '+props.companyInfos.other_company[0].advanceparams.banque+' - '}</Text> : false}
                    {(props.companyInfos.other_company[0].advanceparams.code_banque != null && props.companyInfos.other_company[0].advanceparams.code_guichet != null && props.companyInfos.other_company[0].advanceparams.numero_compte != null && props.companyInfos.other_company[0].advanceparams.cle_rib != null) ? <Text style={{marginBottom:'4'}}>{props.t('labelNumeroDeCompte')+' : '+props.companyInfos.other_company[0].advanceparams.code_banque+' '+props.companyInfos.other_company[0].advanceparams.code_guichet+' '+props.companyInfos.other_company[0].advanceparams.numero_compte+' '+props.companyInfos.other_company[0].advanceparams.cle_rib}</Text> : false}
                </Text>
            :
                <Text style={styles.numCompte} fixed> 
                    {(props.authParam.company.advanceparams.banque != null) ? <Text style={{marginBottom:'4'}}>{props.t('labelBank')+' : '+props.authParam.company.advanceparams.banque+' - '}</Text> : false}
                    {(props.authParam.company.advanceparams.code_banque != null && props.authParam.company.advanceparams.code_guichet != null && props.authParam.company.advanceparams.numero_compte != null && props.authParam.company.advanceparams.cle_rib != null) ? <Text style={{marginBottom:'4'}}>{props.t('labelNumeroDeCompte')+' : '+props.authParam.company.advanceparams.code_banque+' '+props.authParam.company.advanceparams.code_guichet+' '+props.authParam.company.advanceparams.numero_compte+' '+props.authParam.company.advanceparams.cle_rib}</Text> : false}
                </Text>
            }

            <View style={styles.titleFooter} fixed> 
                <Text render={({ pageNumber, totalPages }) => (
                    `${pageNumber} / ${totalPages}`
                )}  />
                <Text>{props.t('generateFreebird')}</Text>
            </View>
            
            
        </Page>
    </Document>
    )
};

export default TicketFacture;
