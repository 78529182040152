import React, { useState} from 'react';
import { useTranslate } from 'react-redux-multilingual'
import {DeleteActionButton} from '@gull'
// Style import
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import axios from 'axios'
import swal from "sweetalert2";

const LineFavorite = (props) =>{
    const t = useTranslate();
    const [showEdit,setShowEdit]=useState(false) 
    const [value,setValue]=useState(props.value.filtre_name) 
    const [loading,setLoading]=useState({
        delete:false,
        edit:false
    }) 

    const handleEdit = () =>{
        setShowEdit(true)
        setValue(props.value.filtre_name)
    }
    
    const handleChange = (e) =>{
        setValue(e.target.value)
    }

    const deleteFavorite = async () =>{
        setLoading({...loading,delete:true})
        const response  = await axios.delete(process.env.REACT_APP_API_URL+"api/filtre/deletefilter/"+props.value.id
        ).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach((interm)=>{
                    let finalMessage=interm.split('/!');
                    if(finalMessage.length==2){
                        messageError+=t(finalMessage[0],{name:finalMessage[1]})+','
                    }
                    else{
                        messageError+=t(interm)+','
                    }
                })
                messageError=messageError.replace('undefined','');
                swal.fire(messageError, "", "error");
            }
            else{
                let newData = props.allFav.filter(value=>value.id!=props.value.id)
                props.setAllFav(newData)
            }
        }).catch((error) => {
            if(error=='end_session') return;
            let messageError = (error.response && error.response.status==403) ? t('forbiddenGeneral') : t('unknowError')
            swal.fire(messageError, "", "error");
        })
        setLoading({...loading,delete:false})
    }

    const handleSubmit = async () =>{
        setLoading({...loading,edit:true})
        const response  = await axios.put(process.env.REACT_APP_API_URL+"api/filtre/edit/"+props.value.id, {
            filtre_name:value
        }).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach((interm)=>{
                    let finalMessage=interm.split('/!');
                    if(finalMessage.length==2){
                        messageError+=t(finalMessage[0],{name:finalMessage[1]})+','
                    }
                    else{
                        messageError+=t(interm)+','
                    }
                })
                messageError=messageError.replace('undefined','');
                swal.fire(messageError, "", "error");
            }
            else{
                //on met à jour le nom du favori
                let allFavData = props.allFav.map(value=>value.id)
                let indexToEdit = allFavData.indexOf(props.value.id)
                let newData = props.allFav.filter(value=>value.id!=props.value.id)
                newData.splice(indexToEdit,0, response.data.data)
                
                props.setAllFav(newData)
                setShowEdit(false)
            }
        }).catch((error) => {
            if(error=='end_session') return;
            let messageError = (error.response && error.response.status==403) ? t('forbiddenGeneral') : t('unknowError')
            swal.fire(messageError, "", "error");
        })
        setLoading({...loading,edit:false})
    }

    const activeFavoriteFiltre = () =>{
        //Si dans favori enregistré, il y a des filtres qui n'apparaissent pas dans la configuration initiale, on ajoute les filtres des favori pour pouvoir visualiser le filtrage 
        let arr = []
        Object.entries(props.value.filtre).map(([filtre,value])=>{
            if(Array.isArray(value)) arr.push(filtre)
        })
        props.setCustomFilter({details:arr})


        let obj = {}

        let keys = Object.keys(props.value.filtre);
        for(var i = 0; i < keys.length; i++){
            if(Array.isArray(props.value.filtre[keys[i]])){
                obj[keys[i]]=[]
                props.value.filtre[keys[i]].forEach(value=>obj[keys[i]].push(value.id))
            }
            else{
                obj[keys[i]]=props.value.filtre[keys[i]]
            }
        }

        props.setSendData(props.value.filtre)//sendData récupère en valeur un objet avec l'id et une valeur 
        props.setFiltre(obj)//filtre récupère uniquement les ids

        if(props.filterItemsPromo){
            props.filterItemsPromo(obj)
        }else{
            props.onSubmit(obj)
        }
        
        props.setFavoriteFilterActived(props.value.id)
        props.setShowFavorite(false)
    }

    return (
        <tr key={props.value.id}>
            <td>
                {showEdit ? 
                    <div>
                        <button className='btn btn-sm btn-primary' onClick={() => handleSubmit()}>{loading.edit ? <div className="spinner-border spinner-border-sm"></div> : t('btnValidate')}</button>&nbsp;&nbsp;
                        <button className='btn btn-sm btn-danger' onClick={() => setShowEdit(false)}>{t('btnCancel')}</button>
                    </div>
                :
                    <>
                        <OverlayTrigger
                            placement="bottom"
                            overlay={
                                <Tooltip id={`tooltip-${props.index}`}>
                                    {t('edit')}
                                </Tooltip>
                            }
                        >
                            <span className="text-primary" onClick={() => handleEdit()} style={{cursor:'Pointer'}}><FontAwesomeIcon icon={faEdit} /></span>
                        </OverlayTrigger>&nbsp;&nbsp;&nbsp;
                        {loading.delete ? <div className="spinner-border spinner-border-sm"></div> : <DeleteActionButton deleteFunctionArgument={props.value.id} actionType={'typeDeleteX'} nameType={'delete'} toDelete={props.value.filtre_name} customClickEvent={deleteFavorite}/>}
                    </>
                }
            </td>
            {showEdit ? 
                <div>
                    <input type="text" value={value} className='form-control' onChange={(e)=>handleChange(e)}/>
                </div>
            :
                <td className='pointer' onClick={()=>activeFavoriteFiltre()}>{props.value.filtre_name}</td>
            }
        </tr>
    )
};

export default LineFavorite;