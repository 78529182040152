import React, { useState,useRef } from 'react';
import { useTranslate } from 'react-redux-multilingual'
import {Form} from 'react-bootstrap'
import {ArticleBarSearch} from '@gull'
import axios from 'axios'
import swal from "sweetalert2";
import {useHistory} from 'react-router-dom'

const ArticleProvisoireFrom = (props) =>{
    const t = useTranslate();
    const history = useHistory()
    const [validButton,setValidButton]=useState({
        add:false,
        associate:false
    }) 

    const [loadingSearchItem,setLoadingSearchItem]=useState(false)
    const [listSearchItem,setListSearchItem]=useState([])
    const [datas,setDatas] = useState(listSearchItem)
    const [inputScan,setInputScan]=useState('')
    const [disabled,setDisabled]=useState(false) 
    const [itemId,setItemId]=useState('') 
    const [checked,setChecked]=useState(false) 
    const searchInput = useRef('')

    const loadItems = async (inputValue) => {
        setLoadingSearchItem(true)
        const response = await axios.get(process.env.REACT_APP_API_URL+"api/erp/article/name/"+inputValue
        ).then((response) => {
            setLoadingSearchItem(false)
            setListSearchItem(response.data.datas)
            let ajour = response.data.datas.filter((value)=>value.name.replace(/\u00A0/g,' ').toLowerCase().includes(searchInput.current.value.replace(/\u00A0/g,' ').toLowerCase()))
            setDatas(ajour)
        }).catch((error) => {
            if(error=='end_session') return;
            setLoadingSearchItem(false)
            let errorName = (error.response==undefined) ? 'unknowError' : (error.response.status==403) ? 'forbiddenGeneral' : 'unknowError'
            swal.fire(t(errorName), "", "error");
        })
    }

    const addNewItem = async (e,articleid,inputname,articleBar) =>{
        let article = (e) ? e.target.value : articleid
        let urlArticle = (e) ? "api/erp/article/reference/" : "api/erp/article/one/"

        setLoadingSearchItem(true)
        const response = await axios.get(process.env.REACT_APP_API_URL+urlArticle+article
        ).then((response) => {
            if(response.data.hasOwnProperty('valid') && !response.data.valid){
                swal.fire(t('articleNoReferenceFind'), "", "error");
            }else{   
                if(e){
                    setInputScan(response.data.data.article.name)
                    setItemId(response.data.data.article.id)
                }else{
                    setInputScan(response.data.data.name)
                    setItemId(response.data.data.id)
                }
                setDisabled(true)
            }
        }).catch((error) => {
            if(error=='end_session') return;
            let errorName = (error.response==undefined) ? 'unknowError' : (error.response.status==403) ? 'forbiddenGeneral' : 'unknowError'
            swal.fire(t(errorName), "", "error");
        })
        setLoadingSearchItem(false)
    }

    const addPermanently = async () =>{
        setValidButton({...validButton,add:true})
        const response = await axios.put(process.env.REACT_APP_API_URL+"api/erp/article/edit/"+props.article.id, {
            provisoire:false
        }).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach((interm)=>{
                    let finalMessage=interm.split('/!');
                    if(finalMessage.length==2){
                        messageError+=t(finalMessage[0],{name:finalMessage[1]})+','
                    }
                    else{
                        messageError+=t(interm)+','
                    }
                })
                messageError=messageError.replace('undefined','');
                swal.fire(t(messageError), "", "error");
            }
            else{
                props.setShowEditProvisoire(false)
                props.setArticle({...props.article,provisoire:false})
            }
        }).catch((error) => {
            if(error=='end_session') return;
            let errorName = (error.response==undefined) ? 'unknowError' : (error.response.status==403) ? 'forbiddenGeneral' : 'unknowError'
            swal.fire(t(errorName), "", "error");
        })
    }

    const associateToItem = async () =>{
        setValidButton({...validButton,associate:true})
        const response = await axios.get(process.env.REACT_APP_API_URL+"api/erp/article/replace_tempory_item/"+props.article.id+'/'+itemId
        ).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach((interm)=>{
                    let finalMessage=interm.split('/!');
                    if(finalMessage.length==2){
                        messageError+=t(finalMessage[0],{name:finalMessage[1]})+','
                    }
                    else{
                        messageError+=t(interm)+','
                    }
                })
                messageError=messageError.replace('undefined','');
                swal.fire(t(messageError), "", "error");
            }
            else{
                props.setShowEditProvisoire(false)
                history.push('/erp/article/edit/'+itemId)
            }
        }).catch((error) => {
            if(error=='end_session') return;
            let errorName = (error.response==undefined) ? 'unknowError' : (error.response.status==403) ? 'forbiddenGeneral' : 'unknowError'
            swal.fire(t(errorName), "", "error");
        })
        setValidButton({...validButton,associate:false})
    }
    

    return (
        <div className='d-flex justify-content-center flex-column'>
            <div className="row">
                <div className='d-flex justify-content-center'>
                    <Form>
                        <Form.Check
                            type="switch"
                            label={t('addThisItemToItemBase')}
                            checked={checked}
                            onChange={()=>setChecked(!checked)}
                        />
                    </Form>
                </div>
                {(checked) ?
                    <div className='col-8 mx-auto mt-1'>
                        <div className='alert alert-warning'>{t('addPermanentlyMsg')}</div>
                        <button disabled={validButton.add} className="btn btn-primary" onClick={()=>addPermanently()}>
                            {validButton.add ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : t('btnValidate')}
                        </button>
                    </div>
                    : false
                }
            </div>
            
            <hr />

            <div className="row d-flex justify-content-center">
                <div className="col-8 mb-3">
                    <div className='form-group'>
                        <label>{t('associateToAnItem')}</label>
                        <ArticleBarSearch 
                            loadingSearchItem={loadingSearchItem}
                            loadItems={loadItems}
                            listSearchItem={listSearchItem}
                            datas={datas}
                            setDatas={setDatas}
                            valueInput={inputScan}
                            setValueInput={setInputScan}
                            handleSubmit={addNewItem}
                            searchInput={searchInput}
                            noBlur={false}
                            disabled={disabled}
                            setDisabled={setDisabled}
                            setItemId={setItemId}
                        />
                    </div>
                    {(itemId!='') ? 
                        <button disabled={validButton.associate} className="btn btn-primary mt-3" onClick={()=>associateToItem()}>
                            {validButton.associate ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : t('btnValidate')}
                        </button>
                        : false
                    }
                </div>
            </div>
        </div>
    )
};

export default ArticleProvisoireFrom;