import React, {useState,useContext,useEffect,useRef} from "react";
import { useTranslate } from 'react-redux-multilingual';
import {imprimeEtiquette} from 'app/imprimeEtiquette'
import { IsAuthContext } from '../../../app/App';
import { espace_mille } from 'app/espace_mille';
import swal from "sweetalert2";

const ModalTagPrintForm = (props) =>{
    const t = useTranslate();
    const [qteTag,setQteTag]=useState(1)
    const [sizeTag,setSizeTag]=useState('') 
    const { authParam } = useContext(IsAuthContext)
    
    const [urlCertificat,setUrlCertificat] = useState('')
    const redirectCertificat = useRef('')
    const [errorCookie,setErrorCookie] = useState(false)

    let cookie
    if(props.cookies){
        if(props.cookies.peripherique){
            cookie = props.cookies.peripherique.split('/!')
        }else{
            cookie = []
        }
    }else if(props.from=='erp'){
        if(props.peripheriqueParam.peripheriqueTagErp){
            cookie = props.peripheriqueParam.peripheriqueTagErp.split('/!')
        }else{
            cookie = []
        }
    }

    let param = (props.cookies || props.from=='erp') ? cookie : props.peripheriqueParam
    
    let devise = authParam.company.devise;
    let formatedPrice = espace_mille(props.articlePrice)

    useEffect(function () {
        if(props.hasOwnProperty('qteToPrint') && props.qteToPrint && +props.qteToPrint!=0 ) setQteTag(props.qteToPrint)
    }, [])

    const handleChange = (e,name) =>{

        if(name=="qte"){
            setQteTag(e.target.value.replace(/\D/g,''))
        }else{
            setSizeTag(e.target.value)
        }
        
    }

    const handleSubmit = () =>{
        setErrorCookie(false)
        if(sizeTag==''){
            swal.fire(t('sizeTagRequired'), "", "error");
        }else if(qteTag>100){
            swal.fire(t('limitTagPrint100'), "", "error");
        }else if(qteTag<1){
            swal.fire(t('mintTagPrint1'), "", "error");
        }else{
            //window.open(process.env.REACT_APP_URL_ETIQUETTE)
            if(Array.isArray(param) && param.length==0){
                setErrorCookie(true)
            }else{
                imprimeEtiquette(param,devise,formatedPrice,t,props.articleName,props.articleUpc,qteTag,props.from,sizeTag,setUrlCertificat,redirectCertificat)
            }
        }
    }

    return(
        <div>
            <a ref={redirectCertificat} href={urlCertificat} className="d-none" target="_blank"></a>
            <label>{t('qteTag')}</label>
            <input type="text" className="form-control mb-3" value={qteTag} onChange={(e)=>handleChange(e,'qte')}/>
            <label>{t('tagSize')}</label>
            <select className="form-control mb-3" value={sizeTag} onChange={(e)=>handleChange(e,'size')}>
                <option value="">--Select--</option>
                <option value="300">35x30mm</option>
                <option value="220">35x22mm</option>
            </select>
            {(errorCookie) ? <div className="alert alert-danger mb-2 mt-2">{t('noConfiguredPeripheral')}</div> : false}
            <button className="btn btn-primary" onClick={() => handleSubmit()}>{t('btnPrint')}</button>
        </div>
    )
}

export default ModalTagPrintForm;