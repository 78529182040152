import React from 'react';
import {SearchCommande} from "@gull";

const MasterCloudCommandeButton = (props) =>{
    
    if(props.modulePos.params.commande_client.active){
        return(<SearchCommande {...props}/>)
    }else{
        return(<></>)
    }
};

export default MasterCloudCommandeButton;