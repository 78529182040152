import React, {useState,useEffect} from "react";
import { Loading,CardPerso,DeleteActionButton,AttributeToStaff,CancelAllInventoryBtn,ControlInventoryCountAdmin,DetailsInventoryDivisionAdminBtn,ExportArticleButton,CarouselImage} from "@gull";
import { useTranslate } from 'react-redux-multilingual'
import swal from "sweetalert2";
import axios from 'axios'
import { useParams } from "react-router-dom";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import filterFactory, { selectFilter  } from 'react-bootstrap-table2-filter';
import ButtonDeleteMasse from '../ButtonDeleteMasse'
import ButtonCountMasse from './ButtonCountMasse'
import CancelInventoryForm from '../CancelInventoryForm'
import HeadOptionsInventory from '../HeadOptionsInventory'
import { Modal } from "react-bootstrap";
import {scrollXDataTable} from 'app/scrollXDataTable';
import { useCookies} from 'react-cookie';
// Style import
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

const useLoad = (posid,relance) => {
    const t =useTranslate()
    const [loading,setLoading] = useState(true)
    const [section,setSection]=useState(false)

    const [loadingDetail,setLoadingDetail] = useState(true)
    const [divisionInfo,setDivisionInfo]=useState({})
    const [divisionIdInfo,setDivisionIdInfo]=useState(false)
    const [divisionRhInfo,setDivisionRhInfo]=useState(false)

    const [restriction,setRestriction]=useState({
        go:true,
        grades:[]
    })

    useEffect(function () {
        (async function() {
            const response = await axios.get(process.env.REACT_APP_API_URL+"api/section/"+posid
            ).then((response) => {
                setLoading(false)
                if(response.data.rh==null || response.data.rh.length==0){
                    swal.fire(t('noRhForSection'), "", "error");
                }
                else{
                    let restriction = response.data.restriction.filter(value=>value.action==='readInventoryDetail')
                    if(restriction.length>0){
                        if(restriction[0].grade.length > 0){
                            setRestriction({
                                go:false,
                                grades: restriction[0].grade.map(value=>value.id)
                            })
                        }
                    }
                    setSection(response.data)
                }
            }).catch((error) => {
                if(error=='end_session') return;
                setLoading(false)
                let messageError
                if(error.response==undefined){
                    messageError='unknowError'
                }else if(error.response.status==403){
                    messageError='forbiddenGeneral'
                }else {
                    messageError='unknowError'
                }
                swal.fire(t(messageError), "", "error");
            })
        })()
    }, [])

    useEffect(function () {
        (async function() {
            if(divisionIdInfo){
                setLoadingDetail(true)
                const response = await axios.get(process.env.REACT_APP_API_URL+"api/erp/inventaire_division/get_one/"+divisionIdInfo
                ).then((response) => {
                    if(!response.data.valid){
                        let interms='';
                        var keys = Object.keys(response.data.errors);
                        for(var i = 0; i < keys.length; i++){
                            interms+=response.data.errors[keys[i]].join(',')+','
                        }
                        interms=interms.split(',')
                        let messageError='';
                        interms.forEach(interm=>messageError+=t(interm)+'. ')
                        messageError=messageError.replace('undefined','');
                        swal.fire(messageError, "", "error");

                    }else{  
                        let obj = {}
                        //on réorganise la réponse, au lieu d'avoir différents comptes avec les memes articles, on regroupe tous les comptages dans un seul article 
                        //si il y a des bins on différenciera les comptages avec id article+id bins
                        response.data.data.inventaire_count.map((value)=>{
                            let binsId = (value.bins_id!=null) ? value.bins_id : ''
                            //si l'article existe deja dans nos data, on lui ajoute un comptage
                            if(obj[value.article_id+'dd'+binsId]){
                                obj[value.article_id+'dd'+binsId]['rh'+value.rh_id]=value.total
                                obj[value.article_id+'dd'+binsId]['count'+value.rh_id]=value.id

                            }else{
                                //sinon on crée l'article 
                                //on initialise infoCount avec rh+l'id du staff avec son comptage et count+l'id du staff avec l'id de son comptage
                                let infoCount = {['rh'+value.rh_id]:value.total,['count'+value.rh_id]:value.id}
                                Object.entries(value).map(([key,item])=>{
                                    if(key!='rh_id' && key!='total' && key!='rh_name' && key!='id'){
                                        infoCount[key]=item
                                    }
                                })
                                obj[value.article_id+'dd'+binsId]=infoCount
                            }
                        })
                        setDivisionInfo(obj)
                        setLoadingDetail(false)
                    }
                }).catch((error) => {
                    if(error=='end_session') return;
                    let messageError
                    if(error.response==undefined){
                        messageError='unknowError'
                    }else if(error.response.status==403){
                        messageError='forbiddenGeneral'
                    }else {
                        messageError='unknowError'
                    }
                    swal.fire(t(messageError), "", "error");
                })
                setLoadingDetail(false)
            }
        })()
    }, [divisionIdInfo,relance])
    
    return [loading,section,loadingDetail,divisionInfo,setDivisionInfo,setDivisionIdInfo,setDivisionRhInfo,divisionRhInfo,restriction,setRestriction]
}

const CustomDataTable = (props) => {
    const t = useTranslate();
    
    useEffect(() => {
        scrollXDataTable(window.screen.width,props.columns.length)
    }, [window.screen.width,props.columns.length]);
    
    return (
        <BootstrapTable
            {...props.propst.baseProps}
            bootstrap4
            pagination={paginationFactory(props.paginationOptions)}
            noDataIndication={t('tableNoData')}
            rowStyle={ props.rowStyle }
            filter={ filterFactory() }
        />
    );
};

const ListInventoryCategory = (props) => {
    const t = useTranslate()
    const [loading,section,loadingDetail,divisionInfo,setDivisionInfo,setDivisionIdInfo,setDivisionRhInfo,divisionRhInfo,restriction,setRestriction] =useLoad(props.allInventoryCategory.section_id,props.relance)
    const { id,zoneid } = useParams();
    let { SearchBar } = Search;
    const [showCancelModal,setShowCancelModal]=useState(false)
    const [show,setShow]=useState(false)   
    const [inventoryRhIds,setInventoryRhIds]=useState([])
    const [finishedDivision,setFinishedDivision]=useState([])
    const [wait,setWait]=useState(false)
    const [perPage,setPerPage]=useState(50)
    const [modalTitle,setModalTitle]=useState('') 
    const [lang, setLang] = useCookies(['lang']);

    //Pour le carousel
    const [showCarousel,setShowCarousel]=useState(false) 
    const [modalItemName,setModalItemName]=useState('') 
    const [modalImage,setModalImage]=useState('') 

    const openModalCarousel = (image,itemName) =>{
        setModalImage(image)
        setModalItemName(itemName)
        setShowCarousel(true)
    }

    const closeModalCarousel = () =>{
        setModalImage('')
        setModalItemName('')
        setShowCarousel(false)
    }
  

    useEffect(function () {
        let arr=[]
        //ici on remplie le tableau finichedDivision avec tous les divisions qui ont un état 'terminé' pour comparer si son length est égale au length des dats d'origine
        props.allInventoryCategory.inventaire_division.length!=0 && props.allInventoryCategory.inventaire_division.map(value=>{
            if(value.all_count && value.same_count){
                arr.push(value.id)
            }
        })
        setFinishedDivision(arr)
    }, [props.allInventoryCategory.inventaire_division])   

    useEffect(function () {
        if(Object.keys(props.allInventoryCategory).length!=0 && props.allInventoryCategory.inventaire_division.length!=0){

            let rhObj = {}
            props.allInventoryCategory.inventaire_division.map(value=>{
                value.rh.map(item=>{
                    if(!rhObj[item.id]){
                        rhObj[item.id]=item
                    }
                })
            })

            let arr = []
            Object.entries(rhObj).map(([key,value])=>{
                arr.push(value)
            })

            setInventoryRhIds(arr)
        }
    }, [props.allInventoryCategory])

    useEffect(function () {
        if(props.allInventoryCategory.wait==2){
            let relanceAppel = setInterval(()=> {
                const response = axios.get(process.env.REACT_APP_API_URL+"api/erp/inventaire/get_one/"+id
                ).then((response) => {
                    if(!response.data.valid){
                        let interms='';
                        var keys = Object.keys(response.data.errors);
                        for(var i = 0; i < keys.length; i++){
                            interms+=response.data.errors[keys[i]].join(',')+','
                        }
                        interms=interms.split(',')
                        let messageError='';
                        interms.forEach(interm=>messageError+=t(interm)+'. ')
                        messageError=messageError.replace('undefined','');
                        swal.fire(messageError, "", "error");

                    }else{  
                        if(response.data.data.status==2){
                            clearInterval(relanceAppel)
                            props.setAllInventoryCategory(response.data.data)
                        }
                    }
                }).catch((error) => {
                    if(error=='end_session') return;
                    let messageError
                    if(error.response==undefined){
                        messageError='unknowError'
                    }else if(error.response.status==403){
                        messageError='forbiddenGeneral'
                    }else {
                        messageError='unknowError'
                    }
                    swal.fire(t(messageError), "", "error");
                })
            }, 15000);
        }
    }, [])

    const attributeStaff = (zoneId,itemName,rhList) =>{
        props.setItemAttribute(zoneId)
        props.setItemName(itemName)
        if(props.searchInput.current){
            props.searchInput.current.focus()
        }

        //Si des rh ont déjà été attribué et qu'on veut en rajouter
        //on affiche les rh existants en dessous de l'input afin de les ajoute en plus au lieu de devoir les écraser 
        if(rhList.length!=0){
            let arr = []
            rhList.map(value=>
                arr.push({id:value.id,name:value.name+' '+value.firstname})
            )
            props.setStaffList(arr)
        }
    }

    const deleteItemFromInventory = async (id) =>{
        const response = await axios.delete(process.env.REACT_APP_API_URL+"api/erp/inventaire_division/delete_single/"+id
        ).then((response) => {
            let newData = props.allInventoryCategory.inventaire_division.filter(value=>value.id!=id)
            props.setAllInventoryCategory({...props.allInventoryCategory,inventaire_division:newData})

            // mise à jour du nombre de ligne par page 
            let newAfficheTab = props.afficheTabArticle.filter(value=>value.id!=id)
            props.setAfficheTabArticle(newAfficheTab)

        }).catch((error) => {
            if(error=='end_session') return;
            let messageError
            if(error.response==undefined){
                messageError='unknowError'
            }else if(error.response.status==403){
                messageError='forbiddenGeneral'
            }else {
                messageError='unknowError'
            }
            swal.fire(t(messageError), "", "error");
        })
    }

    const selectRow = (name,rowid) =>{
        props.setItemAttribute(false)//si une row à été sélectionnée par btn attribute dans les actions, on le passe à false pour éviter les erreurs au submit
        if(name=='all'){
            if(props.afficheTabArticle.length == props.selectedRow.length){
                //Si tout à été selectionné, on décoche tout
                props.setSelected(false)
                props.setSelectedRow([])
                props.setItemAttributeMasse(false)
            }else{
                props.setSelected(true)
                props.setItemAttributeMasse(true)
                //Sinon on coche le reste
                let newArr = []
                //on ne coche que les lignes arrichées sur la page en cours
                props.afficheTabArticle.map(value=>
                    newArr.push(value.id)
                )
                
                props.setSelectedRow(newArr)
            }
        }else{
            if(props.selectedRow.includes(rowid)){
                //si la case est cochée, on la décoche
                let newArr = props.selectedRow.filter(value=>value!=rowid)
                props.setSelectedRow(newArr)
                if(newArr.length!=0){
                    props.setItemAttributeMasse(true)
                }else{
                    props.setItemAttributeMasse(false)
                }
                if(props.afficheTabArticle.length == props.selectedRow.length){
                    //Si tout est coché et qu'on en décoche un, le select all se décoche
                    props.setSelected(false)
                }
            }else{
                if(props.afficheTabArticle.length == props.selectedRow.length+1){
                    //Si après avoir coché, toute la liste est cochée, on coche le select all
                    props.setSelected(true)
                }
                props.setItemAttributeMasse(true)
                props.setSelectedRow([...props.selectedRow,rowid])
            }
        }
    }

    const selected_article_page = (page,sizePerPage) =>{
        let indice = ((page-1)*sizePerPage);
        props.setAfficheTabArticle(props.allInventoryCategory.inventaire_division.slice(indice,indice+sizePerPage));
    }

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
          Showing { from } to { to } of { size } Results
        </span>
    );

    const sizePerPageOptionRenderer = ({
        text,
        page,
        onSizePerPageChange
    }) => (
        <li
            key={ text }
            role="presentation"
            className="dropdown-item"
            onMouseDown={ (e) => {
                e.preventDefault();
                onSizePerPageChange(page);
                setPerPage(page)
            } }
        >
            <a
                href="#"
                tabIndex="-1"
                role="menuitem"
                data-page={ page }
                style={ { color: 'black' } }
            >
                { text }
            </a>
        </li>
    );

    let paginationOptions = {
        paginationSize: 5,
        pageStartIndex: 1,
        firstPageText: t('tableFirst'),
        prePageText: t('tableBack'),
        nextPageText: t('tableNext'),
        lastPageText: t('tableLast'),
        nextPageTitle: t('tableNextTitle'),
        prePageTitle: t('tableBackTitle'),
        firstPageTitle: t('tableFistTitle'),
        lastPageTitle: t('tableLastTitle'),
        sizePerPageOptionRenderer,
        sizePerPage: perPage,
        showTotal: true,
        onPageChange: (page, sizePerPage) => {
            //Quand on change de page, on décoche tout
            props.setSelectedRow([])
            props.setSelected(false)
            selected_article_page(page,sizePerPage)
        },
        paginationTotalRenderer: customTotal
    };

    let dataTable = new Array
    Object.keys(props.allInventoryCategory).length!=0 && props.allInventoryCategory.inventaire_division.length!=0 && props.allInventoryCategory.inventaire_division.map((value,ind)=>{
        let staffAttibue 
        (value.rh.length!=0) ? 
            value.rh.map((value,ind)=>{ 
                if(ind==0){
                    staffAttibue=value.name+' '+value.firstname
                }else{
                    staffAttibue+=' / '+value.name+' '+value.firstname
                }
                
            }) 
        : 
            staffAttibue=''
        
        let obj = {
            itemName: value.article_inventaire.name,
            masque:'',
            line_id: value.id,
            article_id: value.article_id,
            image: (value.article_inventaire.image!=null) ? <img className="vignetteBaseArticle" src={value.article_inventaire.image.split(';')[0]} alt={value.article_inventaire.name} onClick={()=>openModalCarousel(value.article_inventaire.image,value.article_inventaire.name)}/> : '',
            selectBtn: <input disabled={props.allInventoryCategory.status!=0 || props.allInventoryCategory.wait==1} checked={props.selectedRow.includes(value.id)} type="checkbox" onChange={()=>selectRow('one',value.id)}/>,
            action: 
                <span className="d-flex justify-content-between">
                    <DeleteActionButton deleteFunctionArgument={value.id} actionType={'typeDeleteX'} nameType={'delete'} toDelete={value.article_inventaire.name} customClickEvent={deleteItemFromInventory}/>
                    <AttributeToStaff callback={attributeStaff} zoneId={value.id} zoneName={value.article_inventaire.name} rhList={value.rh}/>
                    <DetailsInventoryDivisionAdminBtn divisionId={value.id} setDivisionIdInfo={setDivisionIdInfo} setShow={setShow} rhIds={value.rh} setDivisionRhInfo={setDivisionRhInfo} restriction={restriction} setRestriction={setRestriction} section={section} posid={props.allInventoryCategory.section_id} module={props.module} titleName={value.article_inventaire.name} setModalTitle={setModalTitle}/>
                    {/* <CancelInventoryBtn divisionId={value.id} rhIds={value.rh} setShowCancelModal={setShowCancelModal} setDivisionRhInfo={setDivisionRhInfo} setIdDivision={setIdDivision}/> */}
                </span>,
            staff:staffAttibue,
            state:(value.rh.length==0) ? 'nonAttribue' : (value.all_count && value.same_count) ? 'finished' : (value.all_count && !value.same_count) ? 'wrongCount' : 'countNotFinished',
            index: ind + 1,
        }
        dataTable.push(obj)
    })

    const selectOptions ={ 
        'finished':t('finished'),
        'wrongCount':t('wrongCount'),
        'countNotFinished':t('countNotFinished'),
        'nonAttribue':t('nonAttribue')
    };

    let columns = [];

    if(props.allInventoryCategory.status==0 && props.allInventoryCategory.wait==0){
        columns.push(
            {
                dataField: "action",
                text: 'Actions',
                editable: false,
                headerClasses: 'head-action-row',
                classes: 'body-action-row',
            },
            {
                dataField: "masque",
                text: '',
                editable: false,
                formatter: (rowContent, row) => {
                    return (    
                        <div style={{display:"block",width:"82px"}}></div>
                    )
                },
                style: (cell, row, rowIndex, colIndex) => {
                    return {
                        width:'99px',
                        backgroundColor:'white'
                    };
                },
            },
        )
    }

    columns.push(
        {
            dataField: "selectBtn",
            text: '',
            editable: false,
            headerStyle: (column, colIndex) => {
                return {
                    width:'50px'
                };
            },
            headerFormatter: (column, colIndex) => ( 
                <input disabled={props.allInventoryCategory.status!=0 || props.allInventoryCategory.wait==1} checked={props.selected} type="checkbox" onChange={()=>selectRow('all')}/>
            )
        },
        {
            dataField: "itemName",
            text: t('article'),
            editable: false,
        },
        {
            dataField: "image",
            text: "Image",
            editable: false,
        },
        {
            dataField: "staff",
            text: t('rh'),
            editable: false,
        },
        {
            dataField: "state",
            text: t('state'),
            editable: false,
            formatter: cell => selectOptions[cell],
            filter: selectFilter({
                options: selectOptions,
            }),
        },
    )

    const rowStyle = (row) => {
        const style = {};

        if (props.selectedRow.includes(row.line_id)) {
            style.backgroundColor = '#00BFFF';
        }else if(row.state=='nonAttribue'){
            style.backgroundColor = '#ffffff';

        }else if(row.state=='wrongCount') {
            style.backgroundColor = '#fccfcf';//mauvais comptage
  
        }else if(row.state=='finished'){
            style.backgroundColor = '#d9fcd2';//finalisé

        }else{
            style.backgroundColor = '#ffffbb';//comptage non fini
        }
        
        return style;
    };

    const closeModal = () =>{
        setShow(false)
        props.setRelance(Date.now())
    }

    if(loading){
        return(<Loading></Loading>)
    }

    return(
        <div className="row">
            <Modal size="xl" show={show} onHide={() => closeModal()}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('inventory')+' : '+modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {(loadingDetail) ? 
                        <span className="d-flex justify-content-center"><div className="spinner-border spinner-border-sm"></div></span>
                    :
                        <ControlInventoryCountAdmin divisionInfo={divisionInfo} divisionRhInfo={divisionRhInfo} setDivisionInfo={setDivisionInfo}/>
                    }
                </Modal.Body>
            </Modal>
            <Modal size="md" show={showCancelModal} onHide={() => setShowCancelModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('cancelInventory')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CancelInventoryForm divisionRhInfo={inventoryRhIds} setRelance={props.setRelance} idDivision={props.allInventoryCategory.id} setShowCancelModal={setShowCancelModal} cancelAllInventory={true} />
                </Modal.Body>
            </Modal>
            <Modal show={showCarousel} onHide={()=>closeModalCarousel()}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalItemName}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CarouselImage images={modalImage} />
                </Modal.Body>
            </Modal>
            <div className="col">
                <CardPerso header={
                    <HeadOptionsInventory 
                        title='inventory' 
                        btnValid={false} 
                        btnReturn={false} 
                        btnRefresh={true}
                        btnReturnHome={true}
                        setRefreshPage={props.setRelance}
                        module={props.module}
                        posid={props.allInventoryCategory.section_id}
                        inventoryData={props.allInventoryCategory}
                        finishedDivision={finishedDivision}
                        id={id}
                        setWait={setWait}
                    />}>
                    <div>
                        {(props.allInventoryCategory.wait==1 || props.allInventoryCategory.wait==2 || wait) ?
                            <div className="alert alert-info d-flex align-items-center mt-2" role="alert">  
                                {(props.allInventoryCategory.wait==1) ? t('inventoryCompareWait') : (props.allInventoryCategory.wait==2) ? t('inventoryValidateWait') : t('inventoryCompareWait')}&nbsp;
                                <div className="spinner-border spinner-border-sm"></div>
                            </div>
                        :
                            <small>
                                <ToolkitProvider
                                    striped
                                    keyField="index"
                                    data={dataTable}
                                    columns={columns}
                                    search
                                >
                                {(propst) => (
                                    <>
                                        <div className="row mb-2">
                                            {(props.allInventoryCategory.status==2) &&
                                                <div className="col d-flex align-items-baseline">
                                                    <ExportArticleButton filtre={{}} lang={lang.lang} title={t('downloadStockGap')} type="button" url={"api/erp/inventaire/csvitem/"+id}></ExportArticleButton>
                                                </div>
                                            }
                                            <div className="col d-flex justify-content-end align-items-center">
                                                <span className="mb-2 me-1">{t('tableSearch')}:</span>
                                                <SearchBar {...propst.searchProps} className="mb-2" />&nbsp;&nbsp;
                                                {(props.allInventoryCategory.status==0 && props.allInventoryCategory.wait==0) ?
                                                    <>
                                                        <ButtonCountMasse 
                                                            path={props.countingPath}
                                                            sectionId={props.allInventoryCategory.section_id}
                                                            id={id}
                                                        />&nbsp;&nbsp;
                                                        <CancelAllInventoryBtn setShowCancelModal={setShowCancelModal}/>
                                                    </>
                                                :
                                                    false
                                                }
                                            </div>
                                            {(props.selectedRow.length!=0) ?
                                                <ButtonDeleteMasse 
                                                    selectedRow={props.selectedRow} 
                                                    setRelance={props.setRelance}
                                                    setSelectedRow={props.setSelectedRow}
                                                    setSelected={props.setSelected}
                                                    afficheTab={props.afficheTabArticle}
                                                    setAfficheTab={props.setAfficheTabArticle}
                                                    inputBar={props.searchInput}
                                                />
                                            :
                                                false
                                            }
                                        </div>
                                        {(props.allInventoryCategory.status==2) ?
                                            <span className="alert alert-success d-flex align-items-center mt-2 text-dark fs-4 col" role="alert">{t('finalizedInventory')}&nbsp;<FontAwesomeIcon icon={faCheckCircle} /></span>
                                        :
                                            false
                                        }
                                        <div className="position-relative">
                                            <CustomDataTable
                                                propst={propst}
                                                paginationOptions={paginationOptions}
                                                rowStyle={rowStyle}
                                                columns={columns}
                                                defaultSortDirection="asc"
                                            />
                                        </div>
                                    </>
                                )}
                                </ToolkitProvider>
                            </small>
                        }
                    </div>
                </CardPerso>
            </div>
        </div>
    )
}

export default ListInventoryCategory;
