import React from 'react';
import { useTranslate } from 'react-redux-multilingual'

const ButtonCommande = (props) =>{
    const t = useTranslate();

    if(props.modulePos.params.commande_client.active){
        return (
            <>
                {(props.showOrderBtn && props.currentTicket.section_id==props.posid) ? 
                    <button 
                        disabled={props.btnDisabled || props.loading.commande} 
                        className="btn btn-sm btn-dark" 
                        onClick={()=>props.handleClick((props.currentTicket.commande!=null) ? 'addToOrder' : 'order')}
                    >
                        {(props.currentTicket.commande==null) ? 
                            t('salesOrder') 
                        : 
                            (props.loading.commande) ? 
                                <div className="spinner-border spinner-border-sm"></div> 
                            : 
                                t('addToOrder')}
                    </button>
                :
                    false
                }
            </>
        )
    }else{
        return(<></>)
    }
};

export default ButtonCommande;