import React, { useState } from 'react';
import { CardPerso } from '@gull'
import { useTranslate } from 'react-redux-multilingual'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import RuleLineItems from './Comp/RuleLineItems'

const BulkRulesCustomForm = (props) =>{
    const t = useTranslate();
    const [disabled,setDisabled]=useState(false) 

    const handleAdd = () =>{
        setDisabled(true)
        props.setAllUpdateRules([{id:'newLine',name:'',type_maj:''},...props.allUpdateRules])
    }
    
    return (
        <div>
            <CardPerso header={<span>
                {t('updateDatasRule')}&nbsp;&nbsp;
                <button disabled={disabled} className='btn btn-sm btn-success' onClick={()=>handleAdd()}><FontAwesomeIcon icon={faPlus} /></button>
                </span> 
            }>
                {(props.allUpdateRules.length==0) ?
                    <div className='text-center mt-3'>{t('noUpdateRule')}</div>
                :
                    props.allUpdateRules.map((value,ind) =>{
                        if(props.from=='ArticleForm'){
                            return(
                                <RuleLineItems key={ind} hardData={props.hardData} fieldsData={props.fieldsData} value={value} index={ind} setAllUpdateRules={props.setAllUpdateRules} allUpdateRules={props.allUpdateRules} setDisabled={setDisabled}/>
                            )
                        }
                    })
                }
            </CardPerso>
        </div>
    )
};

export default BulkRulesCustomForm;