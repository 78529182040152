import React from "react";
import { useTranslate } from 'react-redux-multilingual';
import { Link } from "react-router-dom";

const  RedirectSwitchBtn = (props) => {
    const t = useTranslate();

    const urlDetail = (props.urlDetails!=null) ? props.urlDetails.split(",") : false;

    let inventaireType
    if(props.description=='inventaireZone'){
        inventaireType='zone'
    }else if(props.description=='inventaireBins'){
        inventaireType='bins'
    }else{
        inventaireType='categorie'
    }

    let categoryName = {
        po: t('purchaseOrder').toLowerCase(),
        shipment: t('shipmentVoucher').toLowerCase(),
        to: t('transferOrder').toLowerCase(),
        ticket: t('ticket').toLowerCase(),
        commande: t('salesOrder').toLowerCase(),
        inventaire: t('inventory').toLowerCase(),
        facture: t('billing').toLowerCase(),
    }

    let path;

    switch (urlDetail[0]){
        case 'po':
            path = '/erp/purchase-order/details/'+urlDetail[1]
        break;
        case 'shipment':
            path = '/erp/shipment/details/'+urlDetail[1]
        break;
        case 'to':
            path = '/erp/transfer-order/details/'+urlDetail[1]
        break;
        case 'ticket':
            path = '/erp/caisse/ticket/'+urlDetail[1]
        break;
        case 'commande':
            path = '/erp/caisse/sales-order/'+urlDetail[1]
        break;
        case 'inventaire':
            path = '/erp/inventory/'+inventaireType+'/'+urlDetail[1]+'/'+props.zone_id
        break;
        case 'facture':
            path = '/invoice/details/'+urlDetail[1]
        break;
    }

    return ((urlDetail) &&
        <div>
            <Link to={path} style={{cursor:'Pointer',textDecoration: 'underline', color:'#0000FF'}}>{t('voirLe')+' '+categoryName[urlDetail[0]]}</Link>
        </div>
    )
}

export default RedirectSwitchBtn;