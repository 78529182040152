import React, {useState,useEffect} from "react";
import {CardPerso, DeleteActionButton,ProfilActionButton,AddButton,ColumDataTableReorganize} from '@gull'
import { useTranslate } from 'react-redux-multilingual'
import axios from 'axios'
import swal from "sweetalert2";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import Moment from 'react-moment';
import {scrollXDataTable} from 'app/scrollXDataTable'
import { useCookies} from 'react-cookie';

const CustomDataTable = (props) => {
    const t = useTranslate();
    
    useEffect(() => {
        scrollXDataTable(window.screen.width,props.columns.length)
    }, [window.screen.width,props.columns.length]);
    
    return (
        <BootstrapTable
            {...props.propst.baseProps}
            bootstrap4
            pagination={ paginationFactory(props.paginationOptions) }
            noDataIndication={t('tableNoData')}
            rowStyle={ props.rowStyle }
            defaultSortDirection={props.defaultSortDirection}
        />
    );
};

const InventoryListMain = (props) => {
    const t =useTranslate() 
    const [perPage,setPerPage]=useState(50)
    const [valueSelect,setValueSelect]=useState({
        status:'encours',
        shop:'',
    })
    const [cookies, setCookie] = useCookies(['inventory_list_main_reorganize']);
    const [columnOrganize,setColumnOrganize]=useState([])
    const [showColumnOrganize,setShowColumnOrganize]=useState(false) 

    let current_date = new Date()
    let timestampdate = current_date.setDate(current_date.getDate() + 90)
    let expire_date = new Date(timestampdate)

    const handleChange = (e,name) =>{
        setValueSelect({...valueSelect,[name]:e.target.value})
    }

    const changeUrlParam = () =>{
        if(valueSelect.shop!=''){
            props.setUrlParam('/'+valueSelect.status+'/'+valueSelect.shop)
        }else{
            props.setUrlParam('/'+valueSelect.status)
        }
    }

    const deleteInventory = async (inventaireId) =>{
        const response = await axios.delete(process.env.REACT_APP_API_URL+"api/erp/inventaire/delete/"+inventaireId
        ).then((response) => {
            let newData = props.allInventory.filter(value=>value.id!=inventaireId)
            props.setAllInventory(newData)
        }).catch((error) => {
            if(error=='end_session') return;
            let messageError
            if(error.response==undefined){
                messageError='unknowError'
            }else if(error.response.status==403){
                messageError='forbiddenGeneral'
            }else {
                messageError='unknowError'
            }
            swal.fire(t(messageError), "", "error");
        })
    }

    const sizePerPageOptionRenderer = ({
        text,
        page,
        onSizePerPageChange
    }) => (
        <li
            key={ text }
            role="presentation"
            className="dropdown-item"
            onMouseDown={ (e) => {
                e.preventDefault();
                onSizePerPageChange(page);
                setPerPage(page)
            } }
        >
            <a
                href="#"
                tabIndex="-1"
                role="menuitem"
                data-page={ page }
                style={ { color: 'black' } }
            >
                { text }
            </a>
        </li>
    );

    let paginationOptions = {
        paginationSize: 5,
        pageStartIndex: 1,
        firstPageText: t('tableFirst'),
        prePageText: t('tableBack'),
        nextPageText: t('tableNext'),
        lastPageText: t('tableLast'),
        nextPageTitle: t('tableNextTitle'),
        prePageTitle: t('tableBackTitle'),
        firstPageTitle: t('tableFistTitle'),
        lastPageTitle: t('tableLastTitle'),
        sizePerPageOptionRenderer,
        sizePerPage: perPage,
        showTotal: true,
        totalSize: props.allInventory.length
    };

    let redirectInventoryURL = (props.module=='erp') ? '/erp/inventory/' : '/pos/'+props.posid+'/inventory/'

    let dataTable = new Array
    props.allInventory.length!=0 && props.allInventory.map((value,ind)=>{
        let obj = {
            name: value.name,
            masque:'',
            section: value.section.name,
            zone: value.zone.name,
            type: (value.type=='zone') ? 'Zone' : (value.type=='categorie') ? t('categorysingular') : 'Bins',
            date: <Moment format="DD/MM/YYYY">{value.created_at}</Moment>,
            status:value.status,
            id: value.id,
            action: 
                <span className="d-flex justify-content-between">
                    <ProfilActionButton path={redirectInventoryURL+value.type+'/'+value.id+'/'+value.zone_id} />
                    {(value.status!=2) && <DeleteActionButton deleteFunctionArgument={value.id} actionType={'typeDeleteX'} nameType={'delete'} toDelete={value.name} customClickEvent={deleteInventory}/>}
                </span>,
            index: ind + 1,
        }
        dataTable.push(obj)
    })

    const headerSortingStyle = { backgroundColor: '#c8e6c9' };

    let columns = [
        {
            dataField: "action",
            text: 'Actions',
            editable: false,
            headerClasses: 'head-action-row',
            classes: 'body-action-row',
        },
        {
            dataField: "masque",
            text: '',
            editable: false,
            formatter: (rowContent, row) => {
                return (    
                    <div style={{display:"block",width:"82px"}}></div>
                )
            },
            style: (cell, row, rowIndex, colIndex) => {
                return {
                    width:'99px',
                    backgroundColor:'white'
                };
            },
        },
        {
            dataField: "name",
            text: t('tableName'),
            editable: false,
            sort: true,
            headerSortingStyle,
        },
        {
            dataField: "section",
            text: t('shop'),
            editable: false,
            sort: true,
            headerSortingStyle,
        },
        {
            dataField: "zone",
            text: 'Zone',
            editable: false,
            sort: true,
            headerSortingStyle,
        },
        {
            dataField: "type",
            text: t('inventoryType'),
            editable: false,
            sort: true,
            headerSortingStyle,
        },
        {
            dataField: "date",
            text: t('createdAt'),
            editable: false,
            sort: true,
            headerSortingStyle,
        },
    ];

    useEffect(function () {
        let columnCookie
        let arrCol = []
            
        if(cookies.inventory_list_main_reorganize){
            let newValueToIncludeInCookie
            let cookieLecture = cookies.inventory_list_main_reorganize.split(',')

            let already_done=[];
            cookieLecture.map(value=>{
                columns.map(item=>{
                    if(item.dataField==value){
                        arrCol.push(item)
                        already_done.push(value);
                    }
                })
            })
            columns.map(item=>{
                if(!already_done.includes(item.dataField)){ 
                    //si une nouvelle à été ajouté aux customsField, elle n'est pas ajoutée desuite au cookie de reorganisation si celui ci existe
                    //dans le cas où il n'existerait pas, on va créer un nouveau cookie afin de l'inclure pour ne pas avoir d'erreur sur les export 
                    newValueToIncludeInCookie=true
                    arrCol.push(item);
                }
            })

            columnCookie = arrCol
            if(newValueToIncludeInCookie==true){ 
                let arr = []
                arrCol.map(value=>
                    arr.push(value.dataField)
                )
                setCookie("inventory_list_main_reorganize",arr.join(),{ path: "/", expires: expire_date })
            }
        }else{
            columnCookie = columns
        } 
        setColumnOrganize(columnCookie)
    }, [])

    const rowStyle = (row,rowIndex) => {
        const style = {};
        if(row.status == 2){
            style.backgroundColor = '#d9fcd2';//finalisé

        }else{
            style.backgroundColor = '#ffffbb';//en cours
        }
        return style;
    };  

    return(
        <div>
            <div className="row mb-4">
                <div className="col-5 form-group">
                    <label htmlFor="section">{t('status')}</label>
                    <select  className="form-control" type="text" onChange={(e)=>handleChange(e,'status')}>
                        <option value="encours">{t('enCours')}</option>
                        <option value="all">{t('allInventories')}</option>
                    </select>
                </div>
                {(props.module=='erp') ?
                    <div className="col-5 form-group">
                        <label htmlFor="section">{t('shop')}</label>
                        <select className="form-control" type="text" onChange={(e)=>handleChange(e,'shop')}>
                            <option value="">{t('allShops')}</option>
                            {(props.section.length!=0) ? 
                                props.section.map((shop)=>{
                                    if(shop.type!='ecommerce') return(<option key={shop.id} value={shop.id}>{shop.name}</option>)
                                })
                            :
                                false
                            }
                        </select>
                    </div>
                :
                    false
                }
                <div className="col-2 d-flex align-items-center">
                    <button disabled={props.loadList} className="btn btn-primary" onClick={()=>changeUrlParam()}>
                        {props.loadList ? <div className="spinner-border spinner-border-sm"></div> : t('btnValidate')}
                    </button>
                </div>
            </div>
            
            <CardPerso header={(props.module=='erp') ? <AddButton title={t('inventory')} setShowModal={props.setShowModal}/> : t('inventory')}>
                <div className="mb-2">
                    <ColumDataTableReorganize 
                        columnOrganize={columnOrganize}
                        setColumnOrganize={setColumnOrganize}
                        setCookie={setCookie}
                        cookieName='inventory_list_main_reorganize'
                        showColumnOrganize={showColumnOrganize}
                        setShowColumnOrganize={setShowColumnOrganize}
                        btnSwitch={false}//pour affiche ou non le btn swith
                        initSwith={true}//initialier à false il affichera par défaut le toggle des colonnes, sur true il affichera le drag and drop pour changer la position des colonnes
                    />
                </div>
                {(columnOrganize.length!=0) &&
                    <small>
                        <ToolkitProvider
                            striped
                            keyField="index"
                            data={dataTable}
                            columns={ columnOrganize }
                        >
                        {(propst) => (
                            <div className="position-relative">
                                <CustomDataTable
                                    propst={propst}
                                    paginationOptions={paginationOptions}
                                    rowStyle={rowStyle}
                                    columns={columns}
                                    defaultSortDirection="asc"
                                />
                            </div>
                        )}
                        </ToolkitProvider>
                    </small>
                }
            </CardPerso>
        </div>
    )
    
}

export default InventoryListMain;
