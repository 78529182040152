import React from 'react';
import { useTranslate } from 'react-redux-multilingual'

const BinsListInfo = (props) =>{
    const t = useTranslate();

    const handleClick = () =>{
        let filtered=[]
        props.itemToMove.map(value=>{
            if(value.bins && value.bins.bar==props.value.bar){
                filtered.push(value)
            }
        })
        //à la sélection d'un article, on set la liste des possibilitées avec une liste contenant que l'article sélectionné 
        props.setItemToMove(filtered)
        //on retourne sur la partie liste des possibilitées d'action avec la nouvelle liste affichant uniquement l'article sélectionné 
        props.setTab('possibilities')
        //on affiche un boutton d'annulation 
        props.setShowBtnReset(true)
    }

    return(
        <button className="col-12 mb-2 btn btn-outline-dark" onClick={()=>handleClick()}>
            <div className='mb-1 d-flex justify-content-center text-center'>
                <span>{props.value.bar}</span>
            </div>
        </button>
    )
}

export default BinsListInfo;