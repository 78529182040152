import React, {useState,useEffect,useContext} from "react";
import {CardPerso,ExportArticleButton} from "@gull"
import { useTranslate } from 'react-redux-multilingual';
import axios from 'axios'
import swal from "sweetalert2";
import { IsAuthContext } from 'app/App';
import { useCookies} from 'react-cookie';
import StatisticSaleTable from './Comp/StatisticSaleTable'

const useLoad = (id,tabName,posid) => {
    const t = useTranslate();
    const [loading,setLoading]=useState(false)
    const [statisticDetail,setStatisticDetail]=useState(false)
    const [reload,setReload]=useState(Date.now()) 
    const [validButton,setValidButton]=useState(false) 
    const { authParam } = useContext(IsAuthContext);

    const [periodStart,setPeriodStart]=useState('') 
    const [periodEnd,setPeriodEnd]=useState('') 
    const [sectionId,setSectionId]=useState(posid ? posid : '') 
    const [filtreParam,setFiltreParam]=useState({}) 
    const [sections,setSections]=useState([]) 

    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');

    let currentShop = (posid) ? authParam.sections.find(shop=>shop.id==posid) : false

    useEffect(() => {
        //On renseigne la date du jour par défaut 
        setPeriodStart(year+'-'+month+'-'+day)
        setPeriodEnd(year+'-'+month+'-'+day)

        if(!posid){ 
            setSections(authParam.sections) //Si posid n'est pas renseigné, c'est qu'on est sur l'erp du coup on récupère la liste de tous les commerces et non celle des otherCompany
        }else{
            setSections([currentShop])
        }
    }, [])

    useEffect(() => {
        let obj = {}
        if(sectionId!=''){ 
            obj['section']=[sectionId]
        }

        setFiltreParam(obj)
    }, [sectionId])

    const validFiltre = () =>{
        setStatisticDetail(false)
        setValidButton(true)
        setReload(Date.now())
    }

    useEffect(function () {
        (async function() {
            if(tabName=='statistic' && !statisticDetail){
                setLoading(true)
                const callapi  = await axios.post(process.env.REACT_APP_API_URL+"api/erp/article/stat/"+id+'/'+periodStart+'/'+periodEnd,filtreParam
                ).then((response) => {
                    if(response.data.hasOwnProperty('valid') && !response.data.valid){
                        let interms='';
                        var keys = Object.keys(response.data.errors);
                        for(var i = 0; i < keys.length; i++){
                            interms+=response.data.errors[keys[i]].join(',')+','
                        }
                        interms=interms.split(',')
                        let messageError='';
                        interms.forEach((interm)=>{
                            let finalMessage=interm.split('/!');
                            if(finalMessage.length==2){
                                messageError+=t(finalMessage[0],{name:finalMessage[1]})+'<br/>'
                            }
                            else{
                                messageError+=t(interm)+'<br/>'
                            }
                        })
                        messageError=messageError.replace('undefined','');
                        swal.fire(messageError, "", "error");
                    }
                    else{
                        setStatisticDetail(response.data.data)
                    }
                }).catch((error) => {
                    if(error=='end_session') return;
                    let messageError;
                    if(!error.response){
                        messageError=t('unknowError')
                    }
                    else if(error.response.status===403){
                        messageError=t('forbiddenGeneral')
                    }
                    else {
                        messageError=t('unknowError')
                    }
                    swal.fire(messageError, "", "error");
                })
            }
            setLoading(false)
            setValidButton(false)
        })()
    }, [id,tabName,reload])

    useEffect(function () {
        (async function() {
            if(posid && currentShop && currentShop.other_company.length!=0 && tabName=='statistic'){
                const response = await axios.get(process.env.REACT_APP_API_URL+"api/othercompany/"+currentShop.other_company[0].id
                ).then((response) => {
                    setSections(response.data.section)
                }).catch((error) => {
                    if(error=='end_session') return;
                    let messageError;
                    if(!error.response){
                        messageError=t('unknowError')
                    }
                    else if(error.response.status===403){
                        messageError=t('forbiddenGeneral')
                    }
                    else {
                        messageError=t('unknowError')
                    }
                    swal.fire(messageError, "", "error");
                })
            }
        })()
    }, [tabName])

    return [loading,statisticDetail,validFiltre,setPeriodStart,periodStart,setPeriodEnd,periodEnd,sectionId,setSectionId,validButton,sections]
}

const StatistiqueVente = (props) => {
    const t = useTranslate();
    const { authParam } = useContext(IsAuthContext);
    const [loading,statisticDetail,validFiltre,setPeriodStart,periodStart,setPeriodEnd,periodEnd,sectionId,setSectionId,validButton,sections] = useLoad(props.article.id,props.tabName,props.posid)
    const [lang, setLang] = useCookies(['lang']);

    let devise = authParam.company.devise;
    let digit = (authParam.company.devise === "XPF") ? 0 : 2;
    
    return (
        <CardPerso header={t('salesStatistics')}>
            <div className="row mb-3 d-flex align-items-center">
                {/* <div className="col-2">
                    <ExportArticleButton filtre={{}} lang={lang.lang} header={props.from} title={t('downloadCSV')} type="button" url={"api/erp/article/csvitem"}></ExportArticleButton>
                </div>
                <div className="col-3 form-group">
                    <label>{t('beginningPeriod')}</label>
                    <input type="date" value={periodStart} className="form-control" onChange={(e)=>setPeriodStart(e.target.value)}/>
                </div>
                <div className="col-3 form-group">
                    <label>{t('endPeriod')}</label>
                    <input type="date" value={periodEnd} className="form-control" onChange={(e)=>setPeriodEnd(e.target.value)}/>
                </div> */}
                <div className="col-4 form-group">
                    <label>{t('beginningPeriod')}</label>
                    <input type="date" value={periodStart} className="form-control" onChange={(e)=>setPeriodStart(e.target.value)}/>
                </div>
                <div className="col-4 form-group">
                    <label>{t('endPeriod')}</label>
                    <input type="date" value={periodEnd} className="form-control" onChange={(e)=>setPeriodEnd(e.target.value)}/>
                </div>
                <div className="col-3 form-group">
                    <label>{t('shop')}</label>
                    <select value={sectionId} className="form-control" onChange={(e)=>setSectionId(e.target.value)}>
                        {(props.from=='erp') && <option value="">{t('allShops')}</option>}
                        {sections.map(section=>{
                            return(
                                <option key={section.id} value={section.id}>{section.name}</option>
                            )
                        })}
                    </select>
                </div>
                <div className="col-1">
                   <button disabled={validButton} className="btn btn-primary" onClick={()=>validFiltre()}>{validButton ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : t('btnValidate')}</button>
                </div>
            </div>
            {(loading) ?
                <span className="d-flex justify-content-center"><div className="spinner-border spinner-border-sm"></div></span>
            :
                <StatisticSaleTable from={props.from} statisticDetail={statisticDetail} devise={devise} digit={digit}/>
            }
        </CardPerso>
    );
}

export default StatistiqueVente;
