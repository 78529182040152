import React from "react";
import { useTranslate } from 'react-redux-multilingual';
import { OverlayTrigger, Tooltip } from "react-bootstrap";

// Style import
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan } from '@fortawesome/free-solid-svg-icons';

const  CancelInventoryBtn = (props) => {
    const t = useTranslate();

    const handleClick=()=>{
        props.setShowCancelModal(true)
        props.setDivisionRhInfo(props.rhIds)
        props.setIdDivision(props.divisionId)
    }

    return (
            <OverlayTrigger
                placement="bottom"
                overlay={
                    <Tooltip>
                        {t('cancelCount')}
                    </Tooltip>
                }
            >
                <span 
                    className="text-danger" 
                    style={{cursor:'pointer'}} 
                    onClick={() =>handleClick()}
                >
                    <FontAwesomeIcon icon={faBan} />
                </span>
            </OverlayTrigger>  
    )
}

export default CancelInventoryBtn;