import React,{useEffect} from "react";
import { useTranslate } from 'react-redux-multilingual'

const RefundGiftCard = (props) =>{
    const t =useTranslate()

    useEffect(()=>{//pour le foccus sur l'input scan giftcard
        const input = document.querySelector('#giftCardScan');
        input.focus()
        
    },[props.currentTicket])

    const handleChange = (e) =>{
        props.setInputValue({...props.inputValue,inputBarCode:e.target.value})
    }

    const handleSubmit = (e) =>{
        e.preventDefault();

        if(props.inputValue.inputBarCode==''){
            props.setErrorPay(true)

        }else{
            props.setLoad(true)
            props.refundApi({
                id:props.cookies.current_ticket,
                paiement_mode:'giftcard',
                paiement_montant:0,
                paiement_verse:0,
                paiement_rendu:0,
                gift_card_reference:props.inputValue.inputBarCode,
                gift_card_montant:+props.total,
                gift_card_client_id:(!props.clientId) ? null : props.clientId
            })
        }
    }

    return(
        <form onSubmit={(e)=>handleSubmit(e)}>
            <div className="row input-group mb-2">
                <div className="col">
                    <label htmlFor="checknumber">{t('scanGiftCard')}</label>
                    <input id='giftCardScan' className='form-control'  value={props.inputValue.inputBarCode} type="text" onChange={(e)=>handleChange(e)} />
                </div>
            </div>
            <div className="row input-group mb-2">
                <div className="col">
                    <label htmlFor="amount">{t('amount')}</label>
                    <input disabled className='form-control'  value={props.total} type="text" />
                </div>
            </div>
            {(props.errorPay) ? <div className="alert alert-danger mb-3">{t('allFieldsRequired')}</div> : false}
            <button disabled={props.load} className="btn btn-primary mt-1">
                {props.load ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : t('add')}
            </button>
        </form>
    )
}

export default RefundGiftCard;