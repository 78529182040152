import React, {useState, useEffect, useContext} from 'react';
import { IsAuthContext } from 'app/App';
import { useTranslate } from 'react-redux-multilingual'
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Modal} from "react-bootstrap";
import { SubmitForm} from "@gull";
import axios from 'axios'
import { useHistory } from "react-router-dom";

const DestinationOriginModal = (props) =>{
    const t = useTranslate();
    const [apiError,setApiError] = useState(false)
    const [success,setSuccess] = useState(false)
    const [validButton,setValidButton] = useState(false)
    const { register, formState: { errors }, handleSubmit, setValue, control } = useForm();
    const { authParam } = useContext(IsAuthContext);
    const [choiceDockSection,setChoiceDockSection] = useState([]);
    const [choiceCommerceSection,setChoiceCommerceSection] = useState([]);
    const history = useHistory()

    const onSubmit = async (data) => {
        setSuccess(false)
        setApiError(false)
        setValidButton(true)
        if(!data.origin) data.origin=[]
        if(!data.destination) data.destination=[]
        let datas = {...data,...props.filtre}
        const response  = await axios.post(process.env.REACT_APP_API_URL+"api/erp/reassort_auto",datas
            ).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach(interm=>messageError+=t(interm)+'. ')
                messageError=messageError.replace('undefined','');
                setApiError(messageError)
                setValidButton(false)
            }
            else{
                hideModal()
                setValidButton(false)
                history.push('/erp/automatic-restock/'+response.data.data.id)
            }
        }).catch((error) => {
            if(error=='end_session') return;
            if(error.response==undefined){
                setApiError(t('unknowError'))
            }
            else if(error.response.status==403){
                setApiError(t('forbiddenGeneral'))
            }
            else {
                setApiError(t('unknowError'))
            }
            setValidButton(false)
        })
    }


    const handleChange = (e,section,other,setOther) => {
        if(e.target.checked){
            let newArray=other.filter(value=>value.id!=section.id)
            setOther(newArray)
        }
        else{
            let newArray=[...other,section];
            newArray.sort((a,b)=>{
                return a.id - b.id;
            })
            setOther(newArray)
        }
    }

    const hideModal= () => {
        props.setShowModalReassort(false)
        setChoiceDockSection([])
        setChoiceCommerceSection([])
        setValue('origin',[])
        setValue('destination',[])
    }

    const showModal = () => {
        setChoiceDockSection(authParam.sections.sort((a,b)=>{
            return a.id - b.id;
        }));
        setChoiceCommerceSection(authParam.sections.sort((a,b)=>{
            return a.id - b.id;
        }));
    }

    return(<>
        <Modal size="lg" show={props.showModalReassort} onShow={() => showModal()} onHide={() => hideModal()}>
          <Modal.Header closeButton>
                <Modal.Title>{t('generateReassort')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {(authParam.company.to_max==null) ?
                    <div className="alert alert-danger">{t('noToMaxForReassort')}</div>
                    :
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            <div className="col-6">
                                <h5><strong>{t('de')}</strong>:</h5>
                                {(choiceDockSection.length!=0 && choiceCommerceSection.length!=0) ? choiceDockSection.map(value=>{
                                        if(value.type!='ecommerce'){
                                            return(
                                                <Form.Check
                                                {...register("origin[]")}
                                                type="switch"
                                                id="custom-switch"
                                                checked={!choiceCommerceSection.includes(value)}
                                                value={value.id}
                                                label={value.name}
                                                onChange={(e) => handleChange(e,value,choiceCommerceSection,setChoiceCommerceSection)}
                                                key={value.id}
                                                />
                                            )
                                        }
                                    })
                                    :
                                    false
                                }
                            </div>
                            <div className="col-6">
                                <h5><strong>{t('vers')}</strong>:</h5>
                                {(choiceCommerceSection.length!=0 && choiceDockSection.length!=0) ? choiceCommerceSection.map(value=>{
                                        if(value.type!='ecommerce'){
                                            return(
                                                <Form.Check
                                                {...register("destination[]")}
                                                type="switch"
                                                id="custom-switch"
                                                checked={!choiceDockSection.includes(value)}
                                                value={value.id}
                                                label={value.name}
                                                onChange={(e) => handleChange(e,value,choiceDockSection,setChoiceDockSection)}
                                                key={value.id}
                                                />
                                            )
                                        }
                                    })
                                    :
                                    false
                                }
                            </div>
                            <SubmitForm error={apiError} success={success} validButton={validButton}/>
                        </div>
                    </form>
                }
            </Modal.Body>
        </Modal>
    </>)
}

export default DestinationOriginModal;