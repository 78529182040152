export const scrollXDataTable = async (screenWidth,columnNumber) =>{
    var elements = document.getElementsByClassName('react-bootstrap-table'); 
    
    let isMouseDown = false;
    let startX;   
    let scrollLeft;

    let columnMax
    if(+screenWidth>=900 && +screenWidth<=1024){
        columnMax = 4
    }else if(+screenWidth>=1024 && +screenWidth<1200){
        columnMax = 5
    }else if(+screenWidth>=1200 && +screenWidth<1500){
        columnMax = 8
    }else if(+screenWidth>=1500 && +screenWidth<1600){
        columnMax = 12
    }else if(+screenWidth>=1600){
        columnMax = 15
    }

    for (let i = 0; i < elements.length; i++) {
        if (+columnNumber > +columnMax) {
            elements[i].style.width = 'max-content';
        } else {
            elements[i].style.width = '100%';
        }

        // Ajoutez un conteneur pour le tableau qui a une hauteur et une largeur spécifiées pour permettre le défilement.
        let container = document.createElement('div');     
        container.className = 'container-table-scroll';
        container.style.overflowX = 'scroll'; 
        container.style.zIndex = '1';
        container.setAttribute('oncontextmenu', 'return false;');
        
        // Vérifier si le conteneur a déjà été créé pour cet élément, il regardera si un conteneur existe avec les class '.container-table-scroll', si oui il ne recréera plus de conteneur
        if (!elements[i].closest('.container-table-scroll')) {
            elements[i].parentElement.insertBefore(container, elements[i]);
            container.appendChild(elements[i]);
        }

        container.addEventListener('mousedown', (event) => {
            if(event.button==2){
                event.preventDefault();
                isMouseDown = true;
                startX = event.pageX - container.offsetLeft;
                scrollLeft = container.scrollLeft;
                container.style.cursor = 'grabbing';
            }
        });

        container.addEventListener('mouseleave', (event) => {
            isMouseDown = false;    
        });
        
        container.addEventListener('mouseup', (event) => {
            isMouseDown = false;    
            container.style.removeProperty('cursor');
        });
        
        container.addEventListener('mousemove', (event) => {
            if (!isMouseDown) return
            event.preventDefault();
            const x = event.pageX - container.offsetLeft;
            const walk = (x - startX) * 3; //scroll-fast
            container.scrollLeft = scrollLeft - walk;
        });
        
    }
}