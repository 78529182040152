import React, { useState } from 'react';
import {FormatedPrice} from '@gull'
import { useTranslate } from 'react-redux-multilingual'
// Style import
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoneyBillAlt } from '@fortawesome/free-solid-svg-icons';

const CashBackAvailable = (props) =>{
    const t = useTranslate();

    return (
        <div>
            <h4 className='fw-bold text-center'>{t('availableCashBack')}</h4>
            <span className='text-success fs-1 fw-bold d-flex justify-content-center align-items-center mt-4'>
                <FontAwesomeIcon icon={faMoneyBillAlt} />&nbsp;&nbsp;
                <FormatedPrice langRegion={'fr-FR'} devise={props.devise} digit={props.digit} priceToFormat={props.clientFideliteInfo.valeur_cfp} />
            </span>
        </div>
    )
};

export default CashBackAvailable;