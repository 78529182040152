import React, {useState, useEffect,useContext} from 'react';
import axios from 'axios';
import { useTranslate } from 'react-redux-multilingual'
import { HeadInfos, Loading, CreateNotification} from "@gull";
import {notificationsType} from 'app/notificationsType';
import NotifDetailLine from './NotifListComp/NotifDetailLine';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { Modal} from "react-bootstrap";
import {IsAuthContext} from 'app/App';

const useLoad = () =>{
    const [allNotifs,setAllNotifs]=useState([]) 
    const [loadingNotif,setLoadingNotif]=useState(true) 
    const [reloading,setReloading]=useState(false) 
    const [error,setError]=useState(false)
    const [sizeDatas,setSizeDatas]=useState(0)
    const [perPage,setPerPage] = useState(50)
    const [currentPage,setCurrentPage] = useState(1)
    const [nbPage,setNbPage]=useState([]) 
    const [relance,setRelance]=useState(Date.now()) 
    const [notifType,setNotifType]=useState('') 

    useEffect(function () {
        (async function() {
            const response = await axios.get(process.env.REACT_APP_API_URL + "api/alert/get_all/"+currentPage+'/'+perPage+notifType
            ).then(response => {
                setAllNotifs(response.data.datas);
                setSizeDatas(response.data.count)

                let arr = [];//on défnie le nombre de page pour le toggle de page
                let totalPage = Math.ceil(+response.data.count / +perPage)
                for (let i = 1; i <= totalPage; i++) {
                    arr.push(i);
                }
                setNbPage(arr)
            }).catch(error => {
                if(error=='end_session') return;
                if(error.response==undefined){
                    setError('unknowError')
                }
                else if(error.response.status==403){
                    setError('forbiddenGeneral')
                }
                else {
                    setError('unknowError')
                }
            });
            setLoadingNotif(false)
            setReloading(false)
            setTimeout(function () {//on relance le useEffect toutes les minutes
                setRelance(Date.now())
            }, 60000);
        })()
    }, [relance,notifType])
    
    return [allNotifs,error,setError,loadingNotif,setLoadingNotif,sizeDatas,nbPage,setRelance,perPage,setPerPage,currentPage,setCurrentPage,setNotifType,setReloading,reloading]
}


const NotificationsListTemplate = () => {
    const t = useTranslate();
    const [allNotifs,error,setError,loadingNotif,setLoadingNotif,sizeDatas,nbPage,setRelance,perPage,setPerPage,currentPage,setCurrentPage,setNotifType,setReloading,reloading] = useLoad()

    const [allNotificationsFiltre,setAllNotificationsFiltre]=useState([])
    const [nbPageToAffiche,setNbPageToAffiche]=useState([])
    const [showNewNotif,setShowNewNotif]=useState(false) 
    const { authParam } = useContext(IsAuthContext)

    useEffect(function () {
        if(allNotifs.length!=0) setAllNotificationsFiltre(allNotifs)
        setNbPageToAffiche(nbPage.slice(0, 5))
    }, [allNotifs,nbPage])

    const handleChange = (e,name) =>{
        if(name=='filtre'){
            //fonction de filtrage du select sur les types de notification 
            setReloading(true)
            setNotifType(e.target.value)
        }else{
            setPerPage(e.target.value)
            setRelance(Date.now())//Pour relancer l'appel à l'api quand on change la pagination
        }
    }

    const handleClick = () =>{
        setShowNewNotif(true)
    }

    const changePagination = (btnValue) =>{
        switch (btnValue) {
            case 'start':
                setCurrentPage(1)
                setNbPageToAffiche(nbPage.slice(0, 5))
            break;
            case 'previous':
                setCurrentPage(currentPage-1)
            break;
            case 'next':
                setCurrentPage(currentPage+1)
            break;
            case 'end':
                setCurrentPage(nbPage[nbPage.length - 1])
                setNbPageToAffiche(nbPage.slice(-5))
            break;
            default :  
                //on récuère l'index de la currentPage sur le tableau nbPage     
                const index = nbPage.findIndex((page) => page === btnValue);
                let pagination

                if(index == 0){
                    //si on est va sur la page 1
                    pagination = nbPage.slice(0, 5)

                }else if(index == 1 ){
                    //si on est va sur la page 2
                    pagination = [nbPage[index-1],nbPage[index]]
                    if(nbPage[index+1]) pagination.push(nbPage[index+1])
                    if(nbPage[index+2]) pagination.push(nbPage[index+2])
                    if(nbPage[index+3]) pagination.push(nbPage[index+3])

                }else if(index == 2 ){
                    //si on est va sur la page 3
                    pagination = [nbPage[index-2],nbPage[index-1],nbPage[index]]
                    if(nbPage[index+1]) pagination.push(nbPage[index+1])
                    if(nbPage[index+2]) pagination.push(nbPage[index+2])

                }else if(index == nbPage.length - 1 ){
                    //si on est va sur la derniere page
                    pagination = [nbPage[index-4],nbPage[index-3],nbPage[index-2],nbPage[index-1],nbPage[index]]

                }else if(index == nbPage.length - 2 ){
                    //si on est va sur l'avant derniere page
                    pagination = [nbPage[index-3],nbPage[index-2],nbPage[index-1],nbPage[index]]
                    if(nbPage[index+1]) pagination.push(nbPage[index+1])

                }else{
                    pagination = [nbPage[index-2],nbPage[index-1],nbPage[index]]
                    if(nbPage[index+1]) pagination.push(nbPage[index+1])
                    if(nbPage[index+2]) pagination.push(nbPage[index+2])
                }
                setNbPageToAffiche(pagination)
                setCurrentPage(btnValue)
        }
        setRelance(Date.now())//Pour relancer l'appel à l'api quand on change de page
    }

    let btnSize = (window.screen.width<500) ? 'btn btn-sm btn-outline-primary' : 'btn btn-outline-primary'
    let btnSizeActive = (window.screen.width<500) ? 'btn btn-sm btn-primary' : 'btn btn-primary'

    if(loadingNotif){
        return(
            <Loading />
        )
    }

    return (<>
            <Modal size="lg" show={showNewNotif} onHide={() => setShowNewNotif(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('createNotification')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CreateNotification authParam={authParam} setShowModal={setShowNewNotif} setRelance={setRelance} />
                </Modal.Body>
            </Modal>
            <HeadInfos titre={<span>Notifications <button className="btn btn-sm btn-primary" onClick={()=>handleClick()}><FontAwesomeIcon icon={faEdit} /></button></span>} />
            <div className="row notifDetail">
                <div className="mb-4 col-xs col-sm-8 col-md-6 col-lg-4">
                    <select disabled={allNotifs.length==0} className='form-control' onChange={(e) => handleChange(e,'filtre')}>
                        <option value="">{t('allNotifications')}</option>
                        {
                            Object.keys(notificationsType).map(value=>(
                                <option key={value} value={'/'+value}>{t(value)}</option>
                            ))
                        }
                    </select>
                </div>

                {(error) ? <div className="alert alert-danger mb-4" dangerouslySetInnerHTML={{ __html: t(error) }}></div> : false}
                
                {(reloading) ? 
                    <span className='d-flex justify-content-center'><div className="spinner-border spinner-border-sm"></div></span>
                :
                    allNotificationsFiltre.length!=0 ?
                        <>
                            <div className='mb-5'>
                                {allNotificationsFiltre.map((value,ind)=>{
                                    let stateNotif = (value.vu) ? '' : 'fw-bold colorBlack'
                                    let description = value.titre+': '+value.message.replace(/<[^>]*>/g, '').toString()
                                    return(
                                        <NotifDetailLine 
                                            key={value.id}
                                            stateNotif={stateNotif}
                                            description={description}
                                            value={value}
                                            allNotificationsFiltre={allNotificationsFiltre}
                                            setAllNotificationsFiltre={setAllNotificationsFiltre}
                                            index={ind}
                                            setError={setError}
                                            setLoadingNotif={setLoadingNotif}
                                        />
                                    )
                                })}
                            </div>
                            <div className="row">
                                <div className='col'>
                                    <select className='form-control' defaultValue={perPage} style={{width:'50px'}} onChange={(e)=>handleChange(e,'perpage')}>
                                        <option value={10}>10</option>
                                        <option value={25}>25</option>
                                        <option value={30}>30</option>
                                        <option value={50}>50</option>
                                    </select>
                                </div>
                                <div className="col text-end m-0 p-0" style={{width:'80%'}}>
                                    <div className='btn-group' role="group">
                                        {(currentPage>3) ? <button className={btnSize} onClick={()=>changePagination('start')}>{"<<"}</button> : false}
                                        {(currentPage>1) ? <button className={btnSize} onClick={()=>changePagination('previous')}>{"<"}</button> : false}
                                        {nbPageToAffiche.map(value=>{
                                            return(
                                                <button key={value} className={(currentPage==value) ? btnSizeActive : btnSize} onClick={()=>changePagination(value)}>{value}</button>
                                            )
                                        })}
                                        {(currentPage <= nbPage[nbPage.length - 2]) ? <button className={btnSize} onClick={()=>changePagination('next')}>{">"}</button> : false}
                                        {(currentPage <= nbPage[nbPage.length - 3]) ? <button className={btnSize} onClick={()=>changePagination('end')}>{">>"}</button> : false}
                                    </div>
                                </div>
                            </div>
                            <span className='mt-2'>{`Showing rows ${currentPage} to ${(perPage<sizeDatas) ? perPage : sizeDatas} of ${sizeDatas}`}</span>
                        </>
                    :
                        <span className='text-center mt-3'>{t('noNotifications')}</span>
                }
            </div>
        </>
    )
}

export default NotificationsListTemplate;