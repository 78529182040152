import React, { useState,useEffect,useRef } from 'react';
import {InpDataList} from '@gull'
import { useTranslate } from 'react-redux-multilingual'
import {notificationsType} from 'app/notificationsType';
import axios from 'axios'
import swal from "sweetalert2";
import { Badge} from "react-bootstrap";
import { useForm } from "react-hook-form";
// Style import
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faPaperPlane} from '@fortawesome/free-solid-svg-icons';

const useLoad = (authParam) =>{
    const t = useTranslate();
    const [allUsers,setAllUsers]=useState([]) 
    const [loading,setLoading]=useState(true) 

    useEffect(function () {
        (async function() {
            const response = await axios.get(process.env.REACT_APP_API_URL+"api/company/"+authParam.company.id
            ).then((response) => {
                setLoading(false)
                setAllUsers(response.data.users)
            }).catch((error) => {
                if(error=='end_session') return;
                if(error.response && error.response.status==403){
                    swal.fire(t('forbiddenGeneral'), "", "error");
                }
                else {
                    swal.fire(t('unknowError'), "", "error");
                }
                setLoading(false)
            })
        })()
    }, [])

    return [allUsers,loading]
}

const EachDataFilter = (props) => {
   
    const inputRef = useRef('')
    const listRef = useRef('')
    const [datas,setDatas] = useState(props.datas)
        
    const handleClickFilter = (id,label,nameInput) => {
        props.setValueInput('')
        props.setIds([...props.ids,{name:label,id:id}])
    }

    const handleSearch = (e,declenchement) => {
        props.setValueInput(e.target.value);
        let ajour = props.datas.filter((value)=>value.name.toLowerCase().includes(e.target.value.toLowerCase()))
        setDatas(ajour)
    }

    const handleFocus = () => {
        setDatas(props.datas)
        listRef.current.classList.remove("d-none");
    }

    const handleBlur = () => {
        listRef.current.classList.add("d-none")
    }

    return(
        <InpDataList
            placeholder="-- Select --"
            value={props.valueInput}
            onClick={handleClickFilter}
            onChange={handleSearch}
            datas={datas}
            declenchement={0}
            listRef={listRef}
            inputRef={inputRef}
            onFocus={handleFocus}
            onBlur={handleBlur}
            inputName={props.inputName}
        />
    )
}

const CreateNotification = (props) =>{
    const t = useTranslate();
    const [allUsers,loading] = useLoad(props.authParam)
    const [userIds,setUserIds]=useState([])
    const [valueInput,setValueInput] = useState('')
    const [validButton,setValidButton]=useState(false) 
    const [error,setError]=useState(false) 
    const [success,setSuccess]=useState(false) 
    const [usersForSelect,setUsersForSelect]=useState([])
    const [disabled,setDisabled]=useState(false) 
    const { register, formState: { errors }, handleSubmit, reset, setValue } = useForm();

    useEffect(function () {
        let arr = []
        let filteredUserForSelect = []
        userIds.map(value=>{
            arr.push(value.id)
        })
        allUsers.map(value=>{
            if(value.id!=props.authParam.userid){
                if(!arr.includes(value.id)) filteredUserForSelect.push(value)
            }
        })
        setUsersForSelect(filteredUserForSelect)

        if(props.from=='alertSaleOrder'){
            //Si on crée une notification depuis le formulaire de commande client, on renseigne le type 
            setValue('type','salesOrder')
            setDisabled(true)
        }
    }, [allUsers,userIds])

    const removeUser = (id) =>{
        let newDatas = userIds.filter(value=>value.id!=id)
        setUserIds(newDatas)
    }

    const onSubmit= async (data) => {
        setValidButton(true)
        setError(false)
        let arrId = []

        let obj ={
            user_id:props.authParam.userid,
            users:arrId
        }

        Object.entries(data).map(([key,value])=>
            obj[key]=value
        )
        userIds.map(value=>
            arrId.push(value.id)
        )

        const callapi  = await axios.post(process.env.REACT_APP_API_URL+"api/alert", obj
        ).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach(interm=>messageError+=t(interm)+'. ')
                messageError=messageError.replace('undefined','');
                setError(messageError);
            }else{
                setSuccess(true)
                setUserIds([])
                if(props.setRelance) props.setRelance(Date.now())
                reset()

                setTimeout(()=>{
                    props.setShowModal(false)
                },1000)
            }
        }).catch((error) => {
            if(error=='end_session') return;
            if(!error.response){
                setError(t('unknowError'));
            }
            else if(error.response.status===403){
                setError(t('forbiddenGeneral'));
            }
            else {
                setError(t('unknowError'));
            }
        })
        setValidButton(false)
    }

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group">
                    <label htmlFor="type">Type :</label>
                    <select disabled={disabled} className='form-control' {...register('type',{required:true})}>
                        <option value="">-- Select --</option>
                        {Object.keys(notificationsType).map(value=>{
                            return(
                                <option key={value} value={value}>{t(value)}</option>
                            )
                        })}
                    </select>
                    {errors.type?.type === 'required' && (
                        <div className="text-danger mt-1 ml-2">
                            {t('fieldRequired')}
                        </div>
                    )}
                </div>

                <div className="form-group">
                    <label htmlFor="type">{t('a')}</label>
                    {(loading) ?
                        <span>&nbsp;<div className="spinner-border spinner-border-sm mt-2"></div></span>
                    :
                        (allUsers.length!=0) ?
                            <EachDataFilter 
                                datas={usersForSelect} 
                                ids={userIds}
                                setIds={setUserIds}
                                valueInput={valueInput}
                                setValueInput={setValueInput}
                            /> 
                        :
                            false
                    }

                    <div className="mb-2">
                        {(userIds.length!=0) ? 
                            userIds.map(value=>(
                                <span key={value.id} >
                                    <Badge bg="primary" text="white">{value.name}&nbsp;&nbsp;<span className="text-danger pointer" onClick={()=>removeUser(value.id)}>{<FontAwesomeIcon icon={faTimesCircle} />}</span></Badge>&nbsp;&nbsp;
                                </span>
                            ))
                        :
                            false
                        }
                    </div>
                </div>
                
                {(userIds.length!=0) ?
                    <>
                        <div className="form-group">
                            <label htmlFor="type">{t('title')}</label>
                            <input autoComplete='off' type="text" className='form-control' {...register('titre',{required:true})}/>
                            {errors.titre?.type === 'required' && (
                                <div className="text-danger mt-1 ml-2">
                                    {t('fieldRequired')}
                                </div>
                            )}
                        </div>

                        <div className="form-group">
                            <label htmlFor="type">{t('chat')}</label>
                            <textarea rows="5" autoComplete='off' type="text" className='form-control' {...register('message',{required:true})}></textarea>
                            {/* <input autoComplete='off' type="text" className='form-control' {...register('message',{required:true})}/> */}
                            {errors.message?.type === 'required' && (
                                <div className="text-danger mt-1 ml-2">
                                    {t('fieldRequired')}
                                </div>
                            )}
                        </div>

                        {(error) ? <div className="alert alert-danger mt-3" dangerouslySetInnerHTML={{ __html: error }}></div> : false}
                        {(success) ? <div className="alert alert-success mt-3">{t('successOperation')}</div> : false}

                        <button disabled={validButton} className="btn btn-primary mt-3">
                            {validButton ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : <FontAwesomeIcon icon={faPaperPlane} />}
                        </button>
                    </>
                :
                    false
                }
            </form>
        </div>
    )
};

export default CreateNotification;