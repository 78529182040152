import React,{useState,useEffect,useRef} from "react";
import { CardPerso,ArticleBarSearch,LineItemInventory,Loading} from '@gull';
import { useTranslate } from 'react-redux-multilingual'
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import axios from 'axios'
import swal from "sweetalert2";
import InpScanBinsAdmin from '../InpScanBinsAdmin'
import { Badge } from "react-bootstrap";
import HeadOptionsInventory from '../HeadOptionsInventory'

const useSearchItem = (zoneid,zoneinventoryid,rhid) => {
    const t =useTranslate()
    const [loadingSearchItem,setLoadingSearchItem]=useState(false)
    const [listSearchItem,setListSearchItem]=useState([])
    const [datas,setDatas] = useState(listSearchItem)
    const searchInput = useRef('')
    const [binsList,setBinsList]=useState([])
    const [loading,setLoading]=useState(true)
    const [zoneWithBins,setZoneWithBins]=useState(false)
    const [listBinsAlreadyScanned,setListBinsAlreadyScanned]=useState([])
    const [relance,setRelance]=useState(Date.now())

    const loadItems = async (inputValue) => {
        setLoadingSearchItem(true)
        const response = await axios.get(process.env.REACT_APP_API_URL+"api/erp/article/name/"+inputValue
        ).then((response) => {
            setLoadingSearchItem(false)
            setListSearchItem(response.data.datas)
            let ajour = response.data.datas.filter((value)=>value.name.replace(/\u00A0/g,' ').toLowerCase().includes(searchInput.current.value.replace(/\u00A0/g,' ').toLowerCase()))
            setDatas(ajour)
        }).catch((error) => {
            if(error=='end_session') return;
            setLoadingSearchItem(false)
            let errorName = (error.response==undefined) ? 'unknowError' : (error.response.status==403) ? 'forbiddenGeneral' : 'unknowError'
            swal.fire(t(errorName), "", "error");
        })
    }

    useEffect(function () {
        (async function() {
            const response = await axios.get(process.env.REACT_APP_API_URL+"api/erp/zone/"+zoneid
            ).then((response) => {
                setBinsList(response.data.bins)
                if(response.data.havebins==1){
                    //si la zone à des bins
                    setZoneWithBins(true)
                }else{
                    setZoneWithBins(false)
                }
            }).catch((error) => {
                if(error=='end_session') return;
                swal.fire(t('unknowError'), "", "error");
            })
        })()
    }, [])

    useEffect(function () {
        (async function() {
            const response = await axios.get(process.env.REACT_APP_API_URL+"api/rh/get_rh_inventaire_division_by_id/"+rhid+'/'+zoneinventoryid
            ).then((response) => {
                if(!response.data.valid){
                    let interms='';
                    var keys = Object.keys(response.data.errors);
                    for(var i = 0; i < keys.length; i++){
                        interms+=response.data.errors[keys[i]].join(',')+','
                    }
                    interms=interms.split(',')
                    let messageError='';
                    interms.forEach(interm=>messageError+=t(interm)+'. ')
                    messageError=messageError.replace('undefined','');
                    swal.fire(messageError, "", "error");

                }else{   
                    let arrData=[]
                    if(response.data.data.inventaire_count.length!=0 && response.data.data.inventaire_count[0].bins_id!=null){
                        response.data.data.inventaire_count.map(value=>{
                            let binsNum = (value.bins.fifth!=null) ? value.bins.first+'-'+value.bins.second+'-'+value.bins.third+'-'+value.bins.fourth+'-'+value.bins.fifth : (value.bins.fourth!=null) ? value.bins.first+'-'+value.bins.second+'-'+value.bins.third+'-'+value.bins.fourth : (value.bins.third!=null) ? value.bins.first+'-'+value.bins.second+'-'+value.bins.third : (value.bins.second!=null) ? value.bins.first+'-'+value.bins.second : value.bins.first

                            //on vérifie que le tableau arrData contient pas deja la bins 
                            if(!arrData.includes(binsNum)) arrData.push(binsNum)
                        })
                    }
                    setListBinsAlreadyScanned(arrData)
                    setLoading(false)
                }
            }).catch((error) => {
                if(error=='end_session') return;
                swal.fire(t('unknowError'), "", "error");
            })
            setLoading(false)
        })()
    }, [relance])

    return [loadingSearchItem,setLoadingSearchItem,loadItems,listSearchItem,datas,setDatas,searchInput,loading,binsList,zoneWithBins,setZoneWithBins,,listBinsAlreadyScanned,setRelance]
}

const ZoneInventoryScan = (props) => {
    const t =useTranslate()
    const { posid,id,zoneid,zoneinventoryid,rhid} = useParams();
    const [loadingSearchItem,setLoadingSearchItem,loadItems,listSearchItem,datas,setDatas,searchInput,loading,binsList,zoneWithBins,setZoneWithBins,,listBinsAlreadyScanned,setRelance]=useSearchItem(zoneid,zoneinventoryid,rhid)
    const [inputScan,setInputScan]=useState('')
    const [itemsScannedList,setItemsScannedList]=useState({})
    const [validButton,setValidButton]=useState(false)
    const [blur,setBlur]=useState(Date.now())
    const [success,setSuccess]=useState(false)
    const history = useHistory();

    const [binsNumber, setBinsNumber]=useState('')
    const [currentBinsId,setCurrentBinsId]=useState(false)

    useEffect(function () {
        if(searchInput.current){
            setTimeout(()=>{
                searchInput.current.focus()
            },300)
        }
    }, [searchInput])

    useEffect(()=>{
        setTimeout(()=>{
            if(searchInput.current){
                searchInput.current.focus()
            }
        },500)

    },[blur])

    const selectBinsForInventory = async (e) =>{
        e.preventDefault();
        
        //Si l'inventaire par zone contient des bins, on vérifie si la bins scanné fait partie de l'inventaire attribué au rh
        if(binsList.length!=0){
            let binExist =binsList.find(value=>{
                let binsNum = (value.fifth!=null) ? value.first+'-'+value.second+'-'+value.third+'-'+value.fourth+'-'+value.fifth : (value.fourth!=null) ? value.first+'-'+value.second+'-'+value.third+'-'+value.fourth : (value.third!=null) ? value.first+'-'+value.second+'-'+value.third : (value.second!=null) ? value.first+'-'+value.second : value.first

                return binsNum == binsNumber
            })

            if(binExist){
                //si la bins fait partie de la liste
                //on vérifie si la bins a déjà eu un comptage 
                if(listBinsAlreadyScanned.length!=0 && listBinsAlreadyScanned.includes(binsNumber)){
                    //si il y a deja eu un comptage sur la bins
                    swal
                    .fire({
                        title: t('confirmSecondCount')+" ?",
                        icon: "warning",
                        type: "question",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: t('btnYes'),
                        cancelButtonText: t('btnNo'),
                    })
                    .then((result) => {
                        if (result.value) {
                            setCurrentBinsId(binExist.id)
                            setZoneWithBins(false)//on passe zoneWithBins à false pour pouvoir afficher le page de scan des articles
                            setBlur(Date.now())
                        }else{
                            setZoneWithBins(true)
                        }
                    });
                }else{
                    setCurrentBinsId(binExist.id)
                    setZoneWithBins(false)//on passe zoneWithBins à false pour pouvoir afficher le page de scan des articles
                    setBlur(Date.now())
                }
                
            }else{
                swal.fire(t('binsNotExistInInventory'), "", "error"); 
                setCurrentBinsId(false)
            }
        }

        setBinsNumber('')
    }

    const addNewItem = async (e,articleid,inputname,articleBar) =>{
        let article = (e) ? e.target.value : articleid
        let urlArticle = (e) ? "api/erp/article/reference/" : "api/erp/article/one/"
        
        if(article){
            if(itemsScannedList[article]){//on vérifie si l'article existe deja dans la liste par l'id si celui ci à été rajouté par la recherche manuelle
                let currentItem = itemsScannedList[article]
                currentItem.qte = +currentItem.qte + 1

                setItemsScannedList({[article]:currentItem,...itemsScannedList})

            }else if(Object.values(itemsScannedList).find((value)=>value.reference==article)){//on vérifie si l'article existe deja dans la liste par sa reference si celui ci à été rajouté scan de code barre
                let currentItem = itemsScannedList[Object.values(itemsScannedList).find((value)=>value.reference==article).id]
                currentItem.qte = +currentItem.qte + 1

                setItemsScannedList({[Object.values(itemsScannedList).find((value)=>value.reference==article).id]:currentItem,...itemsScannedList})

            }else{//si il n'a pas encore été scanné
                setLoadingSearchItem(true)
                const response = await axios.get(process.env.REACT_APP_API_URL+urlArticle+article
                ).then((response) => {
                    if(response.data.hasOwnProperty('valid') && !response.data.valid){
                        swal.fire(t('articleNoReferenceFind'), "", "error");
                    }else{   
                        if(e){
                            setItemsScannedList({[response.data.data.article.id]:{article_name:response.data.data.article.name,id:response.data.data.article.id,reference:response.data.data.article.reference,qte:1},...itemsScannedList})
                        }else{
                            setItemsScannedList({[response.data.data.id]:{article_name:response.data.data.name,id:response.data.data.id,reference:response.data.data.reference,qte:1},...itemsScannedList})
                        }
                        
                    }
                }).catch((error) => {
                    if(error=='end_session') return;
                    let errorName = (error.response==undefined) ? 'unknowError' : (error.response.status==403) ? 'forbiddenGeneral' : 'unknowError'
                    swal.fire(t(errorName), "", "error");
                })
                setLoadingSearchItem(false)
            }
            
        }
        setBlur(Date.now())
        setInputScan('')
    }

    const validateInventory = async () =>{
        setValidButton(true)

        let paramBins = (currentBinsId) ? '/'+currentBinsId : ''

        let data = {}  

        if(Object.keys(itemsScannedList).length!=0){
            let itemArr = []
            let qteArr = []
            Object.entries(itemsScannedList).map(([key,value])=>{
                itemArr.push(value.id)
                qteArr.push(value.qte)
            })

            data['article']=itemArr
            data['total']=qteArr
        }

        const response = await axios.post(process.env.REACT_APP_API_URL+"api/wms/inventaire_count/comptage_zone/"+zoneinventoryid+'/'+rhid+paramBins,data
        ).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach(interm=>messageError+=t(interm)+'. ')
                messageError=messageError.replace('undefined','');
                swal.fire(messageError, "", "error");

            }else{ 
                setItemsScannedList({})
                setValidButton(false)
                if(currentBinsId){
                    //si l'inventaire par zone contient des bins, apres avoir scanné le contenu de la bins 
                    //on passe zoneWithBins à true pour retourner au scan de la bins afin de continuer l'inventaire de la zone
                    setZoneWithBins(true)
                    setRelance(Date.now())
                    setSuccess(true) //si la zone contient des bins, on affiche un message de success apres validation et on reste sur la page pour continuer l'inventaire

                }else{//si la zone ne contient pas de bins, on renvoie sur la page de l'inventaire après validation
                    if(props.module=='pos'){
                        history.push("/pos/"+posid+"/inventory/zone/"+id+'/'+zoneid)
                    }else{{
                        history.push("/erp/inventory/zone/"+id+'/'+zoneid)
                    }}
                }
            }
        }).catch((error) => {
            if(error=='end_session') return;
            let errorName = (error.response==undefined) ? 'unknowError' : (error.response.status==403) ? 'forbiddenGeneral' : 'unknowError'
            swal.fire(t(errorName), "", "error");
        })
        setValidButton(false)
        setTimeout(()=>{
            setSuccess(false)
        },1000)
    }

    if(loading){
        return(<Loading></Loading>)
    }
    
    return (<div style={(props.module=='pos') ? {marginTop:'-30px'} : {marginTop:'0'}}>
        <CardPerso header={
            <HeadOptionsInventory 
                title='manualCounting' 
                btnValid={true} 
                btnReturn={true} 
                btnReturnHome={true}
                type='zone' 
                id={id} 
                zoneid={zoneid} 
                posid={posid} 
                validateInventory={validateInventory}
                itemsScannedList={itemsScannedList}
                validButton={validButton}
                zoneWithBins={zoneWithBins}
                module={props.module}
            />}>
            <div className="row ">        
                
                    {(zoneWithBins) ? 
                        <div className="col-10 offset-1">
                            <InpScanBinsAdmin 
                                actionScan={selectBinsForInventory} 
                                binsNumber={binsNumber}
                                setBinsNumber={setBinsNumber}
                            />
                            {(listBinsAlreadyScanned.length!=0) ? 
                                listBinsAlreadyScanned.map((bins,ind)=>{
                                    
                                    return(
                                        <span key={ind}>
                                            <Badge bg="warning" text="dark" className="mb-1">{bins}</Badge>&nbsp;
                                        </span>
                                    )
                                })
                            :
                                false
                            }
                        </div>
                    :
                        <div className="row offset-1">
                            <ArticleBarSearch 
                                loadingSearchItem={loadingSearchItem}
                                loadItems={loadItems}
                                listSearchItem={listSearchItem}
                                datas={datas}
                                setDatas={setDatas}
                                valueInput={inputScan}
                                setValueInput={setInputScan}
                                handleSubmit={addNewItem}
                                searchInput={searchInput}
                                noBlur={false}
                            />
                            {(success) ? <div className="alert alert-success mt-3">{t('successOperation')}</div> : false}
                        </div>
                    }
                
                
                <div className='row mx-auto mt-3 d-flex justify-content-center'>
                    {(Object.keys(itemsScannedList).length!=0) ? 
                        Object.entries(itemsScannedList).map(([key,value],ind)=>{
                            let style = (ind%2 == 0) ? 'stripeBg stripePadding px-2 col-8' : 'stripePadding px-2 col-8';
                            return(
                                <LineItemInventory
                                    key={key}
                                    itemValue={value}
                                    itemsList={itemsScannedList}
                                    setItemsList={setItemsScannedList}
                                    style={style}
                                    setBlur={setBlur}
                                />
                            )
                        })
                    :
                        false
                    }
                </div>
            </div>
        </CardPerso>
        </div>
    );
    

}

export default ZoneInventoryScan;
