import React from "react";
import {  Link } from "react-router-dom";
import { useTranslate } from 'react-redux-multilingual';
import { OverlayTrigger, Tooltip } from "react-bootstrap";

// Style import
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';

const  ProfilActionButton = (props) => {
    const t = useTranslate();

    return (
        <OverlayTrigger
            placement="bottom"
            overlay={
                <Tooltip id={`tooltip-${props.index}`}>
                    {t('btnSeeProfil')}
                </Tooltip>
            }
        >
            <Link to={props.path} style={{cursor:'Pointer', color:'#FF8919'}}><FontAwesomeIcon icon={faEye} /></Link>
        </OverlayTrigger>  
    )
}

export default ProfilActionButton;