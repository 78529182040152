import React, {useState,useEffect} from 'react';
import { useTranslate } from 'react-redux-multilingual'
import axios from 'axios'

const useLoad = (document) =>{
    const t = useTranslate();
    const [loading,setLoading]=useState(true)
    const [apiError,setApiError] = useState(false)
    const [emailConfig,setEmailConfig] = useState(false) 

    useEffect(function () {
        (async function() {
            const response = await axios.get(process.env.REACT_APP_API_URL+"api/mail/get_all_mail_config"
            ).then((response) => {
                if(!response.data.valid){
                    let interms='';
                    var keys = Object.keys(response.data.errors);
                    for(var i = 0; i < keys.length; i++){
                        interms+=response.data.errors[keys[i]].join(',')+','
                    }
                    interms=interms.split(',')
                    let messageError='';
                    interms.forEach(interm=>messageError+=t(interm)+'. ')
                    messageError=messageError.replace('undefined','');
                    setApiError(messageError)
                    setLoading(false)
                }else{
                    let confMail 
                    if(document.other_company!=null){
                        confMail = response.data.datas.find(value=>value.other_company_id==document.other_company.id)
                    }else{
                        confMail = response.data.datas.find(value=>value.other_company_id==null && value.section_id==null)
                    }
                    if(confMail) setEmailConfig(confMail)
                    setLoading(false)
                }
            }).catch((error) => {
                if(error=='end_session') return;
                let errorName = (error.response==undefined) ? 'unknowError' : (error.response.status==403) ? 'forbiddenGeneral' : 'unknowError'
                setApiError(t(errorName))
                setLoading(false)
            })
        })()
    }, [])

    return [loading,apiError,setApiError,emailConfig]
}

const SendEmailForm = (props) => {
    const t = useTranslate();
    const [loading,apiError,setApiError,emailConfig] = useLoad(props.document)
    const [validButton,setValidButton] = useState(false)
    const [inputValue,setInputValue] = useState('')

    const handleChange = (e) => {
        setInputValue(e.target.value)
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setApiError(false)

        let allEmail = inputValue.split(',')

        let mailNoGood=true
        allEmail.forEach(value=>{
            if(!value.includes('@')) mailNoGood=false
        })

        if(!mailNoGood){
            setApiError(t('errDestinataireEmail'))
        }else{
            setValidButton(true)

            let obj = {
                destinataires:allEmail,
                url:process.env.REACT_APP_FREEBIRD_URL_EMAIL+"session/document/download/"+props.document.id+"/"+props.document.token
            }
            const response  = await axios.post(process.env.REACT_APP_API_URL+"api/mail/mail_b2b/"+props.document.id, obj
            ).then((response) => {
                if(!response.data.valid){
                    let interms='';
                    var keys = Object.keys(response.data.errors);
                    for(var i = 0; i < keys.length; i++){
                        interms+=response.data.errors[keys[i]].join(',')+','
                    }
                    interms=interms.split(',')
                    let messageError='';
                    interms.forEach(interm=>messageError+=t(interm)+'. ')
                    messageError=messageError.replace('undefined','');
                    setApiError(messageError)
                }
                else{
                    props.closeModal()
                }
            }).catch((error) => {
                if(error=='end_session') return;
                if(error.response==undefined){
                    setApiError(t('unknowError'))
                }
                else if(error.response.status==403){
                    setApiError(t('forbiddenGeneral'))
                }
                else {
                    setApiError(t('unknowError'))
                }
            })
            setValidButton(false)
            setInputValue('')
        }
    };

    return(<>
        {loading ?
            <span className='d-flex justify-content-center mt-3'><div className="spinner-border spinner-border-sm"></div></span>
        :
            <>
                {!emailConfig && <div className='alert alert-danger mb-2 row '><span className='text-center fw-bold'>{t('noEmailAdressConfigured')}</span></div>}
                <form onSubmit={(e)=>handleSubmit(e)}>
                    <div className="form-group">
                        <label>{t('recipient')+'(s)*'}</label>
                        <input disabled={!emailConfig} type="text" value={inputValue} placeholder='dupont@emailexemple.com,david@emailexemple.com,...' className='form-control' onChange={(e)=>handleChange(e)} />
                        <label>{t('addMoreRecipientsMsg')}</label>
                    </div>
                    {(apiError) ? <div className="alert alert-danger mt-3" dangerouslySetInnerHTML={{ __html: apiError }}></div> : false}
                    <button disabled={!emailConfig || validButton || inputValue==''} className="btn btn-primary mt-3">
                        {validButton ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : t('send')}
                    </button>
                </form>
            </>
        }
    </>
    )
}

export default SendEmailForm;