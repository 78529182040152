import React, {useState, useEffect, useRef, lazy, Suspense} from "react";
import { useTranslate } from 'react-redux-multilingual';
// import {CardInfoArticle} from "@gull";
import axios from 'axios'
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
const CardInfoArticle = lazy (() => import("../../../@gull/components/cards/CardInfoArticle"));

const useLoad = (wmsid) => {
    const [apiError,setApiError] = useState(false)
    const [article,setArticle]=useState({})
    const [show,setShow] = useState(false)
    const [loading,setLoading] = useState(false)

    useEffect(function () {
        (async function() {
            if(show){
                setApiError(false)
                setLoading(true)
                const response = await axios.get(process.env.REACT_APP_API_URL+"api/erp/article/article_id_in_section/"+show+'/'+wmsid
                ).then((response) => {
                    if(!response.data.valid){
                        setLoading(false)
                        setApiError('articleNoReferenceFind')
                    }else{
                        setArticle(response.data.data)
                        setLoading(false)
                    }
                }).catch((error) => {
                    if(error=='end_session') return;
                    if(error.response==undefined){
                        setApiError('unknowError')
                    }
                    else if(error.response.status==403){
                        setApiError('forbiddenGeneral')
                    }
                    else {
                        setApiError('unknowError')
                    }
                    setLoading(false)
                })
            }
        })()
    }, [show])

    return [loading,apiError,article,setArticle,setShow,show]
}

const BtnItemBlockInfo = (props) =>{
    const t = useTranslate();
    const [loading,apiError,article,setArticle,setShow,show] =useLoad(props.wmsid)
    const [editUpc,setEditUpc]=useState(false)
    const inputRef = useRef('')

    const handleClick = () => {
        props.setHideInput(true)
        setShow(props.articleId)
    };

    const closeModal = () => {
        props.setHideInput(false)
        setShow(false)
    };
    
    return(
        <div>
            <Modal show={show} onHide={() => closeModal()}>
                <Modal.Header closeButton>
                    <Modal.Title>{Object.keys(article).length!=0 ? article.article.name : ''}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loading ?
                        <span className="d-flex justify-content-center mt-1"><div className="spinner-border spinner-border-sm"></div></span> 
                    :
                        <span>
                            {(apiError) &&
                                <p className="text-danger text-center mt-3"><strong>{t(apiError)}</strong></p>
                            }
                            {(Object.keys(article).length!=0) ?
                                <span className="position-relative">
                                    <Suspense>
                                        <CardInfoArticle 
                                            article={article}
                                            editUpc={editUpc}
                                            inputRef={inputRef}
                                            setEditUpc={setEditUpc}
                                            setArticle={setArticle}
                                        />
                                    </Suspense>
                                </span>
                            :
                                false}
                        </span>
                    }
                </Modal.Body>
            </Modal>
            <FontAwesomeIcon 
                icon={faEye} 
                className="text-info pointer"
                onClick={()=>handleClick()}
            />
        </div>
    )
}

export default BtnItemBlockInfo;