import React, { useState,useEffect,useContext } from 'react';
import {SelectDataListSearch} from '@gull'
import { useTranslate } from 'react-redux-multilingual'
import axios from 'axios'
import swal from "sweetalert2";
import { Badge} from "react-bootstrap";
// Style import
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faPaperPlane} from '@fortawesome/free-solid-svg-icons';
import { IsAuthContext } from 'app/App';

const useLoad = (authParam) =>{
    const t = useTranslate();
    const [allUsers,setAllUsers]=useState([]) 
    const [loading,setLoading]=useState(true) 

    useEffect(function () {
        (async function() {
            const response = await axios.get(process.env.REACT_APP_API_URL+"api/company/"+authParam.company.id
            ).then((response) => {
                setLoading(false)
                setAllUsers(response.data.users)
            }).catch((error) => {
                if(error=='end_session') return;
                if(error.response && error.response.status==403){
                    swal.fire(t('forbiddenGeneral'), "", "error");
                }
                else {
                    swal.fire(t('unknowError'), "", "error");
                }
                setLoading(false)
            })
        })()
    }, [])

    return [allUsers,loading]
}

const AddUserToConvers = (props) =>{
    const t = useTranslate();
    const { authParam } = useContext(IsAuthContext);
    const [allUsers,loading] = useLoad(authParam)
    const [userIds,setUserIds]=useState([])
    const [valueInput,setValueInput] = useState('')
    const [validButton,setValidButton]=useState(false) 
    const [userForSelect,setUserForSelect]=useState([]) 

    useEffect(function () {
        let arr = []
        let userSelect = []
        props.notificationDetail.alert_vu.map(value=>
            arr.push(value.user.id)
        )
        allUsers.map(value=>{
            if(!arr.includes(value.id)) userSelect.push(value) 
        })

        setUserForSelect(userSelect)
    }, [props.notificationDetail,allUsers])
    

    const removeUser = (id) =>{
        let newDatas = userIds.filter(value=>value.id!=id)
        setUserIds(newDatas)
    }

    const addNewUser = async () => {
        setValidButton(true)
    
        const callapi  = await axios.put(process.env.REACT_APP_API_URL+"api/alert/add_more_user/"+props.id+'/'+userIds[0].id
        ).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach(interm=>messageError+=t(interm)+'. ')
                messageError=messageError.replace('undefined','');
                swal.fire(t(messageError), "", "error");
            }else{
                props.setShowFormAddUser(false)
                setUserIds([])
                clearTimeout(props.timeoutRef.current);
                props.setRelance(Date.now())
            }
        }).catch((error) => {
            if(error=='end_session') return;
            if(!error.response){
                swal.fire(t('unknowError'), "", "error");
            }
            else if(error.response.status===403){
                swal.fire(t('forbiddenGeneral'), "", "error");
            }
            else {
                swal.fire(t('unknowError'), "", "error");
            }
        })
        setValidButton(false)
    }

    return (
        <div>
            {(loading) ? 
                <span className="d-flex justify-content-center"><div className="spinner-border spinner-border-sm"></div></span>
            :
                <>
                    <SelectDataListSearch 
                        datas={userForSelect} 
                        ids={userIds}
                        setIds={setUserIds}
                        valueInput={valueInput}
                        setValueInput={setValueInput}
                        disabled={userIds.length!=0}
                        writeLabel={false}
                    />
                    <div className="mt-2">
                        {(userIds.length!=0) ? 
                            userIds.map(value=>(
                                <span key={value.id} >
                                    <Badge bg="primary" text="white">{value.name}&nbsp;&nbsp;<span className="text-danger pointer" onClick={()=>removeUser(value.id)}>{<FontAwesomeIcon icon={faTimesCircle} />}</span></Badge>&nbsp;&nbsp;
                                </span>
                            ))
                        :
                            false
                        }
                    </div>
                    <div>
                        <button disabled={validButton || userIds.length==0} className="btn btn-primary mt-4" onClick={()=>addNewUser()}>
                            {validButton ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : t('btnValidate')}
                        </button>
                    </div>
                </>
            }
        </div>
    )
};

export default AddUserToConvers;