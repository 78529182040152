import React,{useState,useContext,useRef,useEffect} from "react";
import { useTranslate } from 'react-redux-multilingual'
import {FormatedPrice,ButtonCommande} from "@gull"
import { Card } from "react-bootstrap";
import LinePaymentRecordRightForm from './PaymentRightComp/LinePaymentRecordRightForm'
import LineRefund from './PaymentRightComp/LineRefund'
import swal from "sweetalert2";
import axios from 'axios'
import { PeripheriqueContext,IsAuthContext } from '../../../../../app/App';
import { epson_tm_h6000V,epson_tm_h6000VOrder} from 'app/imprime';
import { openTiroir } from "app/ouvertureTiroir";
import Ticket from "../../../../../app/views/Pdf/Ticket"
import TicketFacture from "../../../../../app/views/Pdf/TicketFacture"
import { BlobProvider } from '@react-pdf/renderer';
import { conditionsRetour } from 'app/conditionsRetour';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';

const PaymentFormRight =  (props) =>{
    const t =useTranslate()
    const btnPdf = useRef('')
    const btnTicketFacture = useRef('')
    const [loading,setLoading]=useState({
        giftprint:false,
        pay:false,
        print:false,
        payprint:false,
        giftprintpdf:false,
        printpdf:false,
        payprintpdf:false,
        commande:false,
        invoice:false,
    })
    const idsLocalStorage=localStorage.getItem("pos").split(',')
    const { peripheriqueParam } = useContext(PeripheriqueContext)  
    const {authParam} = useContext(IsAuthContext);

    const [ticketPdf,setTicketPdf]=useState({})
    const [totalAchat,setTotalAchat]=useState(0)
    const [totalTgc,setTotalTgc]=useState(false)
    const [giftPdf,setGiftPdf]=useState(false)
    const [showOrderBtn,setShowOrderBtn]=useState(true)
    const [pointsFidelitePdf,setPointsFidelitePdf]=useState({
        total:0,
        acquis:0,
        cashbackTotal:0,
        cashbackAcquis:0,
        mode:null
    }) 

    let thisShop = authParam.sections.length!=0 && authParam.sections.find(value=>value.id==props.currentTicket.section_id)

    useEffect(function () {
        //on n'affiche pas le bouton commande client si il y a des articles retourné ou une gift card sur le ticket
        Object.keys(props.currentTicket).length!=0 && props.currentTicket.ticket_detail.find(value=>{  
            if(value.special=='retour' || value.special=='gift_card'){
                setShowOrderBtn(false)
            }
        })
    }, [])

    const finishPayment = async (name) =>{
        setLoading({...loading,[name]:true})
        const response = await axios.post(process.env.REACT_APP_API_URL+"api/pos/ticket/construction/"+props.posid+"/"+idsLocalStorage[1]+"/"+idsLocalStorage[0],{
            id:props.cookies.current_ticket,
            close:true
        }).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach(interm=>messageError+=t(interm)+'. ')
                messageError=messageError.replace('undefined','');
                swal.fire(messageError, "", "error");
            }
            else{   
                props.setCurrentTicket(response.data.data)
                props.setPointsFidelite({
                    total:response.data.total_point_fidelite_client,
                    acquis:response.data.total_point_fidelite_ticket,
                    cashbackTotal:response.data.fidelite_client.valeur_cfp ? response.data.fidelite_client.valeur_cfp : 0,
                    cashbackAcquis:response.data.valeur_cfp_ticket ? response.data.valeur_cfp_ticket : 0,
                    mode:response.data.fidelite_client.mode_fidelite
                })
                setLoading({...loading,[name]:false})

                props.setCookie("last_ticket",props.cookies.current_ticket,{ path: "/", expires: props.expire_date }) 

                if(name=='payprint'){
                    epson_tm_h6000V('print',props.currentTicket.section_id,response.data.data,t,props.devise,peripheriqueParam,false,authParam,response.data.total_point_fidelite_client,response.data.total_point_fidelite_ticket,response.data.fidelite_client.valeur_cfp,response.data.valeur_cfp_ticket,response.data.fidelite_client.mode_fidelite)

                }else if(name=='pay'){
                    openTiroir(peripheriqueParam,t)

                }else if(name=='payprintpdf'){
                    getPdfTicket(response.data.data.bar,'printpdf')
                    
                }
                props.setBtnDisabled(false)
                props.setClientFideliteInfo(false)
                props.setShowBtnFidelite(false)
            }
        }).catch((error) => {
            if(error=='end_session') return;
            swal.fire(t('unknowError'), "", "error");
        })
        setLoading({...loading,[name]:false})
        props.setBtnDisabled(false)
    }

    const getPdfTicket = async (ticketBar,stylePdf) =>{
        setLoading({...loading,[stylePdf]:true})
        if(stylePdf=='giftprintpdf'){ 
            setGiftPdf(true)
        }else{
            setGiftPdf(false)
        }
        const response = await axios.get(process.env.REACT_APP_API_URL+"api/pos/ticket/get_by_reference/"+ticketBar
        ).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach(interm=>messageError+=t(interm)+'. ')
                messageError=messageError.replace('undefined','');
                swal.fire(messageError, "", "error");
            }
            else{ 
                setTicketPdf(response.data.data)
                setPointsFidelitePdf({
                    total:response.data.total_point_fidelite_client,
                    acquis:response.data.total_point_fidelite_ticket,
                    cashbackTotal:response.data.fidelite_client.valeur_cfp ? response.data.fidelite_client.valeur_cfp : 0,
                    cashbackAcquis:response.data.valeur_cfp_ticket ? response.data.valeur_cfp_ticket : 0,
                    mode:response.data.fidelite_client.mode_fidelite
                })

                var total = 0
                let totaltgc = {}
                response.data.data.ticket_detail.map(item=>{
                    total+=item.prix_remise
                    if(item.tgc!=0){//on exécute pas cette action sur le taux 0
                        if(totaltgc[item.tgc]){
                            //si le taux de tgc existe deja on ajoute le pris du produit au total existant
                            totaltgc={...totaltgc,[item.tgc]:totaltgc[item.tgc]+=item.prix_remise}
                        }else{
                            //Si aucun taux existe on ajoute la taxe 
                            totaltgc={...totaltgc,[item.tgc]:item.prix_remise}
                        }
                        
                    }
                })
                setTotalAchat(total)
                setTotalTgc(totaltgc)

                if(stylePdf=='invoice'){ 
                    setTimeout(()=>{
                        btnTicketFacture.current.click()
                    },1000)
                }else{
                    setTimeout(()=>{
                        btnPdf.current.click()
                    },1000)
                }
            }
        }).catch((error) => {
            if(error=='end_session') return;
            swal.fire(t('unknowError'), "", "error");
        })
        setLoading({...loading,[stylePdf]:false})
    }

    const addToOrder = async(ticketid,sectionid) => {
        setLoading({...loading,commande:true});
        const response  = await axios.put(process.env.REACT_APP_API_URL+"api/pos/commande/add_more_article/"+ticketid+"/"+sectionid
        ).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach(interm=>messageError+=t(interm)+'. ')
                messageError=messageError.replace('undefined','');
                swal.fire(messageError, "", "error");
            }
            else{
                setLoading({...loading,commande:false});
                props.setTicketCommande(response.data.data)
                props.setCookie("last_sale_order",response.data.data.id,{ path: "/", expires: props.expire_date }) 
                props.resetSale('salesOrder')
                epson_tm_h6000VOrder('print',response.data.data.ticket.section_id,response.data.data,t,props.devise,peripheriqueParam,false,authParam)
                props.setBtnDisabled(false)
            }
        }).catch((error) => {
            if(error=='end_session') return;
            swal.fire(t('unknowError'), "", "error");
        })
        props.setBtnDisabled(false)
    }

    const handleClick = (name) =>{
        switch (name) {
            case 'giftprint':
                epson_tm_h6000V('giftprint',
                    props.currentTicket.section_id,
                    props.currentTicket,
                    t,
                    props.devise,
                    peripheriqueParam,
                    false,
                    authParam,
                    props.pointsFidelite.total,
                    props.pointsFidelite.acquis,
                    props.pointsFidelite.cashbackTotal,
                    props.pointsFidelite.cashbackAcquis,
                    props.pointsFidelite.mode
                )
            break;
            case 'print':
                epson_tm_h6000V('print',
                    props.currentTicket.section_id,
                    props.currentTicket,
                    t,
                    props.devise,
                    peripheriqueParam,
                    false,
                    authParam,
                    props.pointsFidelite.total,
                    props.pointsFidelite.acquis,
                    props.pointsFidelite.cashbackTotal,
                    props.pointsFidelite.cashbackAcquis,
                    props.pointsFidelite.mode
                )
            break;
            case 'giftprintpdf':
                getPdfTicket(props.currentTicket.bar,'giftprintpdf',)
            break;
            case 'printpdf':
                getPdfTicket(props.currentTicket.bar,'printpdf')
            break;
            case 'pay':
                finishPayment('pay');
                props.setBtnDisabled(true)
            break;
            case 'payprint':
                finishPayment('payprint');
                props.setBtnDisabled(true)
            break;
            case 'payprintpdf':
                finishPayment('payprintpdf');
                props.setBtnDisabled(true)
            break;
            case 'order':
                props.setOrderForm(true);
                props.setModalTitle(t('salesOrder'))
            break;
            case 'addToOrder':
                addToOrder(props.currentTicket.id,props.posid)
                props.setBtnDisabled(true)
            break;
            case 'invoice':
                getPdfTicket(props.currentTicket.bar,'invoice')
                props.setBtnDisabled(true)
            break;
            case 'devis':
                props.setShowDevisPrint(true)
                props.setBtnDisabled(true)
                props.setModalTitle(t('quotation'))
            break;
        }
    }

    let tgcForTicketPdf={}
    Object.entries(totalTgc).forEach(([tgcName,montant])=>{
        //fonction qui calcule le montant de tgc à payer par type de tgc
        let coef = +tgcName / 100 + 1
        let totalAmountTgc = +montant - (Math.round(+montant / +coef))
        tgcForTicketPdf[tgcName] = totalAmountTgc
    })
    //on calcule le total de tgc payé sur le ticket
    let tgcTotal = 0 
    Object.entries(tgcForTicketPdf).map(([key,value])=> 
        tgcTotal+=value
    )

    return(
        <>
            <BlobProvider 
                document={(Object.keys(ticketPdf).length!=0 && tgcTotal!=NaN) ?
                    <Ticket 
                        ticketPdf={ticketPdf}
                        thisShop={thisShop}
                        t={t}
                        devise={props.devise}
                        digit={props.digit}
                        totalAchat={totalAchat}
                        tgcForTicketPdf={tgcForTicketPdf}
                        tgcTotal={tgcTotal}
                        giftPdf={giftPdf}
                        pointsFidelite={pointsFidelitePdf}
                        moduleParam={props.modulePos}
                    />
                : 
                    false
                }
            >
                {({ url }) => (
                    <a ref={btnPdf} className="d-none" href={url} target="_blank">Print</a>
                )}
            </BlobProvider>
            <BlobProvider 
                document={(Object.keys(ticketPdf).length!=0 && tgcTotal!=NaN && thisShop) ?
                    <TicketFacture 
                        t={t}
                        documentType='billing'
                        companyInfos={thisShop}
                        authParam={authParam}
                        ticketFacture={ticketPdf}
                        totalTgc={tgcTotal}
                        totalAchat={totalAchat}
                        tgcForTicketPdf={tgcForTicketPdf}
                        devise={props.devise}
                        pointsFidelite={pointsFidelitePdf}
                        moduleParam={props.modulePos}
                    />
                : 
                    false
                }
            >
                {({ url }) => (
                    <a ref={btnTicketFacture} className="d-none" href={url} target="_blank">Print</a>
                )}
            </BlobProvider>
            <Card id={props.id}>
                <Card.Header className="text-end">
                    {<FormatedPrice langRegion={'fr-FR'} devise={props.devise} digit={props.digit} priceToFormat={props.total} />}
                </Card.Header>
                <Card.Body className='scrollPayRight'>
                    <strong className="textTotalPosition">Total :</strong>

                    <div className="bodyPaymentDue">
                        {Object.keys(props.currentTicket).length!= 0 &&
                            props.currentTicket.remboursement_records.length!=0 ?
                            props.currentTicket.remboursement_records.map((value,ind)=>{
                                return(
                                    <LineRefund 
                                        key={value.id}
                                        style='row stripeBgRefund stripePadding position-relative'
                                        value={value}
                                        devise={props.devise}
                                        digit={props.digit}
                                        setCurrentTicket={props.setCurrentTicket}
                                        currentTicket={props.currentTicket}
                                        cookies={props.cookies}
                                        posid={props.posid}
                                        restrictionRemoveLinePayment={props.restrictionRemoveLinePayment}
                                        setRestrictionRemoveLinePayment={props.setRestrictionRemoveLinePayment}
                                        section={props.section}
                                    />
                                )
                            })
                        :
                            false
                        }
                        {Object.keys(props.currentTicket).length!= 0 &&
                            props.currentTicket.paiement_records.length!=0 ?
                                props.currentTicket.paiement_records.map((value,ind)=>{
                                    let style = (ind%2 == 0) ? 'row stripeBg stripePadding position-relative' : 'row stripePadding position-relative';
                                    return(
                                        <LinePaymentRecordRightForm 
                                            key={value.id}
                                            style={style}
                                            value={value}
                                            devise={props.devise}
                                            digit={props.digit}
                                            setCurrentTicket={props.setCurrentTicket}
                                            cookies={props.cookies}
                                            posid={props.posid}
                                            restrictionRemoveLinePayment={props.restrictionRemoveLinePayment}
                                            setRestrictionRemoveLinePayment={props.setRestrictionRemoveLinePayment}
                                            section={props.section}
                                            peripheriqueParam={peripheriqueParam}
                                            currentTicket={props.currentTicket}
                                            setClientFideliteInfo={props.setClientFideliteInfo}
                                        />
                                    )
                                })
                            :
                                false
                        }
                    </div>

                    <strong className="textDuePosition">{t('due')}</strong>
                </Card.Body>
                <Card.Footer className="text-end">
                    <FormatedPrice langRegion={'fr-FR'} devise={props.devise} digit={props.digit} priceToFormat={props.valueDue} />
                </Card.Footer>
            </Card>
            <div className="row mt-3">
                {(props.currentTicket.commande!=null) ? 
                    <span className="mt-3 mb-2">{t('salesOrder')+' : '}<strong>{props.currentTicket.commande.bar}</strong></span>
                : 
                    (props.currentTicket.devis!=null) ? 
                        <span className="mt-3 mb-2">{t('quotation')+' : '}<strong>{props.currentTicket.devis.bar}</strong></span>
                    : 
                        false
                }
                <div className="col text-end mt-4 m-0 p-0">
                    {(props.currentTicket.status==1) ? 
                        <>
                            <button className="btn btn-sm btn-warning" onClick={()=>handleClick('giftprint')}>{(loading.giftprint) ? <div className="spinner-border spinner-border-sm"></div> : t('btnGiftPrint')}</button>&nbsp;&nbsp;
                            <button className="btn btn-sm btn-warning" onClick={()=>handleClick('giftprintpdf')}>{(loading.giftprintpdf) ? <div className="spinner-border spinner-border-sm"></div> : <FontAwesomeIcon icon={faFilePdf} className='fs-5' />}</button>&nbsp;&nbsp;

                            <button className="btn btn-sm btn-primary" onClick={()=>handleClick('print')}>{(loading.print) ? <div className="spinner-border spinner-border-sm"></div> : t('ticket')}</button>&nbsp;&nbsp;
                            <button className="btn btn-sm btn-primary" onClick={()=>handleClick('printpdf')}>{(loading.printpdf) ? <div className="spinner-border spinner-border-sm"></div> : <FontAwesomeIcon icon={faFilePdf} className='fs-5' />}</button>&nbsp;&nbsp;
                            <button className="btn btn-sm btn-primary" onClick={()=>handleClick('invoice')}>{(loading.invoice) ? <div className="spinner-border spinner-border-sm"></div> : t('billing')+' A4'}</button>
                        </>
                    :
                        <>
                            <button disabled={props.btnDisabled} className="btn btn-sm btn-success" onClick={()=>handleClick('pay')}>{(loading.pay) ? <div className="spinner-border spinner-border-sm"></div> : t('btnPay')}</button>&nbsp;&nbsp;
                            {(peripheriqueParam && peripheriqueParam.imprimante_ticket ) ? 
                                <>
                                    <button disabled={props.btnDisabled} className="btn btn-sm btn-info" onClick={()=>handleClick('payprint')}>{(loading.payprint) ? <div className="spinner-border spinner-border-sm"></div> : t('btnPayPrint')}</button>&nbsp;&nbsp;
                                </>
                            : 
                                <>
                                    <button disabled={props.btnDisabled} className="btn btn-sm btn-info" onClick={()=>handleClick('payprintpdf')}>{(loading.payprintpdf) ? <div className="spinner-border spinner-border-sm"></div> : t('btnPayPrint')}&nbsp;<FontAwesomeIcon icon={faFilePdf} className='fs-5' /></button>&nbsp;&nbsp;
                                </>
                            }
                            <ButtonCommande showOrderBtn={showOrderBtn} currentTicket={props.currentTicket} posid={props.posid} btnDisabled={props.btnDisabled} loading={loading} handleClick={handleClick} modulePos={props.modulePos}/>&nbsp;&nbsp;
                            {props.currentTicket.paiement_records && props.currentTicket.paiement_records.length==0 && <button disabled={props.btnDisabled} className="btn btn-sm btn-warning" onClick={()=>handleClick('devis')}>{(props.currentTicket.devis!=null) ? t('btnModifier')+' '+t('quotation') : t('quotation')}</button>}
                        </>
                    }
                </div>
            </div>
            
        </>
    )
}

export default PaymentFormRight;