import React, {useContext} from "react";
import { useTranslate } from 'react-redux-multilingual'
import {InpDataList} from "@gull";
import { IsAuthContext } from 'app/App';

const MasterCaissierSelect = (props) => {
    const t = useTranslate()
    const { authParam } = useContext(IsAuthContext);
    const moduleErp = authParam.modules.find(value=>value.name=='erp')
    const caissierInputShow = (moduleErp.params.staff.active && !Array.isArray(moduleErp.params.staff.params) && moduleErp.params.staff.params.hasOwnProperty('cashier_field') && moduleErp.params.staff.params.cashier_field) ? true : false
    
    if(caissierInputShow){
        return(
            <div className="form-group col-6 mt-3 position-relative">
                <label>{t('cashier')+' :'}</label>
                <InpDataList
                    placeholder={t('cashier')}
                    value={props.value}
                    onClick={props.onClick}
                    onChange={props.onChange}
                    datas={props.datas}
                    declenchement={2}
                    listRef={props.listRef}
                    inputRef={props.inputRef}
                    onBlur={props.onBlur}
                    inputName="searchCashier"
                    className={props.className}
                    onFocus={props.onFocus}
                    onKeyPress={props.onKeyPress}
                />
                {(props.load.caissier) ?
                    <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm topBarLeftPosition3"></div></span>
                :
                    false
                }
            </div>
        )

    }else{
        return(
            <></>
        )
    }
}

export default MasterCaissierSelect;