import React,{useState,useEffect,useRef,useContext} from "react";
import { useTranslate } from 'react-redux-multilingual';
import { Accordion, useAccordionButton   } from "react-bootstrap";
import axios from 'axios'
import swal from "sweetalert2";
import ArticleListFiltre from './ArticleListFiltre'
import AttributeInventoryToStaff from "../AttributeInventoryToStaff";
import { Badge} from "react-bootstrap";
// Style import
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { IsAuthContext } from 'app/App';
import { devises_list } from "app/devises";

const useLoad = (posid) => {
    const t =useTranslate()
    const [loading,setLoading] = useState(true)
    const [section,setSection]=useState(false)
    const [customFields,setCustomFields]=useState(false)
    const [optionsDataTable,setOptionsDataTable]=useState({
        devise:[],
        marque:[],
        fournisseur:[],
        firstlevelcat:[],
        secondlevelcat:[],
        thirdlevelcat:[],
        fourthlevelcat:[],
        fifthlevelcat:[]

    })
    const [ourOptionsDataTable,setOurOptionsDataTable]=useState({
        devise:{},
        marque:{},
        fournisseur:{},
        firstlevelcat:{},
        secondlevelcat:{},
        thirdlevelcat:{},
        fourthlevelcat:{},
        fifthlevelcat:{}

    })

    useEffect(function () {
        (async function() {
            const response = await axios.get(process.env.REACT_APP_API_URL+"api/custom_fields/all/article"
            ).then((response) => {
                setCustomFields(response.data.datas)
            }).catch((error) => {
                if(error=='end_session') return;
                setLoading(false)
                let messageError
                if(error.response==undefined){
                    messageError='unknowError'
                }else if(error.response.status==403){
                    messageError='forbiddenGeneral'
                }else {
                    messageError='unknowError'
                }
                swal.fire(t(messageError), "", "error");
            })
        })()
    }, [])

    useEffect(function () {
        (async function() {
            const response = await axios.get(process.env.REACT_APP_API_URL+"api/section/"+posid
            ).then((response) => {
                setLoading(false)
                if(response.data.rh==null || response.data.rh.length==0){
                    swal.fire(t('noRhForSection'), "", "error");
                }
                else{
                    setSection(response.data)
                }
            }).catch((error) => {
                if(error=='end_session') return;
                setLoading(false)
                let messageError
                if(error.response==undefined){
                    messageError='unknowError'
                }else if(error.response.status==403){
                    messageError='forbiddenGeneral'
                }else {
                    messageError='unknowError'
                }
                swal.fire(t(messageError), "", "error");
            })
        })()
    }, [])

    useEffect(function () {
        (async function() {
            const response = await axios.get(process.env.REACT_APP_API_URL+"api/erp/article/liaison"
            ).then((response) => {
                let arr = ['firstlevelcat','secondlevelcat','thirdlevelcat','fourthlevelcat','fifthlevelcat','fournisseur','marque']
                //on crée une zone de stockage temporaire des données avant de tout set
                let optionsDataTableTemp = optionsDataTable
                let ourOptionsDataTableTemp = ourOptionsDataTable
                
                //formatage des options du select dans le cell edit du datatable pour les devises
                let arrDevise=[]
                let objDeviseOur={}
                devises_list.map(value=>{
                    arrDevise.push({'value':value.name+" - "+value.code,'label':value.name+" - "+value.code})
                    objDeviseOur[value.name+" - "+value.code]=value.code
                })

                optionsDataTableTemp={...optionsDataTableTemp,devise:arrDevise}
                ourOptionsDataTableTemp={...ourOptionsDataTableTemp,devise:objDeviseOur}

                //formatage des options du select dans le cell edit du datatable pour le reste des colones
                arr.forEach(item=>{
                    let optionsArr = [{'value':t('emptyValue'),'label':t('emptyValue')}]
                    let ourOptionObj = {}

                    ourOptionObj[t('emptyValue')]=''
                    response.data.datas[item].map(value=>{
                        optionsArr.push({'value':value.name,'label':value.name})
                        ourOptionObj[value.name]=value.id
                    })

                    optionsDataTableTemp={...optionsDataTableTemp,[item]:optionsArr}
                    ourOptionsDataTableTemp={...ourOptionsDataTableTemp,[item]:ourOptionObj}
                })

                setOptionsDataTable(optionsDataTableTemp)
                setOurOptionsDataTable(ourOptionsDataTableTemp)
            }).catch((error) => {
                if(error=='end_session') return;
                swal.fire(t('unknowError'), "", "error");
            })
        })()
    }, [])
    
    return [loading,section,customFields,optionsDataTable,ourOptionsDataTable]
}

const ArticleListGeneral = (props) => {
    const t = useTranslate();
    const [showAddBtn,setShowAddBtn]=useState(true)
    const [filtre,setFiltre] = useState({havestock:'oui'})
    const [sendData, setSendData] = useState({})
    const [validButton,setValidButton]=useState(false)
    const [loading,section,customFields,optionsDataTable,ourOptionsDataTable] =useLoad(props.allInventoryCategory.section_id)
    const [reload,setReload] =useState(Date.now())
    const { authParam } = useContext(IsAuthContext);
    const suppPrice = authParam.company.advanceparams.hasOwnProperty('prix_supplementaire') ? authParam.company.advanceparams.prix_supplementaire : []
    const maxConditionnement = (authParam.company.advanceparams.max_conditionnement!='' && authParam.company.advanceparams.max_conditionnement!=null) ? +authParam.company.advanceparams.max_conditionnement : 0
    const btnItemRef = useRef('')

    let initAccordionStatus = {
        1: false,
    }
    const [accordionStatus,setAccordionStatus] = useState(initAccordionStatus);

    const removeStaff = (id) =>{
        let newDatas = props.staffList.filter(value=>value.id!=id)
        props.setStaffList(newDatas)
    }

    const addItemToInventoryList = async () =>{
        setValidButton(true)

        let data = filtre

        if(props.staffList.length!=0){
            let arr=[]
            props.staffList.map(value=>
                arr.push(value.id)
            )
            data['rh']=arr
        }

        const response = await axios.post(process.env.REACT_APP_API_URL+"api/erp/inventaire_division/create_categorie_inventaire/"+props.id,data
        ).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach(interm=>messageError+=t(interm)+'. ')
                messageError=messageError.replace('undefined','');
                swal.fire(messageError, "", "error");

            }else{ 
                if(btnItemRef.current) btnItemRef.current.click()//on referme l'accordion du filtre article 
                setFiltre({}) //on reset les recherches du filtre d'articles 
                let allFilter = {
                    sortField:"",
                    sortOrder:"",
                    havestock:'oui'
                }
                Object.keys(sendData).map(value=>
                    allFilter[value]=[]
                )
                setSendData(allFilter)
                setReload(Date.now())
                setShowAddBtn(true)//on disabled le bt ajouter a la liste d'inventaire
                props.setAllInventoryCategory({...props.allInventoryCategory,inventaire_division:response.data.data.inventaire_division})
                props.setAfficheTabArticle(response.data.data.inventaire_division)
            }
        }).catch((error) => {
            if(error=='end_session') return;
            let errorName = (error.response==undefined) ? 'unknowError' : (error.response.status==403) ? 'forbiddenGeneral' : 'unknowError'
            swal.fire(t(errorName), "", "error");
        })
        setValidButton(false)
        props.setStaffList([])
    }

    const attributeStaff = async () =>{
        setValidButton(true)

        let obj = {}
        if(props.itemAttribute){
            //en cas d'ajout de staff apres avoir créé la zone d'inventaire
            obj['inventaire_division']=[props.itemAttribute]
        }else if(props.itemAttributeMasse){
            //en cas d'ajout de staff en masse par sélection 
            obj['inventaire_division']=props.selectedRow
        }
        if(props.staffList.length!=0){
            let arr=[]
            props.staffList.map(value=>
                arr.push(value.id)
            )

            obj['rh']=arr
        }

        if(props.selectedRow.length==0 && !props.itemAttribute){
            swal.fire(t('errorNoBinsSelected'), "", "error");
        }else if(props.staffList.length==0){
            swal.fire(t('errorNoStaffSelected'), "", "error");
        }else{
            const response = await axios.put(process.env.REACT_APP_API_URL+"api/erp/inventaire_division/rh_attribution/"+props.id, obj
            ).then((response) => {
                if(!response.data.valid){
                    let interms='';
                    var keys = Object.keys(response.data.errors);
                    for(var i = 0; i < keys.length; i++){
                        interms+=response.data.errors[keys[i]].join(',')+','
                    }
                    interms=interms.split(',')
                    let messageError='';
                    interms.forEach(interm=>messageError+=t(interm)+'. ')
                    messageError=messageError.replace('undefined','');
                    swal.fire(messageError, "", "error");

                }else{ 
                    props.setRelance(Date.now())
                }
            }).catch((error) => {
                if(error=='end_session') return;
                let errorName = (error.response==undefined) ? 'unknowError' : (error.response.status==403) ? 'forbiddenGeneral' : 'unknowError'
                swal.fire(t(errorName), "", "error");
            })
        }

        props.setStaffList([])
        setValidButton(false)
        if(props.itemAttribute) props.setItemAttribute(false)
        if(props.itemAttributeMasse) props.setItemAttributeMasse(false)
        props.setSelected(false)
        props.setSelectedRow([])
        props.setItemName('')
    }

    const handleClick = ()=>{
        if(props.itemAttributeMasse || props.itemAttribute){
            attributeStaff()
        }else{
            addItemToInventoryList()
        }
    }

    const CustomToggleAccordion = ({ children, eventKey, accordionStatus, setAccordionStatus }) => {
        const decoratedOnClick = useAccordionButton(eventKey, () =>{
            let modifAccordionStatus = initAccordionStatus;
            modifAccordionStatus[eventKey]=!accordionStatus[eventKey];
            setAccordionStatus(modifAccordionStatus);
        });

        let hisClass = (accordionStatus[eventKey]) ? "btn btn-sm btn-success mb-3" : "btn btn-sm btn-outline-success mb-3"
      
        return (
          <button
            type="button"
            className={hisClass}
            style={{marginRight:'10px'}}
            onClick={decoratedOnClick}
            ref={btnItemRef}
          >
            {children}
          </button>
        );
    }
    
    return (
        <div>
            <Accordion>
                <div className="row  d-flex align-items-center">
                    <div className="col-6 d-flex align-items-baseline">
                        <CustomToggleAccordion accordionStatus={accordionStatus} setAccordionStatus={setAccordionStatus} eventKey="1">{t('items')}</CustomToggleAccordion>
                        <button 
                            disabled={validButton || showAddBtn} 
                            className="btn btn-sm btn-dark" 
                            onClick={() => {
                                swal
                                .fire({
                                    title: t('attributeSelectionToStaff')+" ?",
                                    icon: "warning",
                                    type: "question",
                                    showCancelButton: true,
                                    confirmButtonColor: "#3085d6",
                                    cancelButtonColor: "#d33",
                                    confirmButtonText: t('btnYes'),
                                    cancelButtonText: t('btnNo'),
                                })
                                .then((result) => {
                                    if (result.value) {
                                        setTimeout(()=>{
                                            props.searchInput.current.focus()
                                        },400)
                                    }else{
                                        addItemToInventoryList()
                                    }
                                });
                            }}
                            >
                            {validButton ? <div className="spinner-border spinner-border-sm"></div> : t('addToInventoryList')}
                        </button>
                        
                    </div>
                    <div className="col-6">
                        <div className="row align-items-center">
                            <div className="col-10">
                                <AttributeInventoryToStaff
                                    searchInput={props.searchInput}
                                    section={section}
                                    staffList={props.staffList}                 
                                    setStaffList={props.setStaffList}
                                />
                            </div>
                            <div className="col">
                                <button disabled={props.staffList.length==0 || validButton} className="btn btn-sm btn-primary" onClick={()=>handleClick()}>
                                    {validButton ? <div className="spinner-border spinner-border-sm"></div> : t('attribuer')}
                                </button>
                            </div>
                            <div className="fw-bold">{props.itemName}</div>
                            <div className="mb-2">
                                {(props.staffList.length!=0) ? 
                                    props.staffList.map(value=>(
                                        <span key={value.id} >
                                            <Badge bg="primary" text="white">{value.name}&nbsp;&nbsp;<span className="text-danger pointer" onClick={()=>removeStaff(value.id)}>{<FontAwesomeIcon icon={faTimesCircle} />}</span></Badge>&nbsp;&nbsp;
                                        </span>
                                    ))
                                :
                                    false
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <Accordion.Collapse eventKey="1">
                    {(customFields) ?
                        <ArticleListFiltre 
                            filtre={filtre}
                            setFiltre={setFiltre}
                            sendData={sendData}
                            setSendData={setSendData}
                            setShowAddBtn={setShowAddBtn}
                            customFields={customFields}
                            reload={reload}
                            setReload={setReload}
                            module={props.module}
                            optionsDataTable={optionsDataTable}
                            ourOptionsDataTable={ourOptionsDataTable}
                            suppPrice={suppPrice}
                            maxConditionnement={maxConditionnement}
                        />
                    :
                        <div></div>
                    }
                </Accordion.Collapse>
            </Accordion>
        </div>
    );
}

export default ArticleListGeneral;
