import React,{useRef} from "react";
import { useTranslate } from 'react-redux-multilingual'

const InpScanBinsAdmin = (props) => {
    const t =useTranslate()
    const inputScan = useRef('')

    const handleChange = (e) =>{
        props.setBinsNumber(e.target.value)
    }

    const handleBlur = () => {
        if(inputScan.current){
            inputScan.current.focus()    
        }
    }
    
    return (
        <div>
            <form onSubmit={(e)=>props.actionScan(e)}>
                <div className="input-group mb-3">
                    <input
                        autoFocus
                        ref={inputScan}
                        value={props.binsNumber}
                        className="form-control" 
                        type="text" 
                        placeholder={t('binsNumber')+'...'} 
                        onChange={(e)=>handleChange(e)}
                        onBlur={()=>handleBlur()}
                    />
                    <button className="btn btn-sm btn-primary">
                        {t('btnValidate')}
                    </button>
                </div>
            </form>
        </div>
    );
    

}

export default InpScanBinsAdmin;
