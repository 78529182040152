import React, {useState,useEffect} from "react";
import { useTranslate } from 'react-redux-multilingual';
import { Modal } from "react-bootstrap";
import axios from 'axios';
import swal from "sweetalert2";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
// Style import
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentAlt} from '@fortawesome/free-solid-svg-icons';

const  BtnFolderShowMemo = (props) => {
    const t = useTranslate();
    const [show,setShow]=useState(false) 
    const [valueMemo,setValueMemo] = useState('')
    const [validButton,setValidButton] = useState(false)
    
    useEffect(function () {
        setValueMemo(props.memoFolder)
    }, [props.memoFolder])

    const onSubmit = async (e) => {
        e.preventDefault()
        setValidButton(true)

        const response  = await axios.put(process.env.REACT_APP_API_URL+"api/facture/dossier_b2b/edit/"+props.folderId,{
            memo:valueMemo
        }).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach(interm=>messageError+=t(interm)+'. ')
                messageError=messageError.replace('undefined','');
                swal.fire(messageError, "", "error");
            }else{ 
                props.setMemoFolder(response.data.data.memo)
            }
        }).catch((error) => {
            if(error=='end_session') return;
            let messageError
            if(error.response==undefined){
                messageError='unknowError'
            }
            else if(error.response.status==403){
                messageError='forbiddenGeneral'
            }
            else {
                messageError='unknowError'
            }
            swal.fire(t(messageError), "", "error");
        })
        setValidButton(false)
    };

    return (<>
        <Modal size="md" show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Memo</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <form onSubmit={(e)=>onSubmit(e)}>
                        <textarea rows='10' value={valueMemo} className="form-control" onChange={(e)=>setValueMemo(e.target.value)}></textarea>
                        <button disabled={validButton} className="btn btn-primary mt-3">
                            {validButton ? <span><div className="spinner-border spinner-border-sm"></div></span> : t('btnValidate')}
                        </button>
                    </form>
                </div>
            </Modal.Body>
        </Modal>
        <OverlayTrigger
                placement="bottom"
                overlay={
                    <Tooltip id={`tooltip-${props.index}`}>
                        Memo
                    </Tooltip>
                }
        >
            <span onClick={() => setShow(true)} className={`pointer text-primary`}><FontAwesomeIcon icon={faCommentAlt} /></span>
        </OverlayTrigger> 
    </>
    )
}

export default BtnFolderShowMemo;