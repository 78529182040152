import { lazy } from "react";

const Pos = lazy(() => import("./Pos"));
const Open = lazy(() => import("./OpenClose/Open"));
const Close = lazy(() => import("./OpenClose/Close"));
const CashOutExternIdent = lazy(() => import("./CashOut/CashOutExternIdent"));
const CashOutExtern = lazy(() => import("./CashOut/CashOutExtern"));
const CheckDepositExternIdent = lazy(() => import("./CheckDeposit/CheckDepositExternIdent"));
const CheckDepositExtern = lazy(() => import("./CheckDeposit/CheckDepositExtern"));
const SearchStock = lazy(() => import("./SearchStock/SearchStock"));
const CashOut = lazy(() => import("./CashOut/CashOut"));
const CheckDeposit = lazy(() => import("./CheckDeposit/CheckDeposit"));
const Consulter = lazy(() => import("./Objectif/Consulter/Consulter"));
const Attribuer = lazy(() => import("./Objectif/Attribuer/Attribuer"));
const BaseArticles = lazy(() => import("./BaseArticle/BaseArticles"));
const StockArticles = lazy(() => import("./StockArticles/StockArticles"));
const ArticleDetailsPos = lazy(() => import("./BaseArticle/Components/ArticleDetailsPos"));
const AutoReassortParentPos = lazy(() => import("./BaseArticle/Components/AutoReassortParentPos"));
const HistoriqueClient = lazy(() => import("./Historique/HistoriqueClient"));
const HistoriqueVente = lazy(() => import("./Historique/HistoriqueVente"));
const HistoriqueCommande = lazy(() => import("./Historique/HistoriqueCommande"));
const HistoriqueDevis = lazy(() => import("./Historique/HistoriqueDevis"));
const ActivityReport = lazy(() => import("./ActivityReport/ActivityReport"));
const PeripheriquePos = lazy(() => import("./Peripherique/PeripheriquePos"));
const TransferOrderPos = lazy(() => import("./TransferOrder/TransferOrderPos"));
const TransferOrderDetailPos = lazy(() => import("./TransferOrder/TransferOrderDetailPos"));
const PurchaseOrderPos = lazy(() => import("./PurchaseOrder/PurchaseOrderPos"));
const PurchaseOrderDetailPos = lazy(() => import("./PurchaseOrder/PurchaseOrderDetailPos"));
const ClotureDetail = lazy(() => import("./ClotureDetail/ClotureDetail"));
const InventairePos = lazy(() => import("./InventairePos/InventairePos"));
const CategoryInventoryPos = lazy(() => import("./InventairePos/Comp/CategoryInventoryPos"));
const CategoryInventoryPosScanMasse = lazy(() => import("./InventairePos/Comp/CategoryInventoryPosScanMasse"));
const ZoneInventoryPos = lazy(() => import("./InventairePos/Comp/ZoneInventoryPos"));
const BinsInventoryPos = lazy(() => import("./InventairePos/Comp/BinsInventoryPos"));
const BinsInventoryPosScan = lazy(() => import("./InventairePos/Comp/BinsInventoryPosScan"));
const ZoneInventoryPosScan = lazy(() => import("./InventairePos/Comp/ZoneInventoryPosScan"));
const NotificationListPos = lazy(() => import("./Notification/NotificationListPos"));
const NotificationDetailPos = lazy(() => import("./Notification/NotificationDetailPos"));
const AutoRestockPos = lazy(() => import("./AutoRestockPos/AutoRestockPos"));
const RestockDetail = lazy(() => import("./AutoRestockPos/Comp/RestockDetail"));
const PosReport = lazy (()=> import("./ReportPbi/PosReport"))

/**********!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!***************** */
/*     Toutes les routes POS doivent commencer par pos/:posid            */
/*     A part la route d'ouverture et de cloture de caisse               */
/**********!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!***************** */
const posBaseRoutes = [
  {
    path: "/openpos/:posid",
    component: Open
  },
  {
    path: "/cloturepos/:posid",
    component: Close
  },
  {
    exact:true,
    path: "/cashout/:posid",
    component: CashOutExternIdent
  },
  {
    exact:true,
    path: "/cashout/:posid/:caisse_ident_id",
    component: CashOutExtern
  },
  {
    exact:true,
    path: "/checkdeposit/:posid",
    component: CheckDepositExternIdent
  },
  {
    exact:true,
    path: "/checkdeposit/:posid/:caisse_ident_id",
    component: CheckDepositExtern
  },
  {
    exact:true,
    path: "/pos/:posid",
    component: Pos
  },
  {
    path: "/pos/:posid/searchstock/:reference?",
    component: SearchStock
  },
  {
    path: "/pos/:posid/cashout",
    component: CashOut
  },
  {
    path: "/pos/:posid/check-deposit",
    component: CheckDeposit
  },
  {
    path: "/pos/:posid/objectif",
    component: Consulter
  },
  {
    path: "/pos/:posid/rhobjectif",
    component: Attribuer
  },
  {
    exact:true,
    path: "/pos/:posid/item-base", 
    component: BaseArticles
  },
  {
    exact:true,
    path: "/pos/:posid/stock-item", 
    component: StockArticles
  },
  {
    path: "/pos/:posid/item-base/details/:id",
    component: ArticleDetailsPos
  },
  {
    path: "/pos/:posid/automatic-restock/parent-item/:id",
    component: AutoReassortParentPos
  },
  {
    path: "/pos/:posid/sales-historical",
    component: HistoriqueVente
  },
  {
    path: "/pos/:posid/customer-historical",
    component: HistoriqueClient
  },
  {
    path: "/pos/:posid/sales-order-historical",
    component: HistoriqueCommande
  },
  {
    path: "/pos/:posid/activity-report",
    component: ActivityReport
  },
  {
    path: "/pos/:posid/peripherals",
    component: PeripheriquePos
  },
  {
    exact:true,
    path: "/pos/:posid/transfer-order",
    component: TransferOrderPos
  },
  {
    exact:true,
    path: "/pos/:posid/transfer-order-detail/:toId",
    component: TransferOrderDetailPos
  },
  {
    exact:true,
    path: "/pos/:posid/purchase-order",
    component: PurchaseOrderPos
  },
  {
    exact:true,
    path: "/pos/:posid/purchase-order-detail/:poId",
    component: PurchaseOrderDetailPos
  },
  {
    exact:true,
    path: "/cloture-detail/:posnum/:depotBanque",
    component: ClotureDetail
  },
  {
    exact:true,
    path: "/pos/:posid/inventory",
    component: InventairePos
  },
  {
    exact:true,
    path: "/pos/:posid/inventory/categorie/:id/:zoneid",
    component: CategoryInventoryPos
  },
  {
    exact:true,
    path: "/pos/:posid/inventory/categorie/:id/:zoneid/:rhid",
    component: CategoryInventoryPosScanMasse
  },
  {
    exact:true,
    path: "/pos/:posid/inventory/zone/:id/:zoneid",
    component: ZoneInventoryPos
  },
  {
    exact:true,
    path: "/pos/:posid/inventory/zone/:id/:zoneid/:zoneinventoryid/:rhid",
    component: ZoneInventoryPosScan
  },
  {
    exact:true,
    path: "/pos/:posid/inventory/bins/:id/:zoneid",
    component: BinsInventoryPos
  },
  {
    exact:true,
    path: "/pos/:posid/inventory/bins/:id/:zoneid/:binsinventoryid/:binsid/:binsnum/:rhid",
    component: BinsInventoryPosScan
  },
  {
    path: "/pos/:posid/notifications/list",
    component: NotificationListPos
},
{
    path: "/pos/:posid/notifications/detail/:id",
    component: NotificationDetailPos
},
{
  path: "/pos/:posid/auto-restock",
  component: AutoRestockPos
},
{
  path: "/pos/:posid/auto-restock-details/:id",
  component: RestockDetail
},
{
  path: "/pos/:posid/quotation-historical",
  component: HistoriqueDevis
},
{
  exact:true,
  path: "/pos/:posid/report",
  component: PosReport
},
];
  
  export default posBaseRoutes; 