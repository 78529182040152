import React from 'react';

const BreadCrumbWms = (props) =>{

    return(
        <div>
            {props.path1 &&
                <span>{props.path1}</span>
            }
            {props.path2 &&
                <span>{'/'+props.path2}</span>
            }
            {props.path3 &&
                <span>{'/'+props.path3}</span>
            }
            {props.path4 &&
                <span>{'/'+props.path4}</span>
            }
            {props.path5 &&
                <span>{'/'+props.path5}</span>
            }
        </div>
    )
}

export default BreadCrumbWms;