import React from 'react';
import { useTranslate } from 'react-redux-multilingual'
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useCookies} from 'react-cookie';
import swal from "sweetalert2";

// Style import
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoice } from '@fortawesome/free-solid-svg-icons';

const BtnReopenDevis = (props) =>{
    const t = useTranslate();
    const history = useHistory();
    const [cookies, setCookie] = useCookies(['current_ticket']);

    let current_date = new Date()
    let timestampdate = current_date.setDate(current_date.getDate() + 90)
    let expire_date = new Date(timestampdate)

    const handleClick = () => {
        if(cookies.current_ticket){
            swal.fire(t('mustCompleteOrCancelReceipt'), "", "error");
        }else{
            //Si il n'y a pas de ticket en cours
            setCookie("current_ticket",props.ticketId,{ path: "/", expires: expire_date }) 
            setTimeout(() => {
                history.push('/pos/'+props.posid)
            }, 200);
        }
    }

    return (<>
        {props.icon=='true' ?
            <OverlayTrigger
                placement="bottom"
                overlay={
                    <Tooltip id={`tooltip-${props.index}`}>
                        {t('reopenQuotation')}
                    </Tooltip>
                }
            >
                {<span onClick={() => handleClick()} className='pointer text-success'><FontAwesomeIcon icon={faFileInvoice} /></span>}
            </OverlayTrigger> 
        :
            <button className='btn btn-sm btn-success'>{t('reopenQuotation')}</button>
        }
    </>
    )
};

export default BtnReopenDevis;