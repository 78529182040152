import React from 'react';
import { useTranslate } from 'react-redux-multilingual'
import { promotionsSecond } from 'app/allPromotions';

const ModalAddItemPromo = (props) =>{
    const t = useTranslate();

    const handleChange = (e,name) =>{
        props.setSelectValue({...props.selectValue,[name]:e.target.value})
    }

    const closeModal = () =>{
        props.setShowModalAdd(false)
        props.setSelectValue({
            list:'1',
            action:'ajout'
        }) 
    }
    
    return (
        <div>
            {(props.relatedItemsList && props.relatedItemsList.type && promotionsSecond.includes(props.relatedItemsList.type)) ?
                <div className='form-group'>
                    <label>{t('listSelection')}</label>
                    <select className='form-control' value={props.selectValue.list} onChange={(e)=>handleChange(e,'list')}>
                        <option value="1">{t('listTriggerPromo')}</option>
                        <option value="2">{t('listBenefitPromo')}</option>
                    </select>
                </div>
            :
                false
            }
            {(props.from!='fideliteRecompenseConfigure') &&
                <div className='form-group mb-4'>
                    <label>{t('actionOnExistingListItem')}</label>
                    <select className='form-control' value={props.selectValue.action} onChange={(e)=>handleChange(e,'action')}>
                        <option value="ajout">{t('addToTheSelection')}</option>
                        <option value="remplacement">{t('replaceTheList')}</option>
                    </select>
                </div>
            }
            {props.loading ?
                <span className="d-flex justify-content-center mt-3"><div className="spinner-border spinner-border-lg"></div></span>
            :
                <div className='mb-3'>
                    <div className='fs-3 mb-5 text-center'>
                        {t('confirmAddItemPromo',{quantity:props.sizeDatas})+" ?"}
                    </div>
                    <div className='d-flex justify-content-around'>
                        <button disabled={props.validButton} className='btn btn-primary' onClick={()=>props.addItemsSubmit(props.showModalAdd)}>
                            {props.validButton ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : t('btnYes')}
                        </button>
                        <button disabled={props.validButton} className='btn btn-danger' onClick={()=>closeModal()}>{t('btnNo')}</button>
                    </div>
                    
                </div>
            }
        </div>
    )
};

export default ModalAddItemPromo;