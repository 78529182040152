import React,{useState, useRef} from "react";
import { InpDataList } from "@gull";
import { useTranslate } from 'react-redux-multilingual'
import axios from 'axios'
import swal from "sweetalert2";

const useSearch = () => {
    const t =useTranslate()
    const [loadingSearchFolder,setLoadingSearchFolder]=useState(false)
    const [listSearchFolder,setListSearchFolder]=useState([])
    const [datas,setDatas]=useState([])
    const searchInputClient = useRef('')

    const loadClients = async (toSearch) => {
        setLoadingSearchFolder(true)
        const response = await axios.get(process.env.REACT_APP_API_URL+"api/facture/dossier_b2b/search_all_by_name/"+toSearch
        ).then((response) => {
            setListSearchFolder(response.data.datas)
            let initial = []
            response.data.datas.forEach((value)=>
                initial.push({
                    id: value.id,
                    name: value.name,
                    nbr: value.nbr
                })
            )
            setListSearchFolder(initial)
            let ajour = initial.filter((value)=>value.name.toLowerCase().includes(searchInputClient.current.value.toLowerCase()))
            setDatas(ajour)
            setLoadingSearchFolder(false)
        }).catch((error) => {
            if(error=='end_session') return;
            let messageError
            if(error.response==undefined){
                messageError='unknowError'
            }
            else if(error.response.status==403){
                messageError='forbiddenGeneral'
            }
            else {
                messageError='unknowError'
            }
            swal.fire(t(messageError), "", "error");
            setLoadingSearchFolder(false)
        })
    }

    return [loadingSearchFolder,loadClients,listSearchFolder,datas,setDatas,searchInputClient]
}

const SearchFolderByName = (props) =>{
    const t =useTranslate()
    const [loadingSearchFolder,loadClients,listSearchFolder,datas,setDatas,searchInputClient]=useSearch()

    const [folderName,setFolderName] = useState('')
    const [lastSearch,setLastSearch]=useState(false) 

    const dataListFolder = useRef('')

    const handleSearch = (e,declenchement) => {
        setFolderName(e.target.value)
        if(e.target.value.length>=declenchement){
            dataListFolder.current.classList.remove("d-none");

            if(!lastSearch || e.target.value.substring(0, declenchement) != lastSearch.substring(0, declenchement)){
                loadClients(e.target.value.substring(0, declenchement))
                setLastSearch(e.target.value.substring(0, declenchement))
            }
        }else{
            dataListFolder.current.classList.add("d-none");
            setLastSearch(false)
        }
        if(e.target.value.length!=declenchement){
            let ajour = listSearchFolder.filter((value)=>value.name.toLowerCase().includes(e.target.value.toLowerCase()))
            setDatas(ajour)
        }
    }

    const handleCLick = (id,name,inputName,reference,quota,image,nbr) => {
        props.setFolderId(id)
        setFolderName(name)
        dataListFolder.current.classList.add("d-none");
    }

    const handleBlur = () => {
        if(folderName==''){
            props.setFolderId(false)
        }
        dataListFolder.current.classList.add("d-none");
    }

    return(
        <>
            <div className="position-relative">
                <InpDataList
                    placeholder={t('folder')+'...'}
                    value={folderName}
                    onClick={handleCLick}
                    onChange={handleSearch}
                    datas={datas}
                    declenchement={3}
                    listRef={dataListFolder}
                    inputRef={searchInputClient}
                    onBlur={handleBlur}
                    disabled={props.disabled}
                    inputName="searchForExistingFolder"
                />
                {(loadingSearchFolder) ?
                    <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm topBarLeftPosition2"></div></span>
                :
                    false
                }
            </div>
        </>
    )
}

export default SearchFolderByName;