import React, {useRef,useEffect,useState} from 'react';
import axios from "axios";
import { useTranslate } from 'react-redux-multilingual'
import swal from "sweetalert2";

const SectionExceptionReassort = (props) =>{
    const t = useTranslate();
    const [validButtonDelete,setValidButtonDelete] = useState(false)

    const handleChangeException = (e,name) =>{
        if(name=='stockMin'){
            props.setSeuilDeclencheValue(e.target.value)

        }else if(name=='stockGood'){
            props.setQteReassortValue(e.target.value)
        }
    }

    const handleSubmit = async (e) =>{
        e.preventDefault();

        let axiosAction = props.exceptionId != 0 ? axios.put : axios.post;
        let url = props.exceptionId != 0 ? "api/pos/reassort_interne/edit_exception/"+props.exceptionId : "api/pos/reassort_interne/create_one_exception/"+props.exceptionToEdit.id;

        let obj = {}

        if(props.exceptionId != 0){
            //Dans le cas d'une édition
            obj['stock_min']=props.seuilDeclenche
            obj['stock_good']=props.qteReassortValue
        }else{
            //Dans le cas d'une création
            obj['stock_min']=props.seuilDeclenche
            obj['stock_good']=props.qteReassortValue
            obj['section']=[props.sectionId]
        }

        props.setValidButton(true)
        const response  = await axiosAction(process.env.REACT_APP_API_URL+url,obj
        ).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach((interm)=>{
                    let finalMessage=interm.split('/!');
                    if(finalMessage.length==2){
                        messageError+=t(finalMessage[0],{name:finalMessage[1]})+'<br/>'
                    }
                    else{
                        messageError+=t(interm)+'<br/>'
                    }
                })
                    messageError=messageError.replace('undefined','');
                    swal.fire(messageError, "", "error");
                    props.setValidButton(false)
            }else{
                if(props.setIsAReload) props.setIsAReload(true)
                props.setReload(Date.now())
            }
        }).catch((error) => {
            if(error=='end_session') return;
            let errorName = (error.response==undefined) ? 'unknowError' : (error.response.status==403) ? 'forbiddenGeneral' : 'unknowError'
            swal.fire(t(errorName), "", "error");
            props.setValidButton(false)
        })
    }

    const handleDelete = async () => {
        setValidButtonDelete(true)
        const response  = await axios.delete(process.env.REACT_APP_API_URL+"api/pos/reassort_interne/delete_exception/"+props.exceptionId
        ).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach((interm)=>{
                    let finalMessage=interm.split('/!');
                    if(finalMessage.length==2){
                        messageError+=t(finalMessage[0],{name:finalMessage[1]})+'<br/>'
                    }
                    else{
                        messageError+=t(interm)+'<br/>'
                    }
                })
                    messageError=messageError.replace('undefined','');
                    swal.fire(messageError, "", "error");
                    setValidButtonDelete(false)
            }else{
                if(props.setIsAReload) props.setIsAReload(true)
                props.setReload(Date.now())
            }
        }).catch((error) => {
            if(error=='end_session') return;
            let errorName = (error.response==undefined) ? 'unknowError' : (error.response.status==403) ? 'forbiddenGeneral' : 'unknowError'
            swal.fire(t(errorName), "", "error");
            setValidButtonDelete(false)
        })
    };

    return(<>
        <form onSubmit={(e)=>handleSubmit(e)}>
            <h4 className='mb-1 font-weight-bold'>{props.sectionToEdit.name}</h4>
            <label htmlFor="article name" className='mb-2'>{props.exceptionToEdit.name}</label>
            
            <div className="row">
                <label htmlFor="stockMin" className='mb-1 col-7'>{t('triggerThresholdInternalRestock')}</label>
                <label htmlFor="stockGood" className='mb-1 col-5'>{t('quantityToRestock')}</label>
            </div>
            <div className="row d-flex align-items-center">
                <div className="form-group col-5">
                    <input ref={props.SeuilRef} value={props.seuilDeclenche} className="form-control" type="number" onChange={(e)=>handleChangeException(e,'stockMin')}/>
                </div>
                <div className="col-2 text-center fs-2">/</div>
                <div className="form-group col-5">
                    <input value={props.qteReassortValue} className="form-control" type="number" onChange={(e)=>handleChangeException(e,'stockGood')}/>
                </div>
            </div>
            <button disabled={props.validButton} className="btn btn-primary mt-3">
                {props.validButton ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : t('btnValidate')}
            </button>&nbsp;&nbsp;
            {(props.exceptionId != 0) &&
                <span 
                    disabled={validButtonDelete} 
                    className="btn btn-danger mt-3" 
                    onClick={() => {
                        swal
                        .fire({
                            title: t('confirmDeleteDivers')+" ?",
                            icon: "warning",
                            type: "question",
                            showCancelButton: true,
                            confirmButtonColor: "#3085d6",
                            cancelButtonColor: "#d33",
                            confirmButtonText: t('btnYes'),
                            cancelButtonText: t('btnNo'),
                        })
                        .then((result) => {
                            if (result.value) {
                                handleDelete()
                            }
                        });
                    }}
                >
                    {validButtonDelete ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : t('btnDelete')}
                </span>
            }
        </form>
    </>
    )
}

const ExceptionReassortModalEdit = (props) => {
    const t = useTranslate();
    const SeuilRef = useRef('')

    useEffect(()=>{
        if(SeuilRef.current){
            SeuilRef.current.focus()
            SeuilRef.current.select()
        }
    
    },[SeuilRef.current])
    
    let exceptionToEdit = props.allExceptions.find(value=>value.id == props.articleIdModal)
    let sectionToEdit = props.section.find(value=>value.id == props.sectionId)

    return(<>
        <SectionExceptionReassort 
            SeuilRef={SeuilRef} 
            exceptionToEdit={exceptionToEdit} 
            sectionToEdit={sectionToEdit} 
            seuilDeclenche={props.seuilDeclenche} 
            setSeuilDeclencheValue={props.setSeuilDeclencheValue} 
            qteReassortValue={props.qteReassortValue} 
            setQteReassortValue={props.setQteReassortValue} 
            exceptionId={props.exceptionId} 
            sectionId={props.sectionId} 
            setReload={props.setReload}
            validButton={props.validButton}
            setValidButton={props.setValidButton}
            setIsAReload={props.setIsAReload}
        />
    </>)
}

export default ExceptionReassortModalEdit;
