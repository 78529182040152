
export const epson_tm_h6000V_Check = async (montant,date,peripheriqueParam,section) =>{

    let checkDate = new Date(date)
    // const day = checkDate.getUTCDate(); // Obtenez le jour
    const day = String(checkDate.getUTCDate()).padStart(2, '0');
    // const month = checkDate.getUTCMonth() + 1; // Obtenez le mois (ajoutez 1 car les mois sont indexés à partir de 0)
    const month = String(checkDate.getUTCMonth() + 1).padStart(2, '0');
    const year = checkDate.getUTCFullYear(); // Obtenez l'année.

    const formattedDate = `${day}/${month}/${year}`;

    
    let dateOnCheck = '"'+formattedDate+'"'
    let shop = (section.other_company && section.other_company.length!=0) ? '"'+section.other_company[0].name+'"' : '" "'
    let price = '"'+montant+'"'   
    let ville = (!Array.isArray(section.advanceparams) && section.advanceparams.hasOwnProperty('ville') && section.advanceparams.ville!='') ? '"'+section.advanceparams.ville+'"' : '" "'
    let host = '"'+peripheriqueParam.imprimante_cheque.host+'"'
    
    const response  = await fetch('http://localhost/portcom/printCheck.php',{
        method: 'POST',
        body: JSON.stringify({
            shop:shop.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
            price:price, 
            city:ville.normalize("NFD").replace(/[\u0300-\u036f]/g, ""), 
            date:dateOnCheck, 
            host:host,
        })
    }).then((response) => {
    }).catch((error) => {
    })
}