import React, { useState } from 'react';
import { useTranslate } from 'react-redux-multilingual'
import { OverlayTrigger, Tooltip } from "react-bootstrap";

// Style import
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

const BtnCopyClipBoard = (props) =>{
    const t = useTranslate();
    const [icon,setIcon]=useState(props.icon=='true' ? faCopy : t('copy')) 
    const [iconColor,setIconColor]=useState(props.icon=='true' ? 'text-primary' : 'btn-primary') 

    const copyToClipboard = async (text) => {
        try {
            await navigator.clipboard.writeText(text);
            setIcon(props.icon=='true' ? faCheckCircle : t('copied'));
            setIconColor(props.icon=='true' ? 'text-success' : 'btn-success');
        } catch (error) {
            setIcon(props.icon=='true' ? faTimesCircle : t('copied'));
            setIconColor(props.icon=='true' ? 'text-danger' : 'btn-danger');
        }

        setTimeout(() => {
            setIcon(props.icon=='true' ? faCopy : t('copy'))
            setIconColor(props.icon=='true' ? 'text-primary' : 'btn-primary');
        }, 1000);
    };
    
    return (<div>   
        <label className=''></label>
        {props.icon=='true' ?
            <OverlayTrigger
                placement="bottom"
                overlay={
                    <Tooltip id={`tooltip-${props.index}`}>
                        {t('copy')}
                    </Tooltip>
                }
            >
                <span onClick={() => copyToClipboard(props.textToCopy)} className={`pointer ${iconColor}`}><FontAwesomeIcon icon={icon} /></span>
            </OverlayTrigger> 
        :
            <button onClick={() => copyToClipboard(props.textToCopy)} className={`btn btn-sm ${iconColor}`}>
                {t('copy')}
            </button>
        }
        </div>
    )
};

export default BtnCopyClipBoard;