import React from "react";
import { CardPerso} from "@gull";
import { useTranslate } from 'react-redux-multilingual'
import BarRight from './BarRight'

const ViewDefault = (props) => {
    const t =useTranslate()

    return(
        <CardPerso id={props.id} >
            <BarRight
                id={props.id} 
                section={props.section}
                currentTicket={props.currentTicket}
                setCurrentTicket={props.setCurrentTicket}
                posid={props.posid}
                cookies={props.cookies}
                setCookie={props.setCookie}
                expire_date={props.expire_date}
                clientId={props.clientId}
                cashierId={props.cashierId}
                setShowModalGiftCard={props.setShowModalGiftCard}
                affichageClient={props.affichageClient}
                peripheriqueParam={props.peripheriqueParam}
                setRelanceFocus={props.setRelanceFocus}
                relanceFocus={props.relanceFocus}
                devise={props.devise}
                view="raccourciCaisse"//affichage par défaut
                
                disabledPay={props.disabledPay}
                setDisabledPay={props.setDisabledPay}
                setDisabledCloudButton={props.setDisabledCloudButton}

                dataNextShortcut={props.dataNextShortcut}
                setDataNextShortcut={props.setDataNextShortcut}
                nextShortcutString={props.nextShortcutString}
                setNextShortcutString={props.setNextShortcutString}
                lastShortCutClicked={props.lastShortCutClicked}
                setLastShortCutClicked={props.setLastShortCutClicked}
                resetSearchRaccourci={props.resetSearchRaccourci}
            />
        </CardPerso>
    )
}

export default ViewDefault;