import React from "react";
import { useTranslate } from 'react-redux-multilingual';

const  CancelAllInventoryBtn = (props) => {
    const t = useTranslate();

    const handleClick=()=>{
        props.setShowCancelModal(true)
    }


    return (
        <button 
            className="btn btn-sm btn-danger mb-2"
            onClick={() => handleClick()}
        >
            {t('cancelAllCount')}
        </button>
    )
}

export default CancelAllInventoryBtn;