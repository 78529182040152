import React from 'react';
import {CreditGiftCard} from "@gull";

const MasterCloudGiftCardButton = (props) =>{
    
    if(props.modulePos.params.gift_card.active){
        return(<CreditGiftCard {...props}/>)
    }else{
        return(<></>)
    }
};

export default MasterCloudGiftCardButton;