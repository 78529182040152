import React, { useState,useEffect,useRef} from 'react';
import { useTranslate } from 'react-redux-multilingual'
import { InpDataList} from "@gull";

const SelectRh = (props) =>{
    const t = useTranslate();
    const dataListDrop = useRef('')
    const searchInput = useRef('')
    const [cashierName,setCashierName]=useState(false)
    const [barObj,setBarObj] = useState({})
    const [rhIdObj,setRhIdObj] = useState({})

    useEffect(function () {
        let initialBarObj = {};
        let initialRhIdObj = {};
        props.section.rh.forEach((value)=>{
            initialBarObj={...initialBarObj,[value.bar]:value.name+' '+value.firstname}
            initialRhIdObj={...initialRhIdObj,[value.bar]:value.id}
        })
        setBarObj(initialBarObj)
        setRhIdObj(initialRhIdObj)
    }, [])

    const handleSearch = (e,declenchement) => {
        props.setValueInputCashier(e.target.value)   
    }

    const handleKeyPress = (e) => {
        if(e.charCode==13){
            if(barObj[e.target.value]){
                props.setValueInputCashier(barObj[e.target.value])
                props.setCashierId(rhIdObj[e.target.value])
        
                setCashierName(barObj[e.target.value])

                setTimeout(()=>{
                    searchInput.current.blur()
                },200)
            }
        }
    }

    const handleBlur = () => {
        if(cashierName){
            props.setValueInputCashier(cashierName)
        }else{
            props.setValueInputCashier('')
        }
        
        dataListDrop.current.classList.add("d-none");
    }

    return (
        <div className="row mt-2">
            <div className='col-12 position-relative form-group'>
                <label className='d-flex'>{t('seller')+' :'}</label>
                <InpDataList
                    placeholder={t('scanSellerBadge')}
                    value={props.valueInputCashier}
                    onChange={handleSearch}
                    declenchement={2}
                    listRef={dataListDrop}
                    inputRef={searchInput}
                    onBlur={handleBlur}
                    inputName="searchCashier"
                    from='barLeft'
                    className="form-control"
                    onKeyPress={handleKeyPress}
                    disabled={props.disabled}
                />
            </div>
        </div>
    )
};

export default SelectRh;