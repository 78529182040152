import React, {useState, useEffect} from "react";
import { useTranslate } from 'react-redux-multilingual';
import { Loading} from "@gull";
import axios from 'axios'
import { useParams } from "react-router-dom";
import { useCookies} from 'react-cookie';
import swal from "sweetalert2";
import BtnPrint from "../../app/views/wms/PeripheriqueWms/comp/BtnPrint";

const useLoad = (wmsid) => {
    const t = useTranslate();
    const [loading,setLoading]=useState(true)
    const [peripheriques,setPeripheriques]=useState([])
    const [selectedPrinter,setSelectedPrinter]=useState({})
    const [cookies, setCookie] = useCookies(['peripherique']);

    useEffect(function () {
        (async function() {
            const response = await axios.get(process.env.REACT_APP_API_URL+"api/peripherique/get_all_of_section/"+wmsid
            ).then((response) => {
                if(!response.data.valid){
                    let interms='';
                    var keys = Object.keys(response.data.errors);
                    for(var i = 0; i < keys.length; i++){
                        interms+=response.data.errors[keys[i]].join(',')+','
                    }
                    interms=interms.split(',')
                    let messageError='';
                    interms.forEach(interm=>messageError+=t(interm)+'. ')
                    messageError=messageError.replace('undefined','');
                    swal.fire(messageError, "", "error");
                }else{
                    setLoading(false)
                    let arrTagPrinter = []
                    response.data.datas.map(value=>{
                        if(value.type=='imprimante_etiquette') arrTagPrinter.push(value)
                    })
                    setPeripheriques(arrTagPrinter)

                    let obj={}

                    if(!cookies.peripherique){
                        response.data.datas.map(value=>{
                            if(value.type=='imprimante_etiquette') obj[value.id] = false
                        })
                    }else{
                        let formated = cookies.peripherique.split('/!')
                        response.data.datas.map(value=>{
                            if(value.type=='imprimante_etiquette'){
                                if(formated[0] == value.id){
                                    obj[value.id] = true
                                }else{
                                    obj[value.id] = false
                                }
                            }
                        })
                    }
                    setSelectedPrinter(obj)
                }
            }).catch((error) => {
                if(error=='end_session') return;
                swal.fire(t('unknowError'), "", "error");
            })
            setLoading(false)
        })()
    }, [])
 
    return [loading,peripheriques,selectedPrinter,setSelectedPrinter,setCookie]
}

const ListPrintWms = (props) =>{
    const { wmsid } = useParams();
    const [loading,peripheriques,selectedPrinter,setSelectedPrinter,setCookie]=useLoad(wmsid)
    
    //Pour le cookie
    let current_date = new Date()
    let timestampdate = current_date.setDate(current_date.getDate() + 90)
    let expire_date = new Date(timestampdate)

    const selectPrinter = (id,host,model,hostname) =>{
        let peripheriqueCookie = [id+'/!'+host+'/!'+model+'/!'+hostname]
        let newObj={}

        Object.entries(selectedPrinter).map(([keys,value])=>{
            if(id==keys){
                newObj[keys]=true
            }else{
                newObj[keys]=false
            }
        })
        
        setSelectedPrinter(newObj)
        setCookie("peripherique",peripheriqueCookie.join(),{ path: "/", expires: expire_date })
    }

    if(props.from=='peripheriqueMenu' && loading){
        return (<Loading></Loading>);

    }else if(loading){
        return(<span className="d-flex justify-content-center mb-5"><div className="spinner-border spinner-border-sm"></div></span>)
    }

    return(
        <div className="row" >
            {(peripheriques.length!=0) ?
                <>
                    {
                        peripheriques.map(value=>{
                            return(
                                <BtnPrint 
                                    key={value.id}
                                    value={value}
                                    selectedPrinter={selectedPrinter}
                                    selectPrinter={selectPrinter}
                                />
                            )
                        })
                    }
                </>
            :
                false
            }
        </div>
    )
}

export default ListPrintWms;