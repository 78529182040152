import React, {useState,useRef} from 'react';
import {InpDataList} from "@gull";
import { useTranslate } from 'react-redux-multilingual';

const EachDataFilter = (props) => {
    const [valueInput,setValueInput] = useState('')
    const inputRef = useRef('')
    const listRef = useRef('')
    const [datas,setDatas] = useState(props.binsList)

    const handleSearch = (e,declenchement) => {
        setValueInput(e.target.value);
        if(e.target.value.length>=declenchement){
            listRef.current.classList.remove("d-none");
            let ajour = props.binsList.filter((value)=>value.toString().toLowerCase().includes(e.target.value.toLowerCase()))
            setDatas(ajour)
        }
        else{
            listRef.current.classList.add("d-none");
        }
    }
    
    const handleClickFilter = (label) => {
        setValueInput(label)
        props.setField({
            ...props.field,
            bins:label,
            quantite:true
        })
        listRef.current.classList.add("d-none");
        setDatas([])     
    }

    return(
        <InpDataList
            placeholder="-- Select --"
            disabled={props.disabled}
            value={valueInput}
            onClick={handleClickFilter}
            onChange={handleSearch}
            datas={datas}
            declenchement={1}
            listRef={listRef}
            inputRef={inputRef}
            from='reorganizationStock'
        />
    )
}

const AdjustmentStockForm = (props) => {
    const t = useTranslate();
    
    return(
        <>
            <form>
                <div className="form-group">
                    <label className="d-flex" htmlFor="choose an action">{t('chooseAnAction')+'* :'}</label>
                    <select disabled={props.isRestricted} className="form-control" {...props.register('action', {onChange : (e)=>props.handleChange(e,'action')})} >
                        <option value="">-- Select --</option>
                        <option value="add">{t('addToStock')}</option>
                        <option value="remove">{t('removeFromStock')}</option>
                    </select>
                </div>
                {(props.field.action && props.field.action!='') ?
                    <div className="form-group">
                        <label className="d-flex" htmlFor="select a shop">{t('selectSection')+'* :'}</label>
                        <select disabled={props.from=='pos'} className="form-control" {...props.register('section', {onChange : (e)=>props.handleChange(e,'section')})} >
                            <option value="">-- Select --</option>
                            {(props.field.action == 'remove') ? 
                                props.shopsList.map((value)=>(
                                    <option key={value[1].sectionid} value={value[1].sectionid}>{value[0]}</option>
                                ))
                            :
                                Object.entries(props.shopWithItems).map(([shopName,value])=>(
                                    <option key={value.sectionid} value={value.sectionid}>{shopName}</option>
                                ))
                            }
                        </select>
                    </div>
                :
                    false
                }
                {(props.field.section && props.field.section!='') ?
                    <div className="form-group">
                        <label className="d-flex" htmlFor="select a storage destination">{t('selectStorageDestination')+'* :'}</label>
                        {(props.loading.zone) ?
                                <div className="col-12 text-center"><div className="spinner-border spinner-border-sm"></div></div>
                            :
                                <select disabled={props.isRestricted} className="form-control" {...props.register('zone', {onChange : (e)=>props.handleChange(e,'zone')})} >
                                    <option value="">-- Select --</option>
                                    {(props.field.action == 'add') ? 
                                        Object.entries(props.data.zone).map(([zoneName,value])=>(
                                            <option key={value.zoneid} value={value.zoneid}>{zoneName}</option>
                                        ))
                                    :
                                        Object.entries(props.data.zoneContainArticle).map(([zoneName,value])=>(
                                            <option key={value.zoneid} value={value.zoneid}>{zoneName}</option>
                                            
                                        ))
                                    }
                                </select>
                        }
                    </div>
                :
                    false
                }
                {(props.field.zone && props.field.zone!='') ?
                    <div className="form-group">
                        <label className="d-flex" htmlFor="bins number">{t('binsNumber')+'* :'}</label>
                        {(props.loading.bins) ?
                                <div className="col-12 text-center"><div className="spinner-border spinner-border-sm"></div></div>
                            :
                                (props.field.action == 'add') ?
                                    <EachDataFilter disabled={props.isRestricted} binsList={props.data.binsAll} field={props.field} setField={props.setField} />
                                :
                                    <select disabled={props.isRestricted}  className="form-control" {...props.register('bins', {onChange : (e)=>props.handleChange(e,'bins')})} >
                                        <option value="">-- Select --</option>
                                        {Object.entries(props.data.bins).map(([binsNum,qteArticle])=>(
                                            <option key={binsNum} value={binsNum}>{binsNum}</option>
                                        ))}
                                    </select>}
                    </div>
                :
                    false
                }
                {(props.field.quantite) ?
                    <div className="form-group">
                        <label className="d-flex" htmlFor="quantity to move">{t('quantity')+'* :'}</label>
                        <input disabled={props.isRestricted} autoComplete='off' className="form-control" value={props.qteToMove} type="text" {...props.register('quantite', {onChange : (e)=>props.handleChange(e,'quantite')})} />
                    </div>
                :
                    false
                }
                {(props.field.memo) ?
                    <div className="form-group">
                        <label className="d-flex" htmlFor="quantity to move">Memo* :</label>
                        <textarea disabled={props.isRestricted} className="form-control" cols="30" rows="5" {...props.register('memo', {onChange : (e)=>props.handleChange(e,'memo')})}></textarea>
                    </div>                
                :
                    false
                }
                {(props.apiError || props.error) ? <div className="alert alert-danger mt-3" dangerouslySetInnerHTML={{ __html: props.apiError || props.error }}></div> : false}
                {(props.success) ? <div className="alert alert-success mt-3">{t('successOperation')}</div> : false}
                
            </form>
            <button 
                disabled={props.field.button} 
                className="btn btn-primary mt-3"
                onClick={(e)=>props.controlBeforeSubmit(e)}
            >
                {props.validButton ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : t('btnValidate')}
            </button>
        </>
    )
}

export default AdjustmentStockForm;
