import React, {useState, useEffect,useRef,useContext} from 'react';
import {SubmitForm,InpDataList} from "@gull";
import { useForm } from "react-hook-form";
import { useTranslate } from 'react-redux-multilingual';
import swal from "sweetalert2";
import axios from 'axios';
import { IsAuthContext } from 'app/App';

const EachDataFilter = (props) => {
    const [valueInput,setValueInput] = useState(props.poName)
    const inputRef = useRef('')
    const listRef = useRef('')
    const [datas,setDatas] = useState(props.fournisseurs)
        
    const handleClickFilter = (id,label,nameInput) => {
        setValueInput(label)
        props.setFournisseurId(id)
    }

    const handleSearch = (e,declenchement) => {
        setValueInput(e.target.value);
        let ajour = props.fournisseurs.filter((value)=>value.name.toLowerCase().includes(e.target.value.toLowerCase()))
        setDatas(ajour)
    }

    const handleFocus = () => {
        setDatas(props.fournisseurs)
        listRef.current.classList.remove("d-none");
    }

    const handleBlur = () => {
        listRef.current.classList.add("d-none")
    }

    return(
        <InpDataList
            placeholder="-- Select --"
            value={valueInput}
            onClick={handleClickFilter}
            onChange={handleSearch}
            datas={datas}
            declenchement={0}
            listRef={listRef}
            inputRef={inputRef}
            onFocus={handleFocus}
            onBlur={handleBlur}
            inputName={props.inputName}
        />
    )
}

const PurchaseOrderModalEdit = (props) => {
    const t = useTranslate();
    const { register, formState: { errors }, handleSubmit, setValue, reset} = useForm();
    const [validButton,setValidButton] = useState(false)
    const [apiError,setApiError] = useState(false)
    const [success,setSuccess] = useState(false)

    const [loadingPo,setLoadingPo]=useState(true)
    const [po,setPo]=useState(false)
    const [customFields,setCustomFields]=useState([])
    const [fournisseurId,setFournisseurId]=useState('')
    const { authParam } = useContext(IsAuthContext);

    useEffect(function () {
        (async function() {
            const response = await axios.get(process.env.REACT_APP_API_URL+"api/erp/po/one/"+props.modalEditId
            ).then((response) => {
                setLoadingPo(false)
                setPo(response.data.po)
                setCustomFields(response.data.custom_fields)
                if(response.data.po.fournisseur!=null){
                    setFournisseurId(response.data.po.fournisseur.id)
                }
            }).catch((error) => {
                if(error=='end_session') return;
                let errorName = (error.response==undefined) ? 'unknowError' : (error.response.status==403) ? 'forbiddenGeneral' : 'unknowError'
                swal.fire(t(errorName), "", "error");
                
            })
        })()
    }, [props.modalEditId])

    if(props.from==='poList'){
        //On détermine l'index à éditer sur la modal edit 
        let poName = props.purchaseOrders.map(value=>value.name)
        var indexToEdit = poName.indexOf(po.name)
    }

    const onSubmit = async (data) => {
        setValidButton(true)
        setApiError(false)
        setSuccess(false)
        data['fournisseur']=`${fournisseurId}` //on converti la valeur de fournisseurId en string
        const callapi  = await axios.put(process.env.REACT_APP_API_URL+"api/erp/po/"+props.modalEditId, data)
            .then((response) => {
                if(!response.data.valid){
                    let interms='';
                    var keys = Object.keys(response.data.errors);
                    for(var i = 0; i < keys.length; i++){
                        interms+=response.data.errors[keys[i]].join(',')+','
                    }
                    interms=interms.split(',')
                    let messageError='';
                    interms.forEach((interm)=>{
                        let finalMessage=interm.split('/!');
                        if(finalMessage.length==2){
                            messageError+=t(finalMessage[0],{name:finalMessage[1]})+'<br/>'
                        }
                        else{
                            messageError+=t(interm)+'<br/>'
                        }
                    })
                    messageError=messageError.replace('undefined','');
                    setApiError(messageError)
                }
                else{
                    setSuccess(true)
                    if(props.from==='poList' && indexToEdit >= 0){
                        let newData = props.purchaseOrders
                        newData.splice(indexToEdit,1, response.data.po)
                        props.setPurchaseOrders(newData)

                    }else if(props.from==='poDetail'){
                        props.setPoDetails(response.data.po)
                    }

                    setPo(response.data.po)   
                }
            }).catch((error) => {
                if(error=='end_session') return;
                let errorName = (error.response==undefined) ? 'unknowError' : (error.response.status==403) ? 'forbiddenGeneral' : 'unknowError'
                swal.fire(t(errorName), "", "error");
            }
        )
        setValidButton(false)
        setTimeout(()=>{
            setSuccess(false);
        },1000)
    }

    return(<>
        {(loadingPo) ? 
            <div className="text-center"><div className="spinner-border spinner-border-sm"></div></div>
        :
            (po) ?
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group">
                        <label htmlFor="nomcategorie">{t('purchaseOrderName')+' :'}</label>
                        <input id="name" defaultValue={po.name} className="form-control" type="text" {...register('name', {required: true,minLength:2,maxLength:250})}/>
                        {errors.name?.type === 'required' && (
                        <div className="text-danger mt-1 ml-2">
                            {t('nameRequired')}
                        </div>
                        )}
                        {errors.name?.type === 'minLength' && (
                        <div className="text-danger mt-1 ml-2">
                            {t('nameMin2')}
                        </div>
                        )}
                        {errors.name?.type === 'maxLength' && (
                        <div className="text-danger mt-1 ml-2">
                            {t('nameMax250')}
                        </div>
                        )}
                    </div>
                    <div className="form-group">
                        <label htmlFor="memo">Memo :</label>
                        <textarea defaultValue={po.memo.replace( /(<([^>]+)>)/ig, '')} rows={5} className="form-control" {...register('memo')}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="nomcategorie">{t('state')+' :'}</label>
                        <select defaultValue={po.status} className="form-control" {...register('status')} >
                            <option value="0">{t('notDelivered')}</option>
                            <option value="1">{t('enCours')}</option>
                            <option value="2">{t('completed')}</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="fournisseur">{t('fournisseur')+' :'}</label>
                        <EachDataFilter 
                            fournisseurs={props.fournisseurs} 
                            setFournisseurId={setFournisseurId}
                            poName={po.fournisseur!=null ? po.fournisseur.name : ''}
                        /> 
                    </div>
                    {customFields.map((item,index)=>(
                        <div className="form-group" key={index}>
                            <label htmlFor={item.name}>{item.name}{(item.required) ? '*' : false}</label>
                            {(item.free) ?
                                <>
                                    <input defaultValue={po.custom_fields[item.name]} className="form-control" type="text" {...register(item.name,{required:item.required})}/>
                                    {errors[item.name]?.type === 'required' && (
                                    <div className="text-danger mt-1 ml-2">
                                        {t('youMustIndicateField')+' "'+item.name+'"'}
                                    </div>
                                    )}
                                </>
                                :
                                <>
                                    <select defaultValue={po.custom_fields[item.name]} className="form-control" {...register(item.name,{required:item.required})} >
                                        <option value="">-- Select --</option>
                                        {item.list.map((value,index)=>
                                            <option key={index} value={value}>{value}</option>
                                        )}
                                    </select>
                                    {errors[item.name]?.type === 'required' && (
                                    <div className="text-danger mt-1 ml-2">
                                        {t('youMustIndicateField')+' "'+item.name+'"'}
                                    </div>
                                    )}
                                </>
                            }
                        </div>
                    ))}
                    <SubmitForm error={apiError} success={success} validButton={validButton}/>
                </form>
            :
            false
        }
    </>
    )
}

export default PurchaseOrderModalEdit;