import React, { useState } from 'react';
import {FormatedPrice,GiftCardForm} from '@gull'
import { useTranslate } from 'react-redux-multilingual'

const GiftCardAvailable = (props) =>{
    const t = useTranslate();
    const [showGiftCardFidelite,setShowGiftCardFidelite]=useState(false) 
    
    let current_date = new Date()
    let timestampdate = current_date.setDate(current_date.getDate() + 90)
    let expire_date = new Date(timestampdate)

    const getGiftCardForm = (Montant) =>{
        setShowGiftCardFidelite(Montant)
    }

    const closeGiftCardModal = () =>{
        setShowGiftCardFidelite(false)
    }

    return (
        <div className='row containerModalRewardPosGc'>
            <h4 className='fw-bold text-center mb-4'>{t('giftcard')}</h4>
            {(!showGiftCardFidelite) ?
                Object.entries(props.clientFideliteInfo.data).map(([nbPoint,montantGc],ind)=>{
                    if(props.from=='actionClientBarLeft'){
                        return(
                            <div key={ind} className="col-4 position-relative" style={{padding:"1px"}} onClick={()=>getGiftCardForm(montantGc)}>
                                <div className="col-12 bg-primary rounded raccourciPos text-white">
                                    <div className="textAlign">
                                        <div className='mb-1'>{nbPoint+' Pts'}</div>
                                        <div className='fw-bold fs-5'><FormatedPrice langRegion={'fr-FR'} devise={props.devise} digit={props.digit} priceToFormat={+montantGc} /></div>
                                    </div>
                                </div>
                            </div>
                        )
                    }else{
                        return(
                            <div key={ind} className="col-4 position-relative" style={{padding:"1px"}}>
                                <div className="col-12 bg-primary rounded raccourciHistoClient text-white">
                                    <div className="textAlign">
                                        <div className='mb-1'>{nbPoint+' Pts'}</div>
                                        <div className='fw-bold fs-5'><FormatedPrice langRegion={'fr-FR'} devise={props.devise} digit={props.digit} priceToFormat={+montantGc} /></div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                })
            :
                false
            }
            {(props.from=='actionClientBarLeft') &&
                <div className={`rewardListPos ${showGiftCardFidelite ? 'visible' : ''}`}>
                    <div className="row d-flex justify-content-center">
                        <div className="col-10">
                            <button className='btn btn-sm btn-danger mb-2' onClick={()=>setShowGiftCardFidelite(false)}>{t('btnBack')}</button>
                        </div>
                    </div>
                    <GiftCardForm 
                        posid={props.posid}
                        cookies={props.cookies}
                        setCookie={props.setCookie}
                        currentTicket={props.currentTicket}
                        setCurrentTicket={props.setCurrentTicket}
                        expire_date={expire_date}
                        closeGiftCardModal={closeGiftCardModal}
                        devise={props.devise}
                        digit={props.digit}
                        valueInputClient={props.valueInputClient}
                        clientId={props.clientId}
                        cashierId={props.cashierId}
                        module='pos'
                        showGiftCardFidelite={showGiftCardFidelite}
                        setShowModalFidelite={props.setShowModalFidelite}
                        // aures_2_l={props.aures_2_l}
                        // peripheriqueParam={props.peripheriqueParam}
                    />
                </div>
            }
        </div>
    )
};

export default GiftCardAvailable;