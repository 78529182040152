import React from 'react';
import { useTranslate } from 'react-redux-multilingual'
import Moment from 'react-moment';
import { Link } from "react-router-dom";

const ModalContainOrderDetails = (props) => {
    const t = useTranslate();

    return(<>
        <div className="table-responsive">
            <table
            id="section_stock_table"
            className="table"
            style={{fontSize:'10px'}}
            >
                <thead>
                    <tr>
                        <th scope="col">{t('po')}</th>
                        <th scope="col">{t('requestedDeliveryDate')}</th>
                        <th scope="col">{t('estimateDeliveryDate')}</th>
                        <th scope="col">{t('expectedReceiptDate')}</th>
                        <th scope="col">{t('expectedReceiptDateInShop')}</th>
                        <th scope="col" style={{textAlign:'right'}}>{t('quantiteWait')}</th>
                    </tr>
                </thead>
                <tbody>
                    {props.inSection.map((value,ind)=>
                        <tr key={ind}>
                            {(props.module && props.module=='pos') ?
                                // <td>{value.po}</td>
                                <td className='lien-to'><Link to={'/pos/'+props.posid+'/'+'purchase-order-detail/'+value.id}>{value.po}</Link></td>
                            :
                                <td className='lien-to'><Link to={'/erp/purchase-order/details/'+value.id}>{value.po}</Link></td>
                            }
                            <td>{value.expedition != null ? <Moment format="DD/MM/YYYY">{value.expedition}</Moment> : ''}</td>
                            <td>{value.estimate_expedition != null ? <Moment format="DD/MM/YYYY">{value.estimate_expedition}</Moment> : ''}</td>
                            <td>{value.reception != null ? <Moment format="DD/MM/YYYY">{value.reception}</Moment> : ''}</td>
                            <td>{value.inshop != null ? <Moment format="DD/MM/YYYY">{value.inshop}</Moment> : ''}</td>
                            <td style={{textAlign:'right'}}>{value.quota}</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    </>);
}

export default ModalContainOrderDetails;