import React, { useEffect } from 'react';
import { useTranslate } from 'react-redux-multilingual'
import { useForm } from "react-hook-form";

const EditHeaderForm = (props) =>{
    const t = useTranslate();
    const { register, formState: { errors }, handleSubmit, setValue } = useForm();

    useEffect(function () {
        setValue(props.editName,props.defaultValue)
    }, [])

    return (
        <form onSubmit={handleSubmit(props.onSubmit)} style={{width:'100%'}}>
            <input autoComplete='off' disabled={props.validButton} className="form-control" type="text" {...register(props.editName)}/>
            <button disabled={props.validButton} className="btn btn-sm btn-primary mt-3">
                {props.validButton ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : t('btnValidate')}
            </button>
        </form>
    )
};

export default EditHeaderForm;