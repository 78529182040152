import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamation} from '@fortawesome/free-solid-svg-icons'
import { useTranslate } from 'react-redux-multilingual'

const AlertCurrentReceipt = () =>{
    const t = useTranslate();

    return(
        <div className="alert alert-warning text-center fs-5 fw-bold text-danger">
            <FontAwesomeIcon className="fs-3" icon={faExclamation} /><br />
            {t('mustCompleteOrCancelReceipt')}
        </div>
    )
}

export default AlertCurrentReceipt;