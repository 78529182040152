import React, {useContext} from 'react';
import { useTranslate } from 'react-redux-multilingual'
import { IsAuthContext } from 'app/App';

// Style import
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit,faWindowClose } from '@fortawesome/free-solid-svg-icons';

const BtnEditField = (props) => {
    const t = useTranslate();
    const { authParam } = useContext(IsAuthContext);

    let unauthorizedUser = authParam.access.find(access=>access.action==props.allowAccessName+'write')

    if(unauthorizedUser){//Si unauthorizedUser est true, c'est qu'il y a une restriction sur notre compte, on ne peut donc pas faire d'édition 
        return(<></>)
    }else{
        return(
            <span onClick={() => props.handleEdit(props.isFor)} style={{cursor:'Pointer'}}>
                {!props.onEdit[props.isFor] ?
                    <FontAwesomeIcon className='text-primary' icon={faEdit} />
                :
                    <FontAwesomeIcon className='text-danger' icon={faWindowClose} />
                }
            </span>
        )
    }
}

export default BtnEditField;