import React, { useState } from 'react';
import { useTranslate } from 'react-redux-multilingual'
import axios from 'axios'
import swal from "sweetalert2";

const GiftCardAddFundForm = (props) =>{
    const t = useTranslate();
    const [valueInput,setValueInput]=useState({
        date:'',
        amount:'',
    }) 
    const [validButton,setValidButton]=useState(false) 

    const handleChange = (e,name) =>{
        if(name=='amount'){
            let montant = e.target.value = e.target.value.replace(/\D/g,'')
            setValueInput({...valueInput,[name]:montant})
        }else{
            setValueInput({...valueInput,[name]:e.target.value})
        }
    }
    
    const addFunds = async () =>{
        setValidButton(true)
        const response = await axios.post(process.env.REACT_APP_API_URL+"api/pos/gift_card/create_records/"+props.giftCardId,{
            records_date: valueInput.date,
            section: props.posid,
            montant: valueInput.amount,
        }).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach(interm=>messageError+=t(interm)+'. ')
                messageError=messageError.replace('undefined','');
                swal.fire(messageError, "", "error");
            }
            else{   
                props.setRelance(Date.now)
                setValueInput({
                    date:'',
                    amount:'',
                })
                props.closeModal()
            }
        }).catch((error) => {
            if(error=='end_session') return;
            let errorName = (error.response==undefined) ? 'unknowError' : (error.response.status==403) ? 'forbiddenGeneral' : 'unknowError'
            swal.fire(t(errorName), "", "error");
        })
        setValidButton(false)
    }

    return (
        <div>
            <div className='form-group'>
                <label>{t('depositDate')+' :'}</label>
                <input type="date" value={valueInput.date} className='form-control' onChange={(e)=>handleChange(e,'date')}/>
            </div>

            <div className='form-group'>
                <label>{t('amount')+' :'}</label>
                <input type="text" value={valueInput.amount} className='form-control' onChange={(e)=>handleChange(e,'amount')}/>
            </div>

            <div>
                <button disabled={valueInput.date=='' || valueInput.amount=='' || validButton} className="btn btn-primary mt-3" onClick={()=>addFunds()}>
                    {validButton ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : t('btnValidate')}
                </button>
            </div>
        </div>
    )
};

export default GiftCardAddFundForm;