import React,{useState,useEffect} from "react";
import { useTranslate } from 'react-redux-multilingual'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus,faMinus} from '@fortawesome/free-solid-svg-icons'

const LineItemInventory = (props) =>{
    const t =useTranslate()
    const [inputValue,setInputValue]=useState(props.itemValue.qte)

    useEffect(function () {
        setInputValue(props.itemValue.qte)
        
    }, [props.itemValue.qte])

    const handleChange = (e) =>{
        setInputValue(+e.target.value.replace(/\D/g,''))
    }

    const handleBlur = () =>{
        let currentItem = props.itemsList[props.itemValue.id]
        currentItem.qte = +inputValue
        props.setItemsList({...props.itemsList,[props.itemValue.id]:currentItem})
        if(props.setBlur) props.setBlur(Date.now())
    }
    
    const handleClick = (name) =>{
        if(name=='plus'){
            let currentItem = props.itemsList[props.itemValue.id]
            currentItem.qte = +currentItem.qte + 1
            props.setItemsList({...props.itemsList,[props.itemValue.id]:currentItem})
            setInputValue(+inputValue+1)

            if(props.setBlur) props.setBlur(Date.now())

        }else if(name=='minus'){
            if(inputValue>=1){
                let currentItem = props.itemsList[props.itemValue.id]
                currentItem.qte = currentItem.qte = props.itemValue.qte-1
                props.setItemsList({...props.itemsList,[props.itemValue.id]:currentItem})
                setInputValue(+inputValue-1)

            }
            if(props.setBlur) props.setBlur(Date.now())
        }
    }

    return(
        <>
            <div className={props.style}>
                <div className="row">
                    <strong className="fsArticlePos">{props.itemValue.article_name}</strong> 
                </div>
                <div className="row marginLeftArticle">
                    <div className="col">
                        <div className="row">
                            <div className="col-8" style={{display:'inline-flex'}}>
                                <span>{t('quantity')+' : '}</span>&nbsp;
                                <span>
                                    <input 
                                        value={inputValue} 
                                        className='form-control form-control-sm' 
                                        type="text" 
                                        onChange={(e)=>handleChange(e)}
                                        onBlur={()=>handleBlur()}
                                        />
                                </span>
                            </div>
                            <div className="col-4 text-end">
                                <button className="btn btn-success btn-sm sizeBtnPos" onClick={()=>handleClick('plus')} ><FontAwesomeIcon icon={faPlus} /></button>&nbsp;
                                <button className="btn btn-danger btn-sm sizeBtnPos" onClick={()=>handleClick('minus')} ><FontAwesomeIcon icon={faMinus} /></button>&nbsp;&nbsp;
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LineItemInventory;