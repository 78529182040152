import React,{useState,useEffect,useRef} from "react";
import { CardPerso,CommandeListPos,OrderFormNext} from "@gull";
import { useTranslate } from 'react-redux-multilingual'
import swal from "sweetalert2";
import axios from "axios";
import {Tabs, Tab, Modal} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import BarRight from './BarRight'

const ViewRestauration = (props) => {
    const t =useTranslate()
    const [lastSaleOrder,setLastSaleOrder]=useState(false)
    const [show,setShow]=useState(false) 
    const [modalTitle,setModalTitle]=useState('') 
    const [dataFiltered,setDataFiltered]=useState([]) 
    const [inputValue,setInputValue]=useState('') 
    const [loading,setLoading]=useState(false) 
    const [relanceNumeroFocus,setRelanceNumeroFocus]=useState(Date.now()) 
    const inputNumeroRef = useRef('')

    useEffect(function () {
        setDataFiltered(props.allCommandes)

    }, [props.allCommandes])

    useEffect(function () {
        if(inputNumeroRef.current){
            setTimeout(()=>{
                if(inputNumeroRef.current){
                    inputNumeroRef.current.focus()
                }
            },1000)
        }
        
    }, [relanceNumeroFocus,props.loadingCommande])

    const selectPosInterface = (k)=>{
        if(k=='commandList'){ 
            props.setRelanceCommande(Date.now())
            setRelanceNumeroFocus(Date.now())
        }else{
            props.setRelanceFocus(Date.now())
        }
    }

    const handleFilterChange = (e) => {
        setInputValue(e.target.value)

        const regex = /^[0-9]+$/;
        let rechercheTicket

        if (regex.test(e.target.value)) {
            //La chaîne ne contient que des chiffres
            if(e.target.value.length>3){
                rechercheTicket = e.target.value.slice(-3)
            }else{
                rechercheTicket = e.target.value
            }
        }else {
            //La chaîne contient d'autres caractères en plus des chiffres
            rechercheTicket = e.target.value
        }
        
        
        let filtered = props.allCommandes.filter((item) =>{
            if(item.numero.toString().includes(rechercheTicket)){
                return item.numero.toString().includes(rechercheTicket)

            }else if(item.ticket.client.firstname.toString().toLowerCase().includes(rechercheTicket.toLowerCase())){
                return item.ticket.client.firstname.toString().toLowerCase().includes(rechercheTicket.toLowerCase())

            }else if(item.ticket.client.name.toString().toLowerCase().includes(rechercheTicket.toLowerCase())){
                return item.ticket.client.name.toString().toLowerCase().includes(rechercheTicket.toLowerCase())

            }
        });

        setDataFiltered(filtered);
    };

    const handleKeyPress = async (e) =>{
        if(e.charCode==13 && inputValue.length>5){
            setLoading(true)
            const test = await axios.get(process.env.REACT_APP_API_URL+"api/pos/commande/get_by_reference/"+inputValue+'/'+props.posid
            ).then((response) => {
                if(!response.data.valid){
                    let interms='';
                    var keys = Object.keys(response.data.errors);
                    for(var i = 0; i < keys.length; i++){
                        interms+=response.data.errors[keys[i]].join(',')+','
                    }
                    interms=interms.split(',')
                    let messageError='';
                    interms.forEach(interm=>messageError+=t(interm)+'. ')
                    messageError=messageError.replace('undefined','');
                    swal.fire(messageError, "", "error");
                }else{  
                    setModalTitle(t('salesOrder'))
                    setShow(true)
                    setLastSaleOrder(response.data.data)
                    setInputValue('')
                    setDataFiltered(props.allCommandes)
                    // setLoad({...load,ticket:false})
                }
            }).catch((error) => {
                if(error=='end_session') return;
                swal.fire(t('unknowError'), "", "error");
            })
            setLoading(false)
        }
    }

    const reset = () =>{
        setInputValue('')
        setDataFiltered(props.allCommandes)
    }
    
    const closeModal = (module) =>{
        setLastSaleOrder(false)
        setShow(false)
    }    

    return(<>
        <Modal size="xl" show={show} onHide={() => closeModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>{modalTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    (!lastSaleOrder) ?
                        <span className="d-flex justify-content-center"><div className="spinner-border spinner-border-sm mt-2"></div></span>
                    :
                        <OrderFormNext
                            ticketCommande={lastSaleOrder}
                            setTicketCommande={setLastSaleOrder}
                            posid={props.posid}
                            digit={props.digit}
                            devise={props.devise}

                            from='listSaleOrderPos'
                            expire_date={props.expire_date}
                            cookies={props.cookies}
                            setCookie={props.setCookie}
                            //removeCookie={props.removeCookie}
                            resetSale={props.resetSale}
                            closeModal={closeModal}
                            setIsSalesOrder={props.setIsSalesOrder}
                            setModalTitle={setModalTitle}
                            setShow={setShow}
                            allCommandes={props.allCommandes} 
                            setAllCommandes={props.setAllCommandes}
                            actualShop={props.actualShop}
                        />
                }
            </Modal.Body>
        </Modal>
        <CardPerso id={props.id} >
            <Tabs defaultActiveKey="scanItem" id="uncontrolled-tab-example" className="mt-1" onSelect={(k)=>selectPosInterface(k)}>
                <Tab eventKey="scanItem" title={t('addItems')}>
                    <BarRight
                        id={props.id} 
                        section={props.section}
                        currentTicket={props.currentTicket}
                        setCurrentTicket={props.setCurrentTicket}
                        posid={props.posid}
                        cookies={props.cookies}
                        setCookie={props.setCookie}
                        expire_date={props.expire_date}
                        clientId={props.clientId}
                        cashierId={props.cashierId}
                        setShowModalGiftCard={props.setShowModalGiftCard}
                        affichageClient={props.affichageClient}
                        peripheriqueParam={props.peripheriqueParam}
                        setRelanceFocus={props.setRelanceFocus}
                        relanceFocus={props.relanceFocus}
                        devise={props.devise}
                        view="raccourciCaisseRestauration"//affichage restaurant
                        
                        disabledPay={props.disabledPay}
                        setDisabledPay={props.setDisabledPay}
                        setDisabledCloudButton={props.setDisabledCloudButton}

                        dataNextShortcut={props.dataNextShortcut}
                        setDataNextShortcut={props.setDataNextShortcut}
                        nextShortcutString={props.nextShortcutString}
                        setNextShortcutString={props.setNextShortcutString}
                        lastShortCutClicked={props.lastShortCutClicked}
                        setLastShortCutClicked={props.setLastShortCutClicked}
                        resetSearchRaccourci={props.resetSearchRaccourci}
                    />
                </Tab>
                <Tab eventKey="commandList" title={t('orderList')}>
                    <>
                        <div className="row">
                            <div className="col-8 position-relative">
                                <input id="inputNumeroRef" disabled={props.loadingCommande} ref={inputNumeroRef} type="text" value={inputValue} placeholder={t('searchSalesOrder')} className='form-control mb-3' onChange={(e)=>handleFilterChange(e)} onKeyPress={(e)=>handleKeyPress(e)}/>
                                {(loading) ? <div className="spinner-border spinner-border-sm" style={{position: 'absolute', top: '16%', right: '10%'}}></div> : false}
                            </div>
                            <div className="col-4 p-0 m-0 text-center">
                                <button className="btn btn-sm btn-danger" onClick={()=>reset()}>{t('btnEffacer')}</button>&nbsp;
                                <button className="btn btn-sm btn-warning" onClick={()=>props.setRelanceCommande(Date.now())}><FontAwesomeIcon icon={faSyncAlt} /></button>
                            </div>
                        </div>
                        {(props.loadingCommande) ? 
                            <div className="d-flex justify-content-center"><div className="spinner-border spinner-border-sm"></div></div>
                        :
                            <CommandeListPos
                                dataFiltered={dataFiltered.toReversed()}
                                setLastSaleOrder={setLastSaleOrder}
                                setShow={setShow}
                                setModalTitle={setModalTitle}
                                posid={props.posid}
                            />
                        }
                    </>
                </Tab>
            </Tabs>
        </CardPerso>
    </>
    )
}

export default ViewRestauration;