import React, { useState } from 'react';
import { useTranslate } from 'react-redux-multilingual'
import ListFreeItemCustomerReward from './ListFreeItemCustomerReward'

const FreeItemCustomerReward = (props) =>{
    const t = useTranslate();
    const [showList,setShowList]=useState(false) 

    const getItemsList = (pointCumul) =>{
        setShowList(pointCumul)
    }

    return (
        <div className="containerModalRewardPos">
            {(!showList) &&
                Object.keys(props.clientFideliteInfo.data).map((value,ind)=>{
                    return(
                        <div key={ind} className="row d-flex justify-content-center mb-1">
                            <div className="col-6">
                                <button className='btn btn-primary' style={{width:'100%'}} onClick={()=>getItemsList(value)}>{value+' Points'}</button>
                            </div>
                        </div>
                    )
                })
            }
            
            <div className={`rewardListPos ${showList ? 'visible' : ''}`}>
                <button className='btn btn-sm btn-danger mb-2' onClick={()=>setShowList(false)}>{t('btnBack')}</button>
                <ListFreeItemCustomerReward 
                    showList={showList}
                    setShowList={setShowList}
                    clientFideliteInfo={props.clientFideliteInfo}
                    posid={props.posid}
                    setDisabledPay={props.setDisabledPay}
                    setRelanceFocus={props.setRelanceFocus}
                    setDisabledCloudButton={props.setDisabledCloudButton}
                    clientId={props.clientId}
                    cashierId={props.cashierId}
                    cookies={props.cookies}
                    setCookie={props.setCookie}
                    setCurrentTicket={props.setCurrentTicket} 
                    affichageClient={props.affichageClient}
                    devise={props.devise}
                    digit={props.digit}
                    peripheriqueParam={props.peripheriqueParam}
                    setShowModalFidelite={props.setShowModalFidelite}
                    from={props.from}
                />
            </div>
        </div>
    )
};

export default FreeItemCustomerReward;