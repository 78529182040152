import React, {useState} from "react";
import {ControlAccess} from '@gull'
import { useTranslate } from 'react-redux-multilingual';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollyFlatbed } from '@fortawesome/free-solid-svg-icons';
import { Modal } from "react-bootstrap";
import StockTransferForm from '../form/StockTransferForm'

const  StockTransferBtn = (props) => {
    const t = useTranslate();
    const [show,setShow]=useState(false) 

    const closeModal = () =>{
        setShow(false)
        if(props.setMasqueScan) props.setMasqueScan(false)
    }

    const openModalWms = () =>{
        setShow(true)
        if(props.setMasqueScan) props.setMasqueScan(true)
    }

    return (<>
        <Modal show={show} onHide={() => closeModal()}>
            <Modal.Header closeButton>
                <Modal.Title>{t('transferTo')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {(props.restrictionStockTransfer && !props.restrictionStockTransfer.go) ?
                    <div style={{marginTop: '60px'}}>
                        <ControlAccess focus={true} section={props.section} restriction={props.restrictionStockTransfer} setRestriction={props.setRestrictionStockTransfer} moduleId={props.sectionId} />
                    </div>
                :
                    <StockTransferForm setWmsContenu={props.setWmsContenu} section={props.section} zoneOrigin={props.zoneOrigin} sectionId={props.sectionId} from={props.from} closeModal={closeModal}/>
                }
            </Modal.Body>
        </Modal>
        {(props.icon=='true') ?
            <OverlayTrigger
                placement="bottom"
                overlay={
                    <Tooltip id={`tooltip-${props.index}`}>
                        {t('stockTransfer')}
                    </Tooltip>
                }
            >
                <div style={{cursor:'Pointer'}} onClick={()=>setShow(true)}><FontAwesomeIcon icon={faDollyFlatbed} /></div>
            </OverlayTrigger> 
        :
            <div><button className="btn btn-sm btn-info" onClick={()=>openModalWms()}>{t('stockTransfer')}</button></div>
        }
    </>
         
    )
}

export default StockTransferBtn;