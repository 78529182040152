import React from 'react';
import { useTranslate } from 'react-redux-multilingual'
import { useForm, useFieldArray } from "react-hook-form";
// Style import
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

const EditImageForm = (props) =>{
    const t = useTranslate();

    let objectImageList=[]
    if(props.image==null){
        objectImageList=[{ url: "" }]
    }
    else{
        let imgSplited = props.image.split(';')
        let imgList = imgSplited.filter(image=>image!='') // on split car dans la chaine de caractère qui est récupéré du back, un ; fini le string, du coup on se retrouve avec un chaine vide dans notre array ce qu'i donne une image dans notre carousel

        imgList.forEach(imageUrl=>
            objectImageList.push({ url: imageUrl })
        )
    }

    const { register, control, handleSubmit, reset, formState, errors } = useForm(
        {
            defaultValues: {
                image: objectImageList
            }
        }
    );
    const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
        {
            control,
            name: "image"
        }
    );

    return (
        <div>
            <form onSubmit={handleSubmit(props.onSubmit)}>
                <div className="form-group">
                    <label htmlFor="title">{t('addImageLink')}</label>
                    {fields.map((item, index) => {
                        return (
                            <div className="row" key={index}>
                                <div className="col-10 mt-2">
                                    <input
                                        disabled={props.validButton}
                                        autoComplete='off'
                                        className="form-control mt-2"
                                        name={`image[${index}].url`}
                                        defaultValue={`${item.url}`}
                                        {...register(`image.${index}.url`)}
                                    />
                                </div>
                                <div className="col-2">
                                    <button type="button" disabled={props.validButton} className="btn btn-sm btn-danger mt-2" onClick={() => remove(index)}><FontAwesomeIcon icon={faTimesCircle} /></button>
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div className="form-group">
                    <button
                        disabled={props.validButton}
                        type="button"
                        className="btn btn-sm btn-success mt-2"
                        onClick={() => {
                            append({ url: "" });
                        }}
                    >
                        {t('addImage')}
                    </button>
                </div>    
                <button disabled={props.validButton} className="btn btn-sm btn-primary mt-3">
                    {props.validButton ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : t('btnValidate')}
                </button>
            </form>
        </div>
    )
};

export default EditImageForm;