import React, {useState} from 'react';
import axios from "axios";
import { useTranslate } from 'react-redux-multilingual'
import swal from "sweetalert2";

const LineZoneContain = (props) => {
    const t = useTranslate();
    const [showTd,setShowTd]=useState(false)
    const [zoneContain,setZoneContain]=useState(false)
    let clicableClass={
        0:'',
        1:'tableLineClick'
    }

    const showDetailsSection = (zoneid) => {
        setZoneContain(false)
        setShowTd(!showTd);

        if(!showTd){
            const response = axios.get(process.env.REACT_APP_API_URL+"api/erp/article/stock_in_zone/"+props.articleid+"/"+zoneid
            ).then((response) => {
                setZoneContain(response.data)
            }).catch((error) => {
                if(error=='end_session') return;
                swal.fire(t('unknowError'), "", "error");
            });
        }
    }

    return(
        <>  
            <tr className={(props.value.total>0) ? 'alert-success '+clicableClass[props.value.havebins] : (props.value.total<0) ? 'alert-danger '+clicableClass[props.value.havebins] : clicableClass[props.value.havebins]} onClick={() => showDetailsSection(props.value.zoneid)}>
                <td>{props.keyName}</td>
                <td style={{textAlign:'right'}}>{props.value.total}</td>
            </tr>
            {(props.value.havebins) ?
                (showTd) &&
                <>
                    {(!zoneContain) ?
                        <tr><td><small><div className="spinner-border spinner-border-sm"></div></small></td></tr>
                        :
                        Object.entries(zoneContain.by_bins).map(([keyName, value], ind) =>{
                            return(
                                <tr key={ind}>
                                    <td>{keyName}</td>
                                    <td style={{textAlign:'right'}}>{value}</td>
                                </tr>
                            )
                        })
                    }
                </>
                :
                false
            }
        </>
    )
}


const ModalStockDetails = (props) =>{

    const t = useTranslate();

    return(<>
        {(props.articleid) &&
            <div className="table-responsive">
                <table
                id="section_stock_table"
                className="table"
                >
                    <thead>
                        <tr>
                            <th scope="col">{t('zone')}</th>
                            <th scope="col" style={{textAlign:'right'}}>{t('quantity')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(props.inSection).length!=0 && Object.entries(props.inSection.by_zone).map(([keyName, value], ind) =>
                            <LineZoneContain key={ind} articleid={props.articleid} keyName={keyName} value={value} zoneid={value.zoneid}/>
                            )
                        }
                    </tbody>
                </table>
            </div>
        }
    </>);
}

export default ModalStockDetails;