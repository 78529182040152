export const notificationsType = {
    chat:{
        icon:'i-Speach-Bubble-6',
        bg:'#df6d14',
    },
    to:{
        icon:'i-Car-2',
        bg:'#808080',
    },
    shipment:{
        icon:'i-Plane',
        bg:'#03224c',
    },
    po:{
        icon:'i-Receipt',
        bg:'#008000',
    },
    inventory:{
        icon:'i-Check',
        bg:'#A52A2A',
    },
    cashRegister:{
        icon:'i-Cash-register-2',
        bg:'#8B0000',
    },
    salesOrder:{
        icon:'i-Shopping-Basket',
        bg:'#000000',
    },
    item:{
        icon:'i-Shopping-Basket',
        bg:'#c73b5e',
    }
};