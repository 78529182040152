import { lazy, useContext } from "react";

const Administration = lazy(() => import("./Administration"));
const RhEdit = lazy(() => import("./Rh/RhEdit"));
const Rh = lazy(() => import("./Rh/Rh"));
const SectionEdit = lazy(() => import("./Section/SectionEdit"));
const GradeEdit = lazy(() => import("./Grade/GradeEdit"));
const OtherCompanyEdit = lazy(() => import("./OtherCompany/OtherCompanyEdit"));
const ClientForm = lazy(() => import("./CustomForms/ClientForm"));
const FournisseurForm = lazy(() => import("./CustomForms/FournisseurForm"));
const MarqueForm = lazy(() => import("./CustomForms/MarqueForm"));
const ArticleForm = lazy(() => import("./CustomForms/ArticleForm"));
const PurchaseOrderForm = lazy(() => import("./CustomForms/PurchaseOrderForm"));
const ShipmentForm = lazy(() => import("./CustomForms/ShipmentForm"));
const TransferOrderForm = lazy(() => import("./CustomForms/TransferOrderForm"));
const SalesOrderForm = lazy(() => import("./CustomForms/SalesOrderForm"));
const StockEdit = lazy(() => import("./Section/StockEdit"));
const EcommerceProfil = lazy(() => import("./Ecommerce/EcommerceProfil"));
const ClientList = lazy(() => import("./Client/ClientList"));
const ClientEdit = lazy(() => import("./Client/ClientEdit"));
const CustomizedItem = lazy(() => import("./CustomizedInterface/CustomizedItem"));
const CustomizedPo = lazy(() => import("./CustomizedInterface/CustomizedPo"));
const CustomizedShipment = lazy(() => import("./CustomizedInterface/CustomizedShipment"));
const CustomizedProviders = lazy(() => import("./CustomizedInterface/CustomizedProviders"));
const CustomizedRppsl = lazy(() => import("./CustomizedInterface/CustomizedRppsl"));
const CustomizedTo = lazy(() => import("./CustomizedInterface/CustomizedTo"));
const CustomizedRaccourci = lazy(() => import("./CustomizedInterface/CustomizedRaccourci"));
const Bins = lazy(() => import("./Bins/Bins"));
const GenerateAllBinsBarCode = lazy(() => import("./Bins/GenerateAllBinsBarCode"));
const NotificationListAdmin = lazy(() => import("./Notification/NotificationListAdmin"));
const NotificationDetailAdmin = lazy(() => import("./Notification/NotificationDetailAdmin"));
const HistoriqueDetail = lazy(() => import("./Historique/HistoriqueDetail"));
const ReportPbiConfiguration = lazy (() => import("./ReportPbi/ReportPbiConfiguration"));
const AccessConfiguration = lazy (() => import("./AccessConfiguration/AccessConfiguration"));

const administrationRoutes = [
    {
      path: "/administration/welcome",
      component: Administration
    },
    {
      path: "/administration/section/:id",
      component: SectionEdit
    },
    {
      path: "/administration/grade/:id",
      component: GradeEdit
    },
    {
      path: "/administration/othercompany/:id",
      component: OtherCompanyEdit
    },
    {
      path: "/administration/customforms/client",
      component: ClientForm
    },
    {
      path: "/administration/customforms/fournisseur",
      component: FournisseurForm
    },
    {
      path: "/administration/customforms/marque",
      component: MarqueForm
    },
    {
      path: "/administration/customforms/article",
      component: ArticleForm
    },
    {
      path: "/administration/customforms/bon-de-commande",
      component: PurchaseOrderForm
    },
    {
      path: "/administration/customforms/shipment",
      component: ShipmentForm
    },
    {
      path: "/administration/customforms/to",
      component: TransferOrderForm
    },
    {
      path: "/administration/customforms/commande-client",
      component: SalesOrderForm
    },
    {
      exact: true,
      path: "/administration/rh",
      component: Rh
    },
    {
      path: "/administration/rh/edit/:id",
      component: RhEdit
    },
    {
      path: "/administration/zone/:id",
      component: StockEdit
    },
    {
      exact: true,
      path: "/administration/client",
      component: ClientList
    },
    {
      path: "/administration/client/edit/:id",
      component: ClientEdit
    },
    {
      path: "/administration/customization/item",
      component: CustomizedItem
    },
    {
      path: "/administration/customization/providers",
      component: CustomizedProviders
    },
    {
      path: "/administration/customization/bon-de-commande",
      component: CustomizedPo
    },
    {
      path: "/administration/customization/rppsl",
      component: CustomizedRppsl
    },
    {
      path: "/administration/customization/shipment-voucher",
      component: CustomizedShipment
    },
    {
      path: "/administration/customization/to",
      component: CustomizedTo
    },
    {
      exact:true,
      path: "/administration/bins/:sectionId/:zoneid",
      component: Bins
    },
    {
      path: "/administration/bins/:zoneid/bar-code-pdf/:first/:second/:third/:fourth/:fifth",
      component: GenerateAllBinsBarCode
    },
    {
      path: "/administration/customization/checkout-shortcut",
      component: CustomizedRaccourci
    },
    {
      path: "/administration/e-commerce/:id",
      component: EcommerceProfil
    },
    {
      path: "/administration/notifications/list",
      component: NotificationListAdmin
  },
  {
      path: "/administration/notifications/detail/:id",
      component: NotificationDetailAdmin
  },
  {
    path: "/administration/historique/edit/:id",
    component: HistoriqueDetail
  },
  {
    path: "/administration/report/configuration",
    component: ReportPbiConfiguration
  },
  {
    exact:true,
    path: "/administration/access-configuration",
    component: AccessConfiguration
  },
  ];
  
  export default administrationRoutes;