import React, { useState } from "react";
import {FormatedPrice} from '@gull'
import { useTranslate } from 'react-redux-multilingual'
import axios from 'axios'
import swal from "sweetalert2";

const LineArticleRetour = (props) =>{
    const t = useTranslate();
    const [loadRetour,setLoadRetour]=useState(false)
    const idsLocalStorage=localStorage.getItem("pos").split(',')
    
    const handleClick = async (ticketRetourId,articleRetourId,toUpDate) =>{
        setLoadRetour(true)
        let data={
            retour_id:ticketRetourId,
            article_retour_id:articleRetourId
        }
        if(props.cookies.current_ticket){
            data['id']=props.cookies.current_ticket
        }
        if(props.cashierId){
            data['rh_id']=props.cashierId
        }
        if(props.clientId){
            data['client_id']=props.clientId
        }


        const response = await axios.post(process.env.REACT_APP_API_URL+"api/pos/ticket/construction/"+props.posid+"/"+idsLocalStorage[1]+"/"+idsLocalStorage[0], data
        ).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach(interm=>messageError+=t(interm)+'. ')
                messageError=messageError.replace('undefined','');
                swal.fire(messageError, "", "error");
            }else{   
                props.setCurrentTicket(response.data.data)
                if(!props.cookies.current_ticket){
                    props.setCookie("current_ticket",response.data.data.id,{ path: "/", expires: props.expire_date })
                }

                //mise à jour de la liste des articles du ticket retourné
                let toUpdateLine = props.ticketRetourDetail[toUpDate]
                let unitPrice = +toUpdateLine.prix_remise / +toUpdateLine.quantity

                toUpdateLine.prix_remise = +toUpdateLine.prix_remise - +unitPrice
                toUpdateLine.quantity = +toUpdateLine.quantity - 1
                props.setTicketRetourDetail({...props.ticketRetourDetail})
                setLoadRetour(false)

                let articleAfficheur = response.data.data.ticket_detail.find(value=>value.article_retour_id == articleRetourId)

                if(props.peripheriqueParam && props.peripheriqueParam.afficheur && props.peripheriqueParam.afficheur.model=='second_screen_display'){
                    // affiche et envoyé en param 'reason' pour afficher le contenu du ticket sur l'écran secondaire, si on envoie 'clear' il néttoiera l'ensemble de l'afficheir
                    props.affichageClient(response.data.data,false,false,'add',false,false,props.peripheriqueParam)

                }else{
                    props.affichageClient(articleAfficheur.article_retour.name,articleAfficheur.prix_remise,articleAfficheur.quantity,0,props.devise,t,props.peripheriqueParam)
                    //le 0 sur la variable 'raison' est passé pour définir que les infos ne concerne pas le total
                }
            }
        }).catch((error) => {
            if(error=='end_session') return;
            swal.fire(t('unknowError'), "", "error");
        })
        setLoadRetour(false)
    }

    return(
        <tr
            className={props.style}
            onClick={()=>handleClick(props.value.ticket_id,props.value.article_id,props.lineId)}
        >
            <td>{props.value.article.name}</td>
            <td >{props.value.remise}</td>
            <td >{props.value.quantity}</td>
            <td className="text-end">
                <span>{<FormatedPrice langRegion={'fr-FR'} devise={props.devise} digit={props.digit} priceToFormat={props.value.prix_remise} />}</span>&nbsp;
                {loadRetour ? <span className="spinner-border spinner-border-sm "></span> : false}
            </td>
        </tr>
    )
}

export default LineArticleRetour;