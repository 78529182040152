import React, {useState,useEffect} from "react";
import { useTranslate } from 'react-redux-multilingual';
import {ProfilActionButton} from "@gull";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { promotions2Inputs,promotionsPrefixe } from 'app/allPromotions';
import Moment from 'react-moment';
import {scrollXDataTable} from 'app/scrollXDataTable';
import BtnAddRemovePromoClient from './BtnAddRemovePromoClient'

const CustomDataTable = (props) => {
    const t = useTranslate();
    
    useEffect(() => {
        scrollXDataTable(window.screen.width,props.columns.length)
    }, [window.screen.width,props.columns.length]);
    
    return (
        <BootstrapTable
            {...props.propst.baseProps}
            bootstrap4
            pagination={paginationFactory(props.paginationOptions)}
            noDataIndication={t('noAssignablePromo')}
            rowStyle={ props.rowStyle }
            defaultSortDirection={props.defaultSortDirection}
        />
    );
};

const AssignablePromoList = (props) => {
    const t = useTranslate();
    let { SearchBar } = Search;
    const [perPage,setPerPage]=useState(50)

    let datas = new Array
    props.promoToAssign.forEach((value,ind)=>{
        let percentPromo = value.type.includes('percent')
        let promo

        if(promotions2Inputs.includes(value.type)){
                promo = value.value[0]+' '+t('for')+' '+value.value[1]

        }else if(promotionsPrefixe.includes(value.type)){
            if(percentPromo){
                if(value.value.length>1){
                    let promoStr = ''
                    value.value.map(promoAmount=>{
                        promoStr += '-'+promoAmount+'% '
                    })
                    promo = promoStr
                }else{
                    promo = '-'+value.value+'%'
                }
            }else{ 
                promo = '-'+value.value
            }
        }else{
            promo = value.value
        }
       
        let obj = {
            name: value.name,
            poids:value.poids,
            masque:'',
            type: t(value.type),
            promotion: promo,
            begin: value.begin != null ? <Moment format="DD/MM/YYYY">{value.begin}</Moment> : t('undefined'),
            end: value.end != null ? <Moment format="DD/MM/YYYY">{value.end}</Moment> : t('undefined'),
            id: value.id,
            on_client: (value.on_client==0) ? t('allCustomer') : t('listOfCustomer'),
            action: 
                <span className="d-flex justify-content-between">
                    <ProfilActionButton path={'/erp/promotion/detail/'+value.id} openWindow={true}/>
                    <BtnAddRemovePromoClient 
                        message='attribuer' 
                        icon='faPlus' 
                        iconColor='text-success' 
                        action='add' 
                        promoId={value.id}
                        clientId={props.clientId}
                        promoToAssign={props.promoToAssign}
                        setPromoToAssign={props.setPromoToAssign}
                        promoAlreadyAssign={props.promoAlreadyAssign}
                        setPromoAlreadyAssign={props.setPromoAlreadyAssign}
                        promoAlreadyAssignIds={props.promoAlreadyAssignIds}
                        setPromoAlreadyAssignIds={props.setPromoAlreadyAssignIds}
                    />
                </span>,
            index:ind
        }
        datas.push(obj)
    })

    const sizePerPageOptionRenderer = ({
        text,
        page,
        onSizePerPageChange
    }) => (
        <li
            key={ text }
            role="presentation"
            className="dropdown-item"
            onMouseDown={ (e) => {
                e.preventDefault();
                onSizePerPageChange(page);
                setPerPage(page)
            } }
        >
            <a
                href="#"
                tabIndex="-1"
                role="menuitem"
                data-page={ page }
                style={ { color: 'black' } }
            >
                { text }
            </a>
        </li>
    );

    let paginationOptions = {
        paginationSize: 5,
        pageStartIndex: 1,
        firstPageText: t('tableFirst'),
        prePageText: t('tableBack'),
        nextPageText: t('tableNext'),
        lastPageText: t('tableLast'),
        nextPageTitle: t('tableNextTitle'),
        prePageTitle: t('tableBackTitle'),
        firstPageTitle: t('tableFistTitle'),
        lastPageTitle: t('tableLastTitle'),
        sizePerPageOptionRenderer,
        sizePerPage: perPage,
        showTotal: true,
        totalSize: datas.length
    };

    const headerSortingStyle = { backgroundColor: '#c8e6c9' };

    let columns = [
        {
            dataField: "action",
            text: 'Actions',
            editable: false,
            // headerClasses: 'head-action-row',
            // classes: 'body-action-row',
        },
        // {
        //     dataField: "masque",
        //     text: '',
        //     editable: false,
        //     formatter: (rowContent, row) => {
        //         return (    
        //             <div style={{display:"block",width:"82px"}}></div>
        //         )
        //     },
        //     style: (cell, row, rowIndex, colIndex) => {
        //         return {
        //             width:'99px',
        //             backgroundColor:'white'
        //         };
        //     },
        // },
        {
            dataField: "name",
            text: t('tableName'),
            editable: false,
            sort: true,
            headerSortingStyle
        },
        {
            dataField: "poids",
            text: t('promoWeight'),
            editable: false,
            sort: true,
            headerSortingStyle
        },
        {
            dataField: "type",
            text: 'Type',
            editable: false,
            sort: true,
            headerSortingStyle
        },
        {
            dataField: "promotion",
            text: t('promotion'),
            editable: false,
            sort: true,
            headerSortingStyle
        },
        {
            dataField: "begin",
            text: t('beginPromo'),
            editable: false,
            sort: true,
            headerSortingStyle
        },
        {
            dataField: "end",
            text: t('endPromo'),
            editable: false,
            sort: true,
            headerSortingStyle
        },
    ];

    return (<>
            <small>
                <ToolkitProvider
                    keyField="index"
                    data={datas}
                    columns={columns}
                    search
                >
                {(propst) => (
                    <>
                        <div className="d-flex justify-content-end align-items-center">
                            <span className="mb-2 me-1">{t('tableSearch')}:</span>
                            <SearchBar {...propst.searchProps} className="mb-2" />
                        </div>
                        <div className="position-relative">
                            <CustomDataTable 
                                propst={propst}
                                paginationOptions={paginationOptions}
                                columns={columns}
                                defaultSortDirection="asc"
                            />
                        </div>
                    </>
                )}
                </ToolkitProvider>
            </small>
        </>
    );
}

export default AssignablePromoList;
