import React, {useState} from 'react';
import axios from 'axios';
import { useTranslate } from 'react-redux-multilingual';
import swal from "sweetalert2";

const ConfigSubmit = (props) => {
    const t = useTranslate();

    const [validButton,setValidButton] = useState(false)
    const [apiError,setApiError] = useState(false)
    const [success,setSuccess] = useState(false)

    const onSubmit = async () => {
        setSuccess(false)
        setApiError(false)
        setValidButton(true)
        const response  = await axios.post(process.env.REACT_APP_API_URL+"api/custom_interface",{ 
            interface : props.interface,
            default: props.config,
            details: props.datas
        }).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach(interm=>messageError+=t(interm)+'. ')
                messageError=messageError.replace('undefined','');
                setApiError(messageError)
            }
            else{
                setSuccess(t('successOperation'))

                if(props.setRepere){
                    props.setRepere(Date.now())
                }else if(props.setReload){
                    props.setReload(Date.now())
                }
                // if(props.interface==='filtreArticle' && props.setReload){
                //     props.setReload(Date.now())
                // }else if(props.interface==='filtrePo' && props.setReload){
                //     props.setReload(Date.now())
                // }else if(props.interface==='filtreShipment' && props.setReload){
                //     props.setReload(Date.now())
                // }else if(props.interface==='filtreRppsl' && props.setReload){
                //     props.setReload(Date.now())
                // }
            }
        }).catch((error) => {
            if(error=='end_session') return;
            let errorReturn
            if(error.response==undefined){
                errorReturn='unknowError'
            }
            else if(error.response.status==403){
                errorReturn='forbiddenGeneral'
            }
            else {
                errorReturn='unknowError'
            }
            swal.fire(t(errorReturn), "", "error");
        })
        setValidButton(false)
        setTimeout(() => {
            setSuccess(false)
          }, 1000);
    }

    return(<>
        {(success) ? <div className="alert alert-success mt-1 col-3" dangerouslySetInnerHTML={{ __html: success }}></div> : false}
        {(apiError) ? <div className="alert alert-danger mt-1 col-3" dangerouslySetInnerHTML={{ __html: apiError}}></div> : false}
        <button 
            className="btn btn-primary mb-3" 
            onClick={()=> onSubmit()}
        >
            {validButton ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : t('btnValidate')}
        </button>
        </>
    )
}

export default ConfigSubmit;