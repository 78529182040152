import React from 'react';
import { useTranslate } from 'react-redux-multilingual'

const SelectZoneOrBins = (props) =>{
    const t = useTranslate();

    const handleChange = (e,name) =>{
        props.setValue({...props.value,[name]:e.target.value})
        // setTimeout(() => {
        //     if(name=='zone' && props.hasOwnProperty('autoValidClick') && props.autoValidClick=='true') props.actionSubmit('zone',e.target.value) //Pour les zones si le param autoValidClick est passé on exécute un click sur le bouton
        // }, 200);
    }

    const handleKeyPress = (e) => {
        if(e.charCode==13){
            setTimeout(() => {
                if(props.hasOwnProperty('autoValidClick') && props.autoValidClick=='true') props.actionSubmit('bins',e.target.value) //Pour les bins si le param autoValidClick est passé on exécute un click sur le bouton
            }, 200);
        }
    };

    return (
        <div>
            <div className="form-group">
                <label>{t('storageArea')}</label>
                <select disabled={props.value.bins!='' || props.validButton} className="form-control" onChange={(e)=>handleChange(e,'zone')}>
                    <option value="">-- Select --</option>
                    {props.section.zone.map(value=>{
                        if(value.havebins==0){
                            return(
                                <option key={value.id} value={value.id}>{value.name}</option>
                            )
                        }
                    })}
                </select>
            </div>
            <div className='d-flex justify-content-center fw-bold mt- mb-1'>{t('or')}</div>
            <div className="form-group">
                <label>Bins</label>
                <input disabled={props.value.zone!='' || props.validButton} className="form-control" type='text' onKeyPress={(e)=>handleKeyPress(e)} onChange={(e)=>handleChange(e,'bins')}/>
            </div>
            {(props.success) ? <div className="alert alert-success mt-3">{t('successOperation')}</div> : false}
            {(props.error) ? <div className="alert alert-danger mt-3">{props.error}</div> : false}
            <button 
                className="btn btn-primary mt-2"
                disabled={(props.value.zone=='' && props.value.bins=='') || props.validButton}
                onClick={() =>props.actionSubmit()}
            >
                {props.validButton ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : t('btnValidate')}
            </button>
        </div>
    )
};

export default SelectZoneOrBins;