export const allPromotions = [
    'percent',
    'franche',
    'prixfixe',
    'xpoury',
    'xpourfixe',
    'percentsecond',
    'suppercent',
    // 'cadeaupourx'
];
export const promotions2Inputs = [
    'xpoury',
    'xpourfixe',
];
export const promotionsPrefixe = [
    'percent',
    'franche',
    'prixfixe',
    'percentsecond',
    'suppercent',
];
export const promotionsDescription = {
    percent:'percentDesc',
    franche:'francheDesc',
    prixfixe:'prixfixeDesc',
    xpoury:'xpouryDesc',
    percentsecond:'percentsecondDesc',
    xpourfixe:'xpourfixeDesc',
    suppercent:'suppercentDesc',
    // cadeaupourx:'cadeaupourxDesc'
};
export const promotionsSecond = [
    'percentsecond',
];
export const promotionsPercent = [
    'percent',
    'percentsecond',
    'suppercent',
];
