import React, { useState,useEffect,useContext,useRef} from 'react';
import { useTranslate } from 'react-redux-multilingual'
import axios from 'axios'
import {LoaderDot,Loading} from '@gull'
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import { useHistory,useParams,useLocation,Link } from "react-router-dom";
// Style import
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus, faPaperPlane,faSignOutAlt, faArrowLeft,faUsers} from '@fortawesome/free-solid-svg-icons';
import {IsAuthContext} from 'app/App';
import swal from "sweetalert2";
import AddUserToConvers from './NotifDetailComp/AddUserToConvers'
import { Modal,Badge } from "react-bootstrap";
import DropdownButton from 'react-bootstrap/DropdownButton';
import Moment from 'react-moment';

const useLoad = (id) =>{
    const t = useTranslate();
    const [notificationDetail,setNotificationDetail]=useState({}) 
    const [newNotifCount,setNewNotifCount]=useState(0) 
    const [error,setError]=useState(false)
    const [loading,setLoading]=useState(true) 
    const [relance,setRelance]=useState(Date.now()) 
    const timeoutRef = useRef(null);

    useEffect(function () {
        (async function() {
            const response = await axios.get(process.env.REACT_APP_API_URL + "api/alert/get_all_contains/"+id
            ).then(response => {
                if(!response.data.valid){
                    let interms='';
                    var keys = Object.keys(response.data.errors);
                    for(var i = 0; i < keys.length; i++){
                        interms+=response.data.errors[keys[i]].join(',')+','
                    }
                    interms=interms.split(',')
                    let messageError='';
                    interms.forEach(interm=>messageError+=t(interm)+'. ')
                    messageError=messageError.replace('undefined','');
                    setError(messageError)
                }else{
                    setNotificationDetail(response.data.data);
                }
            }).catch(error => {
                if(error=='end_session') return;
                if(error.response==undefined){
                    setError('unknowError')

                }else if(error.response.status==403){
                    setError('forbiddenGeneral')

                }else {
                    setError('unknowError')
                }
            });
            setLoading(false)
        })()
    }, [relance])

    useEffect(function () {
        (async function() {
            const response = await axios.get(process.env.REACT_APP_API_URL + "api/alert/count_no_vu"
            ).then(response => {
                setNewNotifCount(response.data.count);
            }).catch(error => {
                if(error === "end_session"){ 
                    return
                }
                if(error.response==undefined){
                    setError('unknowError')

                }else if(error.response.status==403){
                    setError('forbiddenGeneral')

                }else {
                    setError('unknowError')
                }
            });
            timeoutRef.current = setTimeout(function () {
                setRelance(Date.now())
            }, 120000);
        })()
    }, [relance])
    
    return [notificationDetail,setNotificationDetail,error,setError,loading,newNotifCount,setRelance,timeoutRef ]
}

const NotificationDetailTemplate = (props) =>{
    const t = useTranslate();
    const { id } = useParams();
    const { authParam } = useContext(IsAuthContext)
    const [notificationDetail,setNotificationDetail,error,setError,loading,newNotifCount,setRelance,timeoutRef ] = useLoad(id)

    const [allMessages,setAllMessages]=useState([]) 
    const [messageValue,setMessageValue]=useState('') 
    const [showFormAddUser,setShowFormAddUser]=useState(false) 

    const messageContainerRef = useRef('')
    const history = useHistory()
    const location = useLocation();
    const domain = location.pathname.split('/');
    let previousUser = null
    let path = (domain[1] == 'notifications') ? "/notifications/list/" : (domain[3] == 'notifications') ? '/'+domain[1]+'/'+domain[2]+'/notifications/list/' : '/'+domain[1]+'/notifications/list/'
                                                                        //Dans le cas où on serait sur les page pos, wms                                    Dans le cas où on serait sur les page erp, administration, user

    useEffect(function () {
        if(Object.keys(notificationDetail).length!=0) setAllMessages(notificationDetail.alert_contains.reverse())

    }, [notificationDetail])

    useEffect(() => {
        if (messageContainerRef.current) {
            const { scrollHeight, clientHeight } = messageContainerRef.current;
            messageContainerRef.current.scrollTop = scrollHeight - clientHeight;
        }
    }, [allMessages]);



    const leaveConversation = async () =>{
        const response = await axios.delete(process.env.REACT_APP_API_URL + "api/alert/quit_alert/"+id
        ).then(response => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach(interm=>messageError+=t(interm)+'. ')
                messageError=messageError.replace('undefined','');
                setError(messageError)
            }else{
                if(domain[1] == 'notifications'){
                    history.push("/notifications/list/")
                }else if(domain[3] == 'notifications'){
                    //Dans le cas où on serait sur les page pos, wms
                    history.push('/'+domain[1]+'/'+domain[2]+'/notifications/list/')
                }else{
                    //Dans le cas où on serait sur les page erp, administration, user
                    history.push('/'+domain[1]+'/notifications/list/')
                }
            }
        }).catch(error => {
            if(error=='end_session') return;
            if(error.response==undefined){
                setError('unknowError')

            }else if(error.response.status==403){
                setError('forbiddenGeneral')

            }else {
                setError('unknowError')
            }
        });
    }
    
    const handleClick = (name) =>{
        if(name == 'leave'){
            swal
            .fire({
                title: t('leaveNotifConfirm')+" ?",
                icon: "warning",
                type: "question",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: t('btnYes'),
                cancelButtonText: t('btnNo'),
            })
            .then((result) => {
                if (result.value) {
                    leaveConversation()
                }
            });
        }else if(name == 'add'){
            setShowFormAddUser(true)
        }
    }
    
    const handleChange = (e) =>{
        setMessageValue(e.target.value)
    }

    const sendMessage = async () =>{
        if(messageValue!='' && messageValue.length>=2){
            const response = await axios.post(process.env.REACT_APP_API_URL+"api/alert/add_content/"+id,{
                user_id:authParam.userid,
                message:messageValue
            }).then(response => {
                if(!response.data.valid){
                    let interms='';
                    var keys = Object.keys(response.data.errors);
                    for(var i = 0; i < keys.length; i++){
                        interms+=response.data.errors[keys[i]].join(',')+','
                    }
                    interms=interms.split(',')
                    let messageError='';
                    interms.forEach(interm=>messageError+=t(interm)+'. ')
                    messageError=messageError.replace('undefined','');
                    setError(messageError)
                }else{
                    setAllMessages(response.data.data.alert_contains.reverse());
                    setMessageValue('')
                    if (messageContainerRef.current) {
                        const { scrollHeight, clientHeight } = messageContainerRef.current;
                        messageContainerRef.current.scrollTop = scrollHeight - clientHeight;
                    }
                }
            }).catch(error => {
                if(error=='end_session') return;
                if(error.response==undefined){
                    setError('unknowError')

                }else if(error.response.status==403){
                    setError('forbiddenGeneral')

                }else {
                    setError('unknowError')
                }
            });
        }else{
            setError('errorMsgMin2')
        }
    }

    let styleTop = (props.module && props.module=='pos') ? 'position-relative mtArticlePos' : 'position-relative'

    return (
        <div className={styleTop} style={{height:'65vh'}}> 
            <Modal size="xl" show={showFormAddUser} onHide={() => setShowFormAddUser(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('addUsers')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AddUserToConvers setShowFormAddUser={setShowFormAddUser} notificationDetail={notificationDetail} setNotificationDetail={setNotificationDetail} setRelance={setRelance} timeoutRef={timeoutRef} id={id}/>
                </Modal.Body>
            </Modal>
            <h4 className='text-center fw-bold'>{(notificationDetail.titre) && notificationDetail.titre}</h4>
            <div className='mb-3 d-flex justify-content-end'>
                <Link className='btn btn-sm btn-light' to={path}>
                    <FontAwesomeIcon icon={faArrowLeft} />&nbsp;&nbsp;
                    {(window.screen.width<500) ? false : <span>{t('retour')}&nbsp;</span>}
                    {(newNotifCount>0) ? <Badge bg="danger" text='white' pill>{newNotifCount}</Badge> : false}
                </Link>&nbsp;&nbsp;
                <DropdownButton size="sm" id="dropdown-basic-button" title={(window.screen.width<500) ? <FontAwesomeIcon icon={faUsers} /> : <span><FontAwesomeIcon icon={faUsers} />&nbsp;{t('member')}</span>}>
                    <ul className="list-group list-group-flush">
                        {(Object.keys(notificationDetail).length!=0) ?
                            notificationDetail.alert_vu.map(value=>{
                                return(
                                    <li key={value.user.id} className="list-group-item">
                                        <div>
                                            {value.user.name}
                                        </div>
                                        <div className='text-end fw-bold' style={{fontSize:'0.8em'}}>
                                            {(value.vu==0) ?
                                                t('unread')
                                            :
                                                t('read')
                                            }
                                        </div>
                                        
                                    </li>
                                )
                            })
                        :
                            false
                        }
                    </ul>
                </DropdownButton>&nbsp;&nbsp;
                <button className='btn btn-sm btn-info' onClick={()=>handleClick('add')}><FontAwesomeIcon icon={faUserPlus} /></button>&nbsp;&nbsp;   
                <button className='btn btn-sm btn-danger' onClick={()=>handleClick('leave')}> 
                    <FontAwesomeIcon icon={faSignOutAlt} />&nbsp;
                    {(window.screen.width<500) ? t('leave') : t('leaveConversation')}
                </button>
            </div>
            {/* position:'absolute', bottom:'0', */}
            <div style={{height:'90%', display:'flex', flexDirection:'column', justifyContent:'end',  width:'100%'}}>
                <div style={{maxHeight:'100%', overflowY: 'auto', paddingBottom:'2.5rem'}} ref={messageContainerRef}>
                    <div className='d-flex flex-column justify-content-end' >
                        {(loading) ? 
                            <span style={{position:'absolute',left:'50%',bottom:'10%'}}>
                                <LoaderDot />
                            </span>
                        :
                            allMessages.map(value=>{
                                if(value.user!=null){//Si le message vient d'un utilisateur
                                    let showUserName = previousUser != value.user.id
                                    previousUser = value.user.id
                                    
                                    if(value.user_id==authParam.userid){
                                        return(
                                            <div key={value.id} className={`d-flex justify-content-end position-relative ${(showUserName) ? 'mt-4' : '' }`}>
                                                {showUserName && <div style={{position:'absolute',top:'-20px',fontWeight:'bold'}}>{value.user.name}</div>}
                                                <div style={{maxWidth:'45%'}} className='message-bubble-right'>
                                                    <div className='mb-2' dangerouslySetInnerHTML={{ __html: value.message }}></div>
                                                    <div className='text-end'>
                                                        <Moment style={{fontSize:'0.8em'}} format="DD/MM/YYYY HH:mm:ss">{value.created_at}</Moment>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        )
                                    }else{
                                        return(
                                            <div key={value.id} className={`d-flex justify-content-start position-relative ${(showUserName) ? 'mt-4' : '' }`}>
                                                {showUserName && <div style={{position:'absolute',top:'-20px',fontWeight:'bold'}}>{value.user.name}</div>}
                                                <div style={{maxWidth:'45%'}} className='message-bubble-left'>
                                                    <div className='mb-2' dangerouslySetInnerHTML={{ __html: value.message }}></div>
                                                    <Moment style={{fontSize:'0.8em'}} format="DD/MM/YYYY HH:mm:ss">{value.created_at}</Moment>
                                                </div>
                                            </div>
                                        )
                                    }
                                }else{//Dans le cas d'un message automatique 
                                    return(
                                        <div key={value.id} className='d-flex justify-content-start position-relative'>
                                            <div style={{maxWidth:'45%'}} className='message-bubble-left'>
                                                <div className='mb-2' dangerouslySetInnerHTML={{ __html: value.message }}></div>
                                                <Moment style={{fontSize:'0.8em'}} format="DD/MM/YYYY HH:mm:ss">{value.created_at}</Moment>
                                            </div>
                                        </div>
                                    )
                                }
                            })
                        }
                        {(error) ? <div className="alert alert-danger mt-3" dangerouslySetInnerHTML={{ __html: t(error) }}></div> : false}
                    </div>
                </div>
            </div>
                {/*  style={{position:'absolute', bottom:'-10%'}} */}
            <div className="input-group mb-3">
                <textarea value={messageValue} className="form-control rounded-0" placeholder={t('writeNewMessageHere')} onChange={(e)=>handleChange(e)} style={{resize:'none'}}></textarea>
                <div className="input-group-append">
                    <button className="btn btn-primary rounded-0" onClick={()=>sendMessage()} style={{height:'100%'}}><FontAwesomeIcon icon={faPaperPlane} /></button>
                </div>
            </div>
        </div>
    )
};

export default NotificationDetailTemplate;