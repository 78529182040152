import React, {useState} from "react";
import {CarouselImage} from '@gull'
import { useTranslate } from 'react-redux-multilingual'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus,faMinus} from '@fortawesome/free-solid-svg-icons'
import { Modal } from "react-bootstrap";

const LineItemTo = (props) =>{
    const t =useTranslate()
    const [showCarousel,setShowCarousel]=useState(false) 
    const [qteInput,setQteInput]=useState(props.value.quantity) 

    const handleChange = (e) =>{
        let valueQte = e.target.value.replace(/\D/g,'')
        setQteInput(valueQte)
        if(/^-?\d*\.?\d+$/.test(valueQte)) {
            

            let currentItem = props.articleToList[props.value.id]
            currentItem.quantity = +valueQte
            props.setArticleToList({...props.articleToList,[props.value.id]:currentItem})
        }
    }
    
    const handleClick = (name) =>{
        if(name=='plus'){
            let currentItem = props.articleToList[props.value.id]
            currentItem.quantity = currentItem.quantity = props.value.quantity+1
            props.setArticleToList({...props.articleToList,[props.value.id]:currentItem})
            setQteInput(+qteInput + 1)
        }else if(name=='minus'){
            if(props.value.quantity>=2){
                let currentItem = props.articleToList[props.value.id]
                currentItem.quantity = currentItem.quantity = props.value.quantity-1
                props.setArticleToList({...props.articleToList,[props.value.id]:currentItem})
                setQteInput(+qteInput - 1)
            }
        }else{
            //on supprime la ligne concerné
            let ajour={}
            Object.entries(props.articleToList).map(([key,value])=>{
                if(key!=props.value.id){
                    ajour[key]=value
                }
            })
            props.setArticleToList(ajour)
        }
    }

    return(
        <>
            <Modal show={showCarousel} onHide={()=>setShowCarousel()}>
                <Modal.Header closeButton>
                    <Modal.Title>{props.value.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {(props.value && props.value.image) ?
                        <CarouselImage images={props.value.image} />
                    :
                        <div></div>
                    }
                </Modal.Body>
            </Modal>
            <div className={props.style}>
                <div className="row">
                    <div className="col-8">
                        <strong className="fsArticlePos">{props.value.name}</strong> 
                    </div>
                    <div className="col-4">
                        {(props.value && props.value.image) ?
                            <div className='text-end mb-1' style={{maxHeight:'60px'}}>
                                <img className="pointer" style={{maxHeight:'50px', maxWidth:'70px'}} src={props.value.image.split(';')[0]} alt={props.value.name} onClick={()=>setShowCarousel(true)}/>
                            </div>
                        :
                            <div className='text-end mb-1' style={{maxHeight:'60px'}}>
                                <img style={{maxHeight:'50px', maxWidth:'70px'}} src="/assets/images/no-image.png" alt="No image" />
                            </div>
                        }
                    </div>
                </div>
                <div className="row marginLeftArticle">
                    <div className="col">
                        <div className="row">
                            <div className="col-2 d-flex align-items-center">
                                <strong>X</strong>
                                <input value={qteInput} type="text" className="form-control form-control-sm" onChange={(e)=>handleChange(e)}/>
                            </div>
                            <div className="col-10 text-end">
                                <button className="btn btn-success btn-sm sizeBtnPos" onClick={()=>handleClick('plus')} ><FontAwesomeIcon icon={faPlus} /></button>&nbsp;
                                <button className="btn btn-danger btn-sm sizeBtnPos" onClick={()=>handleClick('minus')} ><FontAwesomeIcon icon={faMinus} /></button>&nbsp;&nbsp;
                                <button className="btn btn-danger btn-sm sizeBtnPos" onClick={()=>handleClick('delete')} >{t('btnDelete')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LineItemTo;