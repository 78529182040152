import React, {useState} from 'react';
import { useTranslate } from 'react-redux-multilingual';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import swal from "sweetalert2";
import axios from 'axios'

// Style import
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClone } from '@fortawesome/free-solid-svg-icons';


const BtnSplitPo = (props) =>{
    const t = useTranslate();
    const [validButton,setValidButton]=useState(false) 

    const handleClick = () =>{
        swal
        .fire({
            title: t('duplicatePoReliquatConfirm')+" ?",
            icon: "warning",
            type: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: t('btnYes'),
            cancelButtonText: t('btnNo'),
        })
        .then((result) => {
            if (result.value) {
                duplicateApiCall()
            }
        });
    
    }
    
    const duplicateApiCall = async () => {
        setValidButton(true)
        const response  = await axios.put(process.env.REACT_APP_API_URL+"api/erp/po_details/divide_po_details/"+props.lineId
        ).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach((interm)=>{
                    let finalMessage=interm.split('/!');
                    if(finalMessage.length==2){
                        messageError+=t(finalMessage[0],{name:finalMessage[1]})+'<br/>'
                    }
                    else{
                        messageError+=t(interm)+'<br/>'
                    }
                })
                messageError=messageError.replace('undefined','');
                swal.fire(t(messageError), "", "error");
            }
            else{
                props.setReload(Date.now())
                setValidButton(false)
            }
        }).catch((error) => {
            if(error=='end_session') return;
            let errorName = (error.response==undefined) ? 'unknowError' : (error.response.status==403) ? 'forbiddenGeneral' : 'unknowError'
            swal.fire(t(errorName), "", "error");
            setValidButton(false)
        })
    }

    return (
        <OverlayTrigger
            placement="bottom"
            overlay={
                <Tooltip id={`tooltip-${props.index}`}>
                    {t('duplicatePoReliquat')}
                </Tooltip>
            }
        >
            <span onClick={() => handleClick()} style={{cursor:'Pointer', color:'#ff00ff'}}>{(validButton) ? <div className="spinner-border spinner-border-sm"></div> : <FontAwesomeIcon icon={faClone} /> }</span>
        </OverlayTrigger>  
    )
};

export default BtnSplitPo;