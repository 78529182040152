import React, { useState } from 'react';
import { useTranslate } from 'react-redux-multilingual'
import { Dropdown} from "react-bootstrap";


const CreditGiftCard = (props) =>{
    const t = useTranslate();

    return (
        <Dropdown.Item eventKey="4" onClick={()=>props.handleClick('giftcardcredit')}>{t('giftCardCredit')}</Dropdown.Item>
    )
};

export default CreditGiftCard;