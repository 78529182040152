import React from 'react';
import {CreateItem} from "@gull";

const MasterCloudCreateItem = (props) =>{
    
    if(props.modulePos.params.article.active && !Array.isArray(props.modulePos.params.article.params) && props.modulePos.params.article.params.hasOwnProperty('create_article') && props.modulePos.params.article.params.create_article){
        return(<CreateItem {...props}/>)
        
    }else{
        return(<></>)
    }
};

export default MasterCloudCreateItem;