import React, {useState,useEffect} from "react";
import { useTranslate } from 'react-redux-multilingual'
import { useForm } from "react-hook-form";
import axios from 'axios'
import swal from "sweetalert2";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'

import SendTestEmail from './EmailConfig/SendTestEmail'

const useLoad = (from, id,setValue) => {
    const t = useTranslate();
    const [configEmailId,setConfigEmailId]=useState(false)

    useEffect(function () {
        (async function() {
            const response  = await axios.get(process.env.REACT_APP_API_URL+"api/mail/get_all_mail_config"
            ).then((response) => {
                if(!response.data.valid){
                    let interms='';
                    var keys = Object.keys(response.data.errors);
                    for(var i = 0; i < keys.length; i++){
                        interms+=response.data.errors[keys[i]].join(',')+','
                    }
                    interms=interms.split(',')
                    let messageError='';
                    interms.forEach((interm)=>{
                        let finalMessage=interm.split('/!');
                        if(finalMessage.length==2){
                            messageError+=t(finalMessage[0],{name:finalMessage[1]})+','
                        }
                        else{
                            messageError+=t(interm)+','
                        }
                    })
                    messageError=messageError.replace('undefined','');
                    swal.fire(messageError, "", "error");
                }
                else{
                    let configEmailInit
                    if(from=='company'){
                        configEmailInit = response.data.datas.find(value=>value.other_company_id===null && value.section_id===null)

                    }else if(from=='other_company'){
                        configEmailInit = response.data.datas.find(value=>value.other_company_id==id.toString())
                    }
                    else if(from=='section'){
                        configEmailInit = response.data.datas.find(value=>value.section_id==id.toString())
                    }

                    if(configEmailInit){
                        setValue('name',configEmailInit.name)
                        setValue('address',configEmailInit.address)
                        setValue('password',configEmailInit.password)
                        setValue('host',configEmailInit.host)
                        setValue('port',configEmailInit.port)
                        setValue('username',configEmailInit.username)
                        setConfigEmailId(configEmailInit.id)
                    }
                }
            }).catch((error) => {
                if(error=='end_session') return;
                let messageError = (error.response && error.response.status==403) ? t('forbiddenGeneral') : t('unknowError')
                swal.fire(messageError, "", "error");
            })

        })()
    }, [id])

    return [configEmailId,setConfigEmailId]
}

const EmailAccountForm = (props) => {
    const t = useTranslate();
    const { register, formState: { errors }, handleSubmit, setValue, reset } = useForm();
    const [configEmailId,setConfigEmailId] = useLoad(props.from,props.id,setValue)
    const [validButton,setValidButton]=useState(false) 
    const [validButtonDel,setValidButtonDel]=useState(false) 
    const [mdpType,setMdpType]=useState('password') 

    const handleChange = (e,name) => {
        setValue(name,e.target.value.replace(/\D/g,''));
    }

    const deleteEmailConf = async () => {
        setValidButtonDel(true)

        const response  = await axios.delete(process.env.REACT_APP_API_URL+"api/mail/delete_mail_config/"+configEmailId
        ).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach((interm)=>{
                    let finalMessage=interm.split('/!');
                    if(finalMessage.length==2){
                        messageError+=t(finalMessage[0],{name:finalMessage[1]})+','
                    }
                    else{
                        messageError+=t(interm)+','
                    }
                })
                messageError=messageError.replace('undefined','');
                swal.fire(messageError, "", "error");
            }
            else{
                setConfigEmailId(false)
                reset()
            }
        }).catch((error) => {
            if(error=='end_session') return;
            let messageError = (error.response && error.response.status==403) ? t('forbiddenGeneral') : t('unknowError')
            swal.fire(messageError, "", "error");
        })
        setValidButtonDel(false)
    }

    const onSubmit = async (data) => {
        setValidButton(true)
        let url = !configEmailId ? "api/mail/create_mail_config" : "api/mail/edit_mail_config/"+configEmailId

        let obj = {
            ...data,
            encryption:'ssl',
        }
        //Dans le cas où on est sur la configuration d'une other company ou d'une section, on renseigne l'id de la other company ou de la section
        //Dans la configuration de la company, on ne passe rien  
        if(props.from=='other_company') obj['other_company']=props.id
        if(props.from=='section') obj['section']=props.id

        const response  = await axios.post(process.env.REACT_APP_API_URL+url, obj
        ).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach((interm)=>{
                    let finalMessage=interm.split('/!');
                    if(finalMessage.length==2){
                        messageError+=t(finalMessage[0],{name:finalMessage[1]})+','
                    }
                    else{
                        messageError+=t(interm)+','
                    }
                })
                messageError=messageError.replace('undefined','');
                swal.fire(messageError, "", "error");
            }
            else{
                setConfigEmailId(response.data.datas.id)
            }
        }).catch((error) => {
            if(error=='end_session') return;
            let messageError = (error.response && error.response.status==403) ? t('forbiddenGeneral') : t('unknowError')
            swal.fire(messageError, "", "error");
        })
        setValidButton(false)
    }

    const handleBlur = (e) => {
        setValue('username',e.target.value)
    };

    return(<>
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className='form-group'>
                <label>{t('accountName')}</label>
                <input disabled={validButton || validButtonDel} type="text" className='form-control' {...register('name',{required: true})}/>
                {errors.name?.type === 'required' && (
                    <div className="text-danger mt-1 ml-2">
                        {t('fieldRequired')}
                    </div>
                )}
            </div>
            <div className='form-group'>
                <label>{t('emailAdress')}</label>
                <input disabled={validButton || validButtonDel} type="email" className='form-control' {...register('address',{required: true, onBlur: (e) => {handleBlur(e)}})}/>
                {errors.address?.type === 'required' && (
                    <div className="text-danger mt-1 ml-2">
                        {t('fieldRequired')}
                    </div>
                )}
            </div>
            <div className='form-group position-relative'>
                <label>{t('password')}</label>
                <input disabled={validButton || validButtonDel} type={mdpType} className='form-control' {...register('password',{required: true})}/>
                <div className="positionEyeMdp">
                    &nbsp;
                    {mdpType=='password' ?
                        <FontAwesomeIcon icon={faEyeSlash} className="fs-5 pointer" onClick={()=>setMdpType('text')}/>
                    :
                        <FontAwesomeIcon icon={faEye} className="fs-5 pointer" onClick={()=>setMdpType('password')}/>
                    }
                </div>
                {errors.password?.type === 'required' && (
                    <div className="text-danger mt-1 ml-2">
                        {t('fieldRequired')}
                    </div>
                )}
            </div>
            <div className='form-group'>
                <label>{t('hostOutgoing')}</label>
                <input disabled={validButton || validButtonDel} type="text" className='form-control' {...register('host',{required: true})}/>
                {errors.host?.type === 'required' && (
                    <div className="text-danger mt-1 ml-2">
                        {t('fieldRequired')}
                    </div>
                )}
            </div>
            <div className='form-group'>
                <label>Port</label>
                <input disabled={validButton || validButtonDel} type="text" className='form-control' {...register('port',{required: true, onChange :(e) => handleChange(e,'port')})}/>
                {errors.port?.type === 'required' && (
                    <div className="text-danger mt-1 ml-2">
                        {t('fieldRequired')}
                    </div>
                )}
            </div>
            <div className='form-group'>
                <label>{t('username')}</label>
                <input disabled={validButton || validButtonDel} type="text" className='form-control' {...register('username',{required: true})}/>
                {errors.username?.type === 'required' && (
                    <div className="text-danger mt-1 ml-2">
                        {t('fieldRequired')}
                    </div>
                )}
            </div>
            <div className="d-flex justify-content-between">
                <button disabled={validButton || validButtonDel} className="btn btn-primary mt-2">
                    {validButton ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : t('btnValidate')}
                </button>
                {configEmailId ? 
                    validButton || validButtonDel ? 
                        <button disabled className="btn btn-danger mt-2">
                            {validButtonDel ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : t('deleteEmailAccount')}
                        </button>
                    :
                        <span className="btn btn-danger mt-2" onClick={()=>deleteEmailConf()}>
                            {t('deleteEmailAccount')}
                        </span>
                :
                    false
                } 
            </div>
        </form>
        {configEmailId ? 
            <SendTestEmail configEmailId={configEmailId} />
        :
            false
        }  
    </>
    )
}

export default EmailAccountForm;