import React, {useEffect} from 'react';
import { useTranslate } from 'react-redux-multilingual'

const DevisForm = (props) =>{
    const t = useTranslate();

    useEffect(function () {
        if(props.currentTicket.devis!=null){
            props.setValue('note',props.currentTicket.devis.note)
        }
    }, [props.currentTicket])

    return (
        <div>
            <div className="row">
                <div className="col-2">
                    <button disabled={props.validButton} className="btn btn-danger mb-2" onClick={()=>props.cancelAction()}>{t('btnBack')}</button>
                </div>
                <div className="col-8 text-center">
                    <strong className='fs-4'>{t('itemsNumber')}: <span>{props.countItems}</span></strong>
                </div>
                <div className="col-2"></div>
            </div>
            <form onSubmit={props.handleSubmit(props.onSubmit)}>
                <div className="form-group col-12 mb-3">
                    <label>Note</label>
                    <textarea autoComplete="off" rows="7" className="form-control" {...props.register('note')}></textarea>
                </div>
                <button disabled={props.validButton} className="btn btn-primary mt-3">
                    {props.validButton ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : t('btnValidate')}
                </button>
            </form>
        </div>
    )
};

export default DevisForm;