import React, { useState,useEffect } from 'react';
import { useTranslate } from 'react-redux-multilingual'
// Style import
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

const EditEnumereForm = (props) =>{
    const t = useTranslate();
    const [error,setError] = useState(false)
    const [valueInput,setValueInput] = useState({
        name:'',
        reference:'',
        quantity:''
    })

    useEffect(function () {
        setValueInput({
            name: (props.article.hasOwnProperty('name_'+props.numeroEnumere) && props.article['name_'+props.numeroEnumere]!=null) ? props.article['name_'+props.numeroEnumere] : '',
            reference:(props.article.hasOwnProperty('reference_'+props.numeroEnumere) && props.article['reference_'+props.numeroEnumere]!=null) ? props.article['reference_'+props.numeroEnumere] : '',
            quantity:(props.article.hasOwnProperty('quantity_'+props.numeroEnumere) && props.article['quantity_'+props.numeroEnumere]!=null) ? props.article['quantity_'+props.numeroEnumere] : ''
        })
    }, [])

    const handleChange = (e,isFor) => {
        if(isFor=='quantity'){
            setValueInput({...valueInput,[isFor]:e.target.value.replace(/\D/g,'')})
        }else{
            setValueInput({...valueInput,[isFor]:e.target.value})
        }
    };

    const handleSubmit = () => {
        setError(false)
        let data = {}
        data['name_'+props.numeroEnumere]=valueInput.name
        data['reference_'+props.numeroEnumere]=valueInput.reference
        data['quantity_'+props.numeroEnumere]=valueInput.quantity

        if(valueInput.quantity=='' || valueInput.reference=='' || valueInput.name==''){
            setError(true)
        }else{
            props.onSubmit(data)
        }
    };

    return (
        <>
            <td>
                <button disabled={props.validButton} className="btn btn-sm btn-primary" onClick={()=>handleSubmit()}>
                    {props.validButton ? <span><div className="spinner-border spinner-border-sm"></div></span> : 'Ok'}
                </button>
            </td>
            <td className='position-relative'>
                {!props.readAccessName ? <input disabled={props.validButton || props.writeAccessName} value={valueInput.name} type="text" className='form-control form-control-sm' onChange={(e)=>handleChange(e,'name')} /> : <></>}
                {error && valueInput.name=='' ? <label className='text-danger fw-bold' style={{position:'absolute', top:'25%', left:'10%'}}> <FontAwesomeIcon className='text-danger' icon={faExclamationCircle} /></label> : false}
            </td>
            <td className='position-relative'>
                {!props.readAccessRef ? <input disabled={props.validButton || props.writeAccessRef} value={valueInput.reference} type="text" className='form-control form-control-sm' onChange={(e)=>handleChange(e,'reference')} /> : <></>}
                {error && valueInput.reference=='' ? <label className='text-danger fw-bold' style={{position:'absolute', top:'25%', left:'10%'}}> <FontAwesomeIcon className='text-danger' icon={faExclamationCircle} /></label> : false}
            </td>
            <td className='position-relative' style={{width:'10%'}}>
                {!props.readAccessQte ? <input disabled={props.validButton || props.writeAccessQte} value={valueInput.quantity} type="text" className='form-control form-control-sm' onChange={(e)=>handleChange(e,'quantity')} /> : <></>}
                {error && valueInput.quantity=='' ? <label className='text-danger fw-bold' style={{position:'absolute', top:'25%', left:'18%'}}> <FontAwesomeIcon className='text-danger' icon={faExclamationCircle} /></label> : false}
            </td>
        </>
    )
};

export default EditEnumereForm;
