import React, { useState,useRef,useEffect } from 'react';
import { useTranslate } from 'react-redux-multilingual'
import { ArticleBarSearch,ControlAccess} from "@gull";
import axios from 'axios'
import swal from "sweetalert2";

const useSearchItem = () => {
    const t = useTranslate();
    const [loadingSearchItem,setLoadingSearchItem]=useState(false)
    const [listSearchItem,setListSearchItem]=useState([])
    const [datas,setDatas] = useState(listSearchItem)
    const searchInput = useRef('')

    const loadItems = async (inputValue) => {
        setLoadingSearchItem(true)
        const response = await axios.get(process.env.REACT_APP_API_URL+"api/erp/article/name/"+inputValue
        ).then((response) => {
            setLoadingSearchItem(false)
            setListSearchItem(response.data.datas)
            let ajour = response.data.datas.filter((value)=>value.name.replace(/\u00A0/g,' ').toLowerCase().includes(searchInput.current.value.replace(/\u00A0/g,' ').toLowerCase()))
            setDatas(ajour)
        }).catch((error) => {
            if(error=='end_session') return;
            setLoadingSearchItem(false)
            swal.fire(t('unknowError'), "", "error");
        }) 
    }

    return [loadingSearchItem,setLoadingSearchItem,loadItems,listSearchItem,datas,setDatas,searchInput]
}

const MatchBarCodeForm = (props) =>{
    const t = useTranslate();
    const [loadingSearchItem,setLoadingSearchItem,loadItems,listSearchItem,datas,setDatas,searchInput]=useSearchItem()
    const [valueInput,setValueInput]=useState('')
    const [barValue,setBarValue]=useState('')
    const [showItemSearch,setShowItemSearch]=useState(false) 
    const [validButton,setValidButton]=useState(false) 
    const [success,setSuccess]=useState(false) 

    const [itemId,setItemId]=useState(false) 
    const [barCodeData,setBarCodeData]=useState('') 

    const scannBar = useRef('')

    useEffect(function () {
        if(showItemSearch){
            if(searchInput && searchInput.current){
                setTimeout(()=>{
                    searchInput.current.focus()
                },400)
            }
        }
        else if(scannBar && scannBar.current){
            setTimeout(()=>{
                if(scannBar && scannBar.current) scannBar.current.focus()
            },400)
        }
    }, [scannBar,showItemSearch])

    const handleChange = (e) =>{
        setBarValue(e.target.value)
    }

    const submitNewBarCode = () =>{
        setBarCodeData(barValue)
        setBarValue('')
        setShowItemSearch(true)
    }

    const handleKeyPress = (e) =>{
        if(e.charCode==13){
            submitNewBarCode()
        }
    }

    const submitNewItem = async (e,articleid,inputName,reference) =>{
        let article = (e) ? e.target.value : articleid
        let urlArticle = (e) ? "api/erp/article/reference/" : "api/erp/article/one/"

        setLoadingSearchItem(true)
        const response = await axios.get(process.env.REACT_APP_API_URL+urlArticle+article
        ).then((response) => {
            if(response.data.hasOwnProperty('valid') && !response.data.valid){
                swal.fire(t('articleNoReferenceFind'), "", "error");
            }else{
                if(e){
                    setValueInput(response.data.data.article.name)
                    setItemId(response.data.data.article.id)
                }else{
                    setValueInput(response.data.data.name)
                    setItemId(response.data.data.id)
                }
            }
        }).catch((error) => {
            if(error=='end_session') return;
            let errorName = (error.response==undefined) ? 'unknowError' : (error.response.status==403) ? 'forbiddenGeneral' : 'unknowError'
            swal.fire(t(errorName), "", "error");
        })
        setLoadingSearchItem(false)
    }

    const handleSudmit = async () =>{
        setValidButton(true)
        const response  = await axios.put(process.env.REACT_APP_API_URL+"api/erp/article/edit/"+itemId,{
            reference:barCodeData
        }).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach((interm)=>{
                    let finalMessage=interm.split('/!');
                    if(finalMessage.length==2){
                        messageError+=t(finalMessage[0],{name:finalMessage[1]})+','
                    }
                    else{
                        messageError+=t(interm)+','
                    }
                })
                messageError=messageError.replace('undefined','');
                swal.fire(messageError, "", "error");
            }
            else{
                setShowItemSearch(false)
                setBarCodeData('')
                setValueInput('')
                setItemId(false)
                setSuccess(true)
            }
        }).catch((error) => {
            if(error=='end_session') return;
            let messageError = (error.response && error.response.status==403) ? t('forbiddenGeneral') : t('unknowError')
            swal.fire(messageError, "", "error");
            
        })
        if(props.isRestriction){ 
            props.setRestrictionAssociate({...props.restrictionAssociate,go:false})
            props.setIsRestriction(false)
        }
        setValidButton(false)
        setTimeout(()=>{
            setSuccess(false)
        },1000)
    }

    const callback = () =>{
        props.setRestrictionAssociate({...props.restrictionAssociate,go:true})
        props.setIsRestriction(true)
    }
    

    if(!props.restrictionAssociate.go){
        return(
            <div style={{marginTop:'60px'}}>
                <ControlAccess section={props.section} focus={true} restriction={props.restrictionAssociate} setRestriction={props.setRestrictionAssociate} moduleId={props.posid} callback={callback}/>
            </div>
        )
    }else{
        return (
            <div>
                <div className="row mb-3">
                    <div className="col-10">
                        <input disabled={validButton} ref={scannBar} value={barValue} placeholder={t('scanDocHere')} type="text" className='form-control' onChange={(e)=>handleChange(e)} onKeyPress={(e)=>handleKeyPress(e)} aria-label="Scan" aria-describedby="button-addon2" />
                    </div>
                    <div className="col-2">
                        <button disabled={validButton} className="btn btn-sm btn-primary" type="button" id="button-addon2" onClick={()=>submitNewBarCode()}>{t('btnValidate')}</button>
                    </div>
                    {barCodeData!='' && <span>{t('new')+' '+t('tableBarCode').toLowerCase()+' : '}<label className='fw-bold mt-2'>{barCodeData}</label></span> }
                </div>
                {(showItemSearch && barCodeData!='') ?
                    <ArticleBarSearch 
                        loadingSearchItem={loadingSearchItem}
                        loadItems={loadItems}
                        listSearchItem={listSearchItem}
                        datas={datas}
                        setDatas={setDatas}
                        valueInput={valueInput}
                        setValueInput={setValueInput}
                        handleSubmit={submitNewItem}
                        searchInput={searchInput}
                        setItemId={setItemId}
                        disabled={validButton}
                    />
                :
                    false
                }
                {(itemId && barCodeData!='') ? 
                    <button disabled={validButton} className='btn btn-primary mt-3' onClick={()=>handleSudmit()}>{validButton ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : t('btnAssociate')}</button> 
                : 
                    false}
                {(success) ? <div className="alert alert-success mt-3">{t('successOperation')}</div> : false}
            </div>
        )
    }
};

export default MatchBarCodeForm;