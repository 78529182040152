const enumereColumnGenerate = (maxConditionnement, columnShow, headerSortingStyle, articleEditionDatatable,t,authParam) => {

    const columns = [];
    
    for (let i=0; i<maxConditionnement; i++){
        let numeroEnumere = i+1

        let readAccessName = authParam.access.find(access=>access.action == 'article_enumerename'+numeroEnumere+'_@!'+'read')
        let writeAccessName = authParam.access.find(access=>access.action == 'article_enumerename'+numeroEnumere+'_@!'+'write')
        let textAppendName = (!writeAccessName) ? '*' : ''

        if(!readAccessName){ 
            let dataInitName = {
                dataField: 'name_'+numeroEnumere,
                text:  t('name')+' '+t('packaging').toLowerCase()+' '+numeroEnumere+textAppendName,
                hidden: (columnShow.includes('name_'+numeroEnumere)) ? false : true,
                sort: false,
                headerSortingStyle,
            }
            if((!writeAccessName)){
                //Si le user est autorisé au éditer ce champ, on intègre validator pour éditer le champ en question
                dataInitName['validator'] = (newValue, row, column, done) => {
                    articleEditionDatatable(row,column.dataField,newValue,done,numeroEnumere,t)
                    return {
                        async: true
                    };
                }
            }else{
                //Si le user n'est pas autorisé à éffectuer des modification, on envoie "editable:false"
                dataInitName['editable'] = false
            }
            columns.push(dataInitName)
        }


        let readAccessRef = authParam.access.find(access=>access.action == 'article_enumerereference'+numeroEnumere+'_@!'+'read')
        let writeAccessRef = authParam.access.find(access=>access.action == 'article_enumerereference'+numeroEnumere+'_@!'+'write')
        let textAppendRef = (!writeAccessRef) ? '*' : ''

        if(!readAccessRef){
            let dataInitReference = {
                dataField: 'reference_'+numeroEnumere,
                text:  t('barcodePackaginglist')+' '+numeroEnumere+textAppendRef,
                hidden: (columnShow.includes('reference_'+numeroEnumere)) ? false : true,
                sort: false,
                headerSortingStyle,
            }
            if((!writeAccessRef)){
                //Si le user est autorisé au éditer ce champ, on intègre validator pour éditer le champ en question
                dataInitReference['validator'] = (newValue, row, column, done) => {
                    articleEditionDatatable(row,column.dataField,newValue,done,numeroEnumere,t)
                    return {
                        async: true
                    };
                }
            }else{
                //Si le user n'est pas autorisé à éffectuer des modification, on envoie "editable:false"
                dataInitReference['editable'] = false
            }
            columns.push(dataInitReference)
        }


        let readAccessQte = authParam.access.find(access=>access.action == 'article_enumerequantity'+numeroEnumere+'_@!'+'read')
        let writeAccessQte = authParam.access.find(access=>access.action == 'article_enumerequantity'+numeroEnumere+'_@!'+'write')
        let textAppendQte = (!writeAccessQte) ? '*' : ''

        if(!readAccessQte){ 
            let dataInitQuantity = {
                dataField: 'quantity_'+numeroEnumere,
                text:  t('quantity')+' '+t('packaging').toLowerCase()+' '+numeroEnumere+textAppendQte,
                hidden: (columnShow.includes('quantity_'+numeroEnumere)) ? false : true,
                sort: false,
                headerSortingStyle,
            }
            if((!writeAccessQte)){
                //Si le user est autorisé au éditer ce champ, on intègre validator pour éditer le champ en question
                dataInitQuantity['validator'] = (newValue, row, column, done) => {
                    articleEditionDatatable(row,column.dataField,newValue,done,numeroEnumere,t)
                    return {
                        async: true
                    };
                }
            }else{
                //Si le user n'est pas autorisé à éffectuer des modification, on envoie "editable:false"
                dataInitQuantity['editable'] = false
            }
            columns.push(dataInitQuantity)
        }
    }
    
    return columns;
};

export default enumereColumnGenerate;