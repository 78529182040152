import React, { useState, useEffect } from "react";
import { useTranslate } from 'react-redux-multilingual'
import {Form} from 'react-bootstrap'

const ChooseReassortSelection = (props) => {
    const t = useTranslate()
    const [choiceDockSection,setChoiceDockSection] = useState([]);
    const [choiceCommerceSection,setChoiceCommerceSection] = useState([]);

    useEffect(function () {
        setChoiceDockSection(props.authParam.sections
            .filter(section => !props.weekDays[props.day].destination.includes(section.id))
            .sort((a, b) => a.id - b.id)
        );
        setChoiceCommerceSection(props.authParam.sections
            .filter(section => !props.weekDays[props.day].from.includes(section.id))
            .sort((a, b) => a.id - b.id)
        );
    }, [])

    const cancelConfig = (setWeekDays,day) =>{
        setWeekDays(prevWeekDays =>{
            const newWeekDays = {...prevWeekDays}
            newWeekDays[day].from=[]
            newWeekDays[day].destination=[]
            return newWeekDays
        })
        props.setShowConfigDay(false)
    }

    const closeConfig = () =>{
        props.setShowConfigDay(false)
    }

    const handleChangeConfig = (e,section,other,setOther,isFrom,setWeekDays) => {
        //Selection de l'origin du réassort vers sa destination 
        if(e.target.checked){
            //Quand on coche un magasin ou un dock, on le fait diparaitre de la suivante d'à coté 
            let newArray=other.filter(value=>value.id!=section.id)
            setOther(newArray)
            setWeekDays(prevWeekDays =>{
                const newWeekDays = {...prevWeekDays}
                newWeekDays[props.day][isFrom].push(parseInt(e.target.value))
                return newWeekDays
            })
        }
        else{
            let newArray=[...other,section];
            newArray.sort((a,b)=>{
                return a.id - b.id;
            })
            setOther(newArray)
            setWeekDays(prevWeekDays =>{
                const newWeekDays = {...prevWeekDays}
                newWeekDays[props.day][isFrom] = newWeekDays[props.day][isFrom].filter(item => item != parseInt(e.target.value));
                return newWeekDays
            })
        }
    }

    return (
        <form onSubmit={closeConfig}>
            <b>{t(props.days[props.showConfigDay])}</b>
            <div className="row">
                <div className="mb-3">
                    <button className="btn btn-sm btn-primary">{t('btnValidate')}</button>&nbsp;&nbsp;
                    <span className="btn btn-sm btn-danger" onClick={()=>cancelConfig(props.setWeekDays,props.day)}>{t('btnCancel')}</span>
                </div>
                <div className="col-6">
                    <h6><strong>{t('de')}</strong>:</h6>
                    {(choiceDockSection.length!=0 && choiceCommerceSection.length!=0) ? choiceDockSection.map(value=>{
                        if(value.type!='ecommerce'){
                            return(
                                <Form.Check
                                    type="switch"
                                    id="custom-switch"
                                    checked={props.weekDays[props.day].from.includes(value.id)}
                                    value={value.id}
                                    label={value.name}
                                    onChange={(e) => handleChangeConfig(e,value,choiceCommerceSection,setChoiceCommerceSection,'from',props.setWeekDays)}
                                    key={value.id}
                                />
                            )
                        }})
                        :
                        false
                    }
                </div>
                <div className="col-6">
                    <h6><strong>{t('vers')}</strong>:</h6>
                    {(choiceCommerceSection.length!=0 && choiceDockSection.length!=0) ? choiceCommerceSection.map(value=>{
                        if(value.type!='ecommerce'){
                            return(
                                <Form.Check
                                    type="switch"
                                    id="custom-switch"
                                    checked={props.weekDays[props.day].destination.includes(value.id)}
                                    value={value.id}
                                    label={value.name}
                                    onChange={(e) => handleChangeConfig(e,value,choiceDockSection,setChoiceDockSection,'destination',props.setWeekDays)}
                                    key={value.id}
                                />
                            )
                        }})
                        :
                        false
                    }
                </div>
            </div>
        </form>
    )
}

export default ChooseReassortSelection; 