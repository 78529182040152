import React, { useState,useRef } from 'react';
import {InpDataList} from '@gull'

const SelectDataListSearch = (props) => {
    const inputRef = useRef('')
    const listRef = useRef('')
    const [datas,setDatas] = useState(props.datas)
        
    const handleClickFilter = (id,label,nameInput,reference,quota) => { //quota est utilisé uniquement dans le cas d'un select d'article 
        if(props.writeLabel){
            props.setValueInput(label)
        }else{
            props.setValueInput('')
        }

        if(props.from && props.from=="selectITems"){
            props.setIds({name:label,id:id,quota:quota})

        }else if(props.from && props.from=="selectPo"){
            props.setIds(id)
            props.setValue('po',id)

        }else if(props.from && props.from=="selectEcomConnect"){
            props.setIds({name:label,id:id})

        }else{
            props.setIds([...props.ids,{name:label,id:id}])
            
        }

        if(props.callBackAfterSelect) props.callBackAfterSelect(id)

        listRef.current.classList.add("d-none")
        if(props.setDisabledValidSearch && props.blockSearchValid) props.setDisabledValidSearch(false)
    }

    const handleSearch = (e,declenchement) => {
        props.setValueInput(e.target.value);
        //si on fait une recherche sans cliquer sur une des propositions, on disabled le btn valider
        if(props.setDisabledValidSearch && props.blockSearchValid) props.setDisabledValidSearch(true)
        let ajour = props.datas.filter((value)=>value.name.toLowerCase().includes(e.target.value.toLowerCase()))
        setDatas(ajour)
    }

    const handleFocus = () => {
        setDatas(props.datas)
        listRef.current.classList.remove("d-none");
    }

    const handleBlur = () => {
        listRef.current.classList.add("d-none")
        if(props.setDisabledValidSearch && props.blockSearchValid) props.setDisabledValidSearch(false)
        //cela permet ne reécrire ce qu'il y avait avant 
        if(props.blockSearchValid) props.setValueInput(props.ids.name)
    }

    return(
        <InpDataList
            placeholder="-- Select --"
            value={props.valueInput}
            onClick={handleClickFilter}
            onChange={handleSearch}
            datas={datas}
            declenchement={0}
            listRef={listRef}
            inputRef={inputRef}
            onFocus={handleFocus}
            onBlur={handleBlur}
            inputName={props.inputName}
            disabled={props.disabled}
            props={props.from}
        />
    )
}

export default SelectDataListSearch;