import React,{useState,useContext,useRef,useEffect} from "react";
import { useTranslate } from 'react-redux-multilingual'
import { PeripheriqueContext,IsAuthContext } from 'app/App';
import { epson_tm_h6000VDevis} from 'app/imprime';
import DevisA4 from "app/views/Pdf/DevisA4"
import { BlobProvider } from '@react-pdf/renderer';
import { Alert } from "react-bootstrap";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf,faCheckCircle } from '@fortawesome/free-solid-svg-icons';

const DevisPrint =  (props) =>{
    const t =useTranslate()
    const btnTicketFacture = useRef('')
    const [loading,setLoading]=useState({
        ticket:false,
        a4:false
    })
    const { peripheriqueParam } = useContext(PeripheriqueContext)  
    const {authParam} = useContext(IsAuthContext);

    const [totalAchat,setTotalAchat]=useState(0)
    const [totalTgc,setTotalTgc]=useState(false)

    let thisShop = authParam.sections.length!=0 && authParam.sections.find(value=>value.id==props.devisInfo.ticket.section_id)

    const print = (name) =>{
        setLoading({...loading,[name]:true})
        if(name=='a4'){
            setTimeout(()=>{
                btnTicketFacture.current.click()
            },1000)
        }else{
            epson_tm_h6000VDevis(props.devisInfo.ticket.section_id,props.devisInfo,t,props.devise,peripheriqueParam,false,authParam)
        }
        setTimeout(()=>{
            setLoading({...loading,[name]:false})
        },900)
    }

    useEffect(function () {
        var total = 0
        let totaltgc = {}
        props.devisInfo.ticket.ticket_detail.map(item=>{
            total+=item.prix_remise
            if(item.tgc!=0){//on exécute pas cette action sur le taux 0
                if(totaltgc[item.tgc]){
                    //si le taux de tgc existe deja on ajoute le pris du produit au total existant
                    totaltgc={...totaltgc,[item.tgc]:totaltgc[item.tgc]+=item.prix_remise}
                }else{
                    //Si aucun taux existe on ajoute la taxe 
                    totaltgc={...totaltgc,[item.tgc]:item.prix_remise}
                }
                
            }
        })
        setTotalAchat(total)
        setTotalTgc(totaltgc)
    }, [])

    let tgcForTicketPdf={}
    Object.entries(totalTgc).forEach(([tgcName,montant])=>{
        //fonction qui calcule le montant de tgc à payer par type de tgc
        let coef = +tgcName / 100 + 1
        let totalAmountTgc = +montant - (Math.round(+montant / +coef))
        tgcForTicketPdf[tgcName] = totalAmountTgc
    })
    //on calcule le total de tgc payé sur le ticket
    let tgcTotal = 0 
    Object.entries(tgcForTicketPdf).map(([key,value])=> 
        tgcTotal+=value
    )
    
    return(
        <div>
            <BlobProvider 
                document={(props.devisInfo && tgcTotal!=NaN && thisShop) ?
                    <DevisA4 
                        t={t}
                        documentType='quotation'
                        companyInfos={thisShop}
                        authParam={authParam}
                        ticketFacture={props.devisInfo}
                        totalTgc={tgcTotal}
                        totalAchat={totalAchat}
                        tgcForTicketPdf={tgcForTicketPdf}
                        devise={props.devise}
                        moduleParam={props.modulePos}
                    />
                : 
                    false
                }
            >
                {({ url }) => (
                    <a ref={btnTicketFacture} className="d-none" href={url} target="_blank">Print</a>
                )}
            </BlobProvider>
            <div className="mt-2 text-center">
                <Alert variant="success">
                    <div style={{fontSize:'3em'}}><FontAwesomeIcon className='mx-auto d-flex justify-content-center' icon={faCheckCircle} /></div>
                    <hr />
                    <Alert.Heading className='text-center'>{t('quotationGenerated')}</Alert.Heading>
                    <div className="mt-3 text-center">
                        <div className="mb-3">
                            <button disabled={loading.a4 || loading.ticket} className="btn btn-primary" onClick={()=>print('ticket')}>{(loading.ticket) ? <div className="spinner-border spinner-border-sm"></div> : t('btnPrint')}</button>
                        </div>
                        <div>
                            <button disabled={loading.a4 || loading.ticket} className="btn btn-primary" onClick={()=>print('a4')}>
                                {(loading.a4) ?
                                    <div className="spinner-border spinner-border-sm"></div>
                                :
                                    <>{t('btnPrint')+' A4 '}<FontAwesomeIcon icon={faFilePdf} className='fs-5' /></>
                                }
                            </button>
                        </div>
                    </div>
                </Alert>
            </div>
        </div>
    )
}

export default DevisPrint;