import React from "react";
import { useTranslate } from 'react-redux-multilingual';
import { Link } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';

const  ProfilParentRestockButton = (props) => {
    const t = useTranslate();

    if(props.optionModule && props.optionModule.params.stock.active){
        if((props.value.parent_name!=null && props.value.parent_name!='') || props.value.havestock){
            return (
                <OverlayTrigger
                    placement="bottom"
                    overlay={
                        <Tooltip id={`tooltip-${props.index}`}>
                            {t('btnSeeParentProfil')}
                        </Tooltip>
                    }
                >
                    <Link to={props.path} style={{cursor:'Pointer', color:'#000000'}}><FontAwesomeIcon icon={faEye} /></Link>
                </OverlayTrigger>  
            )
        }else{
            return false
        }
    }else{
        return false
    }
}

export default ProfilParentRestockButton;