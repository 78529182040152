import swal from "sweetalert2";

export const openTiroir = async (peripheriqueParam,t) =>{
    if(peripheriqueParam && peripheriqueParam.imprimante_ticket && peripheriqueParam.imprimante_ticket.model=='epson_tm_h6000V'){
        let host = '"'+peripheriqueParam.imprimante_ticket.host+'"'  

        const res = await fetch('http://localhost/portcom/opencaisse.php',{
            method: 'POST',
            body: JSON.stringify({host:host})
        }).then((response) => {
            console.log('good')
        }).catch((error) => {
            console.log(error)
        })
    }
}