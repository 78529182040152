import React, {useState} from 'react';
import { useTranslate } from 'react-redux-multilingual'
import {CardPerso} from '@gull'
import { Tabs, Tab } from "react-bootstrap";

const ReportDetailView = (props) => {
    const t = useTranslate();
    const [tabkey,setTabKey] = useState((props.report.pbi_details.length!=0) ? props.report.pbi_details[0].name : '')
    
    return(
        <div>
            <CardPerso header={t('reportPbi')+' : '+props.report.name}>
                {(props.report.pbi_details.length!=0) ?
                    <Tabs activeKey={tabkey} id="uncontrolled-tab-example" className='mt-1' onSelect={(k)=>setTabKey(k)}>
                        {props.report.pbi_details.map(pbi=>{
                            return(
                                <Tab key={pbi.id} eventKey={pbi.name} title={pbi.name}>
                                    <iframe title={pbi.name} width="100%" height="600px" src={pbi.adress} frameBorder="0" allowFullScreen={true}></iframe>
                                </Tab>
                            )
                        })}
                    </Tabs>
                :
                    <div className='d-flex justify-content-center alert alert-info'>{t('noReportingAvl')}</div>
                }
            </CardPerso>
        </div>
    )
}

export default ReportDetailView;