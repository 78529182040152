import React, {useState,useEffect} from 'react';
import {FacturationCreateDossier,FacturationSelectDossier} from '@gull'
import { useTranslate } from 'react-redux-multilingual'
import { Tabs, Tab } from "react-bootstrap";

const LinkToDoc = (props) => {
   const t = useTranslate();
   const [tabKey,setTabKey] = useState("link")

    useEffect(function () {
        if(props.folderFacture && props.folderFacture!=null){
            setTabKey("link")
        }else if(props.folderFacture && props.folderFacture==null){
            setTabKey("create")
        }
    }, [])

   return(
       <div className='mt-3'>
            <Tabs activeKey={tabKey} id="uncontrolled-tab-example" onSelect={(k)=>setTabKey(k)}>
                {((props.folderFacture && props.folderFacture!=null) || !props.folderFacture) && 
                    <Tab eventKey="link" title={t('searchForExistingFolder')}>
                        <div className='row'>
                            <FacturationSelectDossier 
                                callbackFolderLink={props.callbackFolderLink}
                                size={props.size}

                                //props passé depuis détail de facture
                                clientFacture={props.clientFacture}
                                folderFacture={props.folderFacture}
                                allInfos={props.allInfos}
                            />
                        </div>
                    </Tab>
                }
                <Tab eventKey="create" title={t('createFolder')}>
                    <div className='row'>
                        <FacturationCreateDossier 
                            company={props.company}
                            callbackFolderLink={props.callbackFolderLink}
                            size={props.size}

                            //props passé depuis détail de facture
                            clientFacture={props.clientFacture}
                            allInfos={props.allInfos}
                        />  
                    </div>
                </Tab>
            </Tabs>
       </div>
   )
}

export default LinkToDoc;