export const modePaiementDefaultAll = [
    'espece',
    'emv',
    'emv_touch',
    'emv_multiple',
    'amex',
    'amex_touch',
    'amex_multiple',
    'cheque',
    'jade',
    'jcb',
    'giftcard',
    'virement'
];

export const modePaiementMustBeTranslate = [
    'espece',
    'emv',
    'emv_touch',
    'emv_multiple',
    'amex',
    'amex_touch',
    'amex_multiple',
    'cheque',
    'jade',
    'jcb',
    'giftcard',
    'virement',
    'annulCommande'
];

export const modePaiementFidelite = [
    'cashback',
    'recompense',
    'fidelite',
];