import React, { useState } from 'react';
import { useTranslate } from 'react-redux-multilingual'
import { Dropdown} from "react-bootstrap";


const CreateItem = (props) =>{
    const t = useTranslate();

    return (
        <Dropdown.Item eventKey="7" onClick={()=>props.handleClick('itemCreation')}>{t('itemCreation')}</Dropdown.Item>
    )
};

export default CreateItem;