import { lazy, useContext } from "react";

const Users = lazy(() => import("./Users"));
const UserEdit = lazy(() => import("./UserEdit"));
const UserCreate = lazy(() => import("./UserCreate"));
const NotificationListUser = lazy(() => import("./Notification/NotificationListUser"));
const NotificationDetailUser = lazy(() => import("./Notification/NotificationDetailUser"));
const HistoriqueDetail = lazy(() => import("./Historique/HistoriqueDetail"));

const usersRoutes = [
    {
      path: "/users/welcome",
      component: Users
    },
    {
      path: "/users/edit/:id",
      component: UserEdit
    },
    {
      path: "/users/create/",
      component: UserCreate
    },
    {
      path: "/users/notifications/list",
      component: NotificationListUser
  },
  {
      path: "/users/notifications/detail/:id",
      component: NotificationDetailUser
  },
  {
    path: "/users/historique/edit/:id",
    component: HistoriqueDetail
  },
  ];
  
  export default usersRoutes;