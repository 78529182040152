import React from "react";
import { useTranslate } from 'react-redux-multilingual'

const RefundEspece = (props) =>{
    const t =useTranslate()

    const handleSubmit = () =>{
        props.setLoad(true)
        props.refundApi({
            id:props.cookies.current_ticket,
            remboursement_montant:+props.total,
            remboursement_mode:'espece',
        })
    }

    return(
        <form>
            <div className="row input-group mb-2">
                <div className="col">
                    <label htmlFor="amount">{t('amount')}</label>
                    <input disabled className='form-control'  value={props.total} type="text" />
                </div>
            </div>
            <button disabled={props.load} className="btn btn-primary mt-1" onClick={()=>handleSubmit()}>
                {props.load ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : t('add')}
            </button>
        </form>
    )
}

export default RefundEspece;