import React, {useState,useEffect,useContext} from 'react';
import { useTranslate } from 'react-redux-multilingual'
import {Loading} from '@gull'
import axios from 'axios'
import swal from "sweetalert2";
import SelectedInvoiceLine from './IndiceCalculatorComp/SelectedInvoiceLine'
import { IsAuthContext} from 'app/App';
import {SelectFournisseur} from '@gull'

const useLoad = (calculIndice) => {
    const t = useTranslate();
    const [loading,setLoading]=useState(true)
    const [relance,setRelance]=useState(Date.now()) 
    const [allFactureAchat,setAllFactureAchat]=useState([])
    const [error,setError] = useState(false)
    const [fournisseurId,setFournisseurId]=useState(false)
    const [fournisseurName,setFournisseurName] = useState(false)

    useEffect(function () {
        (async function() {
            if(fournisseurId){
                setError(false)
                const response = await axios.get(process.env.REACT_APP_API_URL+"api/facture/invoice/get_billing_buy_by_fournisseur/"+fournisseurId
                ).then((response) => {
                    if(!response.data.valid){
                        let interms='';
                        var keys = Object.keys(response.data.errors);
                        for(var i = 0; i < keys.length; i++){
                            interms+=response.data.errors[keys[i]].join(',')+','
                        }
                        interms=interms.split(',')
                        let messageError='';
                        interms.forEach(interm=>messageError+=t(interm)+'. ')
                        messageError=messageError.replace('undefined','');
                        swal.fire(messageError, "", "error");
                    }else{
                        setLoading(false)
                        setAllFactureAchat(response.data.data)
                    }
                }).catch((error) => {
                    if(error=='end_session') return;
                    swal.fire(t('unknowError'), "", "error");
                    setLoading(false)
                })
            }else{
                setLoading(false)
            }
        })()
    }, [relance,fournisseurId])

    useEffect(function () {
        (async function() {
            if(calculIndice){
                setError(false)
                let douaneFactures = Object.values(calculIndice.categorie_douane)
                let listFactures={factures:[...calculIndice.facture_achat,...douaneFactures]}
                const response = await axios.post(process.env.REACT_APP_API_URL+"api/facture/invoice/list_of_facture",listFactures
                ).then((response) => {
                    if(!response.data.valid){
                        let interms='';
                        var keys = Object.keys(response.data.errors);
                        for(var i = 0; i < keys.length; i++){
                            interms+=response.data.errors[keys[i]].join(',')+','
                        }
                        interms=interms.split(',')
                        let messageError='';
                        interms.forEach(interm=>messageError+=t(interm)+'. ')
                        messageError=messageError.replace('undefined','');
                        swal.fire(messageError, "", "error");
                    }else{
                        console.log(response.data.datas)
                    }
                }).catch((error) => {
                    if(error=='end_session') return;
                    swal.fire(t('unknowError'), "", "error");
                    setLoading(false)
                })
            }else{
                setLoading(false)
            }
        })()
    }, [relance,fournisseurId])
   
    return [loading,relance,setRelance,allFactureAchat,error,fournisseurId,setFournisseurId,fournisseurName,setFournisseurName]
}

const IndiceCalculationForm = (props) => {
    const t = useTranslate();
    const { authParam } = useContext(IsAuthContext)
    const [loading,relance,setRelance,allFactureAchat,error,fournisseurId,setFournisseurId,fournisseurName,setFournisseurName] = useLoad(props.calculIndice)
    const [validButton,setValidButton] = useState(false)
    const [errorField,setErrorField] = useState(false)
    const [errorDevise,setErrorDevise] = useState(false)

    const [deviseValue,setDeviseValue] = useState({})
    const [factureAchatSelected,setFactureAchatSelected] = useState([])
    const [arrAchatSelected,setArrAchatSelected] = useState([])

    const [displayingInvoiceId,setDisplayingInvoiceId] = useState(false)

    let devise = authParam.company.devise;

    useEffect(function () {
        //On constuit le state de toutes les devises afin de pouvoir écrire sur chaque champs devise
        if(props.allDevises.length==0){
            setErrorDevise('noCurrencyProvided')
        }else{
            let obj = {}
            props.allDevises.map(devise=>{
                obj[devise] = ''
            })
    
            setDeviseValue(obj)
        }
    }, [props.allDevises])

    // useEffect(function () {
    //     if(props.calculIndice && allFactureAchat.length!=0){//Si une configuration de calcul d'indice à été effectué
            
    //         let arrFacture = []
    //         let arrFactureSelected = []
            
    //         props.calculIndice.facture_achat.map(idFacture=>{
    //             //Gestion de la liste de facture qui ne sont pas liées a une catégorie douanière
    //             let factureAchat = allFactureAchat.find(facture=>facture.id==idFacture)
    //             console.log(factureAchat)
    //             arrFacture.push({id:factureAchat.id, name:factureAchat.bar+' : '+factureAchat.ttc+devise, associate:[]})

    //             arrFactureSelected.push(idFacture) //Permet de stocker les ids des facture deja sélectionnées pour ne pas les afficher dans le select de facture
    //         })

    //         let objFacture = {}
    //         Object.entries(props.calculIndice.categorie_douane).map(([categorieDouane,factureId])=>{
    //             //Gestion de la liste de facture qui sont liées a une ou plusieurs catégories douanière 
    //             if(!objFacture.hasOwnProperty(factureId)){
    //                 objFacture[factureId]=[categorieDouane]
    //             }else{
    //                 objFacture[factureId].push(categorieDouane)
    //             }
    //         })
    //         Object.entries(objFacture).map(([factureId,categorieDouaneArr])=>{
    //             let factureAchat = allFactureAchat.find(facture=>facture.id==factureId)
    //             arrFacture.push({id:factureAchat.id, name:factureAchat.bar+' : '+factureAchat.ttc+devise, associate:categorieDouaneArr})

    //             arrFactureSelected.push(factureId) //Permet de stocker les ids des facture deja sélectionnées pour ne pas les afficher dans le select de facture
    //         })

    //         setFactureAchatSelected(arrFacture)
    //         setDeviseValue(props.calculIndice.taux_de_change)
    //         setArrAchatSelected(arrFactureSelected)
    //     }
    // }, [props.calculIndice,allFactureAchat])

    const handleChange = (e,name,devise) => {//devise est true uniquement lorsque la modification vient de l'un des inputs de devise
        if(name=='devise'){
            setDeviseValue({...deviseValue,[devise]:e.target.value.replace(/[^0-9\.]/g, '')})
        }else{
            let dataFactureAchat = e.target.value.split('@!?')
            //On ajoute la facture sélectionné à la liste à afficher
            setFactureAchatSelected([...factureAchatSelected,{id:dataFactureAchat[0], name:dataFactureAchat[1], associate:[]}])
            //On ajoute l'id de la facture sélectionné pour pouvoir retirer de du select la facture choisit 
            setArrAchatSelected([...arrAchatSelected,dataFactureAchat[0]])
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault()
        setErrorField(false)
        setValidButton(true)

        let valueDeviseGo = true //Si une des devises à son champs taux de change vide, on retourne une erreur 
        Object.entries(deviseValue).map(([key,value])=>{
            if(value=='') valueDeviseGo = false
        })

        if(!valueDeviseGo ){
            setErrorField(true)
            setValidButton(false)
        }else{
            let customsCat = {}
            let factureAchat = []

            factureAchatSelected.map(value=>{
                if(value.associate.length==0){
                    factureAchat.push(value.id)
                }else{
                    value.associate.map(customsCatName=>{
                        customsCat[customsCatName] = value.id
                    })
                }
            })
    
            let obj = {
                taux_de_change:deviseValue,
                categorie_douane:customsCat,
                facture_achat:factureAchat
            }

            const response  = await axios.post(process.env.REACT_APP_API_URL+"api/erp/po/calcul_indice/"+props.poId, obj
            ).then((response) => {
                if(!response.data.valid){
                    let interms='';
                    var keys = Object.keys(response.data.errors);
                    for(var i = 0; i < keys.length; i++){
                        interms+=response.data.errors[keys[i]].join(',')+','
                    }
                    interms=interms.split(',')
                    let messageError='';
                    interms.forEach(interm=>messageError+=t(interm)+'. ')
                    messageError=messageError.replace('undefined','');
                    swal.fire(t(messageError), "", "error");
                    setValidButton(false)
                }else{
                    props.setReload(Date.now())
                    setFactureAchatSelected([])
                    setValidButton(false)
                    props.setShow(false)
                }
            }).catch((error) => {
                if(error=='end_session') return;
                if(error.response==undefined){
                    swal.fire(t('unknowError'), "", "error");
                }
                else if(error.response.status==403){
                    swal.fire(t('forbiddenGeneral'), "", "error");
                }
                else {
                    swal.fire(t('unknowError'), "", "error");
                } 
                setValidButton(false)
            })
        }
    };

    return(<>
        {loading ? 
            <span className='d-flex justify-content-center'><div className="spinner-border spinner-border-sm"></div></span>
        :
            <form onSubmit={(e)=>handleSubmit(e)}>
                <label className='text-muted'>{t('exchangeRate')}</label>
                {props.allDevises.map(devise=>{
                    return(
                        <div key={devise} className="mb-2">
                            <div className="input-group ">
                                <div className="input-group-prepend">
                                    <div className="input-group-text rounded-0" style={{height:'100%'}}>{devise}</div>
                                </div>
                                <input disabled={validButton || error} value={deviseValue[devise]} type="text" className="form-control" onChange={(e)=>handleChange(e,'devise',devise)}/>
                            </div>
                            {(errorField && deviseValue[devise]=='') &&
                                <div className="text-danger mt-1 ml-2">
                                    {t('fieldRequired')}
                                </div>
                            }
                        </div>
                    )
                })}
                <div className="form-group ">
                    <label>{t('billingBuy')}</label>
                    <SelectFournisseur
                        documentType='billingBuy'
                        fournisseurId={fournisseurId}
                        setFournisseurId={setFournisseurId}
                        setFournisseurName={setFournisseurName}
                    />
                    <select disabled={validButton || error} className="form-control mt-2" onChange={(e)=>handleChange(e,'facture',false)}>
                        <option value="">-- Select --</option>
                        {allFactureAchat.map(value=>{
                            if(!arrAchatSelected.includes(value.id.toString())){
                                return(
                                    <option key={value.id} value={value.id+'@!?'+value.bar+' : '+value.ht+devise}>{value.bar+' : '+value.ht+devise}</option>
                                )
                            }
                        })}
                    </select>
                    {(errorField && factureAchatSelected.length==0) &&
                        <div className="text-danger mt-1 ml-2">
                            {t('fieldRequired')}
                        </div>
                    }
                </div>
                <div>
                    <ul className="list-group list-group-flush">
                        {factureAchatSelected.map((value,ind)=>{
                            let style = (ind%2 == 0) ? 'stripeBg stripePadding px-2 row d-flex align-items-center' : 'stripePadding px-2 row d-flex align-items-center';

                            return(
                                <SelectedInvoiceLine 
                                    key={value.id}
                                    value={value}
                                    style={style}
                                    factureAchatSelected={factureAchatSelected}//infos
                                    setFactureAchatSelected={setFactureAchatSelected}
                                    arrAchatSelected={arrAchatSelected}//id uniquement
                                    setArrAchatSelected={setArrAchatSelected}
                                    allCustomsCategories={props.allCustomsCategories}
                                    validButton={validButton}
                                    displayingInvoiceId={displayingInvoiceId}
                                    setDisplayingInvoiceId={setDisplayingInvoiceId}
                                />
                            )
                        })}              
                    </ul>
                </div>
                
                {error && <div className='alert alert-danger mt-3'>{t(error)}</div>}
                {errorDevise && <div className='alert alert-danger mt-3'>{t(errorDevise)}</div>}
                <button disabled={validButton || error} className="btn btn-primary mt-3">
                    {validButton ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : t('btnValidate')}
                </button>
            </form>
        }
    </>
    )
}

export default IndiceCalculationForm;