import React from 'react';
import { useTranslate } from 'react-redux-multilingual'
import LineFavorite from './Comp/LineFavorite'

const ListFavorite = (props) =>{
    const t = useTranslate();

    return (
        <div>
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>Actions</th>
                        <th>{t('favoriteName')}</th>
                    </tr>
                </thead>
                <tbody>
                    {(props.allFav.length!=0) ? 
                        props.allFav.map((value,ind)=>{
                            return(
                                <LineFavorite 
                                    key={value.id}
                                    value={value}
                                    setAllFav={props.setAllFav}
                                    allFav={props.allFav}
                                    onSubmit={props.onSubmit}
                                    filterItemsPromo={props.filterItemsPromo}
                                    setSendData={props.setSendData}
                                    setFiltre={props.setFiltre}
                                    setFavoriteFilterActived={props.setFavoriteFilterActived}
                                    setCustomFilter={props.setCustomFilter}
                                    setShowFavorite={props.setShowFavorite}
                                />
                            )
                        })
                    :
                        <tr>
                            <td>{t('tableNoData')}</td>
                            <td></td>
                        </tr>
                    }
                </tbody>
            </table>
        </div>
    )
};

export default ListFavorite;