import React, { useState,useEffect,useRef,useContext} from "react";
import { useTranslate } from 'react-redux-multilingual'
import { ActionClientBarLeft} from "@gull";
import { IsAuthContext } from 'app/App';


const MasterClientSelect = (props) => {
    const t = useTranslate()
    const {authParam} = useContext(IsAuthContext);
    const moduleErp = authParam.modules.find(value=>value.name=='erp')

    if(moduleErp.params.client.active){
        return(
            <div className="form-group col-6 mt-3">
                <label>{t('customer')+' :'}</label>
                <div className="row">
                    <ActionClientBarLeft 
                        setValueInputClient={props.setValueInputClient}
                        valueInputClient={props.valueInputClient}
                        invalidInput={props.invalidInput}
                        setInvalidInput={props.setInvalidInput}
                        noRedInput={props.noRedInput}
                        updateCurentTicket={props.updateCurentTicket}
                        load={props.load}
                        currentTicket={props.currentTicket}
                        from='erp'
                        module='erp'//à passer pour obligatoirement pour connaitre les options de son module 
                        style="col-7 position-relative"
                        searchInputClient={props.searchInputClient}
                    />
                </div>
                
            </div>
        )
    }else{
        return(
            <></>
        )
    }
}

export default MasterClientSelect;