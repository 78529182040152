import React, {useState,useEffect} from "react";
import { useTranslate } from 'react-redux-multilingual'
import {CustomerRewards,CardPerso} from "@gull";
import axios from 'axios'
import swal from "sweetalert2";

const useSearchClients = (clientId,tabKey) => {
    const t =useTranslate()
    const [loading,setLoading]=useState(false)
    const [clientFideliteInfo,setClientFideliteInfo]=useState(false) 

    useEffect(function () {
        (async function() {
            if(tabKey=='loyalty'){
                setLoading(true)
                const response = await axios.get(process.env.REACT_APP_API_URL+"api/erp/fidelite/get_fidelite_client/"+clientId
                ).then((response) => {
                    setLoading(false)
                    setClientFideliteInfo(response.data)
                    
                }).catch((error) => {
                    setLoading(false)
                    if(error=='end_session') return;
                    swal.fire(t('unknowError'), "", "error");
                })
            }
        })()
    }, [clientId,tabKey])

    return [loading,clientFideliteInfo]
}

const HistoFideliteClient = (props) => {
    const t =useTranslate()
    const [loading,clientFideliteInfo]=useSearchClients(props.clientId,props.tabKey)

    return(<>
        {(loading) ? 
            <span className="d-flex justify-content-center"><div className="spinner-border spinner-border-sm"></div></span> 
        : 
            <CardPerso>
                {!clientFideliteInfo && <div className="text-center fw-bold">{t('customerLoyalty')}</div> }
                {clientFideliteInfo &&
                    <>
                        <div className="text-center fw-bold mb-2"> 
                            <span className="fs-3">{t('accumulatedPoints')+' : '}</span>
                            <span className="fs-2">{(clientFideliteInfo) ? clientFideliteInfo.total_point_fidelite_client : ''}</span>
                        </div>
                        <CustomerRewards 
                            clientFideliteInfo={clientFideliteInfo}
                            posid={false}
                            from='histoErp'
                        />
                    </>
                }
            </CardPerso>
        }
        </>
    )
    
}

export default HistoFideliteClient;