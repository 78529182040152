import React,{useState,useEffect} from "react";
import { FormatedPrice } from "@gull";
import { useTranslate } from 'react-redux-multilingual'
import axios from 'axios'
import swal from "sweetalert2";
import Moment from 'react-moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExchangeAlt} from '@fortawesome/free-solid-svg-icons'

const GetSaveSale = (props) =>{
    const t = useTranslate();
    const [loading, setLoading]=useState(false)
    const idsLocalStorage=localStorage.getItem("pos").split(',')

    const getTicket = async (ticketId) =>{
        setLoading(true)
        const response = await axios.post(process.env.REACT_APP_API_URL+"api/pos/ticket/construction/"+props.posid+"/"+idsLocalStorage[1]+"/"+idsLocalStorage[0],{
            id:ticketId
        }).then((response) => {
            if(!response.data.valid){
                swal.fire(t('unknowError'), "", "error");
            }
            else{   
                let newData = [] 
                for(var i=0; i<props.savedList.length; i++){
                    if(props.savedList[i][0] != ticketId){
                        newData.push(props.savedList[i][0]+'/!'+props.savedList[i][1]+'/!'+props.savedList[i][2])
                    }
                }

                if(props.cookies.current_ticket){//si il y aun ticket en cours
                    props.saveTicket(ticketId)
                    props.setCookie("current_ticket",ticketId,{ path: "/", expires: props.expire_date })
                }else{
                    props.setCookie("current_ticket",ticketId,{ path: "/", expires: props.expire_date })
                    props.setCookie("save_ticket",newData.join(),{ path: "/", expires: props.expire_date })
                }
                props.setCurrentTicket(response.data.data)
                setLoading(false)
                props.closeModal()
            }
        }).catch((error) => {
            if(error=='end_session') return;
            swal.fire(t('unknowError'), "", "error");
        })
        setLoading(false)
    }

    return(<>
        {loading ? 
            <span className="spinner-border spinner-border-sm"></span> 
        :
            <FontAwesomeIcon className="fs-5 text-primary pointer" icon={faExchangeAlt} onClick={()=>getTicket(props.value[0])}/>}
        </>
    )

}

const SavedTicketModal = (props) =>{
    const t = useTranslate();
    const [savedList,setSavedList]=useState([])

    useEffect(function () {
        var listSaved = []
        if(props.cookies.save_ticket){
            let savedTickets = props.cookies.save_ticket;
            let cookieLecture = savedTickets.split(',')
    
            for(var i=0; i<cookieLecture.length; i++){
                listSaved.push(cookieLecture[i].split('/!'))
            }
        }

        setSavedList(listSaved)
    }, [props.cookies.save_ticket])

    return(
        <div>
            <table className="table">
                <thead>
                    <tr>
                        <th scope="col">Date</th>
                        <th className="col">Total</th>
                        <th className="col text-end">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {(savedList.length!=0) ?
                        savedList.map((value,ind)=>{
                            let style = (ind%2 == 0) ? 'stripeBg' : '';
                            return(
                                <tr
                                    key={ind} 
                                    className={style}
                                >
                                    <td>{<Moment format="DD/MM/YYYY hh:mm:ss">{value[1]}</Moment>}</td>
                                    <td>{<FormatedPrice langRegion={'fr-FR'} devise={props.devise} digit={props.digit} priceToFormat={value[2]} />}</td>
                                    <td className='text-end'>
                                        <GetSaveSale 
                                            value={value}
                                            posid={props.posid}
                                            cookies={props.cookies}
                                            saveTicket={props.saveTicket}
                                            setCookie={props.setCookie}
                                            expire_date={props.expire_date}
                                            setCurrentTicket={props.setCurrentTicket}
                                            closeModal={props.closeModal}
                                            savedList={savedList}
                                        />
                                    </td>
                                </tr>
                            )
                        })
                    :
                        <tr>
                            <td className='text-end border-0'>{t('noSalesSaved')}</td>
                        </tr>
                    }
                </tbody>
            </table>
        </div>
    )
}

export default SavedTicketModal;