import React, {useState,useRef,useEffect} from "react";
import {InpDataList} from '@gull'
import { useTranslate } from 'react-redux-multilingual'

const AttributeInventoryToStaff = (props) => {
    const t = useTranslate()
    const [inputStaff,setInputStaff]=useState('')
    const [datasStaff,setDatasStaff] = useState([])
    const [datas,setDatas] = useState([])
    const dataListDrop = useRef('')

    useEffect(function () {
        let initial = [];
        (props.section) && props.section.rh.forEach((value)=>{
            initial.push({
                id:value.id,
                name:value.name+' '+value.firstname
            })
        })
        setDatasStaff(initial)
        setDatas(initial)

    }, [props.section])


    const handleSearch = (e,declenchement) => {
        setInputStaff(e.target.value)
        if(e.target.value.length>=declenchement){
            let ajour = datasStaff.filter(value=>value.name.toLowerCase().includes(e.target.value.toLowerCase()));
            setDatas(ajour)
            dataListDrop.current.classList.remove("d-none");
        }
        else{
            dataListDrop.current.classList.add("d-none");
        }        
    }

    const handleCLick = (id,valeur) => {
        let allreadyIn = props.staffList.map(value=>value.id) //on vérifie si le staff n'a pas deja été attribué 
        if(!allreadyIn.includes(id)) props.setStaffList([...props.staffList,{id:id,name:valeur}])

        setInputStaff('')
        dataListDrop.current.classList.add("d-none");
    }

    return(
        <div className="col form-group">
            <label htmlFor="">{t('attribuerA')}</label>
            <InpDataList
                value={inputStaff}
                onClick={handleCLick}
                onChange={handleSearch}
                datas={datas}
                declenchement={2}
                listRef={dataListDrop}
                inputRef={props.searchInput}
                inputName="searchStaff"
            />
        </div>
    )
}

export default AttributeInventoryToStaff;
