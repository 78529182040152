import React, { useState,useContext, useEffect, useRef} from 'react';
import { useTranslate } from 'react-redux-multilingual'
import { useForm } from "react-hook-form";
import { IsAuthContext } from 'app/App';
import axios from 'axios';
import swal from "sweetalert2";

const GenerateToForm = (props) =>{
    const t = useTranslate();
    const { authParam } = useContext(IsAuthContext)
    const { register, formState: { errors }, handleSubmit, setValue, reset} = useForm();
    const [sectionId,setSectionId]=useState(false) 
    const [staff,setStaff]=useState([])
    const [loadingRh,setLoadingRh]=useState(false) 
    const [success,setSuccess]=useState(false) 
    const [validButton,setValidButton]=useState(false) 

    const btnRef = useRef('')

    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');

    useEffect(() => {
        //On renseigne la date du jour par défaut 
        setValue('expected',year+'-'+month+'-'+day)
    }, [])

    useEffect(() => {
        if(sectionId){
            setLoadingRh(true)
            const res = axios.get(process.env.REACT_APP_API_URL+"api/rh/get_all_of_section/"+sectionId
            ).then((response) => {
                setStaff(response.data.datas) 
                setLoadingRh(false)
            }).catch((error) => {
                if(error=='end_session') return;
                swal.fire(t('unknowError'), "", "error");
                setLoadingRh(false)
            })
        }
    }, [sectionId])

    const handleChange = (e,name) =>{
        setSectionId(e.target.value)
        setValue(name,e.target.value)
        setValue('rh','') //Si on change de commerce, il faut réinitialiser le rh au cas où l'utilisateur avait deja sélectionné un rh
    }

    const onSubmit = async (data) => {
        setValidButton(true)

        const callapi  = await axios.post(process.env.REACT_APP_API_URL+"api/erp/shipment/create_to/"+props.id, data
        ).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach((interm)=>{
                    let finalMessage=interm.split('/!');
                    if(finalMessage.length==2){
                        messageError+=t(finalMessage[0],{name:finalMessage[1]})+'<br/>'
                    }
                    else{
                        messageError+=t(interm)+'<br/>'
                    }
                })
                messageError=messageError.replace('undefined','');
                swal.fire(messageError, "", "error");
            }
            else{
                setSuccess(true)
            }
        }).catch((error) => {
            if(error=='end_session') return;
            let errorName = (error.response==undefined) ? 'unknowError' : (error.response.status==403) ? 'forbiddenGeneral' : 'unknowError'
            swal.fire(t(errorName), "", "error");
        })
        setValidButton(false)
        setTimeout(()=>{
            setSuccess(false);
            props.setShowModalTo(false)
        },1000)
    }
    
    return (<div>
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group">
                <label htmlFor="commerce de destination">{t('destination_section')+' :*'}</label>
                <select className="form-control" type="text" {...register('fsection', {required: true,onChange:(e)=>handleChange(e,'fsection')})}> 
                    <option value="">-- Select --</option>
                    {(authParam.sections) && authParam.sections.map((value)=>{
                        if(value.type!='ecommerce') return(<option key={value.id} value={value.id}>{value.name}</option>)
                    })}                                    
                </select>
                {errors.fsection?.type === 'required' && (
                <div className="text-danger mt-1 ml-2">
                    {t('fsectionRequired')}
                </div>
                )}
            </div>
            <div className="form-group">
                <label htmlFor="date de transfert">{t('transfert_le')+' :*'}</label>
                <input  type='date' className="form-control" {...register('expected', {required: true})}/>
                {errors.expected?.type === 'required' && (
                <div className="text-danger mt-1 ml-2">
                    {t('expectedRequired')}
                </div>
                )}
            </div>
            <div className="form-group">
                <label htmlFor="staff">{t('rh')+' :'}</label>
                {(loadingRh) ? 
                    <div className='form-control'>
                        <div className="spinner-border spinner-border-sm mt-2"></div>
                    </div>
                :
                    <select className="form-control" disabled={staff.length==0} type="text" {...register('rh')}>
                        <option value="">-- Select --</option>
                        {(staff.length!=0) && staff.map((value)=>
                            <option key={value.id} value={value.id}>{value.name+' '+value.firstname}</option>
                        )}                                    
                    </select>
                }
            </div>
            <div className="form-group">
                <label htmlFor="memo">Memo :</label>
                <textarea rows={5} className="form-control" {...register('memo')}/>
            </div>   
            {(success) ? <div className="alert alert-success mt-3 mb-3">{t('successOperation')}</div> : false}
            <button ref={btnRef} className='d-none'></button>
        </form>
        <button 
            disabled={validButton} 
            className="btn btn-primary mt-3"
            onClick={() => {
                swal
                .fire({
                    title: t('confirmGenerateTo')+" ?",
                    icon: "warning",
                    type: "question",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: t('btnYes'),
                    cancelButtonText: t('btnNo'),
                })
                .then((result) => {
                    if (result.value) {
                        if(btnRef.current)
                        btnRef.current.click()
                    }
                });
            }}>
            {validButton ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : t('btnValidate')}
        </button>
    </div>
    )
};

export default GenerateToForm;