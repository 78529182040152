import React,{useState,useEffect} from "react";
import { CardPerso,LineItemInventory,Loading,FiltreSearchBar} from '@gull';
import { useTranslate } from 'react-redux-multilingual'
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import axios from 'axios'
import swal from "sweetalert2";
import InpScanBinsAdmin from '../InpScanBinsAdmin'
import HeadOptionsInventory from '../HeadOptionsInventory'
import { Badge } from "react-bootstrap";

const useLoad = (id,rhid,zoneid) => {
    const t =useTranslate()
    const [loading,setLoading]=useState(true)
    const [itemsScannedList,setItemsScannedList]=useState({})

    const [binsList,setBinsList]=useState([])
    const [zoneWithBins,setZoneWithBins]=useState(false)
    const [listBinsAlreadyScanned,setListBinsAlreadyScanned]=useState([])
    const [relance,setRelance]=useState(Date.now())

    useEffect(function () {
        (async function() {
            const response = await axios.get(process.env.REACT_APP_API_URL+"api/erp/zone/"+zoneid
            ).then((response) => {
                setBinsList(response.data.bins)
                if(response.data.havebins==1){
                    //si la zone à des bins
                    setZoneWithBins(true)
                }else{
                    setZoneWithBins(false)
                }
            }).catch((error) => {
                if(error=='end_session') return;
                swal.fire(t('unknowError'), "", "error");
            })
        })()
    }, [])

    useEffect(function () {
        (async function() {
            const response = await axios.get(process.env.REACT_APP_API_URL+"api/rh/get_rh_inventaire_by_id/"+rhid+'/'+id
            ).then((response) => {
                //formatage des datas des articles pour afficher les cards
                let obj = {}
                response.data.datas.map(value=>
                    obj[value.article_inventaire.id]={article_name:value.article_inventaire.name,id:value.article_inventaire.id,reference:value.article_inventaire.reference,qte:0,lineId:value.id}
                )
                setItemsScannedList(obj)

                //ici on récupère toutes les bins qui ont deja été scannée 
                let arrData=[]
                response.data.datas.map(value=>
                    (value.inventaire_count.length!=0 && value.inventaire_count[0].bins!=null) && value.inventaire_count.map(item=>{
                        let binsNum = (item.bins.fifth!=null) ? item.bins.first+'-'+item.bins.second+'-'+item.bins.third+'-'+item.bins.fourth+'-'+item.bins.fifth : (item.bins.fourth!=null) ? item.bins.first+'-'+item.bins.second+'-'+item.bins.third+'-'+item.bins.fourth : (item.bins.third!=null) ? item.bins.first+'-'+item.bins.second+'-'+item.bins.third : (item.bins.second!=null) ? item.bins.first+'-'+item.bins.second : item.bins.first

                        //on vérifie que le tableau arrData contient pas deja la bins 
                        if(!arrData.includes(binsNum)) arrData.push(binsNum)
                    })
                )
                setListBinsAlreadyScanned(arrData)
                setLoading(false)
              
            }).catch((error) => {
                if(error=='end_session') return;
                let errorName = (error.response==undefined) ? 'unknowError' : (error.response.status==403) ? 'forbiddenGeneral' : 'unknowError'
                swal.fire(t(errorName), "", "error");
            })
            setLoading(false)
            
        })()
    }, [relance])

    return [loading,itemsScannedList,setItemsScannedList,binsList,zoneWithBins,setZoneWithBins,,listBinsAlreadyScanned,setRelance]
}

const CategoryInventoryScan = (props) => {
    const t =useTranslate()
    const { posid,id,zoneid,rhid} = useParams();
    const [loading,itemsScannedList,setItemsScannedList,binsList,zoneWithBins,setZoneWithBins,,listBinsAlreadyScanned,setRelance]=useLoad(id,rhid,zoneid)
    const [validButton,setValidButton]=useState(false)
    const [success,setSuccess]=useState(false)
    const history = useHistory();
    const [itemsScannedSearch,setItemsScannedSearch]=useState([])

    const [binsNumber, setBinsNumber]=useState('')
    const [currentBinsId,setCurrentBinsId]=useState(false)

    const selectBinsForInventory = async (e) =>{
        e.preventDefault();
        
        //Si l'inventaire par zone contient des bins, on vérifie si la bins scanné fait partie de l'inventaire attribué au rh
        if(binsList.length!=0){
            let binExist =binsList.find(value=>{
                let binsNum = (value.fifth!=null) ? value.first+'-'+value.second+'-'+value.third+'-'+value.fourth+'-'+value.fifth : (value.fourth!=null) ? value.first+'-'+value.second+'-'+value.third+'-'+value.fourth : (value.third!=null) ? value.first+'-'+value.second+'-'+value.third : (value.second!=null) ? value.first+'-'+value.second : value.first

                return binsNum == binsNumber
            })

            if(binExist){
                //si la bins fait partie de la liste
                //on vérifie si la bins a déjà eu un comptage 
                if(listBinsAlreadyScanned.length!=0 && listBinsAlreadyScanned.includes(binsNumber)){
                    //si il y a deja eu un comptage sur la bins
                    swal
                    .fire({
                        title: t('confirmSecondCount')+" ?",
                        icon: "warning",
                        type: "question",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: t('btnYes'),
                        cancelButtonText: t('btnNo'),
                    })
                    .then((result) => {
                        if (result.value) {
                            setCurrentBinsId(binExist.id)
                            setZoneWithBins(false)//on passe zoneWithBins à false pour pouvoir afficher le page de scan des articles
                        }else{
                            setZoneWithBins(true)
                        }
                    });
                }else{
                    setCurrentBinsId(binExist.id)
                    setZoneWithBins(false)//on passe zoneWithBins à false pour pouvoir afficher le page de scan des articles
                }
                
            }else{
                swal.fire(t('binsNotExistInInventory'), "", "error"); 
                setCurrentBinsId(false)
            }
        }

        setBinsNumber('')
    }
    
    const validateInventory = async () =>{
        setValidButton(true)

        let paramBins = (currentBinsId) ? '/'+currentBinsId : ''

        let data = {}  

        if(Object.keys(itemsScannedList).length!=0){
            let itemArr = []
            let qteArr = []
            Object.entries(itemsScannedList).map(([key,value])=>{
                itemArr.push(value.id)
                qteArr.push(value.qte)
            })

            data['article']=itemArr
            data['total']=qteArr
        }

        const response = await axios.post(process.env.REACT_APP_API_URL+"api/wms/inventaire_count/comptage_categorie/"+id+'/'+rhid+paramBins,data
        ).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach(interm=>messageError+=t(interm)+'. ')
                messageError=messageError.replace('undefined','');
                swal.fire(messageError, "", "error");

            }else{ 
                setItemsScannedList({})
                setValidButton(false)
                if(currentBinsId){
                    //si l'inventaire par zone contient des bins, apres avoir scanné le contenu de la bins 
                    //on passe zoneWithBins à true pour retourner au scan de la bins afin de continuer l'inventaire de la zone
                    setZoneWithBins(true)
                    setRelance(Date.now())
                    setSuccess(true) //si la zone contient des bins, on affiche un message de success apres validation et on reste sur la page pour continuer l'inventaire

                }else{//si la zone ne contient pas de bins, on renvoie sur la page de l'inventaire après validation
                    if(props.module=='pos'){
                        history.push("/pos/"+posid+"/inventory/categorie/"+id+'/'+zoneid)
                    }else{{
                        history.push("/erp/inventory/categorie/"+id+'/'+zoneid)
                    }}
                }
            }
        }).catch((error) => {
            if(error=='end_session') return;
            let errorName = (error.response==undefined) ? 'unknowError' : (error.response.status==403) ? 'forbiddenGeneral' : 'unknowError'
            swal.fire(t(errorName), "", "error");
        })
        setValidButton(false)
        setTimeout(()=>{
            setSuccess(false)
        },1000)
    }

    if(loading){
        return(<Loading></Loading>)
    }
    
    return (<div style={(props.module=='pos') ? {marginTop:'-30px'} : {marginTop:'0'}}>
        <CardPerso header={
            <HeadOptionsInventory 
                title='manualCounting' 
                btnValid={true} 
                btnReturn={true} 
                btnReturnHome={true}
                type='categorie' 
                id={id} 
                zoneid={zoneid} 
                posid={posid} 
                validateInventory={validateInventory}
                itemsScannedList={itemsScannedList}
                validButton={validButton}
                zoneWithBins={zoneWithBins}
                module={props.module}
            />}>
            <div className="row">        
                {(zoneWithBins) ? 
                    <div className="col-10 offset-1">
                        <InpScanBinsAdmin 
                            actionScan={selectBinsForInventory} 
                            binsNumber={binsNumber}
                            setBinsNumber={setBinsNumber}
                        />
                        {(listBinsAlreadyScanned.length!=0) ? 
                            listBinsAlreadyScanned.map((bins,ind)=>{
                                
                                return(
                                    <span key={ind}>
                                        <Badge bg="warning" text="dark" className="mb-1">{bins}</Badge>&nbsp;
                                    </span>
                                )
                            })
                        :
                            false
                        }
                        {(success) ? <div className="alert alert-success mt-3">{t('successOperation')}</div> : false}
                    </div>
                :
                    <>
                        <FiltreSearchBar dataToFiltre={itemsScannedList} setFilteredData={setItemsScannedSearch}/>
                        <div className="position-relative">
                            {(itemsScannedSearch.length!=0) ?
                                <div className='row mt-3 d-flex justify-content-center'>
                                    {itemsScannedSearch.map((item,ind)=>{
                                            return(
                                                <LineItemInventory
                                                    key={ind}
                                                    itemValue={item}
                                                    itemsList={itemsScannedList}
                                                    setItemsList={setItemsScannedList}
                                                    style='stripeBg stripePadding col-5 mr1 mb-1'
                                                />
                                            )
                                        })
                                    }
                                </div>
                            :
                                <div className='row mt-3 d-flex justify-content-center'>
                                    {(Object.keys(itemsScannedList).length!=0) ? 
                                        Object.entries(itemsScannedList).map(([key,value])=>{
                                            return(
                                                <LineItemInventory
                                                    key={key}
                                                    itemValue={value}
                                                    itemsList={itemsScannedList}
                                                    setItemsList={setItemsScannedList}
                                                    style='stripeBg stripePadding col-5 mr1 mb-1'
                                                />
                                            )
                                        })
                                    :
                                        false
                                    }
                                </div>
                            }
                        </div>
                    </>
                }
            </div>
        </CardPerso>
        </div>
    );
    

}

export default CategoryInventoryScan;
