import React, {useEffect} from 'react';
import { useTranslate } from 'react-redux-multilingual'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const CarouselImage = (props) =>{
    const t = useTranslate();

    let el = document.getElementsByClassName('carousel-slider')
   
    useEffect(function () {
        if (el.length > 0) {
            // Accédez à l'élément spécifique dans la collection
            el[0].style.maxHeight = '230px';
        }
    }, [el])

    let imgSplited = props.images.split(';')
    let imgList = imgSplited.filter(image=>image!='') // on split car dans la chaine de caractère qui est récupéré du back, un ; fini le string, du coup on se retrouve avec un chaine vide dans notre array ce qu'i donne une image dans notre carousel

    return (
        <Carousel showIndicators={false} swipeable={true} emulateTouch={true} dynamicHeight={true} showThumbs={true} thumbWidth={60}>
            {imgList.map((img,index)=>{
                return(
                    <div key={index} style={{maxHeight:'230px'}}>
                        <img src={img} style={{maxHeight:'230px', objectFit:'contain'}}/>
                    </div>
                )
            })}

        </Carousel> 
    )
};

export default CarouselImage;
