import React, { useState } from 'react';
import {SelectZoneOrBins} from '@gull'
import { useTranslate } from 'react-redux-multilingual'
import axios from 'axios'
import swal from "sweetalert2";

const StockTransferForm = (props) =>{
    const t = useTranslate();
    const [validButton,setValidButton] = useState(false)
    const [success,setSuccess] = useState(false)
    const [error,setError]=useState(false) 
    const [value,setValue]=useState({
        zone:'',
        bins:''
    }) 

    const callApiTransfer = async () =>{
        setValidButton(true)

        let obj = {
            type: "simple",
            description: "massMouvement",
        }

        if(props.from=='zone'){
            obj['origin_zone']=props.zoneOrigin
        }else{
            obj['origin_bins']=props.zoneOrigin
        }

        if(value.zone!=''){
            obj['destination_zone']=value.zone
        }else{
            obj['destination_bins']=value.bins
        }

        const response  = await axios.post(process.env.REACT_APP_API_URL+"api/wms/mouvment/move_mass_stock/"+props.sectionId, obj
        ).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach(interm=>messageError+=t(interm)+'. ')
                messageError=messageError.replace('undefined','');
                swal.fire(t(messageError), "", "error");
            }
            else {
                if(props.setWmsContenu) props.setWmsContenu([])//function utilisé dans le wms uniquement pour mettre à jour l'affichage
                setSuccess(true)
                props.closeModal()
            }
        }).catch((error) => {
            if(error=='end_session') return;
            let messageError
            if(error.response==undefined){
                messageError='unknowError'
            }else if(error.response.status==403){
                messageError='forbiddenGeneral'
            }else {
                messageError='unknowError'
            }
            swal.fire(t(messageError), "", "error");
        })
        setValue({
            zone:'',
            bins:''
        }) 
        setValidButton(false)
        setTimeout(()=>{
            setSuccess(false);
        },1000)
    }

    const submitTransfer = () =>{
        setSuccess(false)
        
        swal
        .fire({
            title: t('stockTransferConfirm')+" ?",
            icon: "warning",
            type: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: t('btnYes'),
            cancelButtonText: t('btnNo'),
        })
        .then((result) => {
            if (result.value) {
                callApiTransfer()
            }
        });
    }
    
    return (
        <div>
            <SelectZoneOrBins 
                actionSubmit={submitTransfer}
                value={value}
                setValue={setValue}
                section={props.section}
                validButton={validButton}
                success={success}
            />
        </div>
    )
};

export default StockTransferForm;