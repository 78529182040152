import React, { useState,useEffect} from "react";
import { useForm } from "react-hook-form";
import { useTranslate } from 'react-redux-multilingual'
import { CardPerso,ObjectifDetail,ObjectifSectionChart,RhGoalsDayChart} from "@gull";
import axios from 'axios'
import Form from 'react-bootstrap/Form';
import { getFirstDayOfWeek,getLastDayOfWeek,getDaysMonth } from 'app/getPeriod';
import swal from "sweetalert2";

const useLoad = (sectionid) => {
    const t =useTranslate()
    const [loading,setLoading] = useState(true)
    const [period,setPeriod] = useState(false)
    const [objectifId,setObjectifId] = useState(false)
    const [rhGoals,setRhGoals] = useState(false)
    const [noObjectif,setNoObjectif] = useState(false)
    const [real,setReal]=useState(false);
    const [periodError,setPeriodError] = useState(false)
    const [periodButton,setPeriodButton] = useState(false)

    const [checked,setChecked]=useState(false)
    const [relance,setRelance]=useState(Date.now())
    const [periodStart,setPeriodStart]=useState('')
    const [periodEnd,setPeriodEnd]=useState('')
    const [realRh,setRealRh]=useState(false);

    let beginPeriod
    let endPeriod

    if(!checked){
        let beginDay = (periodStart!='') ? periodStart : getFirstDayOfWeek(new Date(),'now');
        let endDay = (periodEnd!='') ? periodEnd : getLastDayOfWeek(new Date(),'now');
    
        let anneeBegin   = beginDay.getFullYear();
        let moisBegin    = beginDay.getMonth() +1;
        let jourBegin    = beginDay.getDate();
    
        let anneeEnd   = endDay.getFullYear();
        let moisEnd    = endDay.getMonth() +1;
        let jourEnd    = endDay.getDate();
    
        let moisFormatBegin = (moisBegin>9) ? moisBegin : '0'+moisBegin;
        let jourFormatBegin = (jourBegin>9) ? jourBegin : '0'+jourBegin;
    
        let moisFormatEnd = (moisEnd>9) ? moisEnd : '0'+moisEnd;
        let jourFormatEnd = (jourEnd>9) ? jourEnd : '0'+jourEnd;
    
        beginPeriod = anneeBegin+'-'+moisFormatBegin+'-'+jourFormatBegin
        endPeriod = anneeEnd+'-'+moisFormatEnd+'-'+jourFormatEnd

    }else{//Si on est en filtre personnalisé, on utilise les values du select de type date
        beginPeriod = periodStart
        endPeriod = periodEnd
    }

    useEffect(function () {
        (async function() {
            setLoading(true)
            setPeriodError(false)
            setNoObjectif(false)
            setPeriod(false)
            const response = await axios.get(process.env.REACT_APP_API_URL+"api/pos/objectif/period/"+sectionid+'/'+beginPeriod+'/'+endPeriod
            ).then((response) => {
                if(!response.data.valid){
                    let interms='';
                    var keys = Object.keys(response.data.errors);
                    for(var i = 0; i < keys.length; i++){
                        interms+=response.data.errors[keys[i]].join(',')+','
                    }
                    interms=interms.split(',')
                    let messageError='';
                    interms.forEach(interm=>messageError+=t(interm)+'. ')
                    messageError=messageError.replace('undefined','');
                    swal.fire(messageError, "", "error");
                }
                else{
                    setPeriod(response.data.period)
                    setObjectifId(response.data.objectifId)
                    setRhGoals(response.data.rhgoals)
                    if(!response.data.period){
                        setNoObjectif(true)
                    }
                    setReal(response.data.real)
                    if(response.data.real_rh) setRealRh(response.data.real_rh)
                    else setRealRh(false)
                    setLoading(false)
                }
            }).catch((error) => {
                if(error=='end_session') return;
                if(error.response==undefined){
                    setPeriodError(t('unknowError'))
                }
                else if(error.response.status==403){
                    setPeriodError(t('forbiddenGeneral'))
                }
                else {
                    setPeriodError(t('unknowError'))
                }
            })
            setLoading(false)
            setPeriodButton(false)
        })()
    }, [relance])

    return [loading,setPeriodStart,setPeriodEnd,period,setPeriod,objectifId,rhGoals,noObjectif,setNoObjectif,real,setRelance,periodError,setPeriodError,periodButton,setPeriodButton,checked,setChecked,realRh,setRealRh]
}

const ObjectifPeriod = (props) => {
    const t = useTranslate()
    const { register, formState: { errors }, handleSubmit, reset } = useForm();
    const [loading,setPeriodStart,setPeriodEnd,period,setPeriod,objectifId,rhGoals,noObjectif,setNoObjectif,real,setRelance,periodError,setPeriodError,periodButton,setPeriodButton,checked,setChecked,realRh,setRealRh]=useLoad(props.sectionid)
    
    const [elementModif,setElementModif]=useState({
        begin: 'day',
        end: false,
    })

    const periodSubmit = (data) => {
        setPeriodButton(true)
        setPeriodError(false)
        setNoObjectif(false)
        setPeriod(false)
        let end = (data.day=='manyDays') ? data.end : 0;

        setPeriodStart(data.begin)
        setPeriodEnd(end)
        setRelance(Date.now())
    }

    const togglePeriod = (e) => {
        if(e.target.value=='day'){
            setElementModif({
                begin: 'day',
                end: false,
            })
        }
        else{
            setElementModif({
                begin: 'de',
                end: 'toA',
            })
        }
    }

    const handleSwith = () =>{
        if(!checked){
            //Si on passe en filtre personnalisé, on vide les données 
            setChecked(true)
            setPeriod(false)
        }else{
            //Si on passe en filtre no personnalisé, on vide les périodes pour relancer le useEffect qui prendra comme période la semaine en cours 
            setChecked(false)
            setPeriodStart('')
            setPeriodEnd('')
            setRelance(Date.now())
        }
    }

    const handleChangePeriod = (e) =>{
        switch (e.target.value) {
            case 'currentWeek':
                setPeriodStart(getFirstDayOfWeek(new Date(),'now'))
                setPeriodEnd(getLastDayOfWeek(new Date(),'now'))
                setRelance(Date.now())
            break;
            case 'lastWeek':
                setPeriodStart(getFirstDayOfWeek(new Date(),'last'))
                setPeriodEnd(getLastDayOfWeek(new Date(),'last'))
                setRelance(Date.now())
            break;
            case 'nextWeek':
                setPeriodStart(getFirstDayOfWeek(new Date(),'next'))
                setPeriodEnd(getLastDayOfWeek(new Date(),'next'))
                setRelance(Date.now())
            break;
            case 'currentMonth':
                setPeriodStart(getDaysMonth('begin','now'))
                setPeriodEnd(getDaysMonth('end','now'))
                setRelance(Date.now())
            break;
            case 'lastMonth':
                setPeriodStart(getDaysMonth('begin','last'))
                setPeriodEnd(getDaysMonth('end','last'))
                setRelance(Date.now())
            break;
            case 'nextMonth':
                setPeriodStart(getDaysMonth('begin','next'))
                setPeriodEnd(getDaysMonth('end','next'))
                setRelance(Date.now())
            break;
        }
    }

    return (<>
        <CardPerso header={t('objectifs')+' '+t('shop')+' - '+t('period')}>
            <div className="row">
                {(!checked) ?
                    <div className="form-group col-md-6">
                        <select className="form-control" onChange={(e)=>handleChangePeriod(e)}>
                            <option value="currentWeek">{t('currentWeek')}</option>
                            <option value="lastWeek">{t('lastWeek')}</option>
                            <option value="nextWeek">{t('nextWeek')}</option>
                            <option value="currentMonth">{t('currentMonth')}</option>
                            <option value="lastMonth">{t('lastMonth')}</option>
                            <option value="nextMonth">{t('nextMonth')}</option>
                        </select>
                    </div>
                :
                    <form onSubmit={handleSubmit(periodSubmit)} className='col-md-6'>
                        <div className="form-group col">
                            <select className="form-control" {...register('day', {onChange:(e) => togglePeriod(e)})} >
                                <option value="day">{t('oneDay')}</option>
                                <option value="manyDays">{t('manyDays')}</option>
                            </select>
                        </div>
                        <div className="form-group row">
                            <div className="col">
                                <label htmlFor="begin">{t(elementModif.begin)}</label>
                                <input className="form-control" type="date"{...register('begin',{required: true})}/>
                                {errors.begin?.type === 'required' && (
                                <div className="text-danger col-12 mt-1 ml-2">
                                    {t('beginRequired')}
                                </div>
                                )}
                            </div>
                            {(elementModif.end) &&
                                <div className="col">
                                    <label htmlFor="end">{t(elementModif.end)}</label>
                                    <input className="form-control" type="date"{...register('end',{required: true})}/>
                                    {errors.end?.type === 'required' && (
                                    <div className="text-danger col-12 mt-1 ml-2">
                                        {t('endRequired')}
                                    </div>
                                    )}
                                </div>
                            }
                        </div>
                        {(periodError) ? <div className="alert alert-danger mt-3" dangerouslySetInnerHTML={{ __html: periodError }}></div> : false}
                        <button disabled={periodButton} className="btn btn-primary mt-3 mb-3">
                            {periodButton ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : t('btnValidate')}
                        </button>
                    </form>
                }
                <div className="col-6 d-flex justify-content-end">
                    <Form className="mb-4">
                        <Form.Check 
                            checked={checked}
                            type="switch"
                            id="custom-switch"
                            label={t('personalizedFilter')}
                            onChange={()=>handleSwith()}
                        />
                    </Form>
                </div>
            </div>
            {(noObjectif) &&
                <div className="alert alert-danger">{t('noObjectif')}</div>
            }
            {(period) && <>
                <hr className="offset-2 col-8 mt-2 mb-5" />
                <ObjectifDetail from={props.from} data={period} setObjectif={setPeriod} objectifId={objectifId}/>
            </>}
        </CardPerso>

        {(loading) ?
            <span className="d-flex justify-content-center"><div className="spinner-border spinner-border-md"></div></span>
        :
            (period && real) && <>
                <div className="row">
                    <div className="col-xl-3 col-md-6">
                        <CardPerso header={"CA TTC - "+t('shop')}>
                            <ObjectifSectionChart isFor="cattc" data={period} real={real} />
                        </CardPerso>
                    </div>
                    {(props.from=='erp') &&
                        <div className="col-xl-3 col-md-6">
                            <CardPerso header={"CA HT - "+t('shop')}>
                                <ObjectifSectionChart isFor="ca" data={period} real={real} />
                            </CardPerso>
                        </div>
                    }
                    <div className="col-xl-3 col-md-6">
                        <CardPerso header={"PM TTC - "+t('shop')}>
                            <ObjectifSectionChart isFor="pmttc" data={period} real={real} />
                        </CardPerso>
                    </div>
                    {(props.from=='erp') &&
                        <div className="col-xl-3 col-md-6">
                            <CardPerso header={"PM HT - "+t('shop')}>
                                <ObjectifSectionChart isFor="pm" data={period} real={real} />
                            </CardPerso>
                        </div>
                    }
                    <div className="col-xl-3 col-md-6">
                        <CardPerso header={"IV - "+t('shop')}>
                            <ObjectifSectionChart isFor="iv" data={period} real={real} />
                        </CardPerso>
                    </div>
                    {(period.entre!=0) ?
                        <div className="col-xl-3 col-md-6">
                            <CardPerso header={"TF - "+t('shop')}>
                                <ObjectifSectionChart isFor="tf" data={period} real={real} />
                            </CardPerso>
                        </div>
                        :
                        <div className="col-xl-3 col-md-6">
                            <CardPerso header={"TF - "+t('shop')}>
                                <div className="alert alert-danger">{t('needEntre')}</div>
                            </CardPerso>
                        </div>
                    }
                </div>
                {props.moduleParam.params.staff.active && (props.moduleParam.params.staff.active && !Array.isArray(props.moduleParam.params.staff.params) && props.moduleParam.params.staff.params.hasOwnProperty('objectifs') && props.moduleParam.params.staff.params.objectifs) ?
                    <RhGoalsDayChart from={props.from} isfor="period" data={period} realRh={realRh} sectionid={props.sectionid} section={props.section} restrictionGoals={props.restrictionGoals} setRestrictionGoals={props.setRestrictionGoals}/>
                :
                    false
                }
            </>
        }
    </>)
}

export default ObjectifPeriod;
