import React from "react";
import { useTranslate } from 'react-redux-multilingual'

const RefundOther = (props) =>{
    const t =useTranslate()

    const handleChange = (e) =>{
        props.setInputValue({...props.inputValue,inputNoteOther:e.target.value})
    }

    const handleSubmit = (e) =>{
        e.preventDefault();

        if(props.inputValue.inputNoteOther==''){
            props.setErrorPay(true)

        }else{
            props.setLoad(true)
            props.refundApi({
                id:props.cookies.current_ticket,
                remboursement_montant:+props.total,
                remboursement_mode:'autre',
                remboursement_note:props.inputValue.inputNoteOther,
            })
        }
    }

    return(
        <form onSubmit={(e)=>handleSubmit(e)}>
            <div className="row input-group mb-2">
                <div className="col">
                    <textarea autoComplete='off' className="form-control" cols="30" rows="5" value={props.inputValue.inputNoteOther} onChange={(e)=>handleChange(e)} placeholder={t('enterRefundMethod')}></textarea>
                </div>
            </div>
            <div className="row input-group mb-2">
                <div className="col">
                    <label htmlFor="amount">{t('amount')}</label>
                    <input disabled className='form-control'  value={props.total} type="text" />
                </div>
            </div>
            {(props.errorPay) ? <div className="alert alert-danger mb-3">{t('allFieldsRequired')}</div> : false}
            <button disabled={props.load} className="btn btn-primary mt-1">
                {props.load ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : t('add')}
            </button>
        </form>
    )
}

export default RefundOther;