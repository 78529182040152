import React, {useState, useEffect} from 'react';
import { useTranslate } from 'react-redux-multilingual'
import axios from 'axios';
import swal from "sweetalert2";
import {CardPerso,Loading} from '@gull'
import AssignablePromoList from './Comp/AssignablePromoList'
import AssignedPromoClient from './Comp/AssignedPromoClient';

const useLoad = (clientId,tabKey) => {
    const t =useTranslate()
    const [promoToAssign,setPromoToAssign]=useState([])
    const [promoAlreadyAssign,setPromoAlreadyAssign]=useState([])
    const [promoAlreadyAssignIds,setPromoAlreadyAssignIds]=useState([])
    const [loading,setLoading] = useState(true)
    const [load,setLoad]=useState({
        alreadyAssign:false,
    })

    useEffect(function () {
        (async function() {
            if(tabKey=='promoList'){
                const response = await axios.get(process.env.REACT_APP_API_URL+"api/client/get_promotions_client/"+clientId
                ).then((response) => {
                    if(!response.data.valid){
                        let interms='';
                        var keys = Object.keys(response.data.errors);
                        for(var i = 0; i < keys.length; i++){
                            interms+=response.data.errors[keys[i]].join(',')+','
                        }
                        interms=interms.split(',')
                        let messageError='';
                        interms.forEach(interm=>messageError+=t(interm)+'. ')
                        messageError=messageError.replace('undefined','');
                        swal.fire(messageError, "", "error");
                        setLoad({...load,alreadyAssign:true})
                    }
                    else{   
                        setPromoAlreadyAssign(response.data.data)
                        let arr = []
                        response.data.data.map(value=>{
                            arr.push(value.id)
                        })
                        setPromoAlreadyAssignIds(arr)
                        setLoad({...load,alreadyAssign:true})
                    }
                }).catch((error) => {
                    if(error=='end_session') return;
                    swal.fire(t('unknowError'), "", "error");
                    setLoad({...load,alreadyAssign:true})
                })
            }
        })()
    }, [clientId,tabKey])

    useEffect(function () {
        (async function() {
            if(tabKey=='promoList' && load.alreadyAssign){
                const response = await axios.post(process.env.REACT_APP_API_URL+"api/client/get_promotions_on_client",{}
                ).then((response) => {
                    if(!response.data.valid){
                        let interms='';
                        var keys = Object.keys(response.data.errors);
                        for(var i = 0; i < keys.length; i++){
                            interms+=response.data.errors[keys[i]].join(',')+','
                        }
                        interms=interms.split(',')
                        let messageError='';
                        interms.forEach(interm=>messageError+=t(interm)+'. ')
                        messageError=messageError.replace('undefined','');
                        swal.fire(messageError, "", "error");
                    }
                    else{  
                        let arrPromo = []
                        response.data.data.map(item=>{
                            if(!promoAlreadyAssignIds.includes(item.id)) arrPromo.push(item)
                        })
                        setPromoToAssign(arrPromo)
                    }
                }).catch((error) => {
                    if(error=='end_session') return;
                    swal.fire(t('unknowError'), "", "error");
                })
                setLoad({...load,alreadyAssign:false})
                setLoading(false)
            }
        })()
    }, [clientId,tabKey,load.alreadyAssign])

    return [promoToAssign,setPromoToAssign,promoAlreadyAssign,setPromoAlreadyAssign,loading,promoAlreadyAssignIds,setPromoAlreadyAssignIds]
}

const ListPromoClient = (props) => {
   const t = useTranslate();
   const [promoToAssign,setPromoToAssign,promoAlreadyAssign,setPromoAlreadyAssign,loading,promoAlreadyAssignIds,setPromoAlreadyAssignIds] =useLoad(props.clientId, props.tabKey)

   if(loading){
    return(
        <span className="d-flex justify-content-center"><div className="spinner-border spinner-border-sm"></div></span>
    )
   }

   return(
       <div className='row'>
            <div className="col-6 d-flex">
                <CardPerso header={t('assignablePromo')}>
                    <AssignablePromoList 
                        promoToAssign={promoToAssign}
                        setPromoToAssign={setPromoToAssign}
                        promoAlreadyAssign={promoAlreadyAssign}
                        setPromoAlreadyAssign={setPromoAlreadyAssign}
                        clientId={props.clientId}
                        promoAlreadyAssignIds={promoAlreadyAssignIds}
                        setPromoAlreadyAssignIds={setPromoAlreadyAssignIds}
                    />
                </CardPerso>
            </div>
            <div className="col-6 d-flex">
                <CardPerso header={t('assignedPromo')}>
                    <AssignedPromoClient
                        promoToAssign={promoToAssign}
                        setPromoToAssign={setPromoToAssign}
                        promoAlreadyAssign={promoAlreadyAssign}
                        setPromoAlreadyAssign={setPromoAlreadyAssign}
                        clientId={props.clientId}
                        promoAlreadyAssignIds={promoAlreadyAssignIds}
                        setPromoAlreadyAssignIds={setPromoAlreadyAssignIds}
                    />
                </CardPerso>
            </div>
       </div>
   )
}

export default ListPromoClient;